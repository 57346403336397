const { gameStepsUiTexts, gamePhasesUiTexts, generalUiTexts } = require('./../ui-texts');

const gameSteps = [
	/* PHASE 1 */
	{
		id: 'welcome', 
		index: 0, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.introduction, 
		subtitle: gameStepsUiTexts.welcome, 
		infoPopup: null,
		page: 'welcome'
	},
	{
		id: 'crew-select', 
		index: 1, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.crew, 
		subtitle: gameStepsUiTexts.crewSelection, 
		infoPopup: {
			title: generalUiTexts.crewInfo,
			contentId: 'crewInfoText',
			isScenarioSpecific: false,
			autoShow: true
		},
		page: 'crew-select'
	},
	{
		id: 'crew-feedback', 
		index: 2, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.crew, 
		subtitle: gameStepsUiTexts.crewSelection, 
		infoPopup: null,
		page: 'crew-feedback'
	},
	{
		id: 'crew-reflection', 
		index: 3, 
		phase: 1,
		sectionId: 2,
		title: gamePhasesUiTexts.crewSelection, 
		subtitle: gameStepsUiTexts.reflection, 
		infoPopup: null,
		page: 'crew-reflection'
	},
	{
		id: 'crew-reflection-result', 
		index: 4, 
		phase: 1,
		sectionId: 3,
		title: gamePhasesUiTexts.crewSelection, 
		subtitle: gameStepsUiTexts.results, 
		infoPopup: null,
		page: 'crew-reflection-result',
		nextBtnCondition: 'crewResultsIsOpen',
		backBtn: true
	},
	{
		id: 'crew-feedback-result',
		index: 5, 
		phase: 1,
		sectionId: 4,
		title: gamePhasesUiTexts.results, 
		subtitle: gameStepsUiTexts.crewSelection, 
		infoPopup: null,
		page: 'crew-feedback-result',
	},
	/* PHASE 2 */
	{
		id: 'check-in', 
		index: 6, 
		phase: 2,
		sectionId: 1,
		title: gamePhasesUiTexts.onTheGround, 
		subtitle: gameStepsUiTexts.checkIn, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: true
		},
		page: 'game-board'
	},
	{
		id: 'preflight', 
		index: 7, 
		phase: 2,
		sectionId: 1,
		title: gamePhasesUiTexts.onTheGround, 
		subtitle: gameStepsUiTexts.preflight, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'game-board'
	},
	{
		id: 'boarding', 
		index: 8, 
		phase: 2,
		sectionId: 1,
		title: gamePhasesUiTexts.onTheGround, 
		subtitle: gameStepsUiTexts.boarding, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'game-board'
	},
	{
		id: 'ground-reflection', 
		index: 9, 
		phase: 2,
		sectionId: 2,
		title: gamePhasesUiTexts.onTheGround, 
		subtitle: gameStepsUiTexts.reflection, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'ground-reflection',
	},
	{
		id: 'ground-reflection-result', 
		index: 10, 
		phase: 2,
		sectionId: 3,
		title: gamePhasesUiTexts.onTheGround, 
		subtitle: gameStepsUiTexts.reflection, 
		page: 'ground-reflection-result',
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		nextBtnCondition: 'groundResultsIsOpen',
		backBtn: true
	},
	{
		id: 'ground-result',
		index: 11, 
		phase: 2,
		sectionId: 4,
		title: gamePhasesUiTexts.results, 
		subtitle: gamePhasesUiTexts.onTheGround, 
		infoPopup: null,
		page: 'ground-result',
	},
	/* PHASE 3 */
	{
		id: 'flight1', 
		index: 12, 
		phase: 3,
		sectionId: 1,
		title: gamePhasesUiTexts.airborne, 
		subtitle: gameStepsUiTexts.cruise, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'game-board' 
	},
	{
		id: 'flight2', 
		index: 13, 
		phase: 3,
		sectionId: 1,
		title: gamePhasesUiTexts.airborne, 
		subtitle: gameStepsUiTexts.cruise, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'game-board' 
	},
	{
		id: 'flight3', 
		index: 14, 
		phase: 3,
		sectionId: 1,
		title: gamePhasesUiTexts.airborne, 
		subtitleIsScenarioSpecific: true,
		subtitle: {
			'pmi-lhr': gameStepsUiTexts.pilotIncapacitation,
			'bcn-cph': gameStepsUiTexts.turbulence,
			'spu-cph': gameStepsUiTexts.warnings,
			'mun-lpa': gameStepsUiTexts.fire,
			'oka-hnd': gameStepsUiTexts.turbulence,
		},
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'game-board'
	},
	/* PHASE 4 */
	{
		id: 'decision-making',
		index: 15,
		phase: 4,
		sectionId: 1,
		title: gamePhasesUiTexts.decisionMaking,
		subtitle: gameStepsUiTexts.input,
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'decision-making'
	},
	{
		id: 'decision-making-result',
		index: 16,
		phase: 4,
		sectionId: 1,
		title: gamePhasesUiTexts.decisionMaking,
		subtitle: gameStepsUiTexts.results,
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'decision-making-result',
		backBtn: true
	},
	/* PHASE 5 */
	{
		id: 'communication',
		index: 17,
		phase: 5,
		sectionId: 1,
		title: gamePhasesUiTexts.communication,
		subtitle: gameStepsUiTexts.input,
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'communication'
	},
	{
		id: 'communication-result',
		index: 18,
		phase: 5,
		sectionId: 1,
		title: gamePhasesUiTexts.communication,
		subtitle: gameStepsUiTexts.results,
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'communication-result',
		backBtn: true
	},
	/* PHASE 6 */
	{
		id: 'final-reflection', 
		index: 19, 
		phase: 6,
		sectionId: 1,
		title: gamePhasesUiTexts.finalReflection, 
		subtitle: gameStepsUiTexts.reflection, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'final-reflection'
	},
	{
		id: 'final-reflection-result', 
		index: 20, 
		phase: 6,
		sectionId: 1,
		title: gamePhasesUiTexts.finalReflection, 
		subtitle: gameStepsUiTexts.results, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'final-reflection-result',
		backBtn: true
	},
	/* PHASE 7 */
	{
		id: 'flights-result',
		index: 21, 
		phase: 7,
		sectionId: 1,
		title: gamePhasesUiTexts.results, 
		subtitle: gameStepsUiTexts.flights, 
		infoPopup: {
			title: generalUiTexts.preflightBriefing,
			contentId: 'preBriefing',
			isScenarioSpecific: true,
			autoShow: false
		},
		showMedal: true,
		page: 'flights-result'
	},
	/* PHASE 8 */
	{
		id: 'gameover', 
		index: 22, 
		phase: 8,
		sectionId: 1,
		title: gamePhasesUiTexts.gameover, 
		infoPopup: null,
		page: 'gameover'
	}
];

module.exports = {gameSteps};