/* eslint-disable max-len */
/* 
Stress / Pressure / Fatigue
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 

Distraction / Complacency / Lack of awareness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},

Lack of communication / teamwork / assertiveness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},

Lack of resources, lack of knowledge, norms
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
*/


/*
Lack of Communication 
{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},

Distraction 
{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},

Lack of Resources 
{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},

Stress 
{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},

Complacency 
{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},

Lack of Teamwork 
{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},

Pressure 
{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},

Lack of Awareness 
{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},

Lack of Knowledge 
{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},

Fatigue 
{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},

Lack of Assertiveness 
{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},

Norms 
{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},

*/

/*
{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
*/

let eventCardsData = {
	/* Day 1 */
	'day-1': [
		{
			id: 5,
			title: {
				en: 'Missing tools',
				de: 'Fehlendes Werkzeug'
			},
			text: {
				en: 'When receiving your company tools at the first day at your new workplace, you notice that 3 tools are missing.',
				de: 'Als Sie am ersten Tag an Ihrem neuen Arbeitsplatz Ihr Firmenwerkzeug in Empfang nehmen, stellen Sie fest, dass 3 Werkzeuge fehlen.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You inform the supervisor and order the tools missing.',
						de: 'Sie informieren den Vorgesetzten und bestellen die fehlenden Werkzeuge.'
					},
					consequences: {
						text: {
							en: 'Proper tool control and supervisor feedback is essential.',
							de: 'Eine ordnungsgemäße Werkzeugkontrolle und die Rückmeldung durch den Vorgesetzten sind entscheidend.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You block the empty slots in the toolbox cut-outs so it is clear that the tool is not supposed to being there.',
						de: 'Sie blockieren die leeren Steckplätze in den Werkzeugkastenausschnitten, damit klar ist, dass das Werkzeug dort nicht vorhanden ist.'
					},
					consequences: {
						text: {
							en: 'Tool control can take place but missing the tool can cause you to make shortcuts later.',
							de: 'Werkzeugkontrollen können so stattfinden. Aber das Werkzeug nicht zu haben, kann Sie später dazu verleiten, Abkürzungen zu nehmen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},	
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']}
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do nothing about this until you have the need to use those particular tools.',
						de: 'Sie ignorieren das, bis Sie genau diese Werkzeuge verwenden müssen.'
					},
					consequences: {
						text: {
							en: 'The tool box is not complete and difficult to count. Ordering the tool when needed may cause delays.',
							de: 'Der Werkzeugkasten ist nicht vollständig und schwer zu kontrollieren. Bei der Bestellung des benötigten Werkzeugs kann es zu Verzögerungen kommen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']}
						],
					},
				},
			]
		},
		{
			id: 6,
			title: {
				en: 'A Near-miss',
				de: 'Ein Beinahe-Zusammenstoß'
			},
			text: {
				en: 'You have been working with a senior colleague, when a near-miss with potential serious outcome happened. This was clearly a reportable incident, but your colleague asks you to keep this between him and you. What do you do?',
				de: 'Sie haben mit einem älteren Kollegen zusammengearbeitet, als es beinahe zu einem Unfall mit möglicherweise schwerwiegenden Folgen kam. Es handelte sich eindeutig um einen meldepflichtigen Vorfall, aber Ihr Kollege bittet Sie, dies für sich zu behalten. Was tun Sie?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Report it anyway.',
						de: 'Sie melden es trotzdem.'
					},
					consequences: {
						text: {
							en: 'A report about this may prevent similar incidents in the future.',
							de: 'Ein Bericht kann ähnliche Vorfälle in Zukunft verhindern.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Since the incident had no negative outcome you respect the wish of your colleague and tell nobody about it.',
						de: 'Da der Vorfall keine negativen Folgen hatte, respektieren Sie den Wunsch Ihres Kollegen und erstellen keinen Bericht darüber.'
					},
					consequences: {
						text: {
							en: 'Despite no negative outcome there has still been an increased risk which should be investigated.',
							de: 'Obwohl keine negativen Folgen aufgetreten sind, besteht dennoch ein erhöhtes Risiko, das untersucht werden sollte.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Tell you colleague that you think it should be reported, but since he is the senior person, you will leave it up to him.',
						de: 'Geben Sie Ihrem Kollegen zu verstehen, dass Sie der Meinung sind, dass es gemeldet werden sollte. Aber da er der Erfahrenere ist, werden Sie ihm die Entscheidung überlassen.'
					},
					consequences: {
						text: {
							en: 'Down-playing the risk and possible outcome may cause him to be reluctant to report this.',
							de: 'Das Herunterspielen des Risikos und der potenziellen Folgen kann dazu führen, dass er sich nicht traut, den Vorfall zu melden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 7,
			title: {
				en: 'The groundskeeper',
				de: 'Der Landschaftspfleger'
			},
			text: {
				en: 'The groundskeeper asks you to act as guide as he attempts to reverse his pickup truck and trailer into the space between two buildings. What do you do?',
				de: 'Der Landschaftspfleger bittet Sie, als Einweiser zu agieren, während er seinen Pickup mit Anhänger zwischen zwei Gebäuden rückwärts einparken möchte. Was müssen Sie tun?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You refuse as this is not a part of your job description.',
						de: 'Sie lehnen ab, da dies nicht zu Ihrem Zuständigkeitsbereich gehört.'
					},
					consequences: {
						text: {
							en: 'Your rejection may increase the risk of an accident if he continues on his own.',
							de: 'Ihre Entscheidung kann das Risiko eines Unfalls erhöhen, wenn er alleine weiterfährt.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You accept and start giving directions.',
						de: 'Sie akzeptieren und beginnen, Anweisungen zu geben.'
					},
					consequences: {
						text: {
							en: 'Working with an unfamiliar person increases the need for good, clear, communication in order to avoid misunderstandings.',
							de: 'Die Arbeit mit einer unbekannten Person erhöht die Notwendigkeit einer guten, klaren Kommunikation, um Missverständnisse zu vermeiden.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},

						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You tell the groundskeeper, that if he feels unsure of how to reverse with a trailer, it is better to unhook it and move it manually which you will be happy to help with.',
						de: 'Sie sagen dem Landschaftspfleger, dass es besser wäre, den Anhänger abzukoppeln und von Hand zu bewegen, wenn er nicht weiß, wie er rückwärts fahren soll, und dass Sie ihm dabei gerne helfen werden.'
					},
					consequences: {
						text: {
							en: 'When performing tasks outside your normal scope ask if your skills may be better used elsewhere.',
							de: 'Wenn Sie Aufgaben übernehmen, die nicht zu Ihrem normalen Aufgabenbereich gehören, fragen Sie sich, ob Ihre Fähigkeiten nicht anderweitig besser eingesetzt werden könnten.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},

						],
					},
				},
			]
		},
		{
			id: 8,
			title: {
				en: 'Borrowing a tool',
				de: 'Ausleihen eines Werkzeugs'
			},
			text: {
				en: 'You notice a senior manager borrowing a tool from a tool board in the workshop. He deposits no tool mark. What do you do?',
				de: 'Sie bemerken, dass ein leitender Angestellter ein Werkzeug von einer Werkzeugwand der Werkstatt ausleiht. Er hinterlegt keine Werkzeugmarke. Was tun Sie?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Nothing. He is the manager.',
						de: 'Nichts. Er ist der Manager.'
					},
					consequences: {
						text: {
							en: 'Safety protocols should be followed by everybody.',
							de: 'Sicherheitsprozesse sollten von allen befolgt werden.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Make a mental note of who borrowed the tool.',
						de: 'Sie merken sich, wer das Werkzeug ausgeliehen hat.'
					},
					consequences: {
						text: {
							en: 'Managing this informally may well lead to things being forgotten.',
							de: 'Ein solch lockerer Umgang kann dazu führen, es zu vergessen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Confront the manager and brief him about the requirements for tool control (no shared tool removed without depositing tool mark)',
						de: 'Sie konfrontieren den Manager und informieren ihn über die Anforderungen an die Werkzeugkontrolle (kein gemeinsam genutztes Werkzeug darf entfernt werden, ohne eine Werkzeugmarke zu hinterlassen).'
					},
					consequences: {
						text: {
							en: 'Assertive action. Be sure to use the proper means of communication.',
							de: 'Durchsetzungsfähiges Auftreten. Achten Sie darauf, dass Sie eine angemessene Kommunikation verwenden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		}
	],
	/* Day 2 */
	'day-2': [
		{
			id: 10,
			title: {
				en: 'Security patrol',
				de: 'Sicherheitspatrouille'
			},
			text: {
				en: 'You have noticed that the airport security staff on their patrols regularly drives very close to, and around, the company aircrafts. What do you do?',
				de: 'Ihnen ist aufgefallen, dass das Sicherheitspersonal des Flughafens während seiner Kontrollfahrten regelmäßig sehr nahe an den Flugzeugen des Unternehmens vorbeifährt. Was tun Sie?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'The next time you see this unsafe action you stop the patrol, and let them know, that their behaviour is creating hazards.',
						de: 'Wenn Sie das nächste Mal eine derartige unsichere Vorgehensweise beobachten, halten Sie die Patrouille an und weisen Sie sie darauf hin, dass ihr Verhalten eine Gefahr darstellt.'
					},
					consequences: {
						text: {
							en: 'Assertive action but the underlying cause may go unaddressed.',
							de: 'Trotz durchsetzungsfähiger Maßnahmen kann die zugrunde liegende Ursache unbehandelt bleiben.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'time', value: 1},
						
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Ignore it. The airport has a liability insurance so there is no reason to worry about damages.',
						de: 'Sie ignorieren es. Der Flughafen hat eine Haftpflichtversicherung, sodass Sie sich keine Sorgen um eventuelle Schäden machen müssen.'
					},
					consequences: {
						text: {
							en: 'Damages may go undetected and extensive downtime and repairs should be avoided in any case.',
							de: 'Schäden können unentdeckt bleiben und umfangreiche Ausfallzeiten und Reparaturen sollten in jedem Fall vermieden werden.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Fill in a report about what you have observed.',
						de: 'Sie füllen einen Bericht über Ihre Beobachtungen aus.'
					},
					consequences: {
						text: {
							en: 'A formalised response may help direct resources to a fix of the root cause.',
							de: 'Eine formelle Vorgehensweise kann helfen, die Aufmerksamkeit auf die Beseitigung der Grundursache zu lenken.'
						},
						effects: [
							{type: 'time', value: 1},		
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 11,
			title: {
				en: 'An unfamiliar task',
				de: 'Eine ungewohnte Aufgabe'
			},
			text: {
				en: 'The workshop is temporarily short on manpower. You are asked to perform an unfamiliar task which the manager himself will release afterwards. He is not constantly around and you run into problems trying to understand the maintenance manual. How do you proceed?',
				de: 'In der Werkstatt herrscht vorübergehend Personalmangel. Sie werden gebeten, eine für Sie unbekannte Aufgabe zu übernehmen, die der Manager selbst anschließend freigeben wird. Er ist nicht ständig anwesend und Sie haben Probleme, das Wartungshandbuch zu verstehen. Wie gehen Sie vor?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Stop and try to locate the manager.',
						de: 'Sie hören auf zu arbeiten und versuchen, den Manager ausfindig zu machen.'
					},
					consequences: {
						text: {
							en: 'While taking longer this approach may prevent confusion and errors.',
							de: 'Dieser Weg dauert zwar länger, kann aber Verwirrung und Fehler vermeiden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Carry on using your best judgement about what to do.',
						de: 'Sie machen weiter und entscheiden nach bestem Wissen, was zu tun ist.'
					},
					consequences: {
						text: {
							en: 'With an unfamiliar task, the risk of making an error is greatly increased.',
							de: 'Bei einer unbekannten Aufgabe ist das Risiko, einen Fehler zu machen, deutlich höher.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Reverse back on the already performed steps and close up. When seeing the manager you inform him that you could not do the task after all.',
						de: 'Sie führen die bereits durchgeführten Schritten rückwärts aus und verschließen den Bereich. Wenn Sie den Manager sehen, teilen Sie ihm mit, dass Sie die Aufgabe doch nicht erledigen konnten.'
					},
					consequences: {
						text: {
							en: 'This action could cause confusion and you may not have the full overview of the implications caused by your action.',
							de: 'Diese Maßnahme könnte zu Missverständnissen führen, weshalb Sie möglicherweise nicht den vollen Überblick über die Auswirkungen Ihrer Maßnahme behalten.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 12,
			title: {
				en: 'The fix',
				de: 'Die Lösung'
			},
			text: {
				en: 'During lunch break you overhear two colleagues talking about how they "fixed" a problem using an unapproved procedure. How du you react?',
				de: 'In der Mittagspause hören Sie, wie sich zwei Kollegen darüber unterhalten, wie sie ein Problem mit einem nicht genehmigten Verfahren "gelöst" haben. Wie reagieren Sie?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Confront them and try to make them see the potential problems their actions could create.',
						de: 'Konfrontieren Sie die beiden und versuchen Sie, ihnen die potenziellen Probleme vor Augen zu führen, die ihr Vorgehen verursachen könnte.'
					},
					consequences: {
						text: {
							en: 'Assertive action but the norms and culture causing this may go unaddressed.',
							de: 'Sie greifen zwar durch, aber die Normen und die Kultur, die dieses Verhalten verusacht haben, bleiben bestehen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Immediately inform the Safety Manager so action can be taken.',
						de: 'Sie informieren sofort den Safety Manager, damit Maßnahmen ergriffen werden können.'
					},
					consequences: {
						text: {
							en: 'Assertive action. Informing the organisation could help directing focus to a potential problem.',
							de: 'Sie zeigen Verantwortung. Eine Information an die Verantwortliche Stelle könnte helfen, auf ein mögliches Problem aufmerksam zu machen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]}, 
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do nothing - you should not question your colleagues actions.',
						de: 'Sie tun nichts - Sie sollten die Handlungen Ihrer Kollegen nicht in Frage stellen.'
					},
					consequences: {
						text: {
							en: 'Lack of assertiveness may cause a bad safety-culture to develop.',
							de: 'Mangelndes Durchsetzungsvermögen kann dazu führen, dass sich eine schlechte Sicherheitskultur entwickelt.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]}, 
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 13,
			title: {
				en: 'Safety investigation',
				de: 'Sicherheitsuntersuchung'
			},
			text: {
				en: 'There has been an incident and the Safety Manager is putting a 3-person team together to collect and analyse the facts behind it. You are invited to be a member of this team. What do you do?',
				de: 'Es hat einen Zwischenfall gegeben und der Safety Manager stellt ein dreiköpfiges Team zusammen, um die Fakten zu sammeln und zu analysieren. Sie werden eingeladen, diesem Team beizutreten. Was tun Sie?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Decline. This will most likely mean trouble with both colleagues and management.',
						de: 'Ablehnen. Das wird höchstwahrscheinlich Ärger mit den Kollegen und dem Management bedeuten.'
					},
					consequences: {
						text: {
							en: 'This could happen in a blame-culture. But an unbiased investigation is key to preventing future occurrences',
							de: 'Das kann in einer "Schuldzuweisungskultur" passieren. Eine unvoreingenommene Untersuchung ist jedoch der Schlüssel zur Vermeidung künftiger Vorfälle.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Accept this opportunity to support the safety work in the company.',
						de: 'Sie nehmen diese Gelegenheit wahr, um die Sicherheitsmaßnahmen in Ihrem Unternehmen zu verbessern.'
					},
					consequences: {
						text: {
							en: 'The input and point of view of hands-on staff may speed up the investigation process.',
							de: 'Die Meinung von Mitarbeitern aus der Praxis kann den Untersuchungsprozess beschleunigen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Decline to officially participate, but offer to informally try to gather information which you might come across.',
						de: 'Sie lehnen eine offizielle Beteiligung ab, bieten aber an, unter der Hand Informationen zu sammeln.'
					},
					consequences: {
						text: {
							en: 'Within a just-culture, transparency is essential in a fair investigation',
							de: 'In einer gerechten Fehlerkultur ist Offenheit eine wesentliche Voraussetzung für eine faire Untersuchung.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		}
	],
	/* Day 3 */
	'day-3': [
		{
			id: 20,
			title: {
				en: 'A new colleague',
				de: 'Ein neuer Kollege'
			},
			text: {
				en: 'It is the first day for a new colleague. You are asked by the supervisor to show him around and introduce him to the rest of the team. How do you react?',
				de: 'Es ist der erste Tag für einen neuen Kollegen. Der Vorgesetzte bittet Sie, ihn herumzuführen und ihm den Rest des Teams vorzustellen. Wie reagieren Sie?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Accept.',
						de: 'Sie stimmen zu.'
					},
					consequences: {
						text: {
							en: 'Some operational distractions are acceptable if handled correctly. It is a chance to get to know the new team member.',
							de: 'Manche betriebliche Ablenkungen sind vertretbar, wenn sie richtig gehandhabt werden. Es ist eine Chance, das neue Teammitglied kennen zu lernen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Decline - this should be the job of the supervisor.',
						de: 'Sie lehnen ab - dies sollte die Aufgabe des Vorgesetzten sein.'
					},
					consequences: {
						text: {
							en: 'Distraction is avoided but may send a message to others that you are not a team player.',
							de: 'Ablenkung wird dadurch vermieden, kann aber anderen signalisieren, dass Sie kein Teamplayer sind.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Reluctantly accept and do this quickly, so you can get back to your tasks at hand again.',
						de: 'Sie akzeptieren widerwillig und tun dies schnell, damit Sie sich wieder Ihren eigentlichen Aufgaben zuwenden können.'
					},
					consequences: {
						text: {
							en: 'Multi tasking poses a risk of doing both jobs poorly.',
							de: 'Multi-Tasking birgt das Risiko, beide Aufgaben nicht richtig zu erledigen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 21,
			title: {
				en: 'Unreported damage',
				de: 'Ungemeldete Schäden'
			},
			text: {
				en: 'There has been discovered damage to a parked aircraft - but no one has admitted or reported this. The manager now asks the team who the responsible is. You witnessed the incident. What do you do?',
				de: 'Es wurde ein Schaden an einem geparkten Flugzeug entdeckt - aber niemand hat dies zugegeben oder gemeldet. Der Manager fragt nun das Team, wer der Verantwortliche ist. Sie haben den Vorfall beobachtet. Was tun Sie?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Say nothing at the moment but later verbally inform the manager.',
						de: 'Sie sagen im Moment nichts, informieren aber später mündlich den Manager.'
					},
					consequences: {
						text: {
							en: 'Verbal information may be subject to interpertation and key elements forgotten.',
							de: 'Mündliche Informationen können falsch interpretiert und dabei wichtige Informationen vergessen werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
				
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You file a safety report with the circumstances as you know them, but makes sure the involved persons cannot be identified.',
						de: 'Sie erstatten einen Sicherheitsbericht mit den Ihnen bekannten Umständen, stellen aber sicher, dass die beteiligten Personen nicht identifiziert werden können.'
					},
					consequences: {
						text: {
							en: 'This will provide management with more to work with - but essential information may have been omitted in order to preserve anonymity',
							de: 'Dies gibt dem Management mehr Anhaltspunkte, aber wesentliche Informationen könnten zum Schutz der Anonymität weggelassen worden sein.'
						},
						effects: [
							{type: 'time', value: 1},
					
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do nothing. This must be up to the involved persons to react to.',
						de: 'Sie tun nichts. Es ist Sache der Beteiligten, darauf zu reagieren.'
					},
					consequences: {
						text: {
							en: 'The involved persons may be reluctant to report this - so a learning opportunity is missed.',
							de: 'Die betroffenen Personen zögern möglicherweise, dies zu melden - und alle verpassen so eine Lernmöglichkeit.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 22,
			title: {
				en: 'The rude manager',
				de: 'Der unhöfliche Vorgesetzte'
			},
			text: {
				en: 'You overhear a conversation between a pilot and your manager. The manager is being rude and using harsh language. Afterwards you hear the pilot saying that he ought to report this. What do you do?',
				de: 'Der Vorgesetzte ist unhöflich und benutzt eine grobe Ausdrucksweise. Anschließend hören Sie den Piloten sagen, dass er dies melden sollte. Was tun Sie nun?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Definitely stay out of this. Interfering will only cause problems.',
						de: 'Halten Sie sich auf jeden Fall aus der Sache heraus. Sich einzumischen, führt nur zu Problemen.'
					},
					consequences: {
						text: {
							en: 'Lack of assertiveness may cause a bad safety-culture to develop.',
							de: 'Mangelndes Engagement kann dazu führen, dass sich eine schlechte Sicherheitskultur entwickelt.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Tell the pilot that he can put you down in the report as a witness.',
						de: 'Sie sagen dem Piloten, dass er Sie als Zeuge in den Bericht aufnehmen kann.'
					},
					consequences: {
						text: {
							en: 'Assertive action but still leaves it to the pilot to actually file a report.',
							de: 'Mit diesem Vorgehen greifen Sie zwar ein, überlassen es aber immer noch dem Piloten, einen Bericht zu verfassen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Tell the manager that you think the pilot might write a report on this.',
						de: 'Sie sagen dem Manager, dass Sie denken, dass der Pilot einen Bericht darüber schreiben könnte.'
					},
					consequences: {
						text: {
							en: 'While this may cause the manager to think about his actions, it may also cause him to retalliate',
							de: 'Dies kann den Manager zwar zum Nachdenken anregen, gleichzeitig aber auch dazu führen, dass er sich zurückzieht.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 23,
			title: {
				en: 'Foreign object',
				de: 'Fremdgegenstand'
			},
			text: {
				en: 'You see some of the office staff enjoying the sun outside the hangar during their lunch break. After they leave you can see that a newspaper has been left behind. What do you do?',
				de: 'Sie sehen einige Mitarbeiter des Büros, die während ihrer Mittagspause die Sonne vor dem Hangar genießen. Nachdem sie gegangen sind, sehen Sie, dass sie eine Zeitung zurückgelassen haben. Was tun Sie nun?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Pick up the newspaper to prevent Foreign Object Damage to any aircrafts. Then you file a safety report about this.',
						de: 'Sie heben die Zeitung auf, um Schäden durch Fremdgegenstände an Flugzeugen zu verhindern. Dann erstatten Sie einen Sicherheitsbericht darüber.'
					},
					consequences: {
						text: {
							en: 'Assertive action both preventing damage and pointing out a problem to the organisation.',
							de: 'Sie greifen durchsetzungsstark ein, um Schäden zu verhindern und die Firma auf ein bestehendes Risiko aufmerksam zu machen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds:  [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},

						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Do nothing.',
						de: 'Sie tun nichts.'
					},
					consequences: {
						text: {
							en: 'Complacency does nothing to support the beleif that safety is everybodies responsibility.',
							de: 'Gleichgültigkeit fördert nicht die Tatsache, dass jeder für die Sicherheit mitverantwortlich ist.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},,
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Confront the office staff, inform them about the rules for Foreign Object Damage prevention and ask them to go back and remove the newspaper.',
						de: 'Sie konfrontieren das Büropersonal, informieren es über die Vorschriften zur Vermeidung von Schäden durch Fremdgegenstände und bitten sie, zurückzugehen und die Zeitung zu entfernen.'
					},
					consequences: {
						text: {
							en: 'While assertive this methode may cause rejection if not communicated properly.',
							de: 'Auch wenn diese Methode sehr effektiv ist, kann sie zu Unmut führen, wenn sie nicht richtig kommuniziert wird.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		}
	],
	/* Day 4 */
	'day-4': [
		{
			id: 25,
			title: {
				en: 'The drunk colleague',
				de: 'Der betrunkene Kollege'
			},
			text: {
				en: 'One of your colleagues turns up for the morning shift visually drunk after a long night out. What do you do?',
				de: 'Einer Ihrer Kollegen kommt nach einer durchgefeierten Nacht sichtlich betrunken zur Frühschicht. Was tun Sie?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Together with the rest of the team you quickly come up with a plan to cover for him while he sleeps it off in an office.',
						de: 'Zusammen mit dem Rest des Teams entwickeln Sie schnell einen Plan, um ihn zu vertreten, während er in einem Büro seinen Rausch ausschläft.'
					},
					consequences: {
						text: {
							en: 'Covering up such things may cause a bad safety-culture to develop.',
							de: 'Das Vertuschen solcher Dinge kann dazu führen, dass sich eine schlechte Sicherheitskultur entwickelt.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
			
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You do not interfere at the moment but file an anonymous safety report about this.',
						de: 'Sie mischen sich vorerst nicht ein, sondern erstatten einen anonymen Sicherheitsbericht über diesen Vorfall.'
					},
					consequences: {
						text: {
							en: 'You avoid the confrontation now, but still highligts the issue to safety management.',
							de: 'Sie vermeiden jetzt die Konfrontation, weisen aber dennoch den Safety Manager auf das Problem hin.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You insist that your colleague must report sick and go home.',
						de: 'Sie bestehen darauf, dass Ihr Kollege sich krank meldet und nach Hause geht.'
					},
					consequences: {
						text: {
							en: 'Assertive action focusing on good norms. It does however not address the underlying problems with work morale.',
							de: 'Das ist ein sicheres Vorgehen, das sich auf gute Standards konzentriert. Die eigentlichen Probleme in Bezug auf die Arbeitsmoral werden damit jedoch nicht angegangen.'
						},
						effects: [
							{type: 'cost', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 26,
			title: {
				en: 'Teamwork',
				de: 'Teamarbeit'
			},
			text: {
				en: 'You are designated as a team leader for two colleagues. The three of you are doing an engine change. Last week you overheard one of them saying, that he hates working together with you. How do you react to this?',
				de: 'Sie sind als Teamleiter für zwei Kollegen eingeteilt. Zu dritt führen Sie einen Triebwerkswechsel durch. Letzte Woche haben Sie gehört, wie einer der beiden sagte, er hasse es, mit Ihnen zusammenzuarbeiten. Wie reagieren Sie darauf?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Ignore it. You expect him to be professional enough to put personal issues aside during work.',
						de: 'Sie ignorieren es. Sie erwarten, dass er professionell genug ist, um persönliche Probleme während der Arbeit beiseite zu schieben.'
					},
					consequences: {
						text: {
							en: 'Unresolved issues may cause loss of focus and distraction.',
							de: 'Ungelöste Konflikte können zu Konzentrationsverlust und Ablenkung führen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Take him aside and ask him what the problem is.',
						de: 'Sie nehmen ihn zur Seite und fragen ihn, was das Problem ist.'
					},
					consequences: {
						text: {
							en: 'Assertive action but be carefull about the way you communicate. Maybe HR should participate.',
							de: 'Sie gehen selbstbewusst vor, achten Sie aber auf die Art und Weise, wie Sie kommunizieren. Vielleicht sollte die Personalabteilung hinzugezogen werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Try to avoid him by working more closely with the other team member.',
						de: 'Sie versuchen, ihn zu meiden, indem Sie enger mit dem anderen Teammitglied zusammenarbeiten.'
					},
					consequences: {
						text: {
							en: 'The teamwork suffers under unresolved personal issues.',
							de: 'Die Teamarbeit kann durch offene persönliche Probleme beeinträchtigt werden. '
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]}, 
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 27,
			title: {
				en: 'A damaged component',
				de: 'Ein beschädigtes Bauteil'
			},
			text: {
				en: 'You are doing the first minor inspection after a heavy check performed at another of the company\'s maintenance facilities. You notice a component with surface damages originating from that maintenance input. What do you do?',
				de: 'Sie führen die erste Minor Inspection nach einer umfangreichen Kontrolle durch, die in einer anderen Niederlassung des Betriebes durchgeführt wurde. Sie bemerken ein Bauteil mit Oberflächenbeschädigungen, die von diesem Wartungseinsatz stammen. Was tun Sie nun?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Write it down as a new defect.',
						de: 'Sie notieren es als neuen Mangel.'
					},
					consequences: {
						text: {
							en: 'While addressing the problem the root cause may go unresolved.',
							de: 'Während Sie sich mit dem Problem befassen, kann die Grundursache ungelöst bleiben.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Ignore it. The other workshop has released the aircraft in this state and must have accepted the condition of the component.',
						de: 'Sie ignorieren es. Die andere Werkstatt hat das Flugzeug in diesem Zustand freigegeben und muss den Zustand des Bauteils akzeptiert haben.'
					},
					consequences: {
						text: {
							en: 'Lack of assertiveness may cause a bad safety-culture to develop and affect airworthiness.',
							de: 'Mangelndes Verantwortungsbewusstsein kann dazu führen, dass sich eine schlechte Sicherheitskultur entwickelt und die Lufttüchtigkeit beeinträchtigt wird.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You file a safety report so the case can be investigated.',
						de: 'Sie reichen einen Sicherheitsbericht ein, damit der Fall untersucht werden kann.'
					},
					consequences: {
						text: {
							en: 'It is important to highligt actual and latent problems to management but also remember to deal with the present discrpancy.',
							de: 'Es ist wichtig, das Management auf tatsächliche und latente Probleme hinzuweisen, aber auch daran zu denken, sich mit der gegenwärtigen Diskrepanz zu befassen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		},
		{
			id: 28,
			title: {
				en: 'An unclear checklist',
				de: 'Eine unklare Checkliste'
			},
			text: {
				en: 'You are doing some scheduled inspections. The CAMO checklists has not especially clear references to which maintenance data should be used. How do you handle that?',
				de: 'Sie führen einige planmäßige Inspektionen durch. Die CAMO-Checklisten enthalten keine eindeutigen Hinweise darauf, welche Instandhaltungvorgaben verwendet werden sollen. Wie können Sie damit umgehen?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Refuse to continue with the inspections until the tasks are clearly defined and has proper references.',
						de: 'Sie weigern sich, mit den Inspektionen fortzufahren, bis die Aufgaben klar definiert sind und die richtigen Referenzen vorliegen.'
					},
					consequences: {
						text: {
							en: 'The delay caused by waiting for a response may induce operational pressure.',
							de: 'Die Verzögerung, die durch das Warten auf eine Antwort verursacht wird, kann zu Stress im Betrieb führen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},	
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'The tasks are quite similar to those of other operators. You use your experience to perform the inspections the best possible way.',
						de: 'Die Aufgaben sind ähnlich wie die der anderen Betreiber. Sie nutzen Ihre Erfahrung, um die Inspektionen bestmöglich durchzuführen.'
					},
					consequences: {
						text: {
							en: 'Working from memory and without proper data increases the risk of making errors.',
							de: 'Wenn Sie aus dem Gedächtnis und ohne geeignete Unterlagen arbeiten, erhöht sich das Risiko, dass Sie Fehler machen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Ask the supervisor to contact the CAMO and tell them that you will not do the inspections until an Operator representative is present during the work.',
						de: 'Bitten Sie den Vorgesetzten, sich mit der CAMO in Verbindung zu setzen und ihr mitzuteilen, dass Sie die Inspektionen erst dann durchführen werden, wenn ein Vertreter des Betreibers bei den Arbeiten anwesend ist.'
					},
					consequences: {
						text: {
							en: 'Even with added support the final responsibility remains yours.',
							de: 'Auch mit zusätzlicher Unterstützung bleibt die letzte Verantwortung bei Ihnen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		}
	]
};


export { eventCardsData };
