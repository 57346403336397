/* eslint-disable max-len */
const eventCards = [
	/* Check-in */
	{
		id: 2,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Crew illness'
		},
		text: {
			en: 'CA2 has called in sick this morning. A stand by has been called. He/she will arrive close to departure time.' // TODO
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait with the preflight briefing until the CA arrives.'
				},
				cost: 2,
				consequences: {
					text: {
						en: ''
					},
					effects: [
						{ type: 'replace-crewmember', slotId: 4 },
						{ type: 'inconvenience', value: 1 },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Start preflight briefing without the CA'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Briefing of the crew member needs to be done when he/she arrives.'
					},
					effects: [
						{ type: 'replace-crewmember', slotId: 4 },
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 }, // pressure
						{ type: 'threat', threatId: 'late-crew' },
					],
				},
			},
		],
	},
	{
		id: 3,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Frost on wing'
		},
		text: {
			en: 'The technician reports frost on the underside of the wings during his pre-flight check. The aircraft have already received preventive de-icing.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Request de-ice'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You request de-icing.'
					},
					effects: [
						{ type: 'risk', value: -1 },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask for a check of ice on the upperside of the wings'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Ice is found on the upperside of the wings. De-ice required.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'risk', value: -1 },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'You already have received preventive deicing. Ignore.'
				},
				cost: 0,
				consequences: {
					text: {
						en: ''
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'ice-on-wings' },
					],
				},
			},
		],
	},
	{
		id: 4,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Commanders Discretion'
		},
		text: {
			en: 'You are scheduled to have a layover at the Canary Islands, but operations wants you to fly back again. They already extended the maximum duty, but needs a Commanders Discretion to plan this way.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny the request'
				},
				cost: 1,
				consequences: {
					text: {
						en: `Nothing happens.
							<br/><br/>Commanders Discretion should be used for unforeseen circumstances. Not for planning purposes.`
					},
					effects: [
						{type: 'threat', threatId: 'fatigue'},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Comply with the request'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Commanders Discretion should be used for unforeseen circumstances. Not for planning purposes.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'dirtydozen', dirtyDozenId: 10, value: 1 }, // fatigue
					],
				},
			},
		],
	},
	{
		id: 5,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Fuel'
		},
		text: {
			en: 'The flight is planned with minimum fuel.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Upload extra fuel'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You order extra fuel.'
					},
					effects: [
						{ type: 'risk', value: -1 }
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Stick to the original fuel calculation'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You are happy with the original fuel calculation.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{type: 'threat', threatId: 'minimum-fuel'}
					],
				},
			},
		],
	},
	/* Preflight */
	{
		id: 7,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Liquid spillage'
		},
		text: {
			en: 'A baggage handler reports liquid dripping from a small hatch at the rear of the plane.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call a Technician'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'A seal in the APU is found to be broken. Replace seal.'
					},
					effects: [
						{ type: 'inconvenience', value: 2}
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'It looks like water. Ignore'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'This could be important.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'leakage' }
					],
				},
			},
		],
	},
	{
		id: 8,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Lid on waste'
		},
		text: {
			en: 'Lid on waste in aft lavatory is missing.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Get an engineer to fix it'
				},
				cost: 2,
				consequences: {
					text: {
						en: ''
					},
					effects: [],
					conditionals: [{
						conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'default' }],
						effects: [{ type: 'inconvenience', value: 1 }],
					}],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Lock lavatory during the flight.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You only have 2 functioning lavatories.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 }
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Fly anyway and make a note in the logbook'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Fire hazard without lid.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'fire-hazard'}
					],
				},
			}
		],
	},
	{
		id: 9,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Late Fuel'
		},
		text: {
			en: 'The fuel will be approximately 20 - 25 min late.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Delay the boarding and wait until the fueling is done'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You are below minimum crew and only allowed to board 150 pax until the standby crewmember arrives.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 }, // pressure
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Start fueling with the passengers on board'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You are below minimum crew and only allowed to board 150 pax until the standby crewmember arrives.'
					},
					effects: [],
					conditionals: [{
						conditions: [{ type: 'threat', threatId: 'late-crew' }],
						effects: [
							{ type: 'risk', value: 1 },
							{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 }, // pressure
						],
					}],
				},
			},
		],
	},
	{
		id: 10,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'No ID'
		},
		text: {
			en: 'A technician is not wearing his Airport ID visible.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Ask him to show his ID'
				},
				cost: 1,
				consequences: {
					text: {
						en: `He shows his ID, and you check it.
							<br /><br />It is important that you check if people have a valid ID-card. He could have lost his ID or it could be stolen from him or expired.`
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore. You have seen the man before'
				},
				cost: 0,
				consequences: {
					text: {
						en: `It is important that you check if people have a valid ID-card. He could have lost his ID or it could be stolen from him or expired.`
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'no-airport-id-card'}
					],
				},
			},
		],
	},

	/* Boarding */
	{
		id: 12,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Lithium battery'
		},
		text: {
			en: 'A passenger has a large Lithium-Ion Battery with a Watt hour rating of 165 Wh in his handluggage. It is for a drone, which is checked in as luggage.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny the Lithium Ion Battery on board'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The man refuses to board. Baggage must be located.<br /><br />Note: Spare Lihium Batteries above 160Wh must be shipped as Dangerous Goods unless it is for a wheelchair (up to 300 Wh) Spare Lithium Batteries below 160Wh must be transported in carry-on baggage, never in cargo.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'risk', value: -1 },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Let him have the Lithium Ion Battery as carry-on baggage'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Lithium Ion Batteries above 160Wh need to be shipped as Dangerous Goods.<br /><br />Note: Spare Lihium Batteries above 160Wh must be shipped as Dangerous Goods unless it is for a wheelchair (up to 300 Wh) Spare Lithium Batteries below 160Wh must be transported in carry-on baggage, never in cargo.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'lithium-ion-battery'}
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Move the bag with the battery to the cargo hold'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Lithium Ion Battery above 160 Wh need to be shipped as Dangerous Goods.<br /><br />Note: Spare Lihium Batteries above 160Wh must be shipped as Dangerous Goods unless it is for a wheelchair (up to 300 Wh) Spare Lithium Batteries below 160Wh must be transported in carry-on baggage, never in cargo.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'lithium-ion-battery'}
					],
				},
			},
		],
	},
	{
		id: 13,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Infant seat belt'
		},
		text: {
			en: `A couple wants an infant seatbelt for their 3 year old. He doesn't want to sit in his own seat. He is crying a lot.`
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Refuse. He has to sit in his own seat during take-off and landing.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Well done. The child finally stops crying. Nothing further happens.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Give them an infant seat belt'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'A child of the age of 3, need to sit in their own seat.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'child-not-secured'}
					],
				},
			},
		],
	},
	{
		id: 14,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Luggage'
		},
		text: {
			en: 'Loadsheet made with 147 pieces/1911 kg of luggage. Gate manager reports 2 extra suitcases arriving shortly. When the loadmaster gives you the figure, he says 156 pieces/2028 kg.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Tell them to count the luggage again. Request a new loadsheet'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'A: You receive a new loadsheet with the correct number and location of luggage.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'risk', value: -1 },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Make a LMC (Last Minute Change) to 156 pieces/2028 kg'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Observe company SOP for maximum allowance on LMC\'s'
					},
					effects: [
						{ type: 'threat', threatId: 'w-and-b'}
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Where are the extra kg loaded? You don\'t know the impact this could have on the CG, the balance, and the trim-setting.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'w-and-b'}
					],
				},
			},
		],
	},
	{
		id: 15,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Handluggage'
		},
		text: {
			en: 'A man has 2 very large bags with him for carry-on baggage.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Insist that 1 bag has to go in the cargo compartment'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Remember to ask for Lithium Batteries.<br /><br />Note: Spare Lithium batteries are NOT allowed in the cargo hold of a passenger aircraft.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 14, optionId: 'b' }],
							effects: [{ type: 'inconvenience', value: 1 }],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 14, optionId: 'default' }],
							effects: [{ type: 'inconvenience', value: 1 }],
						}
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let him have the 2 backs in the cabin'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Let him carry both bags in the cabin.<br /><br />Note: Spare Lithium batteries are NOT allowed in the cargo hold of a passenger aircraft.'
					},
					effects: [
						{ type: 'threat', threatId: 'large-handluggage' },
					],
				},
			},
		],
	},

	/* Flight 1 */
	{
		id: 17,
		gameStep: 'flight1',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'ice-on-wings' }],
				cardId: 101,
			},
		],
		title: {
			en: 'Possible Critical Event'
		},
		text: {
			en: ''
		},
		title2: {
			en: 'Crisis averted'
		},
		text2: {
			en: 'Congratulations. By requesting de-icing earlier you already have a slot at the de-icing platform and avoid a longer delay.'
		},
		roundsMax: 0,
	},
	{
		id: 18,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Passenger seat'
		},
		text: {
			en: 'A passenger seat cannot lock in upright position. It is a window seat (9A).'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Reseat passenger(s) and make an entry in the tech log'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You probably need to reseat passengers in both seat 9A and 10A.'
					},
					effects: [
						{ type: 'risk', value: -1},
						{ type: 'threat', threatId: 'passenger-seat'}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Make a remark in the tech log'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'In case of an evacuation, the path from the seat behind (10A) could be obstructed.'
					},
					effects: [
						{ type: 'risk', value: 1},
						{ type: 'threat', threatId: 'passenger-seat'}
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore. You can\'t do anything now'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'In case of an evacuation, the path from the seat behind (10A) could be obstructed.'
					},
					effects: [
						{ type: 'risk', value: 1},
						{ type: 'threat', threatId: 'passenger-seat'}
					],
				},
			},
		],
	},
	{
		id: 19,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Diaper change'
		},
		text: {
			en: `A mother changes a diaper on her baby at the seat. It's just pee, but a passenger nearby complains.`
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Interrupt the change and ask her to go to the lavatory'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'She goes to the lavatory.'
					},
					effects: [
						{ type: 'inconvenience', value: -1 }
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let her finish the diaper change'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Nothing happens.'
					},
					effects: [],
					
				},
			},
		],
	},
	{
		id: 20,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Burn Accident'
		},
		text: {
			en: `CA3 burns their hand on one of the ovens. It doesn't look good.`
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Seat crew member and treat the hand with cooling gel'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'In the next round you have only three action tokens because she is off-duty.'
					},
					effects: [
						{ type: 'disable-resources', value: 1, duration: 1 },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let the crew member return to duties after splashing some water on it'
				},
				cost: 0,
				consequences: {
					text: {
						en: ''
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 4, value: 1 } // stress
					],
					
				},
			},
		],
	},

	/* Flight 2 */
	{
		id: 22, // critical 102
		gameStep: 'flight2',
		type: 'critical',
		title: {
			en: 'Epileptic seizure'
		},
		text: {
			en: 'A 13 year old girl is having an epileptic seizure. According to the parents, it has never happened before.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call for a doctor to help you'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'A nurse is on board and she helps to get the situation under control.'
					},
					effects: [
						{ type: 'threat', threatId: 'epileptic-seizure'}
					],
					
				},
			},
			{
				id: 'default',
				text: {
					en: 'Help the parents in the situation'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The girl recovers after 5 - 7 min, but is a bit confused.'
					},
					effects: [
						{ type: 'threat', threatId: 'epileptic-seizure'}
					],
				},
			},
		],
	},
	{
		id: 23,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'No passport'
		},
		text: {
			en: 'A young male passenger approach you in the galley. He tells you he cannot find his passport. He is travelling alone. He seems nervous.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Help him search his handluggage for the the passport'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The Crew are busy managing the medical issue. The passport is found on the floor 2 rows down from his seat.'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 }, // pressure
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Tell him to look again in the area near his seat'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'He finds his passport. It was on the floor 2 rows down from his seat.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 24,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Short connection time'
		},
		text: {
			en: 'A passenger has been rebooked to this flight and he tells you that he only have 35 min to the next flight if we land on schedule.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Ask to flight deck for information about gate and possible transfer in the airport'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The flight deck will get back to you before landing with the information.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'You are busy and tell him you will get back to him later'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You make a mental note to remember this.'
					},
					effects: [
						{ type: 'threat', threatId: 'short-connection'}
					],
				},
			},
		],
	},
	{
		id: 25,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Taking pictures'
		},
		text: {
			en: 'A young girl seated at 19D is filming the epileptic siezure with her mobile phone. She is very close to the scene.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Ask her to stop filming and delete the movie. Tell her you will make a report of this'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'She stops an deletes the movie.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'You are to busy with the child, so you choose to ignore for now'
				},
				cost: 0,
				consequences: {
					text: {
						en: ''
					},
					effects: [
						{ type: 'threat', threatId: 'video' }
					],
				},
			},
		],
	},

	/* Flight 3 */
	{
		id: 27,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Infant seat belt'
		},
		text: {
			en: 'A mother has her sleeping baby in a sling attached to her body. She does not want to wake up the baby to secure him properly for landing with an infant seat belt.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Insist the she has to use the infant belt'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The mother refuses. The father becomes very upset.'
					},
					effects: [
						{ type: 'critical-event', criticalCardId: 103 }
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let them be. It looks secure'
				},
				cost: 0,
				consequences: {
					text: {
						en: `You don't know if this protects the baby in case of an impact.`
					},
					effects: [
						{ type: 'risk', value: 1},
						{ type: 'threat', threatId: 'child-not-secure'}
					],
				},
			},
		],
	},
	{
		id: 28,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Children in the aisle'
		},
		text: {
			en: '2 children are running around in the aisle. Some passenger are complaining.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Tell the parents to make sure the children are seated during the flight'
				},
				cost: 1,
				consequences: {
					text: {
						en: ''
					},
					effects: [
						{ type: 'inconvenience', value: -1 },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'They could get hurt from a trolley if not seen.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'loose-children'}
					],
				},
			},
		],
	},
	{
		id: 29,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Short connection time'
		},
		text: {
			en: 'The passenger from before is approaching you in the galley. He wants information about his connection. He seems stressed.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Ask the flight deck for information about gate and possible transfer in the airport'
				},
				cost: 1,
				consequences: {
					text: {
						en: ''
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Tell him that you will get back with the information just before landing. There is nothing you can do now'
				},
				cost: 0,
				consequences: {
					text: {
						en: ''
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
					],
				},
			},
		],
	},
	{
		id: 30,
		gameStep: 'flight3',
		type: 'replace',
		cardId: 105,
		conditionals: [
			{
				conditions: [{ type: 'prev-choice', cardId: 12, optionId: 'b' }],
				cardId: 104
			},
			{
				conditions: [{ type: 'prev-choice', cardId: 12, optionId: 'default' }],
				cardId: 106
			}
		],
		title: '',
		text: '',
		roundsMax: 0,
	},


	/* Critical events */
	{
		id: 101,
		title: {
			en: 'Heavy snow'
		},
		type: 'critical',
		text: {
			en: 'During taxi you encounter heavy snow. You are number 6 for departure.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Request de-ice'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You request de-ice and taxi via the de-ice platform. There are 8 aircrafts in front of you. Delay 20 - 30 min.'
					},
					effects: [
						{ type: 'risk', value: -1 },
						{ type: 'inconvenience', value: 2 },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask SCCM to check wings for snow.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'SCCM reports snow on the wings. De-cing is required. There are 8 aircrafts in front of you. Delay 20 - 30 min.'
					},
					effects: [
						{ type: 'risk', value: -1 },
						{ type: 'inconvenience', value: 2 },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore. You expect preventive de-icing to be sufficient'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You do not know the state of the aircraft.'
					},
					effects: [
						{ type: 'risk', value: 2 },
						{ type: 'threat', threatId: 'heavy-snow'}
					],
				},
			},
		],
	},
	// 102 is id 22
	{
		id: 103,
		title: {
			en: 'Discussion with passengers'
		},
		type: 'critical',
		text: {
			en: 'The event escalate and the father becomes very angry. He vocally abuse you.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Give him a warning that they have to follow the regulation onboard, or you will report this to the authorities.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The father calms down. The baby has woken up anyway due to the discussion.'
					},
					effects: [],
					conditionals: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let it be and report it to the SCCM'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'They need to follow crew Instructions.'
					},
					effects: [],
					conditionals: [
						{ type: 'risk', value: 1},
						{ type: 'threat', threatId: 'unruly-passengers'}
					],
				},
			}
		],
	},
	{
		id: 104,
		title: {
			en: 'Lithium Battery Fire'
		},
		type: 'critical',
		text: {
			en: 'The large Lithium-Ion Battery you allowed on board at boading has caught fire. Heavy smoke is comming from the overhead compartment above row 5.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Alert Flight deck, start firefighting'
				},
				cost: 2,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: `Refer to the following:
							<br/><br/>Company SOP for appropriate actions during an onboard fire.
							<br/><br/>ICAO Doc 9481 Emergency Response Guidance for Aircraft Incidents Involving Dangerous Goods.`
					},
					effects: [],
					conditionals: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Start firefighting, and move passengers'
				},
				cost: 2,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: `Refer to the following:
							<br/><br/>Company SOP for appropriate actions during an onboard fire.
							<br/><br/>ICAO Doc 9481 Emergency Response Guidance for Aircraft Incidents Involving Dangerous Goods.`
					},
					effects: [],
					
				},
			},
			{
				id: 'default',
				text: {
					en: 'Direct passengers, start firefighting, alert flight deck'
				},
				cost: 0,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: `Refer to the following:
							<br/><br/>Company SOP for appropriate actions during an onboard fire.
							<br/><br/>ICAO Doc 9481 Emergency Response Guidance for Aircraft Incidents Involving Dangerous Goods.`
					},
					effects: [
					],
				},
			},
		],
	},
	{
		id: 105,
		title: {
			en: 'iPad Fire'
		},
		type: 'critical',
		text: {
			en: 'An iPad has caught fire during charging and the passenger (seated 6F) has thrown it on the floor under the seat in front. People are starting to move away.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Alert Flight deck, start firefighting'
				},
				cost: 2,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: `Refer to the following:
							<br/><br/>Company SOP for appropriate actions during an onboard fire.
							<br/><br/>ICAO Doc 9481 Emergency Response Guidance for Aircraft Incidents Involving Dangerous Goods.`
					},
					effects: [],
					conditionals: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Start firefighting, and move passengers'
				},
				cost: 2,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: `Refer to the following:
							<br/><br/>Company SOP for appropriate actions during an onboard fire.
							<br/><br/>ICAO Doc 9481 Emergency Response Guidance for Aircraft Incidents Involving Dangerous Goods.`
					},
					effects: [],
					conditionals: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Direct passengers, start firefighting, alert flight deck'
				},
				cost: 0,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: `Refer to the following:
							<br/><br/>Company SOP for appropriate actions during an onboard fire.
							<br/><br/>ICAO Doc 9481 Emergency Response Guidance for Aircraft Incidents Involving Dangerous Goods.`
					},
					effects: [
					],
				},
			},
		],
	},
	{
		id: 106,
		title: {
			en: 'Lithium Battery Fire'
		},
		type: 'critical',
		text: {
			en: 'You get a Cargo Fire Warning. The large Lithium-Ion battery you allowed in the cargo compartment earlier, has caught fire.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Evaluate the situation and call ATC for direct vectors'
				},
				cost: 2,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: 'It is time to make a decision.'
					},
					effects: [],
					conditionals: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Fly towards nearest suitable airport'
				},
				cost: 0,
				consequences: {
					hasCallFacilitatorText: true,
					text: {
						en: 'It is time to make a decision.'
					},
					effects: [],
					conditionals: [],
				},
			},
		]

	},
];

export { eventCards };
