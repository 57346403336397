/* eslint-disable max-len */
/* Regex replace:
	text: '(.+?)'
text: {
	en: '$1'
}
*/


const eventCards = [
	/* Check-in */
	{
		id: 2,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Late crew'
		},
		text: {
			en: 'CA3 is 30 minutes late due to FTL.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for the CA'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for the CA.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Start preflight briefing without the CA'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Briefing of the crew member needs to be done upon arrival. This could create pressure in the boarding phase.'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 },
						{ type: 'threat', threatId: 'late-crew' },
					],
				},
			},
		],
	},
	{
		id: 3,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Late baggage'
		},
		text: {
			en: 'A baggage belt in the airport has broken down. This means that 50-60 suitcases will be approx. 45 min. late. There are many connecting flights.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for the suitcases'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You delay the flight to get the suitcases on board.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'threat', threatId: 'missed-connections' }
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 2, optionId: 'a' }],
							text: {
								en: 'As you are already waiting for the CA, this doesn\'t add much extra time.'
							},
							effects: [{ type: 'threat', threatId: 'missed-connections' }],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Leave the suitcases behind'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You leave the suitcases behind.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 4,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Weather at destination'
		},
		text: {
			en: 'Weather at destination is deteriorating and snow clearing of runways might be necessary.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Load extra fuel'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You take no chances and load extra fuel.'
					},
					effects: [{ type: 'risk', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Stick to the original fuel calculation'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You stick to the original fuel calculation and accept minimum fuel.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 },
						{ type: 'threat', threatId: 'minimum-fuel' },
					],
				},
			},
		],
	},
	{
		id: 5,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Two unaccompanied minors'
		},
		text: {
			en: 'Two unaccompanied brothers at the ages of 10 and 12 have checked in. They are used to flying without supervisors.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding due to risk of diversion'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You need to find passengers\' baggage.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 2, optionId: 'a' }],
							text: {
								en: 'As you are already waiting for the CA, this doesn\'t add much extra time.'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 3, optionId: 'a' }],
							text: {
								en: 'As you are already waiting for the CA, this doesn\'t add much extra time.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'In case of diversion you have the responsibility of the unaccompanied minors.'
					},
					effects: [
						{ type: 'threat', threatId: 'unaccompanied-minors' },
					],
				},
			},
		],
	},

	/* Preflight */
	{
		id: 7,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'First Aid Kit'
		},
		text: {
			en: 'The seal on a First Aid kit is broken.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for a new kit'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for a new kit.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Check contents are okay and write a note'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Everything seems ok after a quick check.'
					},
					effects: [{ type: 'threat', threatId: 'first-aid-kit-unsealed' }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You assume the content is fine.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'first-aid-kit-unsealed' },
					],
				},
			},
		],
	},
	{
		id: 8,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Navigation Database'
		},
		text: {
			en: 'A technician is having trouble updating the Navigation Database. Database expires Feb. 12 23:59.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue update. Expect 30 minutes delay'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You delay the flight to make sure the update is completed.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'a' }],
							text: {
								en: 'The update time matches up with the time it takes to get the new first aid kit.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Stop the process and fly without update'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You are still be able to fly the rest of the day. In case of diversion you need an updated database or a MEL release before departure.'
					},
					effects: [{ type: 'threat', threatId: 'database-expiry' }],
				},
			},
		],
	},
	{
		id: 9,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Passenger Meals Missing'
		},
		text: {
			en: 'Two trolleys containing 50 passenger meals are missing'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Ask the catering company for extra food. Expected delay: 20-25 minutes'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for the food.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 7, optionId: 'a' },
								{ type: 'prev-choice', cardId: 8, optionId: 'a' },
							],
							text: {
								en: 'The time it takes for the extra food to arrive matches the time it takes to get the new first aid kit and update the database.'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'a' }],
							text: {
								en: 'The time it takes for the extra food to arrive matches the time it takes to get the new first aid kit.'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 8, optionId: 'a' }],
							text: {
								en: 'The time it takes for the extra food to arrive matches the time it takes to update the database.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You continue with less food than planned.'
					},
					effects: [
						{ type: 'inconvenience', value: 2 },
						{ type: 'threat', threatId: 'no-food' },
					],
				},
			},
		],
	},
	{
		id: 10,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Oven and coffee machine have no power'
		},
		text: {
			en: 'One coffee machine in the forward galley is not working.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call for a technician to fix the problem'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for a technician to fix the problem.'
					},
					effects: [
						{ type: 'inconvenience', value: 3 },
						{ type: 'risk', value: -1 },
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'a' }],
							text: {
								en: 'You are already delayed, so waiting for a technician won\'t add to that.'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'risk', value: -1 },
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 8, optionId: 'a' }],
							text: {
								en: 'You are already delayed, so waiting for a technician won\'t add to that.'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'risk', value: -1 },
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 9, optionId: 'a' }],
							text: {
								en: 'You are already delayed, so waiting for a technician won\'t add to that.'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'risk', value: -1 },
							],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Check C/B and call for a technician to disconnect the coffee machine'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You now have 3 electrical problems in the FWD galley. There is a risk of electrical failure in the wire system.'
					},
					effects: [
						{ type: 'risk', value: 2 },
						{ type: 'threat', threatId: 'electrical-malfunction' },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Use other coffee machines'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You now have 3 electrical problems in the FWD gally. There is a risk of electrical failure in the wire system.'
					},
					effects: [
						{ type: 'risk', value: 2 },
						{ type: 'threat', threatId: 'electrical-malfunction' },
					],
				},
			},
		],
	},

	/* Boarding */
	{
		id: 12,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Cello in cabin'
		},
		text: {
			en: 'A musician refuses to check in his cello as oddsize baggage and has brought it to the gate.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Let the passenger keep his instrument. Strap the instrument to an unused seat somewhere'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'If the Cello is not properly secured to the seat, it could be a potential threat later. I.e., during turbulence.'
					},
					effects: [{ type: 'threat', threatId: 'cello-in-cabin' }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Insist on leaving it in cargo'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger refuses to board. Baggage must be located.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 13,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Deportee'
		},
		text: {
			en: 'One unaccompanied male deportee is crying. He is mumbling weird things when asked if he wants to board.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Baggage must be located.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 12, optionId: 'default' }],
							text: {
								en: 'You get the luggage out while also getting the luggage from the cellist who refused to board.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The behavior of the deportee once airborne is unknown. This could become a problem later.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'deportee' },
					],
				},
			},
		],
	},
	{
		id: 14,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Water, please'
		},
		text: {
			en: 'A passenger demands a glass of water for his 2-year-old daughter.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Give him a bottle of water. Charge him later'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You make a mental note to charge him later.'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Give him a bottle of water. No charge.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You bring him some water.'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let them wait'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The dehydration might get worse.'
					},
					effects: [{ type: 'threat', threatId: 'dehydrated-baby' }],
				},
			},
		],
	},
	{
		id: 15,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Insulin in cargo'
		},
		text: {
			en: 'A diabetic forgot to pack her medicine in her handluggage. No problem if potential delay is less than 45 minutes.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Locate the medicine in cargo'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You manage to locate the bag with the medicine.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 12, optionId: 'default' },
								{ type: 'prev-choice', cardId: 13, optionId: 'a' },
							],
							text: {
								en: 'You get the medicine out while also getting the luggage from the other passengers who didn\'t board.'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 12, optionId: 'default' }],
							text: {
								en: 'You get the medicine out while also getting the luggage from the cellist who refused to board.'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 13, optionId: 'a' }],
							text: {
								en: 'You get the medicine out while also getting the luggage from the deportee.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Other passengers might have the needed medicine if a long delay should occur.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'This could be a problem later.'
					},
					effects: [{ type: 'threat', threatId: 'medicine-in-cargo' }],
				},
			},
		],
	},

	/* Flight 1 */
	{
		id: 17,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Arguing passengers'
		},
		text: {
			en: 'A big drama is playing out in the rear of the cabin. A couple is yelling at each other using very explicit language.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to calm down the passengers'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Despite your best effort the couple continues to argue. The situation could escalate and become a problem.'
					},
					effects: [
						{ type: 'inconvenience', value: 2 },
						{ type: 'threat', threatId: 'arguing-passengers' },
					],
					conditionals: [
						{
							conditions: [
								{ type: 'crew', isSelected: true, crewId: 9 },
								{ type: 'dirtydozen', dirtyDozenId: 4, valueMin: 0, valueMax: 0 },
							],
							text: {
								en: 'Lillian manages to calm them down.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Place passengers far apart'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You place the passengers far apart.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Don\'t interfere'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The couple continues to argue. The situation could escalate and become a problem.'
					},
					effects: [{ type: 'threat', threatId: 'arguing-passengers' }],
				},
			},
		],
	},
	{
		id: 18,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Smoking in the lavatory'
		},
		text: {
			en: 'A passenger is caught smoking in the lavatory. She seems nervous.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Confiscate cigarettes and file a report'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You take care of the cigarettes and file a report on the situation.'
					},
					effects: [{ type: 'risk', value: -1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Confiscate cigarettes'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You confiscate the cigarettes.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let it slide.'
					},
					effects: [{ type: 'risk', value: 1 }],
				},
			},
		],
	},
	{
		id: 19,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Diaper change in cabin'
		},
		text: {
			en: 'A father is changing a diaper on his baby at his seat. It\'s just pee, but a passenger nearby complains.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'You direct him to the lavatory, so he can finish the diaper change'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'He goes to the lavatory to change the diaper.'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let him finish the diaper change'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'He finishes quickly, and nothing further happens.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 20,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Burn accident with oven'
		},
		text: {
			en: 'SCCM gets 3 cm blisters on the right hand from a burn accident involving an oven.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Seat the SCCM and treat the hand with cooling gel'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'In the next round you only have three resources because SCCM is off-duty.'
					},
					effects: [{ type: 'disable-resources', value: 1, duration: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let the SCCM return to duty after splashing some water on it'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Despite the burns, the SCCM returns to work.'
					},
					effects: [{ type: 'dirtydozen', dirtyDozenId: 4, value: 1 }],
				},
			},
		],
	},

	/* Flight 2 */
	{
		id: 22,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'arguing-passengers' }],
				cardId: 105,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'What happened to the arguing passengers?'
		},
		title2: {
			en: 'Calm couple'
		},
		text2: {
			en: 'Congratulations. By solving the problem with the arguing passengers early on you avoided an escalation of the situation.'
		},
		roundsMax: 0,
	},
	{
		id: 23,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'dehydrated-baby' }],
				cardId: 104,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'What happened to the thirsty baby?'
		},
		title2: {
			en: 'Hydrated child'
		},
		text2: {
			en: 'Congratulations. By giving water to the child previously you avoided an incident.'
		},
		roundsMax: 0,
	},

	{
		id: 24,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Seat is broken'
		},
		text: {
			en: 'A passenger seat cannot lock in upright position.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to fix the seat'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You manage to fix the seat to a workable state.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'No one complains about the seat.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 25,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Turbulence warning'
		},
		text: {
			en: 'Coffee is being served. Light-moderate turbulence is expected in 10 minutes.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Cancel coffee'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You stop serving coffee and clear away the coffee serving as quickly as possible.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let passengers finish their coffee'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let the passengers finish their coffee. If turbulence is encountered, people could spill their hot coffee.'
					},
					effects: [{ type: 'threat', threatId: 'coffee-in-cabin' }],
				},
			},
		],
	},

	/* Flight 3 */
	{
		id: 27,
		gameStep: 'flight3',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'coffee-in-cabin' }],
				cardId: 103,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Turbulence is getting worse ...'
		},
		title2: {
			en: 'Halted serving'
		},
		text2: {
			en: 'Congratulations. By refusing to serve coffee early on, you avoided an incident where a passenger got burned with hot coffee.'
		},
		roundsMax: 0,
	},
	{
		id: 28,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Lightning strike'
		},
		text: {
			en: 'A big boom and all light goes out in cabin. Some people start screaming.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to restore light in the cabin before telling the passengers about what happened'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Backup electrical system is activated. Intercom does not work.'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 1, value: 1 },
						{ type: 'threat', threatId: 'no-intercom' },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Calm down passengers over PA-system before restoring the light in the cabin'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Backup electrical system is activated. Intercom does not work.'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 1, value: 1 },
						{ type: 'threat', threatId: 'no-intercom' },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Focus on other possible issues'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'There might be some more damage to the electrical system. Intercom does not work.'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 1, value: 1 },
						{ type: 'threat', threatId: 'no-intercom' },
					],
				},
			},
		],
	},
	{
		id: 29,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Passengers become airsick'
		},
		text: {
			en: 'Turbulence is too much for at least two passengers who vomit all over the floor.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Cabin crew help with clean up during turbulence'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The cabin crew do their best to clean up the mess.'
					},
					effects: [{ type: 'risk', value: 2 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let them wait until turbulence is completely over'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You judge it is best to wait with the cleaning until you\'re out of the turbulence.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 30,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Passenger goes to the lavatory'
		},
		text: {
			en: 'While turbulence is rocking the plane, a passenger suddenly leaves his seat and aims for the lavatory.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Leave your station and stop the passenger physically'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'While trying to stop him, a cabin crew member gets injured...'
					},
					effects: [{ type: 'critical-event', criticalCardId: 102 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Yell that the passenger must return to his seat immediately'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The passenger ignores your call...'
					},
					effects: [{ type: 'critical-event', criticalCardId: 101 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let the passenger go to the lavatory. He looks airsick'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The passenger continues towards the toilet...'
					},
					effects: [{ type: 'critical-event', criticalCardId: 101 }],
				},
			},
		],
	},

	/* Critical events */
	{
		id: 101,
		title: {
			en: 'Passenger injured'
		},
		type: 'critical',
		text: {
			en: 'The turbulence is now severe. The passenger is thrown to the floor and isn\'t responding.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Assist the injured passenger right away.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger has a severe head trauma, and transparent fluid is running out of his ear. You manage to stabilize the passenger once the turbulence has cleared. While trying to help the passenger, the crew member is knocked to the floor. The right foot appears to be broken.'
					},
					hasCallFacilitatorText: true,
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'first-aid-kit-unsealed' }],
							text: {
								en: 'Passenger has a severe head trauma, and transparent fluid is running out of his ear. The extra oxygen masks are missing. The passenger goes unconscious.<br />While trying to help the passenger, the crew member is knocked to the floor. The right foot appears to be broken.'
							},
							hasCallFacilitatorText: true,
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Call flight deck. Aid the passenger, if the turbulence lasts longer than 3 minutes.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger has a severe head trauma and transparent fluid is running out of his ear. Intercom does not work. However, turbulence clears after two minutes. Patient is breathing, but is unconscious.'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Tell passengers to remain seated. The injured passenger must wait.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger has a severe head trauma and transparent fluid is running out of his ear.<br /><br />Turbulence clears after two minutes. Patient is breathing but is unconscious.'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
		],
	},
	{
		id: 102,
		title: {
			en: 'Crew member injured'
		},
		type: 'critical',
		text: {
			en: 'The turbulence is now severe. The CA is thrown to the floor and goes unconscious.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Assist the injured CA right away'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'CA has a severe head trauma, and transparent fluid is running out of her ear.<br /><br />You manage to stabilize her once the turbulence has cleared.'
					},
					hasCallFacilitatorText: true,
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'first-aid-kit-unsealed' }],
							text: {
								en: 'CA has a severe head trauma, and transparent fluid is running out of her ear.<br /><br />The oxygen masks are missing from first aid kit. The CA goes unconscious.'
							},
							hasCallFacilitatorText: true,
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Call the flight deck for information about the duration of the turbulence. Aid the injured CA, if the turbulence lasts longer than 3 minutes.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'CA has a severe head trauma and transparent fluid is running out of her ear.<br /><br />Turbulence clears after two minutes. CA is breathing but is unconscious.'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Leave the CA in the aisle, until the turbulence is over'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'CA has a severe head trauma and transparent fluid is running out of her ear.<br /><br />Turbulence clears after two minutes. CA is breathing but is unconscious.'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
		],
	},
	{
		id: 103,
		title: {
			en: 'Coffee spill'
		},
		type: 'critical',
		text: {
			en: 'Three passengers are burned by spilled coffee.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Treat passengers in galley'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You treat the passengers in the AFT galley despite the turbulence.'
					},
					effects: [{ type: 'risk', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask passengers to remain seated regardless of pain.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You tell the passengers to remain seated.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Let the passengers handle the problem.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let the other passengers sitting close by help out.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 104,
		title: {
			en: 'Father and child need attention'
		},
		type: 'critical',
		text: {
			en: 'The two-year old that didn\'t get water before take-off is now drowsy and does not respond very well. The father is very upset.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Calm down the father and offer the child some water and sweets'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Child is ok after treatment.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let other passengers handle the problem'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Child is ok after treatment.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 105,
		title: {
			en: 'Couple starts fighting'
		},
		type: 'critical',
		text: {
			en: 'The heavy discussion from before has now ended in a physical fight. The woman is in a total frenzy and hits the man again and again. He tries to defend himself and apparently does not fight back.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to calm down the two passengers.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You don\'t manage to calm down the couple. You need help from passengers to strap her down.'
					},
					effects: [{ type: 'inconvenience', value: 2 }, { type: 'risk', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'crew', isSelected: true, crewId: 9 },
								{ type: 'dirtydozen', dirtyDozenId: 4, valueMin: 0, valueMax: 0 },
							],
							text: {
								en: 'Lillian manages to calm them down.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Relocate the woman and strap her to a seat far away'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'All passengers get scared because of the crazy woman, when she is dragged through the cabin.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let other passengers handle the situation'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'With great effort some passengers manages to take control of the situation.'
					},
					effects: [{ type: 'inconvenience', value: 2 }, { type: 'risk', value: 2 }],
				},
			},
		],
	},
];

module.exports = {eventCards};