const languagesData = [
	{
		id: 'en',
		title: 'English'
	},
	{
		id: 'de',
		title: 'Deutsch'
	},
	{
		id: 'ja',
		title: '日本人'
	}
];

export {languagesData};