import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import CrewCardFlippable from 'components/crew-card/crew-card-flippable';
import CrewExperience from 'components/crew-experience/crew-experience';
import CrewIssues from 'components/crew-issues/crew-issues';
import './crew-popup.scss';

const CrewPopup = ({ languageId, gameType, selectedCrew, setShowCrewPopup }) => {

	const crewData = gamesData[gameType].crewData;
	const [selectedCard, setSelectedCard] = useState(null);

	const handleSelectSlot = (slotId, cardData) => {
		if (selectedCard && slotId === selectedCard.slotId) {
			setSelectedCard(null);
		} else {
			let slotTitle = getText(crewData.crewSlots.find((slot) => {return slot.id === slotId;}).title, languageId);
			setSelectedCard({...cardData, slotTitle});
		}
	};
	
	const selectedMember = selectedCard && selectedCrew.find((member) => { return member.id ===  selectedCard.id; } );
	const fadeSelected = (selectedMember && selectedMember.isRemoved === true);

	return (
		<div className={'CrewPopup ' + gameType} onClick={() => {setShowCrewPopup(false);}}>
			<div className="CrewPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="CrewPopup-header">
					<div className="CrewPopup-title">{getText(generalUiTexts.crewPopupTitle, languageId)}</div>
					<div className="CrewPopup-closeBtn" onClick={() => {setShowCrewPopup(false);}}/>
				</div>
				<div className="CrewPopup-body">
					<div className="CrewPopup-crew">
						<div className="CrewPopup-selectedCockpitCrew">
							{crewData.crewSlots.filter((slot) => {
								return (slot.type === 'cockpit' || slot.type === 'pilot');
							}).map((slot, i) => {
								let crewId = selectedCrew.find((crew) => {return crew.slotId === slot.id;}).id;
								let cardData = crewId
									? crewData.availableCrew.find((person) => {
										return person.id === crewId;
									})
									: null;
								if (cardData) {
									cardData = JSON.parse(JSON.stringify(cardData));
									cardData.slotId = slot.id;
								}
								const isSelected = (selectedCard && selectedCard.slotId === slot.id);
								const crewMemberData = selectedCrew.find((member) => {return member.id ===  crewId;});
								const fadeIt =  (crewMemberData && crewMemberData.isRemoved === true);
								return (
									<div
										key={slot.id}
										className={`CrewPopup-slot ${fadeIt ? 'CrewPopup-slot--removed' : ''}`}
										onClick={() => {if (isSelected) handleSelectSlot(slot.id);}}
									>
										<span className="CrewPopup-slotTitle">{getText(slot.title, languageId)}</span>
										{(!isSelected && cardData) && 
											<CrewCardFlippable 
												canFlip={false}
												languageId={languageId}
												gameType={gameType}
												layout="crewPopupSmall"
												cardData={cardData}
												cardTitle={getText(slot.title, languageId)}
												selectedCrew={selectedCrew}
												onClick={() => {handleSelectSlot(slot.id, cardData);}}
											/>
										}
									</div>
								);
							})}
						</div>
						<div className="CrewPopup-selectedCabinCrew">
							{crewData.crewSlots.filter((slot) => {
								return (
									slot.type === 'cabin' || 
								slot.type === 'sysop' || slot.type === 'paramedic' || slot.type === 'doctor'
								);
							}).map((slot, i) => {
								let crewId = selectedCrew.find((crew) => {return crew.slotId === slot.id;}).id;
								let cardData = crewId
									? crewData.availableCrew.find((person) => {
										return person.id === crewId;
									})
									: null;
								if (cardData) {
									cardData = JSON.parse(JSON.stringify(cardData));
									cardData.slotId = slot.id;
								}
								const isSelected = (selectedCard && selectedCard.slotId === slot.id);
								const currentMember = selectedCrew.find((member) => { return member.id ===  crewId; } );
								const fadeIt =  (currentMember && currentMember.isRemoved === true);
								return (
									<div
										key={slot.id}
										className={`CrewPopup-slot ${fadeIt ? 'CrewPopup-slot--removed' : ''}`}
										onClick={() => {if (isSelected) handleSelectSlot(slot.id);}}
									>
										<div className="CrewPopup-slotTitle">
											<span>{getText(slot.title, languageId)}</span>
										</div>
										{(!isSelected && cardData) && 
											<CrewCardFlippable 
												canFlip={false}
												languageId={languageId}
												gameType={gameType}
												layout="crewPopupSmall"
												cardData={cardData}
												cardTitle={getText(slot.title, languageId)}
												selectedCrew={selectedCrew}
												onClick={() => {handleSelectSlot(slot.id, cardData);}}
											/>
										}
									</div>
								);
							})}
						</div>
					</div>
					<div className="CrewPopup-selectedSlot">
						{selectedCard && (
							<CrewCardFlippable
								canFlip={(gameType === 'crm-aeroplanes' ? true : false)}
								isFlipped={(gameType === 'crm-helicopters' ? true : false)}
								languageId={languageId}
								gameType={gameType}
								layout="crewPopup"
								cardData={selectedCard}
								cardTitle={selectedCard.slotTitle}
								selectedCrew={selectedCrew}
								addedClass={`${fadeSelected ? ' memberRemoved' : ''	}`}
								onClick={() => {
									if (gameType === 'crm-helicopters') handleSelectSlot(selectedCard.slotId);
								}}
							/>
						)}
						{gameType === 'crm-helicopters' && !selectedCard && 
							<React.Fragment>
								<CrewExperience 
									languageId={languageId} 
									gameType={gameType} 
									selectedCrew={selectedCrew} 
								/>
								<span>&nbsp;</span>
								<CrewIssues 
									languageId={languageId} 
									gameType={gameType} 
									selectedCrew={selectedCrew} 
								/>
							</React.Fragment>}
					</div>
				</div>
			</div>
		</div>
	);
};

CrewPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	selectedCrew: PropTypes.array.isRequired,
	setShowCrewPopup: PropTypes.func.isRequired,
};

export default CrewPopup;