import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import './create-facilitator-popup.scss';

const CreateFacilitatorPopup = (props) => {
	let {isCreatingFacilitator, errMsgFacilitator, setShowCreateFacilitatorPopup, createFacilitator} = props;

	let [facilitatorName, setFacilitatorName] = useState('');
	let [facilitatorEmail, setFacilitatorEmail] = useState('');

	let createBtnClass = 'CreateFacilitatorPopup-createBtn' + 
		(isCreatingFacilitator ? ' saving' : '') +
		(facilitatorName.length > 0 && facilitatorEmail.length > 0 ? ' active' : '');

	return (
		<div className="CreateFacilitatorPopup">
			<div className="CreateFacilitatorPopup-content">
				<div className="CreateFacilitatorPopup-header">
					<div className="CreateFacilitatorPopup-title">{adminUiTexts.createFacilitator}</div>
					<div 
						className="CreateFacilitatorPopup-closeBtn" 
						onClick={() => {setShowCreateFacilitatorPopup(false);}} 
					/>
				</div>
				<div className="CreateFacilitatorPopup-body">
					<div className="CreateFacilitatorPopup-inputRow">
						<span>{adminUiTexts.name}:</span>
						<input 
							type="text"
							name="name"
							placeholder={adminUiTexts.name.toLowerCase()}
							value={facilitatorName}
							onChange={(event) => {setFacilitatorName(event.target.value);}}
						/>
					</div>
					<div className="CreateFacilitatorPopup-inputRow">
						<span>{adminUiTexts.email}:</span>
						<input 
							type="text"
							name="email"
							placeholder={adminUiTexts.email.toLowerCase()}
							value={facilitatorEmail}
							onChange={(event) => {setFacilitatorEmail(event.target.value);}}
						/>
					</div>
					<div className="CreateFacilitatorPopup-errMsg">{errMsgFacilitator}</div>

					{/* Create button */}
					<div 
						className={createBtnClass} 
						onClick={() => {createFacilitator(facilitatorEmail, facilitatorName);}}
					>{adminUiTexts.create}</div>		
				</div>
			</div>
		</div>
	);
};



CreateFacilitatorPopup.propTypes = {
	isCreatingFacilitator: PropTypes.bool.isRequired,
	errMsgFacilitator: PropTypes.any,
	setShowCreateFacilitatorPopup: PropTypes.func.isRequired,
	createFacilitator: PropTypes.func.isRequired
};

export default CreateFacilitatorPopup;