const daysData = [
	{
		id: 1,
		gameStep: 'day-1',
		cards: require('./event-cards-data').eventCardsData['day-1']
	},
	{
		id: 2,
		gameStep: 'day-2',
		cards: require('./event-cards-data').eventCardsData['day-2']
	},
	{
		id: 3,
		gameStep: 'day-3',
		cards: require('./event-cards-data').eventCardsData['day-3']
	},
	{
		id: 4,
		gameStep: 'day-4',
		cards: require('./event-cards-data').eventCardsData['day-4']
	},
];

module.exports = {daysData};