const threatsData = [
	{id: 'late-crew', eventCardId: 2, title: {
		en: 'Late crew',
		ja: '乗務員の到着遅れ'
	}},
	{id: 'missed-connections', eventCardId: 3, title: {
		en: 'Missed connections',
		ja: '乗り継ぎ出来ず'
	}},
	{id: 'minimum-fuel', eventCardId: 4, title: {
		en: 'Minimum fuel',
		ja: '少ない搭載燃料'
	}},
	{id: 'unaccompanied-minors', eventCardId: 5, title: {
		en: 'Unaccompanied minors',
		ja: '付き添いのいない子供達'
	}},
	{id: 'first-aid-kit-unsealed', eventCardId: 7, title: {
		en: 'First aid kit unsealed',
		ja: '封印の開けられた救急キット'
	}},
	{id: 'database-expiry', eventCardId: 8, title: {
		en: 'Database expiry',
		ja: '航法用地図データベースの期限切れ'
	}},
	{id: 'no-food', eventCardId: 9, title: {
		en: 'No food',
		ja: '食事の不足'
	}},
	{id: 'electrical-malfunction', eventCardId: 10, title: {
		en: 'Electrical malfunction',
		ja: '電気系統の不具合'
	}},
	{id: 'cello-in-cabin', eventCardId: 12, title: {
		en: 'Cello in cabin',
		ja: '客室内のチェロ'
	}},
	{id: 'deportee', eventCardId: 13, title: {
		en: 'Deportee',
		ja: '強制退去者'
	}},
	{id: 'dehydrated-baby', eventCardId: 14, title: {
		en: 'Dehydrated baby',
		ja: '脱水症状の赤ちゃん'
	}},
	{id: 'medicine-in-cargo', eventCardId: 15, title: {
		en: 'Insulin in cargo',
		ja: '貨物に搭載されたインスリン'
	}},
	{id: 'arguing-passengers', eventCardId: 17, title: {
		en: 'Arguing passengers',
		ja: '乗客同士の諍い'
	}},
	{id: 'coffee-in-cabin', eventCardId: 25, title: {
		en: 'Coffee in cabin',
		ja: '客室のコーヒー'
	}},
	{id: 'no-intercom', eventCardId: 28, title: {
		en: 'No intercom',
		ja: 'インターフォン使用不可'
	}}
];

export {threatsData};