const { gameStepsUiTexts, gamePhasesUiTexts } = require('./../ui-texts');

const gameSteps = [
	/* PHASE 1 */
	{
		id: 'welcome', 
		index: 0, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.mayday, 
		subtitle: gameStepsUiTexts.introduction, 
		infoPopup: null,
		page: 'welcome'
	},
	{
		id: 'crew-select', 
		index: 1, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.selectStaff,
		subtitle: null,
		infoPopup: {
			title: gamePhasesUiTexts.staffSelection,
			contentId: 'crewInfoText',
			isScenarioSpecific: false,
			autoShow: true
		},
		page: 'crew-select'
	},
	{
		id: 'crew-feedback', 
		index: 2, 
		phase: 1,
		sectionId: 1,
		title: gameStepsUiTexts.selectedStaff, 
		subtitle: null,
		infoPopup: {
			title: gameStepsUiTexts.firstDebrief,
			contentId: 'crewFeedbackText',
			isScenarioSpecific: false,
			autoShow: true
		},
		page: 'crew-feedback'
	},
	{
		id: 'crew-reflection', 
		index: 3, 
		phase: 1,
		sectionId: 2,
		title: gamePhasesUiTexts.staffSelection, 
		subtitle: gameStepsUiTexts.firstDebrief, 
		infoPopup: {
			title: gameStepsUiTexts.firstDebrief,
			contentId: 'crewFeedbackText',
			isScenarioSpecific: false,
			autoShow: false
		},
		page: 'crew-reflection',
	},
	{
		id: 'crew-reflection-result', 
		index: 4, 
		phase: 1,
		sectionId: 3,
		title: gameStepsUiTexts.staffReflection, 
		subtitle: gameStepsUiTexts.results, 
		infoPopup: {
			title: gameStepsUiTexts.firstDebrief,
			contentId: 'crewFeedbackText',
			isScenarioSpecific: false,
			autoShow: false
		},
		backBtn: true,
		page: 'crew-reflection-result',
	},

	/* PHASE 2 */
	{
		id: 'game-board-intro',
		index: 5,
		phase: 2,
		sectionId: 1,
		title: gamePhasesUiTexts.day1,
		subtitle: gameStepsUiTexts.introduction,
		infoPopup: null,
		page: 'game-board-intro'
	},
	{
		id: 'day-1',
		index: 6,
		phase: 2,
		sectionId: 1,
		title: gamePhasesUiTexts.day1,
		subtitle: null,
		infoPopup: {
			title: gamePhasesUiTexts.day1,
			contentId: 'day1Text',
			isScenarioSpecific: true,
			autoShow: false
		},
		page: 'game-board'
	},

	/* PHASE 3 */
	{
		id: 'day-2',
		index: 7,
		phase: 3,
		sectionId: 1,
		title: gamePhasesUiTexts.day2,
		subtitle: null,
		infoPopup: {
			title: gamePhasesUiTexts.day2,
			contentId: 'day2Text',
			isScenarioSpecific: true,
			autoShow: true
		},
		page: 'game-board'
	},
	{
		id: 'best-practices',
		index: 8,
		phase: 3,
		sectionId: 1,
		title: gameStepsUiTexts.secondDebrief,
		subtitle: gameStepsUiTexts.reflection,
		infoPopup: {
			title: gameStepsUiTexts.secondDebrief,
			contentId: 'bestPracticesText',
			isScenarioSpecific: false,
			autoShow: true
		},
		page: 'best-practices'
	},
	{
		id: 'best-practices-result', 
		index: 9, 
		phase: 3,
		sectionId: 2,
		title: gameStepsUiTexts.secondDebrief, 
		subtitle: gameStepsUiTexts.results, 
		infoPopup: {
			title: gameStepsUiTexts.secondDebrief,
			contentId: 'bestPracticesText',
			isScenarioSpecific: false,
			autoShow: false
		},
		page: 'best-practices-result',
		backBtn: true,
		nextBtnCondition: 'bestPracticesResultsIsOpen'
	},
	{
		id: 'best-practices-benefits',
		index: 10,
		phase: 3,
		sectionId: 2,
		title: gameStepsUiTexts.secondDebrief,
		subtitle: gameStepsUiTexts.results,
		infoPopup: {
			title: gameStepsUiTexts.secondDebrief,
			contentId: 'bestPracticesText',
			isScenarioSpecific: false,
			autoShow: false
		},
		page: 'best-practices-benefits'
	},

	/* PHASE 4 */
	{
		id: 'day-3',
		index: 11,
		phase: 4,
		sectionId: 1,
		title: gamePhasesUiTexts.day3,
		subtitle: null,
		infoPopup: {
			title: gamePhasesUiTexts.day3,
			contentId: 'day3Text',
			isScenarioSpecific: true,
			autoShow: true
		},
		page: 'game-board'
	},

	/* PHASE 5 */
	{
		id: 'day-4',
		index: 12,
		phase: 5,
		sectionId: 1,
		title: gamePhasesUiTexts.day4,
		subtitle: null,
		infoPopup: {
			title: gamePhasesUiTexts.day4,
			contentId: 'day4Text',
			isScenarioSpecific: true,
			autoShow: true
		},		
		page: 'game-board'
	},
	{
		id: 'dd-reflection',
		index: 13,
		phase: 5,
		sectionId: 1,
		title: gameStepsUiTexts.thirdDebrief,
		subtitle: gameStepsUiTexts.reflection,
		infoPopup: {
			title: gameStepsUiTexts.thirdDebrief,
			contentId: 'ddReflectionText',
			isScenarioSpecific: false,
			autoShow: true
		},
		// nextBtnCondition: 'ddReflectionResultsIsOpen',
		page: 'dd-reflection',
	},
	{
		id: 'dd-reflection-result',
		index: 14,
		phase: 5,
		sectionId: 2,
		title: gameStepsUiTexts.thirdDebrief,
		subtitle: gameStepsUiTexts.results,
		infoPopup: {
			title: gameStepsUiTexts.thirdDebrief,
			contentId: 'ddReflectionText',
			isScenarioSpecific: false,
			autoShow: false
		},
		page: 'dd-reflection-result',
		backBtn: true
	},

	/* PHASE 6 */
	{
		id: 'final-reflection',
		index: 15, 
		phase: 6,
		sectionId: 1,
		title: gamePhasesUiTexts.finalReflection, 
		subtitle: null, 
		infoPopup: {
			title: gamePhasesUiTexts.finalReflection,
			contentId: 'finalReflectionText',
			isScenarioSpecific: false,
			autoShow: false
		},
		page: 'final-reflection',
	},
	{
		id: 'final-reflection-result',
		index: 16, 
		phase: 6,
		sectionId: 1,
		title: gamePhasesUiTexts.finalReflection, 
		subtitle: gameStepsUiTexts.results, 
		infoPopup: {
			title: gamePhasesUiTexts.finalReflection,
			contentId: 'finalReflectionText',
			isScenarioSpecific: false,
			autoShow: false
		},
		page: 'final-reflection-result',
		backBtn: true
	},

	/* PHASE 7 */
	{
		id: 'final-result',
		index: 17, 
		phase: 7,
		sectionId: 1,
		title: gamePhasesUiTexts.results, 
		subtitle: null,
		infoPopup: {
			title: gamePhasesUiTexts.finalResults,
			contentId: 'finalResultsText',
			isScenarioSpecific: false,
			autoShow: false
		},
		showMedal: true,
		page: 'game-result',
	},
	/* PHASE 8 */
	{
		id: 'gameover', 
		index: 18, 
		phase: 8,
		sectionId: 1,
		title: gamePhasesUiTexts.gameover, 
		subtitle: null,
		infoPopup: null,
		page: 'gameover'
	}
];

module.exports = {gameSteps};