const { gamePhasesUiTexts } = require('./../ui-texts');

const gamePhases = [
	{
		id: 1,
		title: gamePhasesUiTexts.staffSelection,
		steps: [
			{
				id: 'crewSelectionIsOpen',
				btnText1: gamePhasesUiTexts.firstPhase,
				btnText2: gamePhasesUiTexts.staffSelection
			}
		],
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
			{id: 2, title: gamePhasesUiTexts.reflection},
			{id: 3, title: gamePhasesUiTexts.debrief}
		],
		statusBtn: {
			gameStepIds: ['crew-reflection', 'crew-reflection-result'],
			types: ['reflection', 'reflection'],
			reflectionIds: ['crew-reflection', 'crew-reflection']
		}
	},
	{
		id: 2,
		title: gamePhasesUiTexts.day1,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.day1,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game},
		]
	},
	{
		id: 3,
		title: gamePhasesUiTexts.day2,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.day2,
		steps: [
			{
				id: 'bestPracticesResultsIsOpen',
				btnText1: gamePhasesUiTexts.thisPhase,
				btnText2: gamePhasesUiTexts.results
			}
		],
		sections: [
			{id: 1, title: gamePhasesUiTexts.game},
			{id: 2, title: gamePhasesUiTexts.debrief},
		],
		statusBtn: {
			gameStepIds: ['best-practices', 'best-practices-result', 'best-practices-benefits'],
			types: ['reflection', 'reflection', 'reflection'],
			reflectionIds: ['best-practices', 'best-practices', 'best-practices']
		}
	},
	{
		id: 4,
		title: gamePhasesUiTexts.day3,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.day3,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game},
		]
	},
	{
		id: 5,
		title: gamePhasesUiTexts.day4,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.day4,
		// steps: [
		// 	{
		// 		id: 'ddReflectionResultsIsOpen',
		// 		btnText1: gamePhasesUiTexts.thisPhase,
		// 		btnText2: gamePhasesUiTexts.results
		// 	}
		// ],
		sections: [
			{id: 1, title: gamePhasesUiTexts.game},
			{id: 2, title: gamePhasesUiTexts.debrief},
		],
		statusBtn: {
			gameStepIds: ['dd-reflection', 'dd-reflection-result'],
			types: ['reflection', 'reflection'],
			reflectionIds: ['dd-reflection', 'dd-reflection']
		}
	},
	{
		id: 6,
		title: gamePhasesUiTexts.finalReflection,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.finalReflection,
		sections: [
			{id: 1, title: gamePhasesUiTexts.reflection},
		],
		statusBtn: {
			gameStepIds: ['final-reflection', 'final-reflection-result'],
			types: ['reflection', 'reflection'],
			reflectionIds: ['final-reflection', 'final-reflection']
		}
	},	
	{
		id: 7,
		title: gamePhasesUiTexts.finalResults,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.finalResults,
		sections: [
			{id: 1, title: gamePhasesUiTexts.totalScore}
		],
		statusBtn: {
			gameStepIds: ['final-result'],
			types: ['scoreboard']
		}
	},
	{
		id: 8,
		title: gamePhasesUiTexts.gameover,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.endGame,
		sections: [
			{id: 1, title: gamePhasesUiTexts.gameover},
		],
		statusBtn: {
			gameStepIds: ['final-result', 'gameover'],
			types: ['scoreboard', 'scoreboard']
		}
	}
];

module.exports = {gamePhases};