import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {gamesArray, gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import Facilitators from 'components/admin/facilitators/facilitators';
import Admins from 'components/admin/admins/admins';
import Button from 'components/button/button';
import CreateFacilitatorPopup from 'components/admin/create-facilitator-popup/create-facilitator-popup';
import DeleteCompanyPopup from 'components/admin/delete-company-popup/delete-company-popup';
import Players from 'components/admin/players/players';
import DownloadCompanyGames from 'components/admin/download-company-games/download-company-games';
import './company.scss';
// TODO: 'en' -> languageId
const Company = (props) => {
	let {
		isDeletingCompany,
		isCreatingFacilitator,
		isResettingFacilitatorPw,
		isImportingPlayers,
		isDeletingPlayer,
		isEditing, 
		isSaving, 
		errMsgFacilitator,
		errMsgCompany,
		importErrorMsg,
		facilitators, 
		players,
		games, 
		archivedGames,
		groups,
		selectedCompany, 
		updateCompany, 
		saveCompany, 
		deleteCompany,
		importPlayers,
		deletePlayer,
		createFacilitator,
		resetFacilitatorPassword,
		selectFacilitator
	} = props;

	/* Select and import file with players */
	let playersFileRef = React.createRef();
	let [importPlayersError, setImportPlayersError] = useState(null);
	const handleImportPlayers = () => {
		setImportPlayersError(null);
		let fileInputElement = playersFileRef;
		if (!fileInputElement || !fileInputElement.files || fileInputElement.files.length === 0) {
			setImportPlayersError(adminUiTexts.errorMessages.noFileSelected);
			return;
		}
		let csvfile = fileInputElement.files[0];
		importPlayers(csvfile);
	};
	useEffect(() => {setImportPlayersError(importErrorMsg);}, [importErrorMsg]);

	/* Create facilitator popup */
	let [showCreateFacilitatorPopup, setShowCreateFacilitatorPopup] = useState(false);

	/* Toggle game access */
	// const toggleGame = (gameId) => {
	// 	let companyGames = JSON.parse(JSON.stringify(selectedCompany.games));
	// 	let gameData = gamesData[gameId];

	// 	// Enable / disable game access 
	// 	let gameIndex = companyGames.findIndex((g) => {return g.id === gameId;});
	// 	if (gameIndex < 0) {
	// 		let scenarios = gameData.scenarios.map((sc) => {return {id: sc.id, enabled: false};});
	// 		companyGames.push({id: gameId, enabled: true, scenarios: scenarios});
	// 		gameIndex = companyGames.length - 1;
	// 	} else {
	// 		companyGames[gameIndex].enabled = !companyGames[gameIndex].enabled;
	// 	}
	// 	// Enable scenarios if game is enabled
	// 	if (companyGames[gameIndex].enabled === true) {
	// 		if (
	// 			!companyGames[gameIndex].hasOwnProperty('scenarios') || 
	// 			companyGames[gameIndex].scenarios.length !== gameData.scenarios.length
	// 		) {
	// 			companyGames[gameIndex].scenarios = gameData.scenarios.map((sc) => {
	// 				return {id: sc.id, enabled: true};
	// 			});
	// 		}
	// 		if (!companyGames[gameIndex].scenarios.some((s) => {return s.enabled === true;})) {
	// 			companyGames[gameIndex].scenarios.forEach((s) => {
	// 				s.enabled = true;
	// 			});
	// 		}
	// 	}

	// 	// Disable scenarios if game is disabled
	// 	if (companyGames[gameIndex].enabled === false) {
	// 		if (
	// 			!companyGames[gameIndex].hasOwnProperty('scenarios') || 
	// 			companyGames[gameIndex].scenarios.length !== gameData.scenarios.length
	// 		) {
	// 			companyGames[gameIndex].scenarios = gameData.scenarios.map((sc) => {
	// 				return {id: sc.id, enabled: false};
	// 			});
	// 		}
	// 		if (companyGames[gameIndex].scenarios.some((s) => {return s.enabled === true;})) {
	// 			companyGames[gameIndex].scenarios.forEach((s) => {
	// 				s.enabled = false;
	// 			});
	// 		}
	// 	}
	// 	updateCompany({target: {name: 'games', value: companyGames}});
	// };

	/* Toggle scenario access */
	const toggleScenario = (gameId, scenarioId) => {
		let gameData = gamesData[gameId];
		let companyGames = JSON.parse(JSON.stringify(selectedCompany.games));
		let gameIndex = companyGames.findIndex((g) => {return g.id === gameId;});
		if (gameIndex >= 0) {
			/* Get game scenarios data */
			if (!companyGames[gameIndex].hasOwnProperty('scenarios') || !companyGames[gameIndex].scenarios) {
				/* No scenarios in db, create all scenarios with default state disabled */
				companyGames[gameIndex].scenarios = gameData.scenarios.map((sc) => {
					return {id: sc.id, enabled: false};
				});
			}
			if (
				companyGames[gameIndex].scenarios.length < gameData.scenarios.length
			) {
				/* Some scenarios missing */
				gameData.scenarios.forEach((sc) => {
					if (!companyGames[gameIndex].scenarios.some((s) => {return s.id === sc.id;})) {
						companyGames[gameIndex].scenarios.push({id: sc.id, enabled: false});
					}
				});
			}

			let scenarioIndex = companyGames[gameIndex].scenarios.findIndex((s) => {return s.id === scenarioId;});
			companyGames[gameIndex].scenarios[scenarioIndex].enabled = 
				!companyGames[gameIndex].scenarios[scenarioIndex].enabled;
			
			// Disable game if all game scenarios are disabled
			if (!companyGames[gameIndex].scenarios.some((s) => {return s.enabled === true;})) {
				companyGames[gameIndex].enabled = false;
			}

			// Enable game if at least 1 scenario is enabled
			if (companyGames[gameIndex].scenarios.some((s) => {return s.enabled === true;})) {
				companyGames[gameIndex].enabled = true;
			}


			updateCompany({target: {name: 'games', value: companyGames}});
		} 
	};

	/* Save button status */
	let saveBtnClass = 'Company-saveBtn' + (isEditing ? ' active' : '') + (isEditing && isSaving ? ' saving' : '');

	/* Delete company popup */
	let [showDeleteCompanyPopup, setShowDeleteCompanyPopup] = useState(false);

	return (
		<div className="Company">
			{/* Save & delete buttons */}
			<div className={saveBtnClass} onClick={() => {saveCompany();}}>{adminUiTexts.saveChanges}</div>
			<div className="Company-deleteBtn active" onClick={() => {setShowDeleteCompanyPopup(true);}}>
				{adminUiTexts.deleteCompany}
			</div>

			{/* Export button */}
			{games.length > 0 && 
				<DownloadCompanyGames 
					company={selectedCompany} 
					facilitators={facilitators} 
					games={games} 
					groups={groups}
					archivedGames={archivedGames}
				/>}
			
			{/* Company title */}
			<div className="Company-title">{adminUiTexts.companyName}:</div>
			<div className="Company-companyTitle">
				<input 							
					type="text"
					name="title"
					placeholder={adminUiTexts.companyName.toLowerCase()}
					value={selectedCompany.title}
					onChange={(event) => {updateCompany(event);}}
				/>
			</div>

			{/* Games and scenarios */}
			<div className="Company-title">{adminUiTexts.games}:</div>
			<div className="Company-games">
				{gamesArray.map((gameId) => {
					if (gamesData.hasOwnProperty(gameId)) {
						let gameData = gamesData[gameId];
						let companyGameData = selectedCompany.games.find((g) => {return g.id === gameId;});
						// let isEnabled = companyGameData ? companyGameData.enabled : false;
						return (
							<div key={gameId} className={'Company-game ' + gameId}>
								<div className="Company-gameHeader">
									{/* <div 
										className={'Company-gameCheckbox' + (isEnabled ? ' checked' : '')} 
										onClick={() => {toggleGame(gameId);}}
									/> */}
									<div className="Company-gameTitle">
										{getText(gameData.title, 'en') + 
											(gameData.subtitle ? ' (' + getText(gameData.subtitle, 'en') + ')' : '')}
									</div>
								</div>
								<div className="Company-gameScenarios">
									{gameData.scenarios && gameData.scenarios.map((scenario) => {

										let companyScenarioData = (companyGameData.scenarios 
											? companyGameData.scenarios.find((s) => {return s.id === scenario.id;})
											: null
										);
										let isEnabled = companyScenarioData ? companyScenarioData.enabled : false;
										return (
											<div key={scenario.id} className={'Company-gameScenario ' + gameId}>
												<div 
													className={'Company-gameScenarioCheckbox' + 
														(isEnabled ? ' checked' : '')} 
													onClick={() => {toggleScenario(gameId, scenario.id);}}
												/>
												<div className="Company-gameScenarioTitle">
													{getText(scenario.title, 'en')}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						);
					}
					return null;
				})}
			</div>

			{/* Facilitators */}
			<div className="Company-title">{adminUiTexts.facilitators}:</div>
			<Button 
				text={adminUiTexts.createNewFacilitator} 
				classes={['createCompany']} 
				isLoading={isCreatingFacilitator}
				onClick={() => {setShowCreateFacilitatorPopup(true);}} 
			/>
			<Facilitators 
				isResettingFacilitatorPw={isResettingFacilitatorPw}
				companyId={selectedCompany.id}
				facilitators={facilitators} 
				games={games}
				selectFacilitator={selectFacilitator}
				resetFacilitatorPassword={resetFacilitatorPassword}
			/>

			{/* Admins */}
			<div className="Company-title">{adminUiTexts.admins}:</div>
			<Admins 
				isResettingFacilitatorPw={isResettingFacilitatorPw}
				companyId={selectedCompany.id}
				facilitators={facilitators} 
				games={games}
				selectFacilitator={selectFacilitator}
				resetFacilitatorPassword={resetFacilitatorPassword}
			/>

			{/* Players */}
			<div className="Company-title">{adminUiTexts.players}:</div>
			<div className="Company-importPlayers">
				<Button 
					text={adminUiTexts.importPlayers} 
					classes={['createCompany']} 
					isLoading={isImportingPlayers}
					onClick={() => {handleImportPlayers();}} 
				/>
				&nbsp;&nbsp;
				<input type="file" ref={(input) => {playersFileRef = input;}}/>
				<div className="Company-importPlayersErr">{importPlayersError}</div>
			</div>
			<Players players={players} deletePlayer={deletePlayer} />
			<Button 
				text={adminUiTexts.deleteAllPlayers}
				classes={['deletePlayers']} 
				isLoading={isDeletingPlayer}
				onClick={() => {deletePlayer(null);}} 
			/>


			{/* Create facilitator popup */}
			{showCreateFacilitatorPopup && 
				<CreateFacilitatorPopup 
					isCreatingFacilitator={isCreatingFacilitator}
					errMsgFacilitator={errMsgFacilitator}
					setShowCreateFacilitatorPopup={setShowCreateFacilitatorPopup}
					createFacilitator={createFacilitator}
				/>
			}

			{showDeleteCompanyPopup && 
				<DeleteCompanyPopup 
					isDeletingCompany={isDeletingCompany}
					errMsgCompany={errMsgCompany}
					selectedCompany={selectedCompany}
					setShowDeleteCompanyPopup={setShowDeleteCompanyPopup}
					deleteCompany={deleteCompany}
				/>
			}
		</div>
	);
};

Company.propTypes = {
	isDeletingCompany: PropTypes.bool.isRequired,
	isCreatingFacilitator: PropTypes.bool.isRequired,
	isResettingFacilitatorPw: PropTypes.any,
	isImportingPlayers: PropTypes.bool.isRequired,
	isDeletingPlayer: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	errMsgFacilitator: PropTypes.any,
	errMsgCompany: PropTypes.any,
	importErrorMsg: PropTypes.any,
	facilitators: PropTypes.array.isRequired,
	players: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	archivedGames: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	selectedCompany: PropTypes.object.isRequired,
	updateCompany: PropTypes.func.isRequired,
	saveCompany: PropTypes.func.isRequired,
	deleteCompany: PropTypes.func.isRequired,
	importPlayers: PropTypes.func.isRequired,
	deletePlayer: PropTypes.func.isRequired,
	createFacilitator: PropTypes.func.isRequired,
	resetFacilitatorPassword: PropTypes.func.isRequired,
	selectFacilitator: PropTypes.func.isRequired
};

export default Company;