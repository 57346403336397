import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import GameBoardStat from 'components/game-board-stat/game-board-stat';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import BestPracticeCardFlippable from 'components/best-practice-card/best-practice-card-flippable';
import './best-practices-benefits.scss';

const BestPracticesBenefits = (props) => {
	let {
		game,
		group,
		handleGoToPage,
		confirmAndContinue, 		
		firstFlip,
		toggleInfoPopup,
		animateTimeStats,
		animateRiskStats,
		animateCostStats,
		statDiffs,
		bestPracticesData
	} = props;

	/* Game step data */
	const gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === 'best-practices-benefits';});
	
	/**
	 * Track if both cards are flipped
	 */
	const [bothFlipped, setBothFlipped] = useState(false);
	useEffect(() => {
		let bool = true;
		if (group.bestPracticesCards) {
			group.bestPracticesCards.forEach((flip)=>{
				if (!flip.effectsApplied) {
					bool = false;
				}
			});
		}
		setBothFlipped(bool);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [group.bestPracticesCards]);

	/* Confirm button status */
	let confirmBtnData = getConfirmButtonData(
		bothFlipped, 
		game, 
		'best-practices-benefits', 
		getText(generalUiTexts.next, game.languageId)
	);
	if (game.type === 'safetytraining') confirmBtnData.classes.push('st');

	return (
		<div className="BestPracticesBenefits">
			<div className="BestPracticesBenefits-header">
				<ProgressBar 
					gameType={game.type}
					page="game-board"
					title={getText(gameStepData.title, game.languageId)}
					onClick={() => {handleGoToPage('welcome');}}
				/>
				{/* Extra time */}
				<GameBoardStat
					languageId={game.languageId}
					animate={animateTimeStats}
					statDiff={statDiffs && statDiffs.time ? statDiffs.time : null}
					type="time"
					title={getText(generalUiTexts.extraTime, game.languageId)}
					gameType={game.type}
					value={(group.timeValue ? group.timeValue : 0)}
					maxValue = {appConfig['st']['timeMax']}
				/>

				{/* Extra risk */}
				<GameBoardStat
					languageId={game.languageId}
					animate={animateRiskStats}
					statDiff={statDiffs && statDiffs.risk ? statDiffs.risk : null}
					type="risk"
					title={getText(generalUiTexts.extraRisk, game.languageId)}
					gameType={game.type}
					value={(group.riskValue ? group.riskValue : 0)}
					maxValue = {appConfig['st']['riskMax']}
				/>

				{/* Extra cost */}
				<GameBoardStat
					languageId={game.languageId}
					animate={animateCostStats}
					statDiff={statDiffs && statDiffs.cost ? statDiffs.cost : null}
					type="cost"
					title={getText(generalUiTexts.extraCost, game.languageId)}
					gameType={game.type}
					value={(group.costValue ? group.costValue : 0)}
					maxValue = {appConfig['st']['costMax']}
				/>

				{(game.type === 'safetytraining') &&
					<Button
						text={''}
						classes={['st', 'info']}
						onClick={() => {toggleInfoPopup();}}
					/>}
				{/* Continue button */}
				<Button
					isDisabled={confirmBtnData.isDisabled}
					text={confirmBtnData.text}
					classes={confirmBtnData.classes}
					onClick={() => {confirmAndContinue('best-practices-benefits');}}
				/>
			</div>
			<div className="BestPracticesBenefits-content">
				<h2 className="BestPracticesBenefits-title">
					{`${getText(gameStepData.subtitle, game.languageId)} 
						// ${getText(gameStepData.title, game.languageId)}`}
				</h2>
				<div className="BestPracticesBenefits-description">
					{getText(generalUiTexts.flipCards, game.languageId)}
				</div>
				<div className="BestPracticesBenefits-board">
					{group.bestPracticesCards.map((card, i) => {
						return (
							<div key={card.id} className={`BestPracticesBenefits-cardWrap 
								${card.isSelected ? 'selected' : 'deselected'}`
							}>
								<BestPracticeCardFlippable
									index={i}
									isDisabled={false}
									bestPracticesCardsConfirmed={group.bestPracticesCardsConfirmed}
									languageId={game.languageId}
									cardStatus={card}
									group={group}
									firstFlip={firstFlip}
									bestPracticesData={bestPracticesData}
								/>					
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

BestPracticesBenefits.propTypes = {
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	firstFlip: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	animateTimeStats: PropTypes.bool.isRequired,
	animateRiskStats: PropTypes.bool.isRequired,
	animateCostStats: PropTypes.bool.isRequired,
	statDiffs: PropTypes.object,
	bestPracticesData: PropTypes.array.isRequired,
};

export default BestPracticesBenefits;