/* eslint-disable max-len */
const eventCards = [
	/* Check-in */
	{
		id: 1,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Crew Member Intoxicated'
		},
		text: {
			en: 'A crewmember is reported being drunk the night before by the hotel staff.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'All crewmembers take an alcohol test'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'One cabin crew is above limit for flying. You manage to get %name% to come in on a short notice to replace them.'
					},
					effects: [
						{ type: 'replace-crewmember', slotId: 4 },
						{ type: 'inconvenience', value: 1 },
						{ type: 'risk', value: -1 },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Give the crewmember a chance to give themselves up'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'No one speaks up. You could be flying with an intoxicated crew member.'
					},
					effects: [
						{ type: 'threat', threatId: 'crew-sus' },
						{ type: 'risk', value: 1 },
					],
				},
			},
		],
	},
	{
		id: 2,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Jump Seat Request'
		},
		text: {
			en: 'A company pilot has requested a jump seat for his girlfriend.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Nothing happens.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Persons on the flight deck could distract you.'
					},
					effects: [
						{type: 'dirtydozen', dirtyDozenId: 2, value: 1},
						{type: 'threat', threatId: 'jump-seat-passenger'}
					],
				},
			},
		],
	},
	{
		id: 3,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Captain Headache'
		},
		text: {
			en: 'The Captain says he has a light headache. He didn\'t sleep well last night.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call in sick and call for a stand by'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You manage to get %name% to come in on a short notice.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'risk', value: -1 },
						{ type: 'replace-crewmember', slotId: 1, notAllowedCrewemberIds: [4]},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Take two painkillers and continue on board'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'This could be something serious.'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 10, value: 1 },
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'captain-headache' },
					],
				},
			},
		],
	},
	{
		id: 4,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Traffic Expected upon Arrival'
		},
		text: {
			en: 'Due to several big events in London this week, a lot of traffic is expected upon arrival.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Load extra fuel'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You request more fuel.'
					},
					effects: [{ type: 'risk', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Stick to the original fuel calculation'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You keep the original fuel calculations. Potential delay into London Heathrow due to heavy traffic could be expected.'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 },
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'minimum-fuel' },
					],
				},
			},
		],
	},
	/* Preflight */
	{
		id: 5,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Oxygen Masks'
		},
		text: {
			en: 'During preflight, two oxygen masks for one of the portable oxygen bottles are found open and they appear to have been used.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for extra masks'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for the extra masks.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore and continue flight without extra masks'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The used oxygen masks might not work.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'oxygen-masks' },
					],
				},
			},
		],
	},
	{
		id: 6,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Cockpit Door'
		},
		text: {
			en: 'The cockpit door lock appears to be unserviceable.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call a technician to fix the problem'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You delay to make sure the door is fixed.'
					},
					effects: [
						{ type: 'risk', value: -1 },
						{ type: 'inconvenience', value: 1 }
					],
					conditionals: [{
						conditions: [{ type: 'prev-choice', cardId: 5, optionId: 'a' }],
						text: {
							en: 'You\'re already delayed waiting for the masks so no further delay is needed.'
						},
						effects: [{ type: 'risk', value: -1 }],
					}],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Make a note and get it fixed at homebase'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The unlocked door could be a security problem.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'cockpit-door-lock' },
					],
				},
			},
		],
	},
	{
		id: 7,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Dog in Handluggage'
		},
		text: {
			en: 'The gate manager reports that a passenger has a very small dog in a handbag. The dog is not listed.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Locate luggage in cargo causing a small delay.<br /><br />Note: Special rules apply for animals into and out of the UK.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 5, optionId: 'a' },
								{ type: 'prev-choice', cardId: 6, optionId: 'a' },
							],
							text: {
								en: 'You locate the luggage in cargo while the door and masks are fixed.<br /><br />Note: Special rules apply for animals into and out of the UK.'
							},
							effects: [],
						},
						{
							conditions: [
								{ type: 'prev-choice', cardId: 5, optionId: 'a' },
							],
							text: {
								en: 'You locate the luggage in cargo while waiting for the masks.<br /><br />Note: Special rules apply for animals into and out of the UK.'
							},
							effects: [],
						},
						{
							conditions: [
								{ type: 'prev-choice', cardId: 6, optionId: 'a' },
							],
							text: {
								en: 'You locate the luggage in cargo while door is getting fixed.<br /><br />Note: Special rules apply for animals into and out of the UK.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'If the dog escapes it could be an issue.<br /><br />Note: Special rules apply for animals into and out of the UK.'
					},
					effects: [{ type: 'threat', threatId: 'dog-in-cabin' }],
				},
			},
		],
	},
	{
		id: 8,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: '6 Passengers Late for Boarding'
		},
		text: {
			en: '6 passengers are arriving late from a connecting fligh. Expect 25 - 30 min. delay.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for the passengers'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The passengers are very happy to make the flight. You now have a full flight.'
					},
					effects: [
						{ type: 'inconvenience', value: -1 },
						{ type: 'threat', threatId: 'full-flight' },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Leave the passengers behind'
				},
				cost: 0,
				consequences: {
					text: '',
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	/* Boarding */
	{
		id: 9,
		gameStep: 'boarding',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'crew-sus' }],
				cardId: 101,
			},
		],
		title: {
			en: 'Possible Incident'
		},
		text: {
			en: 'The police arrives ...'
		},
		title2: {
			en: 'Alcohol test'
		},
		text2: {
			en: 'The police arrives at the gate to perform an alcohol test on the crew. They where called from the hotel due to suspicion of intoxication. Thankfully you already took care of this issue, so nothing further happens.'
		},
		roundsMax: 0,
	},
	{
		id: 10,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Drunk Passenger'
		},
		text: {
			en: 'A big man has difficulties keeping his balance on his way to the gate. He seems polite although he is very drunk.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You need to locate the passengers luggage in cargo.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The drunk passenger falls asleep. He might cause more problems when he wakes up.'
					},
					effects: [{ type: 'threat', threatId: 'drunk-passenger' }],
				},
			},
		],
	},
	{
		id: 11,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Lots of Handluggage'
		},
		text: {
			en: 'A large amount of hand luggage is present and it is difficult to find free space.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Move the excess luggage to the cargo compartment'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You take the time to get the extra hand luggage stored in cargo. Remember to ask about Lithium batteries and Medicin in the luggage placed in cargo.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ask passengers to stow away everything as good as possible'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The overloaded luggage compartment could become a problem.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'hand-luggage' }
					],
				},
			},
		],
	},
	{
		id: 12,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Child Seated at Emergency Exit'
		},
		text: {
			en: 'A child is seated at an emergency exit. The passengers (3) have paid extra for the seats.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Reseat the passengers'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'With some effort you manage to reseat the passengers.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'risk', value: -1 },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let the passengers be, despite the risk.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'emergency-exit' },
					],
				},
			},
		],
	},

	/* Flight 1 */
	{
		id: 13,
		gameStep: 'flight1',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'hand-luggage' }],
				cardId: 102,
			},
		],
		title: {
			en: 'Possible Incident'
		},
		text: {
			en: 'Meal service is happening...'
		},
		title2: {
			en: 'Crisis averted'
		},
		text2: {
			en: 'When a woman opens the hand luggage bin you notice a bottle of whisky placed close to the opening. Had there been more handluggage, experience tells you this would likely have fallen out. Crisis averted.'
		},
		roundsMax: 0,
	},
	{
		id: 14,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Afraid of Flying'
		},
		text: {
			en: 'A young women is very nervous. When asked, she reveals that she is terrified of flying.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Take her to the AFT galley and try to calm her down'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You spend 20 min. calming her down.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore the problem'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'If the passenger panics later on this could become a problem.'
					},
					effects: [{ type: 'threat', threatId: 'fear-of-flying' }],
				},
			},
		],
	},
	{
		id: 15,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Re-routing'
		},
		text: {
			en: 'ATC wants you to re-route due to traffic. The route will extend flight time by 20 min.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny the request'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You refuse the re-routing. This will cause some holding upon arrival.'
					},
					effects: [{ type: 'threat', threatId: 'possible-holding' }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Accept the re-routing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Because you loaded extra fuel, the re-routing isn\'t a threat.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'minimum-fuel' }],
							text: {
								en: 'You didn\'t load extra fuel so the re-routing has certain risks.'
							},
							effects: [
								{ type: 'risk', value: 1 },
								{ type: 'threat', threatId: 'possible-holding' },
							],
						},
					],
				},
			},
		],
	},
	{
		id: 16,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Short Connection Time'
		},
		text: {
			en: 'A group of 15 business executives has a very short connection time. They want you to contact the airport for direct transport to the aircraft. One of them wants you to contact the airport for direct transportation to the aircraft. She seems stressed.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny the request'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You calmly tell them you can\'t comply with their request.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 15, optionId: 'default' }, ],
							text: {
								en: 'Unfortunately, because you accepted the re-routing they will probably miss their flight.'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'threat', threatId: 'short-connection'}
							]
						}
					]
				},
			},
			{
				id: 'default',
				text: {
					en: 'Comply with the request'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You contact the airport as requested.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 15, optionId: 'default' }, ],
							text: {
								en: 'Unfortunately, because you accepted the re-routing they will probably miss their flight.'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'threat', threatId: 'short-connection'}
							]
						}
					]
				},
			},
		],
	},

	/* Flight 2 */
	{
		id: 17,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'drunk-passenger' }],
				cardId: 103,
			},
		],
		title: {
			en: 'Possible Incident'
		},
		text: {
			en: 'About that drunk passenger...'
		},
		title2: {
			en: 'Crisis averted'
		},
		text2: {
			en: 'You are told that the drunk passenger you rejected at the gate started acting up in the airport because he was denied boarding. Crisis averted.'
		},
		roundsMax: 0,
	},
	{
		id: 18,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Passenger argument'
		},
		text: {
			en: 'Two passengers are arguing because one passenger does not want to raise his seat to an upright position during the meal service.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to calm them down'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You help the passenger reach an agreement for now.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'dirtydozen', dirtyDozenId: 1, valueMin: 1 },
							],
							text: {
								en: 'You try to calm them down, but your lack of communication skills renders your effort futile.'
							},
							effects: [{ type: 'inconvenience', value: 1 }],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let the passengers sort it out'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'They agree and settle down.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 19,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Table Broken'
		},
		text: {
			en: 'An aisle seat table is broken and will not fasten.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Relocate passengers to other seats'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You relocate the passenger.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'full-flight' }],
							text: {
								en: 'You can\'t relocate the passenger as there are no vacant seats. The broken table could be an issue in case of an evacuation.'
							},
							effects: [
								{ type: 'risk', value: 1 },
								{ type: 'threat', threatId: 'table-broken' },
							],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Try to fix the table with duct tape'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The table is fixed with duct tape. Nothing further happens.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The broken table could become a problem in case of evacuation.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'table-broken' },
					],
				},
			},
		],
	},
	{
		id: 20,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Company Survey'
		},
		text: {
			en: 'You have to complete the company survey before landing.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Cancel the survey'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You cancel the survey.'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ask C/A 3 to distribute the leaflets and collect them again just before landing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You manage to get the surveys handled.'
					},
					effects: [{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 }],
				},
			},
		],
	},

	/* Flight 3 */
	{
		id: 21,
		gameStep: 'flight3',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'dog-in-cabin'}],
				cardId: 25
			}
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'You hear some noises comming from the AFT galley ...'
		},
		title2: {
			en: 'All is well'
		},
		text2: {
			en: 'It was nothing. You take a moment of rest before continuing your duties.<br /><br />By rejecting the dog from boarding, you avoided a critical incident.'
		},
		roundsMax: 0,
	},
	{
		id: 22,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Short Connection Time'
		},
		text: {
			en: 'One of the business executives is asking about connections and rebookings, since she can see that they will miss their flight.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Tell her to sit down and contact the transfer center in the airport. You cannot do anything now'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'She sits down and accepts your answer.'
					},
					effects: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Try to help her with the request'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You can\'t get the information, since there is heavy delay into LHR, and gates are changing all the time.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore her as you are busy with other things'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'She sits down and doesn\'t approach you any further.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 23,
		gameStep: 'flight3',
		type: 'replace',
		cardId: 27,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'possible-holding'}],
				cardId: 105
			},
			{
				conditions: [{ type: 'prev-choice', cardId: 15, optionId: 'a' }, ],	
				cardId: 26
			}
		],
		title: '',
		text: '',
		roundsMax: 0,
	},
	{
		id: 24,
		gameStep: 'flight3',
		type: 'replace',
		cardId: 107,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'captain-headache'}],
				cardId: 106
			}
		],
		title: '',
		text: '',
		roundsMax: 0,
	},

	/* Extra cards */
	{
		id: 25,
		type: 'default',
		title: {
			en: 'Dog in Cabin'
		},
		text: {
			en: 'The dog has escaped the hand bag and is running around freely in the cabin.'
		},
		roundsMax: 0,
		options: [
			{
				id: 'a',
				text: {
					en: 'Help the passenger to catch the dog'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The dog is caught and returned to the bag. Nothing further happens.'
					},
					effects: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask other passengers to help the passenger to catch the dog'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The dog is caught and returned to the bag. Nothing further happens.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore the situation'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The dog is caught and returned to the bag. Nothing further happens.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 26,
		title: {
			en: 'Crisis averted'
		},
		type: 'passive',
		text: {
			en: 'ATC ask you to join holding over present position due to traffic indbound LHR. Expected delay 45 min. Thankfully you didn\'t accept the re-route earlier, so this is within your fuel budget.'
		},
		roundsMax: 1
	},
	{
		id: 27,
		title: {
			en: 'Crisis averted'
		},
		type: 'passive',
		text: {
			en: 'ATC informs you of heavy traffic inbound at LHR. Thankfully your re-routing helps you arrive at a more oportune time, resulting in minimum holding time.'
		},
		roundsMax: 1
	},

	/* Critical events */
	{
		id: 101,
		title: {
			en: 'Alcohol test'
		},
		type: 'critical',
		text: {
			en: 'The police arrives at the gate to perform an alcohol test on the crew. They where called from the hotel due to suspicion of intoxication.'
		},
		roundsMax: 0,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'All crew members take the test'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'CA # 2 is above limit for flying and is arrested. %name% is called. The flight is delayed 1 hour and 15 min.'
					},
					effects: [
						{ type: 'inconvenience', value: 2 },
						{ type: 'replace-crewmember', slotId: 4},
					],
					conditionals: [],
				},
			},
		],
	},
	{
		id: 102,
		title: {
			en: 'Hand Luggage'
		},
		type: 'critical',
		text: {
			en: 'During meal service a women needs her handbag in the overhead bin. As she opens the bin, a bottle of whiskey falls out and hit another passenger in the head. The man is bleeding.'
		},
		roundsMax: 0,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Give First Aid to the passenger'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The man is ok after treatment, and returns to his seat.'
					},
					effects: [],
					conditionals: [],
				},
			},
		],
	},
	{
		id: 103,
		title: {
			en: 'Drunk Passenger'
		},
		type: 'critical',
		text: {
			en: 'The passenger who was drunk earlier, has woken up. He is in forward galley and wants a vodka/lemon and a beer.'
		},
		roundsMax: 0,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny him any alcohol, but offer him a water or a soft drink free of charge.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The man calms down and return to his seat and falls asleep. No further problems.'
					},
					effects: [],
					conditionals: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Deny him any alcohol and tell him to sit down'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The man gets angry and tries to force his way into the cockpit in order to speak with the Captain. After a while he calms down and nothing further happens.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'cockpit-door-lock' }],
							text: {
								en: 'The man gets angry and try to force his way into the cockpit in order to speak with the Captain. The damaged cockpit door soon gives in. with much effort you and some other passengers manage to get the man strapped to a chair where he falls asleep.'
							},
							effects: [
								{ type: 'inconvenience', value: 1},
								{ type: 'risk', value: 1},
								{ type: 'threat', threatId: 'passenger-strapped' },
							],
						},
					],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Give him what he has requested, since he seems ok now.'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The man return to his seat with his drinks. He is soon drunk again and is a potential threat.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'drunk-passenger2'}, // TODO: extra?
					],
					conditionals: [],
				},
			},
		],
	},
	{
		id: 104,
		title: {
			en: 'Passenger in Cockpit'
		},
		type: 'critical',
		text: {
			en: 'The drunk passenger manages to force the malfunctioning door open and is entering the cockpit.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to calm the the passenger down and get him out of the cockpit'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The man calms down and leaves cockpit voluntarily.'
					},
					effects: [{ type: 'dirtydozen', dirtyDozenId: 4, value: 1 }],
					conditionals: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask a couple of passenger to assists you and force the man out of the cockpit. Strap him to his seat'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You manage to force the passenger out of the cockpit.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'passenger-strapped' },
					],
					conditionals: [],
				},
			},
		],
	},
	{
		id: 105,
		title: {
			en: 'ATC'
		},
		type: 'critical',
		text: {
			en: 'ATC ask you to join holding over present position due to traffic indbound LHR. Expected delay 45 min.'
		},
		roundsMax: 0,
		options: [],
		consequences: {
			text: {
				en: 'ATC ask you to join holding over present position due to traffic indbound LHR. Expected delay 45 min.'
			},
			hasCallFacilitatorText: true,
			effects: []
		}
	},
	{
		id: 106,
		title: {
			en: 'Captain Incapacitated'
		},
		type: 'critical',
		text: {
			en: 'The captain is very sick. He is not responding but is breathing. He looks pale.'
		},
		roundsMax: 0,
		options: [],
		consequences: {
			text: {
				en: 'The captain is very sick. He is not responding but is breathing. He looks pale.'
			},
			hasCallFacilitatorText: true,
			effects: []
		}
	},
	{
		id: 107,
		title: {
			en: 'Co-pilot Incapacitated'
		},
		type: 'critical',
		text: {
			en: 'Out of the blue the Co-pilot feels uncomfortable and passes out. He is still breathing. He looks pale.'
		},
		roundsMax: 0,
		options: [],
		consequences: {
			text: {
				en: 'Out of the blue the Co-pilot feels uncomfortable and passes out. He is still breathing. He looks pale.'
			},	
			hasCallFacilitatorText: true,
			effects: []
		}
	},
];

export { eventCards };
