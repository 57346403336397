/* eslint-disable max-len */
/* Regex replace:
	text: '(.+?)'
text: {
	en: '$1'
}
*/


const eventCards = [
	/* Check-in */
	{
		id: 2,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Late crew',
			ja: '乗務員の到着遅れ'
		},
		text: {
			en: 'CA3 is 30 minutes late due to FTL.',
			ja: 'CA3は業務間の休息規定のため30分遅れる'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for the CA',
					ja: '客室乗務員を待つ'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for the CA.',
						ja: '客室乗務員を待つ'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Start preflight briefing without the CA',
					ja: '客室乗務員抜きで飛行前ブリーフィングを開始'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Briefing of the crew member needs to be done upon arrival. This could create pressure in the boarding phase.',
						ja: 'ブリーフィングは乗務員の到着の際に行う必要がある。そのため、航空機搭乗のフェーズにプレッシャーがかかる可能性がある。'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 },
						{ type: 'threat', threatId: 'late-crew' },
					],
				},
			},
		],
	},
	{
		id: 3,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Late baggage',
			ja: '手荷物の到着遅れ'
		},
		text: {
			en: 'A baggage belt in the airport has broken down. This means that 50-60 suitcases will be approx. 45 min. late. There are many connecting flights.',
			ja: '空港内の荷物輸送コンベアが故障した。そのため、50～60個のスーツケース到着が約45分遅れることになる。羽田からの乗り継ぎ便は多い。'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for the suitcases',
					ja: 'スーツケースを待つ'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You delay the flight to get the suitcases on board.',
						ja: 'スーツケースが搭載を待つため、フライトが遅れる'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'threat', threatId: 'missed-connections' }
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 2, optionId: 'a' }],
							text: {
								en: 'As you are already waiting for the CA, this doesn\'t add much extra time.',
								ja: 'すでに客室乗務員を待っている状況なので、それほど余分な時間はかからない。'
							},
							effects: [{ type: 'threat', threatId: 'missed-connections' }],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Leave the suitcases behind',
					ja: 'スーツケースを搭載しない'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You leave the suitcases behind.',
						ja: 'スーツケースを搭載せず出発する'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 4,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Weather at destination',
			ja: '目的地（羽田）の天候'
		},
		text: {
			en: 'Weather at destination is deteriorating and snow clearing of runways might be necessary.',
			ja: '目的地の天候が悪化しており、羽田の滑走路の除雪が必要となる可能性がある。'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Load extra fuel to cover any landing delays',
					ja: '着陸許可が下りるまでの空中待機のため余分な燃料を搭載'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You take no chances and load extra fuel.',
						ja: '安全策をとり余分な燃料を搭載する'
					},
					effects: [{ type: 'risk', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Stick to the original fuel calculation',
					ja: '当初の燃料搭載量を維持する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You stick to the original fuel calculation and accept minimum fuel.',
						ja: '当初の燃料搭載量から変更せずに少ない燃料量でいく'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 },
						{ type: 'threat', threatId: 'minimum-fuel' },
					],
				},
			},
		],
	},
	{
		id: 5,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Two unaccompanied minors',
			ja: '２名の同伴者のいない未成年者'
		},
		text: {
			en: 'Two unaccompanied brothers at the ages of 10 and 12 have checked in. They are used to flying without supervisors.',
			ja: '10歳と12歳の、大人の同伴者がいない兄弟2人がチェックインした。彼らは監督者なしのフライトに慣れている。'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding due to risk of diversion',
					ja: '目的地変更のリスクがあるため、搭乗を拒否する。(あなたには羽田に到着するまでの子供の管理に責任がある。）'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You need to find passengers\' baggage.',
						ja: '預け荷物から乗客の荷物を取り出す必要がある'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 2, optionId: 'a' }],
							text: {
								en: 'As you are already waiting for the CA, this doesn\'t add much extra time.',
								ja: 'あなたは既に客室乗務員の到着を待っているので、更にプラスアルファの時間を要することはない'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 3, optionId: 'a' }],
							text: {
								en: 'As you are already waiting for the CA, this doesn\'t add much extra time.',
								ja: 'あなたは既に客室乗務員の到着を待っているので、更にプラスアルファの時間を要することはない'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding',
					ja: '搭乗を許可する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'In case of diversion you have the responsibility of the unaccompanied minors.',
						ja: '目的地変更の場合は、同伴者のいない未成年者について責任を負うことになる'
					},
					effects: [
						{ type: 'threat', threatId: 'unaccompanied-minors' },
					],
				},
			},
		],
	},

	/* Preflight */
	{
		id: 7,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'First Aid Kit',
			ja: '救急キット'
		},
		text: {
			en: 'The seal on a First Aid kit is broken.',
			ja: '救急キットの封印シールが破れている'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for a new kit',
					ja: '新しい救急キットを待つ'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for a new kit.',
						ja: '新しい救急キットを待つ'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Check contents are okay and write a note',
					ja: '中身に問題がないことを確認し記録を残す'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Everything seems ok after a quick check.',
						ja: '素早い確認で全てOKであることを確認'
					},
					effects: [{ type: 'threat', threatId: 'first-aid-kit-unsealed' }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore',
					ja: '無視する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You assume the content is fine.',
						ja: '中身は大丈夫であろうと想定する'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'first-aid-kit-unsealed' },
					],
				},
			},
		],
	},
	{
		id: 8,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Navigation Database',
			ja: '飛行機の航法に必要なデータベース'
		},
		text: {
			en: 'A technician is having trouble updating the Navigation Database. Database expires Feb. 12 23:59.',
			ja: '整備士による、飛行機の航法に必要なコンピュータのデータベースの更新が不具合で実施出来ない。データベースの有効期限は2月12日 23:59迄（現状、航法システムは動作はするが、利用するデータを最新版の情報に更新できない）。'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue update. Expect 30 minutes delay',
					ja: '更新作業を続ける。30分の予定時間からの遅延が発生する見込み。'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You delay the flight to make sure the update is completed.',
						ja: '更新作業完了を待つことでフライトを遅延させることになる'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'a' }],
							text: {
								en: 'The update time matches up with the time it takes to get the new first aid kit.',
								ja: '更新作業完了までの時間は、救急キットが届くまでの時間と一致している'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Stop the process and fly without update',
					ja: '作業を中止させて、更新しないままフライトする'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You are still be able to fly the rest of the day. In case of diversion you need an updated database or a MEL release before departure.',
						ja: 'この日の残りのフライトは可能。目的地変更の場合、変更先からの出発前にデータベースを更新するか、もしくはデータベースを更新せずに飛行する特別な許可を得ることが必要となる。'
					},
					effects: [{ type: 'threat', threatId: 'database-expiry' }],
				},
			},
		],
	},
	{
		id: 9,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Passenger Meals Missing',
			ja: '機内食の不足'
		},
		text: {
			en: 'Two trolleys containing 50 passenger meals are missing',
			ja: '50食分の機内食が積まれたカート2台が行方不明'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Ask the catering company for extra food. Expected delay: 20-25 minutes',
					ja: '機内食ケータリング会社に追加の機内食配送を依頼：20 から25分の遅れ発生の見込み'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for the food.',
						ja: '機内食を待つ'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 7, optionId: 'a' },
								{ type: 'prev-choice', cardId: 8, optionId: 'a' },
							],
							text: {
								en: 'The time it takes for the extra food to arrive matches the time it takes to get the new first aid kit and update the database.',
								ja: '追加の機内食が届くまでの時間と、新しい救急キットの入手やデータベースの更新にかかる時間が一致'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'a' }],
							text: {
								en: 'The time it takes for the extra food to arrive matches the time it takes to get the new first aid kit.',
								ja: '追加の機内食が届くまでの時間と、新しい救急キットの入手にかかる時間が一致'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 8, optionId: 'a' }],
							text: {
								en: 'The time it takes for the extra food to arrive matches the time it takes to update the database.', 
								ja: '追加の機内食が届くまでの時間と、データベースの更新にかかる時間が一致'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing',
					ja: '何もしない'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You continue with less food than planned.', 
						ja: '予定よりも少ない機内食搭載量のまま進める'
					},
					effects: [
						{ type: 'inconvenience', value: 2 },
						{ type: 'threat', threatId: 'no-food' },
					],
				},
			},
		],
	},
	{
		id: 10,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Oven and coffee machine have no power',
			ja: 'ギャレーのオーブンとコーヒーマシンの電源が入らない'
		},
		text: {
			en: 'One coffee machine in the forward galley is not working.',
			ja: '前方ギャレーのコーヒーマシン１台が使用出来ない'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call for a technician to fix the problem',
					ja: '整備士を呼んで不具合を直してもらう'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for a technician to fix the problem.',
						ja: '不具合を直すために整備士を待つ'
					},
					effects: [
						{ type: 'inconvenience', value: 3 },
						{ type: 'risk', value: -1 },
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'a' }],
							text: {
								en: 'You are already delayed, so waiting for a technician won\'t add to that.',
								ja: 'すでに遅れており、整備士を待っても遅延時間に追加はない'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'risk', value: -1 },
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 8, optionId: 'a' }],
							text: {
								en: 'You are already delayed, so waiting for a technician won\'t add to that.',
								ja: 'すでに遅れており、整備士を待っても遅延時間に追加はない'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'risk', value: -1 },
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 9, optionId: 'a' }],
							text: {
								en: 'You are already delayed, so waiting for a technician won\'t add to that.',
								ja: 'すでに遅れており、整備士を待っても遅延時間に追加はない'
							},
							effects: [
								{ type: 'inconvenience', value: 1 },
								{ type: 'risk', value: -1 },
							],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Check C/B and call for a technician to disconnect the coffee machine',
					ja: '電源ブレーカーを確認し、整備士にコーヒーマシンを電気的に回路から切り離すことを依頼する'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You now have 3 electrical problems in the FWD galley. There is a risk of electrical failure in the wire system.',
						ja: '前方ギャレーに３つの電気的不具合が発生している。電気系統の不具合が発生しているリスクがある。'
					},
					effects: [
						{ type: 'risk', value: 2 },
						{ type: 'threat', threatId: 'electrical-malfunction' },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Use other coffee machines',
					ja: '他のコーヒーマシンを使用する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You now have 3 electrical problems in the FWD gally. There is a risk of electrical failure in the wire system.',
						ja: '前方ギャレーに３つの電気的不具合が発生している（フライト情報を確認してください）。電気系統の不具合が発生しているリスクがある。'
					},
					effects: [
						{ type: 'risk', value: 2 },
						{ type: 'threat', threatId: 'electrical-malfunction' },
					],
				},
			},
		],
	},

	/* Boarding */
	{
		id: 12,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Cello in cabin',
			ja: '客室内のチェロ'
		},
		text: {
			en: 'A musician refuses to check in his cello as oddsize baggage and has brought it to the gate.',
			ja: '音楽家が、チェロを超過サイズ預け荷物とするのを拒否し、搭乗口まで持ってきている'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Let the passenger keep his instrument. Strap the instrument to an unused seat somewhere',
					ja: 'その乗客に楽器の客室内持ち込みを許す。楽器をどこか空いている座席にベルトで固定する。'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'If the Cello is not properly secured to the seat, it could be a potential threat later. I.e., during turbulence.',
						ja: 'もしチェロがしっかりと座席に固定できていない場合、それは後に脅威（スレット）となる可能性がある。例えば乱気流遭遇時など。'
					},
					effects: [{ type: 'threat', threatId: 'cello-in-cabin' }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Insist on leaving it in cargo',
					ja: '貨物室へ搭載するよう要請する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger refuses to board. Baggage must be located.',
						ja: '乗客が搭乗するのを拒否。乗客の預け荷物を見つけないとならない。'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 13,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Deportee',
			ja: '強制送還者'

		},
		text: {
			en: 'One unaccompanied male deportee is crying. He is mumbling weird things when asked if he wants to board.',
			ja: '一人の男性の強制送還者（警察の護衛なし）が泣いている。彼は明日、羽田から強制送還される予定。搭乗するかどうか聞いても、よく分からないことをつぶやいている。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding',
					ja: '搭乗を拒否する'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Baggage must be located.',
						ja: '預け荷物を見つけないとならない'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 12, optionId: 'default' }],
							text: {
								en: 'You get the luggage out while also getting the luggage from the cellist who refused to board.',
								ja: '搭乗を拒否したチェリストの預け荷物を取り出しつつ、この乗客の預け荷物を取り出す。'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding',
					ja: '搭乗させる'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The behavior of the deportee once airborne is unknown. This could become a problem later.',
						ja: '離陸後にとる強制送還者の行動が不明。のちにトラブルになる可能性がある。'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'deportee' },
					],
				},
			},
		],
	},
	{
		id: 14,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Water, please',
			ja: '水をください'
		},
		text: {
			en: 'A passenger demands a glass of water for his 2-year-old daughter.',
			ja: '乗客が、彼の２歳の娘のために水を1杯欲しがっている'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Give him a bottle of water. Charge him later',
					ja: 'ボトル入りの水を渡す。のちに料金を請求する。'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You make a mental note to charge him later.',
						ja: '後に彼に料金を請求することを覚えておく'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Give him a bottle of water. No charge.',
					ja: 'ボトル入りの水を渡す。料金は請求しない。'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You bring him some water.',
						ja: '彼に水を渡す'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let them wait',
					ja: '待ってもらう'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The dehydration might get worse.',
						ja: '脱水症状が悪化する可能性がある'
					},
					effects: [{ type: 'threat', threatId: 'dehydrated-baby' }],
				},
			},
		],
	},
	{
		id: 15,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Insulin in cargo',
			ja: '貨物室のインシュリン'
		},
		text: {
			en: 'A diabetic forgot to pack her medicine in her handluggage. No problem if potential delay is less than 45 minutes.',
			ja: '糖尿病患者が手荷物の中に薬を入れ忘れた。45分以内の遅延であれば問題ない。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Locate the medicine in cargo',
					ja: '貨物内の医薬品を見つける'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You manage to locate the bag with the medicine.',
						ja: '何とか医薬品を入れたバッグを見つけた'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 12, optionId: 'default' },
								{ type: 'prev-choice', cardId: 13, optionId: 'a' },
							],
							text: {
								en: 'You get the medicine out while also getting the luggage from the other passengers who didn\'t board.',
								ja: '搭乗しなかった他の乗客の荷物を取り出す際に、薬を取り出すことができた'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 12, optionId: 'default' }],
							text: {
								en: 'You get the medicine out while also getting the luggage from the cellist who refused to board.',
								ja: '搭乗を拒否したチェリストの荷物を取り出す際に、薬を取り出すことができた'
							},
							effects: [],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 13, optionId: 'a' }],
							text: {
								en: 'You get the medicine out while also getting the luggage from the deportee.',
								ja: '強制送還者の荷物を取り出す際に、薬を取り出すことができた'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Other passengers might have the needed medicine if a long delay should occur.',
					ja: 'もし長時間の遅延となった場合には、他の乗客が必要な薬を持っていることを期待する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'This could be a problem later.',
						ja: 'これは後にトラブルとなる可能性がある'
					},
					effects: [{ type: 'threat', threatId: 'medicine-in-cargo' }],
				},
			},
		],
	},

	/* Flight 1 */
	{
		id: 17,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Couple in heavy discussion',
			ja: 'もめているカップル'
		},
		text: {
			en: 'A big drama is playing out in the rear of the cabin. A couple is yelling at each other using very explicit language.',
			ja: '客室の後方で、大きなもめごとが繰り広げられている。カップルが罵詈雑言で怒鳴り合っている。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to calm down the passengers',
					ja: '乗客を落ち着かせようとする'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Despite your best effort the couple continues to argue. The situation could escalate and become a problem.',
						ja: 'あなたが最善を尽くしても、カップルは口論を続けている。状況がエスカレートして問題になる可能性がある。'
					},
					effects: [
						{ type: 'inconvenience', value: 2 },
						{ type: 'threat', threatId: 'arguing-passengers' },
					],
					conditionals: [
						{
							conditions: [
								{ type: 'crew', isSelected: true, crewId: 9 },
								{ type: 'dirtydozen', dirtyDozenId: 4, valueMin: 0, valueMax: 0 },
							],
							text: {
								en: 'Lillian manages to calm them down.',
								ja: 'リリアンが彼らを落ち着かせた'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Place passengers far apart',
					ja: '乗客同士を離れた席に移した'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You place the passengers far apart.',
						ja: '乗客同士を離れた席に移した'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Don\'t interfere',
					ja: '関与しない'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The couple continues to argue. The situation could escalate and become a problem.',
						ja: 'カップルは口論を続けている。状況がエスカレートして問題になる可能性がある。'
					},
					effects: [{ type: 'threat', threatId: 'arguing-passengers' }],
				},
			},
		],
	},
	{
		id: 18,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Smoking in the lavatory',
			ja: '化粧室での喫煙'
		},
		text: {
			en: 'A passenger is caught smoking in the lavatory. She seems nervous.',
			ja: '化粧室で喫煙している乗客が見つかった。彼女はナーバスになっているようだ。'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Confiscate cigarettes and file a report',
					ja: 'タバコを没収して報告書を提出する'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You take care of the cigarettes and file a report on the situation.',
						ja: 'タバコの始末をし、状況報告書を提出する'
					},
					effects: [{ type: 'risk', value: -1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Confiscate cigarettes',
					ja: 'タバコを没収する'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You confiscate the cigarettes.',
						ja: 'タバコを没収する'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing',
					ja: '何もしない'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let it slide.',
						ja: '見なかったことにする'
					},
					effects: [{ type: 'risk', value: 1 }],
				},
			},
		],
	},
	{
		id: 19,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Diaper change in cabin',
			ja: '機内でのおむつ交換'
		},
		text: {
			en: 'A father is changing a diaper on his baby at his seat. It\'s just pee, but a passenger nearby complains.',
			ja: '父親が自分の席で赤ちゃんのオムツを替えている。おしっこだけだが、近くにいた乗客からクレームが入る。'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'You direct him to the lavatory, so he can finish the diaper change',
					ja: 'トイレに案内して、おむつ交換を終わらせる'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'He goes to the lavatory to change the diaper.',
						ja: '父親はトイレに行き、おむつ交換を行う'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let him finish the diaper change',
					ja: 'そのまま父親におむつ交換させる'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'He finishes quickly, and nothing further happens.',
						ja: 'すばやく交換が終わり、それ以上は何も起きない'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 20,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Burn accident with oven',
			ja: 'オーブンでの火傷事故'
		},
		text: {
			en: 'SCCM gets 3 cm blisters on the right hand from a burn accident involving an oven.',
			ja: '先任客室乗務員がオーブンで火傷して右手に3cmの水ぶくれができた'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Seat the SCCM and treat the hand with cooling gel',
					ja: '先任客室乗務を座らせて仕事から外し、冷却ジェルで手の手当をする'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'In the next round you only have three resources because SCCM is off-duty.',
						ja: '次のラウンドでは、先任客室乗務員が非番となるため、3つのリソースしかない'
					},
					effects: [{ type: 'disable-resources', value: 1, duration: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let the SCCM return to duty after splashing some water on it',
					ja: '火傷に水をかけて先任客室乗務員を職務に復帰させる'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Despite the burns, the SCCM returns to work.',
						ja: '火傷を負いながらも、先任客室乗務員は仕事に復帰する'
					},
					effects: [{ type: 'dirtydozen', dirtyDozenId: 4, value: 1 }],
				},
			},
		],
	},

	/* Flight 2 */
	{
		id: 22,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'arguing-passengers' }],
				cardId: 105,
			},
		],
		title: {
			en: 'Possible incident',
			ja: '可能性のあるインシデント'
		},
		text: {
			en: 'What happened to the arguing passengers?',
			ja: '言い争っていた乗客はどうなったのか？'
		},
		title2: {
			en: 'Calm couple',
			ja: '落ち着くカップル'
		},
		text2: {
			en: 'Congratulations. By solving the problem with the arguing passengers early on you avoided an escalation of the situation.',
			ja: 'おめでとうございます。言い争っている乗客の問題を早い段階で解決することで、状況の悪化を避けることができました。'
		},
		roundsMax: 0,
	},
	{
		id: 23,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'dehydrated-baby' }],
				cardId: 104,
			},
		],
		title: {
			en: 'Possible incident',
			ja: '可能性のあるインシデント'
		},
		text: {
			en: 'What happened to the thirsty baby?',
			ja: '喉が渇いた赤ちゃんはどうなった？'
		},
		title2: {
			en: 'Hydrated child',
			ja: '水分補給された子供'
		},
		text2: {
			en: 'Congratulations. By giving water to the child previously you avoided an incident.',
			ja: 'おめでとうございます。子どもに水を与えたことで、インシデントを回避することができました。'
		},
		roundsMax: 0,
	},

	{
		id: 24,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Seat is broken',
			ja: '座席の不具合'
		},
		text: {
			en: 'A passenger seat cannot lock in upright position.',
			ja: '客室座席の１つで背もたれを立てた状態で固定できない'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to fix the seat',
					ja: '座席の修理を試みる'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You manage to fix the seat to a workable state.',
						ja: '使用可能な状態に座席を修理することに成功した'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing',
					ja: '何もしない'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'No one complains about the seat.',
						ja: '誰も座席について不平を言わない'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 25,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Turbulence warning',
			ja: '乱気流の警報'
		},
		text: {
			en: 'Coffee is being served. Light-moderate turbulence is expected in 10 minutes.',
			ja: '現在コーヒーが提供されている。10分後に軽度から中程度の乱気流が予想される。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Cancel coffee', 
					ja: 'コーヒー提供を中止する'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You stop serving coffee and clear away the coffee serving as quickly as possible.',
						ja: 'コーヒーの提供を中止し、できるだけ早く片付ける'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let passengers finish their coffee',
					ja: '乗客にコーヒーを飲ませつづける'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let the passengers finish their coffee. If turbulence is encountered, people could spill their hot coffee.',
						ja: 'そのままコーヒーを飲み終わらせる。乱気流に遭遇した場合、乗客は熱いコーヒーをこぼすかもしれない。'
					},
					effects: [{ type: 'threat', threatId: 'coffee-in-cabin' }],
				},
			},
		],
	},

	/* Flight 3 */
	{
		id: 27,
		gameStep: 'flight3',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'coffee-in-cabin' }],
				cardId: 103,
			},
		],
		title: {
			en: 'Possible incident',
			ja: '可能性のあるインシデント'
		},
		text: {
			en: 'Turbulence is getting worse ...',
			ja: '乱気流が強くなる ...'
		},
		title2: {
			en: 'Halted serving',
			ja: 'サービスの中止'
		},
		text2: {
			en: 'Congratulations. By refusing to serve coffee early on, you avoided an incident where a passenger got burned with hot coffee.',
			ja: 'おめでとうございます。早い段階でコーヒーの提供を中止したことで、乗客が熱いコーヒーで火傷をするような事態を回避できました。'
		},
		roundsMax: 0,
	},
	{
		id: 28,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Lightning strike',
			ja: '雷の直撃'
		},
		text: {
			en: 'A big boom and all light goes out in cabin. Some people start screaming.',
			ja: '大きな音がして、機内の明かりが消える。何人かの人が叫びだす。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to restore light in the cabin before telling the passengers about what happened',
					ja: '乗客に状況を知らせる前に、機内の明るさを回復させることを試みる'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Backup electrical system is activated. Intercom does not work.',
						ja: 'バックアップ用電気系統が作動。インターホン（機内電話システム）は作動しない。'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 1, value: 1 },
						{ type: 'threat', threatId: 'no-intercom' },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Calm down passengers over PA-system before restoring the light in the cabin',
					ja: '客室の電灯を回復させる前に、機内アナウンスで乗客を落ち着かせる'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Backup electrical system is activated. Intercom does not work.',
						ja: 'バックアップ用電気系統が作動。インターホン（機内電話システム）は作動しない。'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 1, value: 1 },
						{ type: 'threat', threatId: 'no-intercom' },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Focus on other possible issues',
					ja: '他の可能性のある問題にフォーカスする'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'There might be some more damage to the electrical system. Intercom does not work.',
						ja: '電気系統に他のダメージがあるかもしれない。インターホン（機内電話システム）が使えない。'
					},
					effects: [
						{ type: 'dirtydozen', dirtyDozenId: 1, value: 1 },
						{ type: 'threat', threatId: 'no-intercom' },
					],
				},
			},
		],
	},
	{
		id: 29,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Passengers become airsick',
			ja: '飛行機酔いの乗客'
		},
		text: {
			en: 'Turbulence is too much for at least two passengers who vomit all over the floor.',
			ja: '強い乱気流で、少なくとも2人の乗客が床一面に嘔吐してしまう'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Cabin crew help with clean up during turbulence',
					ja: '乱気流の中で客室乗務員が清掃を実施'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The cabin crew do their best to clean up the mess.',
						ja: '客室乗務員が一生懸命に掃除する'
					},
					effects: [{ type: 'risk', value: 2 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let them wait until turbulence is completely over',
					ja: '乱気流が収まるまで、そのままの状況で待ってもらう'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You judge it is best to wait with the cleaning until you\'re out of the turbulence.',
						ja: 'あなたは、乱気流を抜けるまでクリーニングで待つのが最良と判断した'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 30,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Passenger goes to the lavatory',
			ja: '化粧室へと席を立つ乗客'
		},
		text: {
			en: 'While turbulence is rocking the plane, a passenger suddenly leaves his seat and aims for the lavatory.',
			ja: '乱気流で揺れる機内で、突然席を立ち、化粧室を目指す乗客がいる。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Leave your station and stop the passenger physically',
					ja: '着席位置から立ち上がり、乗客を止めに行く'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'While trying to stop him, a cabin crew member gets injured...',
						ja: '乗客を止めようとしている際に、客室乗務員が怪我を負う…'
					},
					effects: [{ type: 'critical-event', criticalCardId: 102 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Yell that the passenger must return to his seat immediately',
					ja: 'すぐに座席に戻るように大きな声で呼びかける'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The passenger ignores your call...',
						ja: '乗客は呼びかけを無視…'
					},
					effects: [{ type: 'critical-event', criticalCardId: 101 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let the passenger go to the lavatory. He looks airsick', 
					ja: '乗客を化粧室に行かせてあげる。彼は飛行機酔いで気分が悪そうだ'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The passenger continues towards the toilet...',
						ja: '乗客は化粧室へ向かっている…'
					},
					effects: [{ type: 'critical-event', criticalCardId: 101 }],
				},
			},
		],
	},

	/* Critical events */
	{
		id: 101,
		title: {
			en: 'Passenger injured',
			ja: '乗客の負傷'
		},
		type: 'critical',
		text: {
			en: 'The turbulence is now severe. The passenger is thrown to the floor and isn\'t responding.',
			ja: '乱気流が激しくなった。乗客は床に投げ出されたまま、反応しない。'
		},
		roundsMax: 1,
		noDefaultOption: true,
		isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Assist the injured passenger right away.',
					ja: '負傷した乗客を直ぐに介抱する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger has a severe head trauma, and transparent fluid is running out of his ear. You manage to stabilize the passenger once the turbulence has cleared. While trying to help the passenger, the crew member is knocked to the floor. The right foot appears to be broken.',
						ja: '乗客は頭に激しい外傷を負っており、耳から透明な液体が流れ出ている。乱気流が去った後、乗客の態勢を安定させることに成功した。一方で乗客を助けようとしたとき、乗務員が床に転倒した。乗務員は右足が折れているようだ。'
					},
					hasCallFacilitatorText: true,
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'first-aid-kit-unsealed' }],
							text: {
								en: 'Passenger has a severe head trauma, and transparent fluid is running out of his ear. The extra oxygen masks are missing. The passenger goes unconscious.<br />While trying to help the passenger, the crew member is knocked to the floor. The right foot appears to be broken.',
								ja: '乗客は頭に激しい外傷を負っており、耳から透明な液体が流れ出ている。予備の酸素マスクがない。乗客は意識を失う。<br />乗客を助けようとした際、乗務員が床に叩きつけられる。右足が折れているようです。'
							},
							hasCallFacilitatorText: true,
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Call flight deck. Aid the passenger, if the turbulence lasts longer than 3 minutes.',
					ja: '操縦席に連絡する。乱気流が3分以上続く場合は、乗客を援助する。'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger has a severe head trauma and transparent fluid is running out of his ear. Intercom does not work. However, turbulence clears after two minutes. Patient is breathing, but is unconscious.',
						ja: '乗客の頭部外傷がひどく、耳から透明な液体が流れ出ている。インターホンは作動しない。しかし、乱気流は2分後に解消される。患者は呼吸はしているが、意識はない。'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Tell passengers to remain seated. The injured passenger must wait.',
					ja: '乗客に席を立たないよう伝える。負傷した乗客には、そのまま待ってもらう'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Passenger has a severe head trauma and transparent fluid is running out of his ear.<br /><br />Turbulence clears after two minutes. Patient is breathing but is unconscious.',
						ja: '乗客は頭に激しい外傷があり、耳から透明な液体が流れ出ている。<br /><br />乱気流は2分後に解消される。患者は呼吸しているが、意識がない。'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
		],
	},
	{
		id: 102,
		title: {
			en: 'Crew member injured',
			ja: '客室乗務員の負傷'
		},
		type: 'critical',
		text: {
			en: 'The turbulence is now severe. The CA is thrown to the floor and goes unconscious.',
			ja: '乱気流が激しくなる。客室乗務員が床に投げ出され、意識不明となる。'
		},
		roundsMax: 1,
		noDefaultOption: true,
		isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Assist the injured CA right away',
					ja: '負傷した客室乗務員を直ぐに介抱する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'CA has a severe head trauma, and transparent fluid is running out of her ear.<br /><br />You manage to stabilize her once the turbulence has cleared.',
						ja: '客室乗務員は頭に激しい外傷を負っており、耳から透明な液体が流れ出ている。<br /><br />乱気流が去った後、乗客の態勢を安定させることに成功した。'
					},
					hasCallFacilitatorText: true,
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'first-aid-kit-unsealed' }],
							text: {
								en: 'CA has a severe head trauma, and transparent fluid is running out of her ear.<br /><br />The oxygen masks are missing from first aid kit. The CA goes unconscious.',
								ja: '客室乗務員は頭に激しい外傷を負っており、耳から透明な液体が流れ出ている。<br /><br />救急キットから酸素マスクが紛失している。客室乗務員は意識不明になる。'
							},
							hasCallFacilitatorText: true,
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Call the flight deck for information about the duration of the turbulence. Aid the injured CA, if the turbulence lasts longer than 3 minutes.',
					ja: '操縦席に連絡して、乱気流の継続時間に関する情報を得る。乱気流が3分以上続く場合は、負傷した客室乗務員を助ける。'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'CA has a severe head trauma and transparent fluid is running out of her ear.<br /><br />Turbulence clears after two minutes. CA is breathing but is unconscious.',
						ja: '客室乗務員は頭に大きな外傷があり、耳から透明な液体が流れ出ている。<br /><br />乱気流は2分後に解消される。客室乗務員は呼吸しているが、意識はない。'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Leave the CA in the aisle, until the turbulence is over',
					ja: '乱気流が収まるまで、そのまま客室乗務員を通路に置いておく。'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'CA has a severe head trauma and transparent fluid is running out of her ear.<br /><br />Turbulence clears after two minutes. CA is breathing but is unconscious.',
						ja: '客室乗務員は頭に大きな外傷があり、耳から透明な液体が流れ出ている。<br /><br />乱気流は2分後に解消される。客室乗務員は呼吸しているが、意識はない。'
					},
					hasCallFacilitatorText: true,
					effects: [],
				},
			},
		],
	},
	{
		id: 103,
		title: {
			en: 'Coffee spill',
			ja: 'コーヒーをこぼした'
		},
		type: 'critical',
		text: {
			en: 'Three passengers are burned by spilled coffee.',
			ja: 'こぼれたコーヒーで乗客3人が火傷を負う。'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Treat passengers in galley',
					ja: 'ギャレーで乗客の手当をする'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You treat the passengers in the AFT galley despite the turbulence.',
						ja: '乱気流の中だが、後部ギャレーで乗客の手当をする'
					},
					effects: [{ type: 'risk', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask passengers to remain seated regardless of pain.',
					ja: '痛みがあっても、乗客に着席したままでいるようお願いする'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You tell the passengers to remain seated.', 
						ja: '乗客たちに座席に座ったままでいるよう伝える'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Let the passengers handle the problem.',
					ja: '乗客同士で対処するように依頼する'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let the other passengers sitting close by help out.',
						ja: '近くに座っている他の乗客に手伝ってもらう'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 104,
		title: {
			en: 'Father and child need attention',
			ja: '要注意な父親と子供'
		},
		type: 'critical',
		text: {
			en: 'The two-year old that didn\'t get water before take-off is now drowsy and does not respond very well. The father is very upset.',
			ja: '離陸前に水を飲まなかった2歳児が、ぼーっとして、反応が鈍くなっている。父親はとても慌てている。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Calm down the father and offer the child some water and sweets',
					ja: '父親を落ち着かせ、子どもに水とお菓子をあげる'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Child is ok after treatment.',
						ja: '子供は手当の後に大丈夫になる'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let other passengers handle the problem',
					ja: '他の乗客に対応してもらう'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Child is ok after treatment.',
						ja: '子供は手当の後に大丈夫になる'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 105,
		title: {
			en: 'Couple starts fighting',
			ja: '喧嘩を始めるカップル'
		},
		type: 'critical',
		text: {
			en: 'The heavy discussion from before has now ended in a physical fight. The woman is in a total frenzy and hits the man again and again. He tries to defend himself and apparently does not fight back.',
			ja: '先程までの激しい言い合いが、今度は手を上げる喧嘩になってしまった。女性は完全に狂乱しており、男を何度も殴りつけている。男性は身を守ろうとするが、反撃はしないようだ。'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to calm down the two passengers.',
					ja: '２名の乗客を落ち着かせようとする'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You don\'t manage to calm down the couple. You need help from passengers to strap her down.',
						ja: 'カップルを落ち着かせることができない。乗客の協力を得て、彼女を取り押さえる必要がある。'
					},
					effects: [{ type: 'inconvenience', value: 2 }, { type: 'risk', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'crew', isSelected: true, crewId: 9 },
								{ type: 'dirtydozen', dirtyDozenId: 4, valueMin: 0, valueMax: 0 },
							],
							text: {
								en: 'Lillian manages to calm them down.',
								ja: 'リリアンがなんとか彼らを落ち着かせる'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Relocate the woman and strap her to a seat far away',
					ja: '女性の座席位置を離れた場所に変更し、ベルトを着用させる'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'All passengers get scared because of the crazy woman, when she is dragged through the cabin.',
						ja: '引きずって機内を移動させられる狂乱状態の女性のせいで、乗客は皆怖がる。'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let other passengers handle the situation',
					ja: '他の乗客に対応を任せる'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'With great effort some passengers manages to take control of the situation.',
						ja: '何人かの乗客の、懸命な努力の末、状況をコントロールすることができた。'
					},
					effects: [{ type: 'inconvenience', value: 2 }, { type: 'risk', value: 2 }],
				},
			},
		],
	},
];

module.exports = {eventCards};