const threatsData = [
	{
		id: 'late-crew',
		eventCardId: 2,
		title: {
			en: 'Late Crew'
		}
	},
	{
		id: 'ice-on-wings',
		eventCardId: 3,
		title: {
			en: 'Ice on wings'
		}
	},
	{
		id: 'fatigue',
		eventCardId: 4,
		title: {
			en: 'Fatigue'
		}
	},
	{
		id: 'minimum-fuel',
		eventCardId: 5,
		title: {
			en: 'Minimum Fuel'
		}
	},
	{
		id: 'leakage',
		eventCardId: 7,
		title: {
			en: 'Leakage'
		}
	},
	{
		id: 'fire-hazard',
		eventCardId: 8,
		title: {
			en: 'Fire Hazard'
		}
	},
	{
		id: 'no-airport-id-card',
		eventCardId: 10,
		title: {
			en: 'No Airport ID Card'
		}
	},
	{
		id: 'lithium-ion-battery',
		eventCardId: 12,
		title: {
			en: 'Lithium-Ion Battery'
		}
	},
	{
		id: 'child-not-secured',
		eventCardId: 13,
		title: {
			en: 'Child Not Secured'
		}
	},
	{
		id: 'w-and-b',
		eventCardId: 14,
		title: {
			en: 'W&B'
		}
	},
	{
		id: 'large-handluggage',
		eventCardId: 15,
		title: {
			en: 'Large Handluggage'
		}
	},
	{
		id: 'passenger-seat',
		eventCardId: 18,
		title: {
			en: 'Passenger seat'
		}
	},
	{
		id: 'epileptic-seizure',
		eventCardId: 22,
		title: {
			en: 'Epileptic seizure'
		}
	},
	{
		id: 'short-connection',
		eventCardId: 24,
		title: {
			en: 'Short connection'
		}
	},
	{
		id: 'video',
		eventCardId: 25,
		title: {
			en: 'Video'
		}
	},
	{
		id: 'child-not-secure',
		eventCardId: 27,
		title: {
			en: 'Child not secure'
		}
	},
	{
		id: 'loose-children',
		eventCardId: 28,
		title: {
			en: 'Loose children'
		}
	},
	{
		id: 'heavy-snow',
		eventCardId: 101,
		title: {
			en: 'Heavy snow'
		}
	},
	{
		id: 'unruly-passengers',
		eventCardId: 103,
		title: {
			en: 'Unruly passengers'
		}
	}
	
];

export { threatsData };
