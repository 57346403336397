/* eslint-disable max-len */
const loginUiTexts = {
	adminBtn: {
		en: 'Administrator',
		de: 'Administrator',
		ja: '管理者（アドミニストレーター）'
	},
	login: {
		en: 'Login',
		de: 'Login',
		ja: 'ログイン'
	},
	facilitator: {
		en: 'Facilitator',
		de: 'Spielleiter',
		ja: 'ファシリテーター'
	},
	group: {
		en: 'Group',
		de: 'Gruppe',
		ja: 'グループ'
	},
	email: {
		en: 'e-mail',
		de: 'E-Mail',
		ja: 'グループ'
	},
	password: {
		en: 'password',
		de: 'Passwort',
		ja: 'パスワード'
	},
	gameCode: {
		en: 'game code',
		de: 'Spielcode',
		ja: 'ゲームコード'
	},
	forgotPassword: {
		en: 'Forgot your password?',
		de: 'Passwort vergessen?',
		ja: 'パスワードをお忘れですか？'
	},
	backToLogin: {
		en: 'Go back to login',
		de: 'Zurück zum Login',
		ja: 'ログインに戻る'
	},
	resetPassword: {
		en: 'Enter your e-mail to reset your password:',
		de: 'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen:',
		ja: 'パスワードをリセットするためＥメールを入力してください:'
	},
	reset: {
		en: 'Reset',
		de: 'Zurücksetzen',
		ja: 'リセット'
	},
	cancel: {
		en: 'Cancel',
		de: 'Abbrechen',
		ja: 'キャンセル'
	},
	passwordResetFeedback: {
		en: 'A link to reset your password has been sent to your e-mail.',
		de: 'Es wurde ein Link zum Zurücksetzen Ihres Passworts an Ihre E-Mail gesendet.',
		ja: 'パスワードをリセットするためのリンクがＥメールに送信されました。'
	},
	unknownError: {
		en: 'Error: unknown error',
		de: 'Fehler: unbekannter Fehler',
		ja: 'エラー: 不明なエラー'
	},
	someFieldsMissing: {
		en: 'Error: Please fill out all fields',
		de: 'Fehler: Bitte füllen Sie alle Felder aus',
		ja: 'エラー: 全てのフィールドに入力してください'
	},
	gameNotFound: {
		en: 'Error: Game not found',
		de: 'Fehler: Spiel nicht gefunden',
		ja: 'エラー: ゲームが見つかりません'
	},
	invalidEmail: {
		en: 'Error: Invalid e-mail',
		de: 'Fehler: Ungültige E-Mail',
		ja: 'エラー: 無効なEメール'
	},
	userNotFound: {
		en: 'Error: User not found',
		de: 'Fehler: Benutzername falsch',
		ja: 'エラー: ユーザーが見つかりません'
	},
	invalidPassword: {
		en: 'Error: Wrong password',
		de: 'Fehler: Falsches Passwort',
		ja: 'エラー: 誤ったパスワード'
	},
	notAvailable: {
		en: 'Error: This game is no longer available',
		de: 'Fehler: Dieses Spiel ist nicht mehr verfügbar',
		ja: 'エラー: このゲームは現在利用できません'
	},
	couldNotFindCompany: {
		en: 'Error: Could not find company',
		de: 'Fehler: Firma konnte nicht gefunden werden',
		ja: 'エラー: カンパニーが見つかりません'
	},
	notAuthorized: {
		en: 'Error: You are not authorized as admin',
		de: 'Fehler: Sie sind nicht als Admin autorisiert',
		ja: 'エラー: 管理者権限がありません'
	},
	error: {
		en: 'Error',
		de: 'Fehler',
		ja: 'エラー'
	}
};


const settingsUiTexts = {
	overview: {
		en: 'Overview',
		de: 'Übersicht',
		ja: '概要'
	},
	createGame: {
		en: 'Create game',
		de: 'Spiel erstellen',
		ja: 'ゲームの作成'
	},
	myGames: {
		en: 'My games',
		de: 'Meine Spiele',
		ja: 'マイ・ゲーム'
	},
	allGames: {
		en: 'All games',
		de: 'Alle Spiele',
		ja: '全てのゲーム'
	},
	play: {
		en: 'Play',
		de: 'Spielen',
		ja: 'プレイ'
	},
	active: {
		en: 'Active',
		de: 'Aktiv',
		ja: 'アクティブ'
	},
	edit: {
		en: 'Edit',
		de: 'Bearbeiten',
		ja: '編集'
	},
	gameCode: {
		en: 'Game code',
		de: 'Spielcode',
		ja: 'ゲームコード'
	},
	deleteGamePopup: {
		title: {
			en: 'Delete game',
			de: 'Spiel löschen',
			ja: 'ゲームの削除'
		},
		areYouSure: {
			en: 'Are you sure you want to delete the game %game%',
			de: 'Sind Sie sicher, dass Sie das Spiel XXX löschen möchten?',
			ja: 'ゲーム %game% を削除してもよいですか'
		},
		delete: {
			en: 'Delete',
			de: 'Löschen',
			ja: '削除'
		},
		cancel: {
			en: 'Cancel',
			de: 'Abbrechen',
			ja: 'キャンセル'
		},
	},
	home: {
		en: 'Home',
		de: 'Startseite',
		ja: 'ホーム'
	},
	editGame: {
		en: 'Edit game',
		de: 'Spiel bearbeiten',
		ja: 'ゲームの編集'
	},
	createdBy: {
		en: 'Created by',
		de: 'Erstellt von',
		ja: '作成'
	},
	company: {
		en: 'Company',
		de: 'Firma',
		ja: 'カンパニー'
	},
	gameTitle: {
		en: 'Game session title',
		de: 'Titel der Spielsitzung',
		ja: 'ゲームセッション・タイトル'
	},
	gameTitlePlaceholder: {
		en: 'enter title',
		de: 'Titel eingeben',
		ja: 'タイトルを入力してください'
	},
	gameDate: {
		en: 'Date for game session',
		de: 'Datum der Spielsitzung',
		ja: 'ゲームセッションの日付'
	},
	scenario: {
		en: 'Scenario type',
		de: 'Szenario-Typ',
		ja: 'シナリオタイプ'
	},
	decisionTool: {
		en: 'Decision making tool',
		de: 'Enscheidungshilfe',
		ja: '意思決定ツール'
	},
	communicationTool: {
		en: 'Communication tool',
		de: 'Kommunikationstool',
		ja: 'コミュニケーションツール'
	},
	exerciseTool: {
		en: 'Exercise tool',
		de: 'Übungstool',
		ja: '演習ツール'
	},
	language: {
		en: 'Language',
		de: 'Sprache',
		ja: '言語'
	},
	create: {
		en: 'Create',
		de: 'Erstellen',
		ja: '作成'
	},
	save: {
		en: 'Save',
		de: 'Speichern',
		ja: '保存'
	},
	numberOfGroups: {
		en: 'Number of groups',
		de: 'Anzahl der Gruppen',
		ja: 'グループの数'
	},
	group: {
		en: 'Group',
		de: 'Gruppe',
		ja: 'グループ'
	},
	groups: {
		en: 'Groups',
		de: 'Gruppen',
		ja: '(複数)グループ'
	},
	startTypingPlayerName: {
		en: 'Start typing player name',
		de: 'Spielername',
		ja: 'プレイヤーの名前をタイプしてください'
	},
	noMatchingNames: {
		en: 'No matching names',
		de: 'Keine passenden Namen',
		ja: '名前が一致しません'
	},
	allNamesAdded: {
		en: 'All names are added',
		de: 'Alle Namen werden hinzugefügt',
		ja: '全ての名前が追加されました'
	},
	missingGameTitle: {
		en: 'You must enter a game title',
		de: 'Sie müssen einen Spieltitel eingeben',
		ja: 'ゲームタイトルを入力してくださいa'
	},
	gameTitleMaxLength: {
		en: 'The game title must be max %chars% characters long',
		de: 'Der Spieltitel darf maximal %chars% Zeichen lang sein',
		ja: 'ゲームタイトルは最大 %chars% 文字の長さです'
	},
	saveBeforePrint: {
		en: 'You need to create the game before you can print',
		de: 'Sie müssen das Spiel erst erstellen, bevor Sie es ausdrucken können',
		ja: 'プリントの前にゲームを作成する必要があります'
	},
	gameUrl: {
		en: 'Game URL: ',
		de: 'URL des Spiels: ',
		ja: 'ゲーム URL:'
	},
	scheduledStart: {
		en: 'Scheduled start date',
		de: 'Geplanter Starttermin',
		ja: 'スケジュールされた開始日'
	},
	createdDate: {
		en: 'Created date',
		de: 'Erstelltes Datum',
		ja: '作成日'
	},
	currentGamePhase: {
		en: 'Current game phase',
		de: 'Aktuelle Spielphase',
		ja: '現在のゲームフェーズ'
	},
	currentGameStep: {
		en: 'Current game step',
		de: 'Aktueller Spielschritt',
		ja: '現在のゲームステップ'
	},
	goToNextGamePhase: {
		en: 'Open next phase',
		de: 'Nächste Phase öffnen',
		ja: '次のフェーズを開く'
	},
	openNextGamePhase: {
		en: 'Open',
		de: 'Öffnen',
		ja: '開く'
	},
	finishGame: {
		en: 'Finish game',
		de: 'Spiel beenden',
		ja: 'ゲームの終了'
	},
	nextPhaseWarningPopup: {
		title: {
			en: 'Warning',
			de: 'Warnung',
			ja: '警告'
		},
		text: {
			en: 'Some groups have not completed the current phase. Do you still want to open the next phase?',
			de: 'Einige Gruppen haben die aktuelle Phase noch nicht abgeschlossen. Möchten Sie trotzdem die nächste Phase eröffnen?',
			ja: '現在のフェーズを終了していないグループがあります。それでも次のフェーズを開きますか？'
		},
		confirm: {
			en: 'Confirm',
			de: 'Bestätigen',
			ja: '確認'
		},
		cancel: {
			en: 'Cancel',
			de: 'Abbrechen',
			ja: 'キャンセル'
		}
	},
	status: {
		en: 'Status',
		de: 'Status',
		ja: 'ステータス'
	},
	noStatusPopup: {
		title: {
			en: 'Status',
			de: 'Status',
			ja: 'ステータス'
		},
		text: {
			en: 'The status shows an overview of group responses on debriefs. It is only active when all groups have completed the debrief. In rounds without debriefs, there is no status overview.',
			de: 'Der Status zeigt einen Überblick über die Antworten der Gruppen zur Nachbesprechung. Runden ohne Nachbesprechung können nicht angezeigt werden.',
			ja: 'ステータスは、デブリーフィングに関するグループの回答の概要を表示します。このステータスは、すべてのグループがデブリーフィングを完了したときのみアクティブになります。デブリーフィングがないラウンドでは、ステータスの概要は表示されません。'
		}
	},
	pause: {
		en: 'Pause',
		de: 'Pause',
		ja: '一時停止'
	},
	unpause: {
		en: 'Unpause',
		de: 'Pause beenden',
		ja: '一時停止の終了'
	},
};


const generalUiTexts = {
	welcome: {
		en: 'Welcome',
		de: 'Wilkommen',
		ja: 'ようこそ'
	},
	group: {
		en: 'Group',
		de: 'Gruppe',
		ja: 'グループ'
	},
	start: {
		en: 'Start',
		de: 'Start',
		ja: '開始'
	},
	continue: {
		en: 'Continue',
		de: 'Fortsetzen',
		ja: '続行'
	},
	next: {
		en: 'Next',
		de: 'Weiter',
		ja: '次へ'
	},
	ok: {
		en: 'OK',
		de: 'OK',
		ja: 'OK'
	},
	confirm: {
		en: 'Confirm',
		de: 'Bestätigen',
		ja: '確認'
	},
	submit: {
		en: 'Submit',
		de: 'Abschicken',
		ja: '送信'
	},
	cancel: {
		en: 'Cancel',
		de: 'Abbrechen',
		ja: 'キャンセル'
	},
	paused: {
		en: 'Game paused',
		de: 'Spiel pausieren',
		ja: 'ゲームの一時停止'
	},
	waiting: {
		en: 'Waiting for facilitator...',
		de: 'Warten auf Spielleiter...',
		ja: 'ファシリテーターを待っています...'
	},

	crewInfo: {
		en: 'Crew Information',
		de: 'Crew Informationen',
		ja: 'クルーの情報'
	},
	flightInfo: {
		en: 'Flight Information',
		de: 'Flug-Informationen',
		ja: 'フライトの情報'
	},
	preflightBriefing: {
		en: 'Preflight briefing',
		de: 'Vorflug-Briefing',
		ja: '飛行前のブリーフィング(打ち合わせ）'
	},
	briefing: {
		en: 'Briefing',
		de: 'Briefing',
		ja: 'ブリーフィング（打ち合わせ）'
	},
	briefings: {
		en: 'Briefings',
		de: 'Briefings',
		ja: 'ブリーフィング'
	},
	statusUpdate: {
		en: 'Status update',
		de: 'Status Update',
		ja: 'ステータスの更新'
	},
	locationInfoPopup: {
		en: 'You have to check the weather conditions at each location before you can close this popup.',
		de: 'Bevor Sie dieses Popup schließen können, müssen Sie die Wetterbedingungen an jedem Ort überprüfen.',
		ja: 'このポップアップを閉じるには、各地点の天候を確認する必要があります。'
	},
	
	title: {
		en: 'Title',
		de: 'Titel',
		ja: 'タイトル'
	},
	age: {
		en: 'Age',
		de: 'Alter',
		ja: '年齢'
	},
	crewExperience: {
		en: 'Crew experience',
		de: 'Crew Erfahrung',
		ja: 'クルーの経験'
	},
	personalIssues: {
		en: 'Personal issues',
		de: 'Personliche Probleme',
		ja: '個人の問題点'
	},
	certifyingStaffOnly: {
		en: 'For CERTIFYING STAFF only',
		de: 'Nur für Freigabeberechtigtes Personal',
		ja: 'CERTIFYING STAFFのみ'
	},
	heliCrewCardBackText: {
		en: 'Here you see %names% degree of experience and personal issues',
		de: 'Hier sehen Sie %names% Erfahrungsgrad und persönliche Probleme',
		ja: 'ここでは、%names%の経験度や個人的な問題点を見ることができます。'
	},
	crewFeedbackInstructionsTitle: {
		en: 'Selected crew',
		de: 'Ausgewählte Crew',
		ja: '選択されたクルー'
	},
	crewFeedbackInstructionsText: {
		en: 'Flip the cards to see what effects your crew selection has. You might get RISK and / or DIRTY DOZEN.<br /><br />Once you have turned all crew cards you are ready to continue.',
		de: 'Drehen Sie die Karten um, um zu sehen, welche Auswirkungen die Auswahl Ihrer Mannschaft hat. Du könntest RISIKOS und / oder DIRTY DOZENS erhalten.<br /><br />Sobald Sie alle Crew-Karten umgedreht haben, können Sie fortfahren.',
		ja: 'カードをめくって、自分の選んだクルーがどのような効果を持つかを確認します。RISK / もしくはDIRTY DOZENを知ることができます。<br /><br />すべてのクルーカードをひっくり返すと、続行する準備が整います。'
	},
	crewFeedbackInstructionsTextHeli: {
		en: 'Flip the cards to see your crew\'s experience and personal issues. The technical crew has experience in both paramedic and system operation, but benefits primarily from the role you have assigned on this mission.<br /><br />Once you have turned all crew cards you are ready to continue.',
		de: 'Drehen Sie die Karten um, um zu sehen, welche Erfahrungen und persönlichen Probleme Ihre Crew hat. Die technische Crew hat sowohl Erfahrung im Sanitätsdienst als auch im Systembetrieb, profitiert aber in erster Linie von der Rolle, die Sie ihnen zugewiesen haben.<br /><br />Sobald Sie alle Crew-Karten umgedreht haben, können Sie fortfahren.',
		ja: 'カードをめくって、クルー達の経験や個人的な問題点を確認します。テクニカルクルーは、救急措置とシステム運用の両方の経験を持っていますが、このミッションであなたが割り当てた役割を主に得ています。<br /><br />すべてのクルーカードをひっくり返すと、続行する準備が整います。'
	},

	reflection: {
		textPlaceholder: {
			en: 'Type your answer here',
			de: 'Geben Sie hier Ihre Antwort ein',
			ja: '回答をここに記入してください'
		},
		notAtAll: {
			en: 'Not at all',
			de: 'Überhaupt nicht',
			ja: 'まったくない'
		},
		extremely: {
			en: 'Extremely',
			de: 'Äußerst',
			ja: '非常に'
		},
		question: {
			en: 'Question',
			de: 'Frage',
			ja: '質問'
		},
		unknownReflection: {
			en: 'Unknown reflection',
			de: 'Unbekannte Reflexion ',
			ja: '不明な振り返り'
		},
		tool: {
			en: 'Tool',
			de: 'Werkzeug',
			ja: 'ツール'
		},
		incident: {
			en: 'Incident',
			de: 'Vorfall',
			ja: 'インシデント(出来事）'
		},
		topics: {
			en: 'Topics',
			de: 'Themen',
			ja: 'トピックス'
		},
	},

	dirtyDozen: {
		en: 'Dirty Dozens',
		de: 'Dirty Dozen',
		ja: 'ダーティ・ダズン(Dirty Dozens)'
	},
	dirtyDozen2: {
		en: 'Dirty Dozen',
		de: 'Dirty Dozens',
		ja: 'ダーティ・ダズン(Dirty Dozens)'
	},
	inconvenience: {
		en: 'Inconvenience',
		de: 'Unannehmlichkeiten',
		ja: '不便さ'
	},
	risk: {
		en: 'Risk',
		de: 'Risiko',
		ja: 'リスク'
	},
	doubleRisk: {
		en: 'Risk (x2)',
		de: 'Risiko (x2)',
		ja: 'リスク (x2)'
	},
	extraRisk: {
		en: 'Extra risk',
		de: 'Zusätzliches Risiko',
		ja: '非常に大きなリスク'
	},
	extraTime: {
		en: 'Extra time',
		de: 'Zusätzlicher Zeitaufwand',
		ja: '余計な時間'
	},
	extraCost: {
		en: 'Extra cost',
		de: 'Zusätzliche Kosten',
		ja: '余計なコスト'
	},
	crewSafety: {
		en: 'Crew Safety',
		de: 'Sicherheit der Crew',
		ja: 'クルーの安全'
	},
	missionSuccess: {
		en: 'Mission Success',
		de: 'Erfolg der Mission',
		ja: 'ミッションの成功'
	},
	compliance: {
		en: 'Compliance',
		de: 'Einhaltung',
		ja: 'コンプライアンス（法令遵守）'
	},
	resources: {
		en: 'Resources',
		de: 'Ressourcen',
		ja: 'リソース'
	},
	threats: {
		en: 'Threats',
		de: 'Bedrohung',
		ja: 'スレット（脅威）'
	},

	crewPopupTitle: {
		en: 'Crew',
		de: 'Crew',
		ja: 'クルー'
	},
	groupsPopupTitle: {
		en: 'Groups',
		de: 'Gruppen',
		ja: 'グループ'
	},

	defaultThisTurn: {
		en: 'Default THIS turn',
		de: 'Vorgabe in DIESER Runde',
		ja: '対処しない場合、この場面では'
	},
	defaultNextTurn: {
		en: 'Default next turn',
		de: 'Vorgabe in der nächsten Runde',
		ja: '対処しない場合、次の場面では'
	},
	noDefaultOption: {
		en: 'NO DEFAULT, you must choose',
		de: 'Keine Vorgaben, Sie müssen wählen',
		ja: 'デフォルトなし、選択する必要があります'
	},
	free: {
		en: 'Free',
		de: 'Frei',
		ja: 'フリー'
	},
	movesToNextRound: {
		en: 'Moves to next turn',
		de: 'In die nächste Runde',
		ja: '次のターンへ移動する'
	},
	callFacilitator: {
		en: 'CALL FACILITATOR',
		de: 'Spielleiter rufen',
		ja: 'ファシリテーターを呼ぶ'
	},

	warning: {
		en: 'Warning',
		de: 'Warnung',
		ja: '警告'
	},
	requiredOptionsWarning: {
		en: 'One or more cards require that you select an option.',
		de: 'Eine oder mehrere Karten verlangen, dass Sie eine Auswahl treffen.',
		ja: '１つかそれ以上のカードでオプションを選ぶ必要があります'
	},
	resourceWarning: {
		en: 'You have not placed all your resources, are you sure you want to confirm?',
		de: 'Sie haben noch nicht alle Ihre Ressourcen eingesetzt. Sind Sie sicher, dass Sie das bestätigen wollen?',
		ja: 'すべてのリソースを配置していませんが、本当に確認しますか？'
	},
	turnCard: {
		en: 'Turn card',
		de: 'Karte drehen',
		ja: 'カードをめくる'
	},
	nextTurn: {
		en: 'Next turn',
		de: 'Nächster Zug',
		ja: '次のターン'
	},
	youGet: {
		en: 'You get',
		de: 'Sie erhalten',
		ja: '入手・了解'
	},
	noDirtyDozen: {
		en: 'You get no Dirty Dozen',
		de: 'Sie erhalten keine Dirty Dozen',
		ja: 'ダーティ・ダズンを承知していません'
	},
	newDirtyDozen: {
		en: 'received a new Dirty Dozen',
		de: 'erhält ein neues Dirty Dozen', 
		ja: 'は新しいダーティ・ダズンを受け取りました'
	},
	extraDirtyDozen: {
		en: 'already has %dd%. Proceed with caution!',
		de: 'hat bereits %dd%. Seien Sie vorsichtig!',
		ja: 'は既に%dd%を持っています。注意して続行してください！'
	},
	multiDirtyDozen: {
		en: 'already has double %dd%!',
		de: 'hat bereits doppelte %dd%!',
		ja: 'はすでに２つ%dd%を持っています！'
	},

	taskExtraTime: {
		en: 'This task took extra time',
		de: 'Diese Aufgabe kostete extra Zeit',
		ja: 'このタスクは更なる時間がかかりました'
	},
	taskExtraRisk: {
		en: 'This task increased the risk',
		de: 'Diese Aufgabe erhöhte das Risiko',
		ja: 'このタスクはリスクを増やしました'
	},
	taskExtraCost: {
		en: 'This task increased the cost',
		de: 'Diese Aufgabe erhöhte die Kosten',
		ja: 'このタスクはコストを増やしました'
	},
	is: {
		en: 'is',
		de: 'ist',
		ja: 'は'
	},
	has: {
		en: 'has',
		de: 'hat',
		ja: 'は'
	},

	flipCards: {
		en: 'Flip the cards and gain the benefits',
		de: 'Drehen Sie die Karten, um die Vorteile zu sehen',
		ja: 'カードをめくり、ベネフィットを得てください'
	},

	extra: {
		en: 'Extra',
		de: 'Extra',
		ja: 'エクストラ（追加・余分）'
	},
	youChose: {
		en: 'You chose',
		de: 'Sie wählten',
		ja: '選びました'
	},

	results: {
		en: 'Results',
		de: 'Ergebnisse',
		ja: '結果'
	},
	total: {
		en: 'Total',
		de: 'Gesamt',
		ja: 'トータル'
	},
};

const gamePhasesUiTexts = {
	crewSelection: {
		en: 'Crew Selection',
		de: 'Crew Auswahl',
		ja: 'クルー選択'
	},
	staffSelection: {
		en: 'Staff selection',
		de: 'Mitarbeiter Auswahl',
		ja: 'スタッフ選択'
	},
	onTheGround: {
		en: 'On the ground',
		de: 'Am Boden',
		ja: '地上'
	},
	airborne: {
		en: 'Airborne',
		de: 'In der Luft',
		ja: '飛行中'
	},
	decisionMaking: {
		en: 'Decision making',
		de: 'Entscheidungsfindung',
		ja: '意思決定'
	},
	communication: {
		en: 'Communication',
		de: 'Kommunikation',
		ja: 'コミュニケーション'
	},
	checkIn: {
		en: 'Check-in',
		de: 'Check-in',
		ja: 'チエックイン(業務開始点呼）'
	},
	scramble: {
		en: 'Scramble',
		de: 'Gedrängel',
		ja: 'スクランブル'
	},
	outbound: {
		en: 'Outbound',
		de: 'Ausgehend',
		ja: 'アウトバウンド'
	},
	onSite: {
		en: 'On site',
		de: 'Vor Ort',
		ja: 'オンサイト'
	},
	transportHospital: {
		en: 'Transport hospital',
		de: 'Transpot Krankenhaus',
		ja: '病院へ移送'
	},
	day1: {
		en: 'Day 1',
		de: 'Tag 1',
		ja: 'Day 1'
	},
	day2: {
		en: 'Day 2',
		de: 'Tag 2',
		ja: 'Day 2'
	},
	day3: {
		en: 'Day 3',
		de: 'Tag 3',
		ja: 'Day 3'
	},
	day4: {
		en: 'Day 4',
		de: 'Tag 4',
		ja: 'Day 4'
	},
	finalReflection: {
		en: 'Final reflection',
		de: 'Abschließende Reflexion',
		ja: '最終の振り返り'
	},
	finalResults: {
		en: 'Final results',
		de: 'Abschließende Ergebnisse',
		ja: '最終の結果'
	},
	totalScore: {
		en: 'Total score',
		de: 'Gesamtpunktzahl',
		ja: 'トータルスコア'
	},
	gameover: {
		en: 'Gameover',
		de: 'Gameover',
		ja: 'ゲームオーバー'
	},
	
	game: {
		en: 'Game',
		de: 'Spiel',
		ja: 'ゲーム'
	},
	reflection: {
		en: 'Reflection',
		de: 'Reflexion',
		ja: '振り返り'
	},
	debrief: {
		en: 'Debrief',
		de: 'Nachbesprechung',
		ja: '終了後打ち合わせ（デブリーフ）'
	},
	missionDebriefing: {
		en: 'Mission De-briefing',
		de: 'Mission De-Briefing',
		ja: 'ミッションのデブリーフィング'
	},
	bestPractices: {
		en: 'Best Practices',
		de: 'Beste Praktiken',
		ja: 'ベスト・プラクティス'
	},
	results: {
		en: 'Results',
		de: 'Ergebnisse',
		ja: '結果'
	},	

	firstPhase: {
		en: 'First phase',
		de: 'Erste Phase',
		ja: '最初のフェーズ'
	},
	thisPhase: {
		en: 'This phase',
		de: 'Diese Phase',
		ja: 'このフェーズ'
	},
	nextPhase: {
		en: 'Next phase',
		de: 'Nächste Phase',
		ja: '次のフェーズ'
	},
	open: {
		en: 'Open',
		de: 'Offen',
		ja: '開く'
	},
	endGame: {
		en: 'End game',
		de: 'Endspiel',
		ja: 'ゲーム終了'
	}
};

const gameStepsUiTexts = {
	mayday: {
		en: 'MAYDAY',
		de: 'MAYDAY',
		ja: 'メーデー'
	},
	introduction: {
		en: 'Introduction',
		de: 'Einleitung',
		ja: 'イントロダクション'
	},
	welcome: {
		en: 'Welcome',
		de: 'Wilkommen',
		ja: 'ようこそ'
	},
	crew: {
		en: 'Crew',
		de: 'Crew',
		ja: 'クルー'
	},
	selectCrew: {
		en: 'Select crew',
		de: 'Crew auswählen',
		ja: 'クルーの選択'
	},
	selectStaff: {
		en: 'Select staff',
		de: 'Mitarbeiter auswählen',
		ja: 'スタッフの選択'
	},
	crewSelection: {
		en: 'Crew Selection',
		de: 'Auswahl der Crew',
		ja: 'クルー選択'
	},
	selectedStaff: {
		en: 'Selected staff', 
		de: 'Auswahl der Mitarbeiter',
		ja: '選択したクルー'
	},
	staffReflection: {
		en: 'Staff reflection',
		de: 'Mitarbeiter Reflexion',
		ja: 'スタッフ反映'
	},
	reflection: {
		en: 'Reflection',
		de: 'Reflexion',
		ja: '振り返り・回想'
	},
	results: {
		en: 'Results',
		de: 'Ergebnisse',
		ja: '結果'
	},
	checkIn: {
		en: 'Check-in',
		de: 'Check-in',
		ja: 'チエックイン（業務開始時)'
	},
	preflight: {
		en: 'Pre-flight',
		de: 'Pre-flight',
		ja: '飛行前'
	},
	boarding: {
		en: 'Boarding',
		de: 'Boarding',
		ja: '飛行機への搭乗'
	},
	cruise: {
		en: 'Cruise',
		de: 'Reiseflug',
		ja: '巡航飛行'
	},
	pilotIncapacitation: {
		en: 'Pilot Incapacitation',
		de: 'Untauglichkeit von Piloten',
		ja: 'パイロットの操縦能力喪失'
	},
	turbulence: {
		en: 'Turbulence',
		de: 'Turbulenzen',
		ja: 'タービュランス'
	},
	fire: {
		en: 'Fire',
		de: 'Feuer',
		ja: '火災'
	},
	warnings: {
		en: 'Warnings',
		de: 'Warnungen',
		ja: '警告・警報'
	},
	input: {
		en: 'Input',
		de: 'Input',
		ja: '入力'
	},
	flights: {
		en: 'Flights',
		de: 'Flüge',
		ja: 'フライト'
	},
	firstDebrief: {
		en: '1st DEBRIEF',
		de: '1. DEBRIEF',
		ja: '１回目のデブリーフィング'
	},
	secondDebrief: {
		en: '2nd DEBRIEF',
		de: '2. DEBRIEF',
		ja: '２回目のデブリーフィング'
	},
	thirdDebrief: {
		en: '3rd DEBRIEF',
		de: '3. DEBRIEF',
		ja: '３回目のデブリーフィング'
	}
};

const adminUiTexts = {
	admin: 'Admin',
	company: 'Company',
	companies: 'Companies',
	facilitator: 'Facilitator',
	facilitators: 'Facilitators',
	admins: 'Admins',
	newCompany: 'New company',
	createNewCompany: 'Create new company',
	createNewFacilitator: 'Create new facilitator',
	id: 'Id',
	name: 'Name',
	email: 'E-mail',
	date: 'Date',
	created: 'Created',
	lastLogin: 'Last login',
	games: 'Games',
	game: 'Game',
	group: 'Group',
	status: 'Status',
	sendInvite: 'Send invitation / reset password',
	notInvited: 'Not invited',
	enabled: 'Enabled',
	disabled: 'Disabled',
	sendEmail: 'Send e-mail',
	scenario: 'Scenario',
	currentPhase: 'Current phase',
	players: 'Players',
	groups: 'Groups',
	saveChanges: 'Save changes',
	disableAccount: 'Disable account',
	activateAccount: 'Activate account',
	deleteAccount: 'Delete account',
	deleteCompany: 'Delete company',
	delete: 'Delete',
	disable: 'Disable',
	cancel: 'Cancel',
	ok: 'Ok',
	createFacilitator: 'Create new facilitator',
	create: 'Create',
	companyName: 'Company name',
	importPlayers: 'Import players',
	deleteAllPlayers: 'Delete all players',
	selectedCrew: 'Selected crew',
	reflectionResults: 'Reflection',
	onTheGround: 'On the ground',
	airborne: 'Airborne',
	decisionMaking: 'Decision making',
	communicationTool: 'Communication tool',
	exerciseTool: 'Exercise tool',
	finalReflection: 'Final reflection',
	finalResults: 'Final results',
	confirmDisableFacilitatorPopup: {
		title: 'Disable facilitator',
		text: 'Are you sure you want to disable the account of %name%?<br /><br />They will not be able to log in until you enable it again. Their games will not be deleted.',
		text2: 'You cannot disable your own account.',
	},
	confirmDeleteFacilitatorPopup: {
		title: 'Delete facilitator',
		text: 'Are you sure you want to delete the account of %name%? This will also delete all their games.',
		text2: 'You cannot delete your own account.',
	},
	confirmDeleteCompanyPopup: {
		title: 'Delete company',
		text: 'Are you sure you want to delete the company \'%company%\'?<br /><br />By deleting the company all games, game data and facilitators, belonging to the company will be removed. If needed you can download gamedata before deleting.',
	},
	errorMessages: {
		notAllContentLoaded: 'Error: Could not load all content. Check your internet connection and reload page.',
		noFileSelected: 'Error: No file selected',
		missingProperties: 'Error: Wrong format, could not find "id" and/or "name"',
		formatError: 'Error: Format error',
		missingImports: 'Warning: Not all players could not be imported due to formatting issues',
		unknownError: 'Error: Unknown error',
		noNewData: 'Warning: No new data added'
	},
	importPlayersWarning: 'You need to save the company before you can import players.',
	deleted: 'deleted',
	yes: 'yes',
	no: 'no'
};

module.exports = {
	loginUiTexts,
	settingsUiTexts,
	generalUiTexts,
	gamePhasesUiTexts,
	gameStepsUiTexts,
	adminUiTexts,
};
