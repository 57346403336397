import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as Papa from 'papaparse';
import appConfig from 'config/app.config';
import {loginUiTexts, adminUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import apiHelper from 'helpers/api-helper';
import {formatDate} from 'helpers/date-helper';
import Admin from './admin';
// TODO: 'en' -> languageId
class AdminController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isCreatingCompany: false,
			isDeletingCompany: false,
			isImportingPlayers: false,
			isDeletingPlayer: false,
			isCreatingFacilitator: false,
			isDeletingFacilitator: false,
			isResettingFacilitatorPw: null,
			isTogglingFacilitatorStatus: false,
			isEditing: false,
			isEditingCompany: false,
			isEditingFacilitator: false,
			isSaving: false,
			loadErrorMsg: null,
			errMsgFacilitator: null,
			errMsgCompany: null,
			importErrorMsg: null,
			companies: [],
			facilitators: [],
			games: [],
			archivedGames: [],
			groups: [],
			selectedCompany: null,
			selectedFacilitator: null
		};
		this.unsubscribeCompanies = null;
		this.unsubscribeFacilitators = null;
		this.unsubscribeGames = null;
		this.unsubscribeGroups = null;
		this.unsubscribeArchivedGames = null;
	};

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Update last login */
		let db = firebase.firestore();
		db.collection('users').doc(this.props.authSessionData.userId)
			.update({lastLogin: formatDate(Math.floor(Date.now() / 1000))})
			.catch((error) => {console.error(error);});

		Promise.all([
			this.subscribeToCompanies(),
			this.subscribeToFacilitators(),
			this.subscribeToGames(),
			this.subscribeToGroups(),
			this.subscribeToArchivedGames(),
		]).then((responses) => {
			let loadErrorMsg = null;
			if (responses.some((response) => {return response.status === 'error';})) {
				loadErrorMsg = adminUiTexts.errorMessages.notAllContentLoaded;
			}
			this.setState({isLoading: false, loadErrorMsg});
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeCompanies !== null) this.unsubscribeCompanies();
		if (this.unsubscribeFacilitators !== null) this.unsubscribeFacilitators();
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
	};

	/**
	 * Subscribe to companies
	 */
	subscribeToCompanies = () => {
		if (this.unsubscribeCompanies !== null) this.unsubscribeCompanies();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeCompanies = db.collection('companies').onSnapshot((docs) => {
				let companies = [];
				docs.forEach((doc) => {companies.push({id: doc.id, ...doc.data()});});
				this.setState({companies}, () => {resolve({status: 'ok'});});
			}, (error) => {
				console.error('could not get companies: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to facilitators
	 */
	subscribeToFacilitators = () => {
		if (this.unsubscribeFacilitators !== null) this.unsubscribeFacilitators();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeFacilitators = db.collection('users').onSnapshot((docs) => {
				let facilitators = [];
				docs.forEach((doc) => {facilitators.push({id: doc.id, ...doc.data()});});
				this.setState({facilitators}, () => {resolve({status: 'ok'});});
			}, (error) => {
				console.error('could not get facilitators: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to games
	 */
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeGames = db.collection(appConfig.gamesDbName).onSnapshot((docs) => {
				let games = [];
				docs.forEach((doc) => {games.push({id: doc.id, ...doc.data()});});
				this.setState({games}, () => {resolve({status: 'ok'});});
			}, (error) => {
				console.error('could not get games: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to groups
	 */
	subscribeToGroups = () => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).onSnapshot((docs) => {
				let groups = [];
				docs.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
				this.setState({groups}, () => {resolve({status: 'ok'});});
			}, (error) => {
				console.error('could not get groups: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to archived games
	 */
	subscribeToArchivedGames = () => {
		if (this.unsubscribeArchivedGames !== null) this.unsubscribeArchivedGames();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeArchivedGames = db.collection(appConfig.archiveDbName).onSnapshot((docs) => {
				let archivedGames = [];
				docs.forEach((doc) => {archivedGames.push({id: doc.id, ...doc.data()});});
				this.setState({archivedGames}, () => {resolve({status: 'ok'});});
			}, (error) => {
				console.error('could not get archived games: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Go to admin page
	 * @param {string} page 
	 */
	goToPage = (page) => {
		this.setState({page: page});
	};

	/**
	 * Create new company
	 */
	createNewCompany = () => {
		this.setState({isCreatingCompany: true}, () => {
			const companyObj = {
				created: formatDate(Math.floor(Date.now() / 1000)),
				title: adminUiTexts.newCompany,
				players: [],
				games: [
					{
						id: 'crm-aeroplanes',
						enabled: false,
						scenarios: [
							{id: 'bcn-cph', enabled: false},
							{id: 'spu-cph', enabled: false},
							{id: 'pmi-lhr', enabled: false},
							{id: 'mun-lpa', enabled: false},
							{id: 'oka-hnd', enabled: false}
						]
					},
					{
						id: 'crm-helicopters',
						enabled: false,
						scenarios: [
							{id: 'default', enabled: false}
						]
					},
					{
						id: 'safetytraining',
						enabled: false,
						scenarios: [
							{id: 'default', enabled: false}
						]
					}
				]
			};
			const db = firebase.firestore();
			db.collection('companies').doc().set(companyObj).then(() => {
				this.setState({isCreatingCompany: false});
			}).catch((error) => {
				console.error(error);
				this.setState({isCreatingCompany: false});
			});

		});
	};

	/**
	 * Delete company
	 */
	deleteCompany = () => {
		this.setState({isDeletingCompany: true, errMsgCompany: null}, () => {
			apiHelper('admin/delete-company', {companyId: this.state.selectedCompany.id}).then(
				(response) => {
					if (response.status === 'success') {
						this.setState({isDeletingCompany: false, selectedCompany: null});
					} else {
						let error = (response.error 
							? JSON.stringify(response.error) 
							: adminUiTexts.errorMessages.unknownError
						);
						this.setState({isDeletingCompany: false, errMsgCompany: error});
					}
				},
				(rejection) => {
					this.setState({isDeletingCompany: false, errMsgCompany: JSON.stringify(rejection)});
				}
			);
		});
	};

	/**
	 * Select company
	 * @param {string} companyId 
	 */
	selectCompany = (companyId = null) => {
		if (!companyId) {
			this.setState({selectedCompany: null, isEditingCompany: false});
		} else {
			let selectedCompany = this.state.companies.find((c) => {return c.id === companyId;});
			if (selectedCompany) {
				this.setState({selectedCompany});
			}
		}
	};

	/**
	 * Update company
	 * @param {object} event 
	 */
	updateCompany = (event) => {
		if (this.state.isSaving || this.state.isCreatingCompany) return;
		let name = event.target.name;
		let value = event.target.value;
		let selectedCompany = JSON.parse(JSON.stringify(this.state.selectedCompany));
		selectedCompany[name] = value;
		this.setState({selectedCompany, isEditingCompany: true});
	};

	/**
	 * Save company
	 */
	saveCompany = () => {
		if (this.state.isEditingCompany && !this.state.isSaving) {
			this.setState({isSaving: true}, () => {
				let companyUpdates = {
					title: this.state.selectedCompany.title,
					games: this.state.selectedCompany.games ? this.state.selectedCompany.games : []
				};
				const db = firebase.firestore();
				let companyId = this.state.selectedCompany.id;
				db.collection('companies').doc(companyId).update(companyUpdates).then(() => {
					this.setState({isEditingCompany: false, isSaving: false});
				}, (error) => {
					console.error(error);
					this.setState({isSaving: false});
				});
			});
		}
	};

	importPlayers = (cvsfile) => {
		if (this.state.isSaving || this.state.isImportingPlayers) return;
		if (this.state.isEditingCompany) {
			this.setState({
				importErrorMsg: getText(loginUiTexts.error, 'en') + ': ' + adminUiTexts.importPlayersWarning
			});
		} else {
			this.setState({isImportingPlayers: true}, () => {
				Papa.parse(cvsfile, {
					header: true,
					complete: this.updatePlayers,
					error: (error) => {
						this.setState({
							isImportingPlayers: false, 
							importErrorMsg: getText(loginUiTexts.error, 'en') + ': ' + error.message
						});
					}
				});
			});
		}
	};
	updatePlayers = (playersData) => {
		let company = this.state.companies.find((c) => {return c.id === this.state.selectedCompany.id;});
		if (company) {
			let players = (company.players ? company.players : []);
			let playersAdded = false;

			let importErrorMsg = null;
			if (playersData && playersData.data && playersData.data.length > 0) {
				playersData.data.forEach((player) => {
					if (
						player.hasOwnProperty('id') && player.hasOwnProperty('name') &&
						player.id.length > 0 && player.name.length > 0
					) {
						if (!players.some((p) => {return p.id === player.id;})) {
							players.push({id: player.id, name: player.name});
							playersAdded = true;
						} else {
							if (!players.some((p) => {return (p.id === player.id && p.name === player.name);})) {
								let playerIndex = players.findIndex((p) => {return p.id === player.id;});
								if (playerIndex >= 0) {
									players[playerIndex].name = player.name;
									playersAdded = true;
								}
							}
						}
					} else {
						importErrorMsg = adminUiTexts.errorMessages.missingProperties;
					}
				});
			} else {
				importErrorMsg = adminUiTexts.errorMessages.formatError;
			}

			if (playersAdded) {
				if (importErrorMsg !== null) {
					importErrorMsg = adminUiTexts.errorMessages.missingImports;
				}
				const db = firebase.firestore();
				db.collection('companies').doc(company.id).update({players: players}).then(() => {
					this.setState({isImportingPlayers: false, importErrorMsg});
				}, (error) => {
					console.error(error);
					if (importErrorMsg === null) importErrorMsg = adminUiTexts.errorMessages.unknownError;
					this.setState({isImportingPlayers: false, importErrorMsg});
				});
			} else {
				if (!playersAdded) importErrorMsg = adminUiTexts.errorMessages.noNewData;
				this.setState({isImportingPlayers: false, importErrorMsg});
			}
		} else {
			this.setState({isImportingPlayers: false});
		}
	};
	deletePlayer = (playerIndex = null) => {
		if (this.state.isDeletingPlayer || this.state.isImportingPlayers) return;
		let company = this.state.companies.find((c) => {return c.id === this.state.selectedCompany.id;});
		if (company) {
			this.setState({isDeletingPlayer: true}, () => {
				let players = (company.players ? company.players : []);
	
				if (playerIndex === null) {
				/* Delete all players */
					players = [];
				} else {
				/* Delete specific player */
					if (players.length > playerIndex) players.splice(playerIndex, 1);
				}
			
				const db = firebase.firestore();
				db.collection('companies').doc(company.id).update({players: players}).then(() => {
					this.setState({isDeletingPlayer: false});
				}, (error) => {
					console.error(error);
					this.setState({isDeletingPlayer: false});
				});
			});
		}
	};

	/**
	 * Create facilitator
	 */
	createFacilitator = (email, name) => {
		this.setState({isCreatingFacilitator: true, errMsgFacilitator: null}, () => {
			apiHelper('admin/create-facilitator', {email, name, companyId: this.state.selectedCompany.id}).then(
				(response) => {
					if (response.status === 'success') {
						this.setState({isCreatingFacilitator: false});
					} else {
						let error = (response.error 
							? JSON.stringify(response.error) 
							: adminUiTexts.errorMessages.unknownError
						);
						this.setState({isCreatingFacilitator: false, errMsgFacilitator: error});
					}
				},
				(rejection) => {
					this.setState({isCreatingFacilitator: false, errMsgFacilitator: JSON.stringify(rejection)});
				}
			);
		});
	};

	/**
	 * Delete facilitator (+ games & groups)
	 * @param {string} userId 
	 */
	deleteFacilitator = (userId) => {
		this.setState({isDeletingFacilitator: true, errMsgFacilitator: null}, () => {
			apiHelper('admin/delete-facilitator', {userId}).then(
				(response) => {
					if (response.status === 'success') {
						this.setState({isDeletingFacilitator: false, selectedFacilitator: null});
					} else {
						let error = (response.error 
							? JSON.stringify(response.error) 
							: adminUiTexts.errorMessages.unknownError
						);
						this.setState({isDeletingFacilitator: false, errMsgFacilitator: error});
					}
				},
				(rejection) => {
					this.setState({isDeletingFacilitator: false, errMsgFacilitator: JSON.stringify(rejection)});
				}
			);
		});
	};

	/**
	 * Send email to facilitator to reset password
	 * @param {string} email 
	 * @param {string} facilitatorId 
	 */
	resetFacilitatorPassword = (email, facilitatorId, invitationSent) => {
		this.setState({isResettingFacilitatorPw: facilitatorId}, () => {
			firebase.auth().sendPasswordResetEmail(email).then(()=>{
				if (!invitationSent) {
					const db = firebase.firestore();
					db.collection('users').doc(facilitatorId).update({invitationSent: true}).then(() => {
						this.setState({isResettingFacilitatorPw: null});
					}, (error) => {
						console.error(error);
						this.setState({isResettingFacilitatorPw: null});
					});
				} else {
					this.setState({isResettingFacilitatorPw: null});
				}
				
			}).catch((error)=>{
				console.error(error);
				this.setState({isResettingFacilitatorPw: null});
			});
		});
	};

	/**
	 * Select facilitator
	 * @param {string} facilitatorId 
	 */
	selectFacilitator = (facilitatorId = null) => {
		if (!facilitatorId) {
			this.setState({selectedFacilitator: null, isEditingFacilitator: false});
		} else {
			let selectedFacilitator = this.state.facilitators.find((f) => {return f.id === facilitatorId;});
			if (selectedFacilitator) {
				this.setState({selectedFacilitator});
			}
		}
	};

	/**
	 * Update facilitator
	 * @param {object} event 
	 */
	updateFacilitator = (event) => {
		if (this.state.isSaving) return;
		let name = event.target.name;
		let value = event.target.value;
		let selectedFacilitator = JSON.parse(JSON.stringify(this.state.selectedFacilitator));
		selectedFacilitator[name] = value;
		this.setState({selectedFacilitator, isEditingFacilitator: true});
	};

	toggleFacilitatorStatus = (isEnabled) => {
		if (this.state.isTogglingFacilitatorStatus) return;
		this.setState({isTogglingFacilitatorStatus: true}, () => {
			const db = firebase.firestore();
			let facilitatorId = this.state.selectedFacilitator.id;
			db.collection('users').doc(facilitatorId).update({isEnabled: isEnabled}).then(() => {
				let selectedFacilitator = JSON.parse(JSON.stringify(this.state.selectedFacilitator));
				selectedFacilitator.isEnabled = isEnabled;
				this.setState({isTogglingFacilitatorStatus: false, selectedFacilitator});
			}, (error) => {
				console.error(error);
				this.setState({isTogglingFacilitatorStatus: false});
			});
		});
	};

	/**
	 * Save facilitator
	 */
	saveFacilitator = () => {
		if (this.state.isEditingFacilitator && !this.state.isSaving) {
			this.setState({isSaving: true}, () => {
				let facilitatorUpdates = {
					name: this.state.selectedFacilitator.name,
				};
				const db = firebase.firestore();
				let facilitatorId = this.state.selectedFacilitator.id;
				db.collection('users').doc(facilitatorId).update(facilitatorUpdates).then(() => {
					this.setState({isEditing: false, isSaving: false});
				}, (error) => {
					console.error(error);
					this.setState({isSaving: false});
				});
			});
		}
	};

	/**
	 * Render component
	 */
	render = () => {
		return (
			<Admin 
				authSessionData={this.props.authSessionData}
				isCreatingCompany={this.state.isCreatingCompany}
				isDeletingCompany={this.state.isDeletingCompany}
				isImportingPlayers={this.state.isImportingPlayers}
				isDeletingPlayer={this.state.isDeletingPlayer}
				isCreatingFacilitator={this.state.isCreatingFacilitator}
				isDeletingFacilitator={this.state.isDeletingFacilitator}
				isResettingFacilitatorPw={this.state.isResettingFacilitatorPw}
				isTogglingFacilitatorStatus={this.state.isTogglingFacilitatorStatus}
				isEditingCompany={this.state.isEditingCompany}
				isEditingFacilitator={this.state.isEditingFacilitator}
				isSaving={this.state.isSaving}
				loadErrorMsg={this.state.loadErrorMsg}
				errMsgFacilitator={this.state.errMsgFacilitator}
				errMsgCompany={this.state.errMsgCompany}
				importErrorMsg={this.state.importErrorMsg}
				companies={this.state.companies}
				facilitators={this.state.facilitators}
				games={this.state.games}
				archivedGames={this.state.archivedGames}
				groups={this.state.groups}
				selectedCompany={this.state.selectedCompany}
				selectedFacilitator={this.state.selectedFacilitator}
				goToPage={this.goToPage}
				selectCompany={this.selectCompany}
				createNewCompany={this.createNewCompany}
				deleteCompany={this.deleteCompany}
				updateCompany={this.updateCompany}
				saveCompany={this.saveCompany}
				importPlayers={this.importPlayers}
				deletePlayer={this.deletePlayer}
				createFacilitator={this.createFacilitator}
				deleteFacilitator={this.deleteFacilitator}
				resetFacilitatorPassword={this.resetFacilitatorPassword}
				toggleFacilitatorStatus={this.toggleFacilitatorStatus}
				selectFacilitator={this.selectFacilitator}
				updateFacilitator={this.updateFacilitator}
				saveFacilitator={this.saveFacilitator}
				handleLogout={this.props.handleLogout}
			/>
		);
	};
};

AdminController.propTypes = {
	authSessionData: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default AdminController;