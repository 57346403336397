import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import { 
	checkIfResourcePlacementsConfirmed,
	checkIfAllCardsEffectsApplied,
	getIndexOfNextActionCard,
	getIndexOfNextPriorityCard,
	getConfirmButtonData, 
	getIndexOfNextUnresolvedCard 
} from 'helpers/group-game-helper';
import {getText} from 'helpers/language-helper';
import Logo from 'components/logo/logo';
import ProgressBar from 'components/progress-bar/progress-bar';
import Button from 'components/button/button';
import Resources from 'components/resources/resources';
import GameBoardStat from 'components/game-board-stat/game-board-stat';
import CrewStatus from 'components/crew-status/crew-status';
import EventCardCRM from 'components/event-card-crm/event-card-crm';
import ThreatPopup from 'components/threat-popup/threat-popup';
import CrewPopup from 'components/crew-popup/crew-popup';
import './game-board-crm-heli.scss';

const GameBoardCRMH = (props) => {
	const {
		isFacilitator,
		delayDealingCards,
		isConfirmingResourcePlacements,
		resolvedEventCardIndex,
		languageId,
		gameType,
		gameScenario,
		group,
		gamePhase,
		handleGoToPage,
		toggleInfoPopup,
		triggerPossibleIncident,
		handleToggleOption,
		handleTriggerCriticalEvent,
		handleConfirmActionCardOption,
		handleConfirmResourcePlacements,
		handleApplyEventCardEffects,
		confirmAndContinue,
		gameIsPaused
	} = props;

	/* Get event cards and threats for this game */
	const gameSteps = gamesData[gameType].gameSteps;
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;
	const threatsData = scenarioData.threatsData;

	/* Get gamestep and gamestep data */
	let [gameStep, setGameStep] = useState(group.gameStep ? group.gameStep : 'check-in');
	let gameStepData = gameSteps.find((step) => {return step.id === gameStep;	});

	/* Update board to show next game step */
	useEffect(() => {
		if (group.gameStep !== gameStep) {
			let newGameStepData = gameSteps.find((step) => {return step.id === group.gameStep;});
			/* Check if next step has the gameboard as its page */
			if (newGameStepData && newGameStepData.page === 'game-board') {
				/* Check that the cards of the next step are ready to display */
				if (group.gameStepCards && group.gameStepCards[gameStep] &&
					group.gameStepCards[gameStep].cards && group.gameStepCards[gameStep].cardsAdjusted === true) {
					setGameStep(group.gameStep);
				}
			}
		}
	}, [gameStep, gameSteps, group.gameStep, group.gameStepCards]);

	/* Check if cards are ready to be displayed */
	let displayCards =
		!delayDealingCards && 
		group.gameStepCards &&
		group.gameStepCards[gameStep] &&
		group.gameStepCards[gameStep].cards &&
		group.gameStepCards[gameStep].cardsAdjusted === true;	


	/* Crew safety, mission success & compliance values */
	let [crewSafetyValue, setCrewSafetyValue] = useState(group.crewSafetyValue ? group.crewSafetyValue : 0);
	let [missionSuccessValue, setMissionSuccessValue] = 
		useState(group.missionSuccessValue ? group.missionSuccessValue : 0);
	let [complianceValue, setComplianceValue] = useState(group.complianceValue ? group.complianceValue : 0);
	
	/* Delay an event card's effect until it has been flipped */ 
	useEffect(() => {
		let newCrewSafetyValue = (group.crewSafetyValue ? group.crewSafetyValue : 0);
		let newMissionSuccessValue = (group.missionSuccessValue ? group.missionSuccessValue : 0);
		let newComplianceValue = (group.complianceValue ? group.complianceValue : 0);
		
		if (
			newCrewSafetyValue !== crewSafetyValue ||
			newMissionSuccessValue !== missionSuccessValue ||
			newComplianceValue !== complianceValue
		) {
			const timer = setTimeout(() => {
				setCrewSafetyValue(newCrewSafetyValue);
				setMissionSuccessValue(newMissionSuccessValue);
				setComplianceValue(newComplianceValue);
			}, 1000); // flip animation lasts 800 ms 
			return () => {return clearTimeout(timer);};
		}
	}, [
		group.crewSafetyValue, 
		group.missionSuccessValue, 
		group.complianceValue, 
		crewSafetyValue, 
		missionSuccessValue, 
		complianceValue
	]);

	/* Resource placements / card effects applied status */
	let resourcePlacementsConfirmed = checkIfResourcePlacementsConfirmed(group);
	let allCardEffectsApplied = checkIfAllCardsEffectsApplied(group);

	/* Group resources (available vs placed) */
	let numberOfAvailableResources = group.resources
		? group.resources.filter((resource) => {return resource.isAvailable === true;}).length
		: 0;
	let numberOfPlacedResources = group.resources
		? group.resources.filter((resource) => {return resource.isAvailable === true && resource.isPlaced;}).length
		: 0;



	/**
	 * Get index of next card
	 * Priorized order:
	 * 1) Priority card ('possible threat' or card the should be replaced with critical card)
	 * 2) Action card (requires the user to select and confirm an option AFTER resource placement)
	 * 3) Normal card waiting to be resolved
	 */
	/* Get index of next priority card */
	let indexOfNextCard = getIndexOfNextPriorityCard(group, eventCards);
	let priorityCardIsInPlay = (indexOfNextCard >= 0);
	/* Get index and status of next action card */
	let actionCardIsInPlay = false;
	let actionCardStatus = null;
	if (!priorityCardIsInPlay) {
		indexOfNextCard = getIndexOfNextActionCard(group, eventCards);
		actionCardIsInPlay = (indexOfNextCard >= 0);
		if (actionCardIsInPlay) {
			actionCardStatus = 'select-option';
			let actionCard = group.gameStepCards[group.gameStep].cards[indexOfNextCard];
			if (actionCard.selectedOptionId) {
				actionCardStatus = 'confirm-selected-option';
				if (actionCard.actionConfirmed === true) {
					actionCardStatus = 'ready-to-resolve';
				}
			}
		}
	}
	/* Get index of next ready-to-solve card */
	if (!actionCardIsInPlay && !priorityCardIsInPlay) {
		indexOfNextCard = getIndexOfNextUnresolvedCard(gameStep, group.gameStepCards);
	}
	let [nextCardIndex, setNextCardIndex] = useState(indexOfNextCard);
	useEffect(() => {
		if (nextCardIndex !== indexOfNextCard) {
			if (nextCardIndex < 0) {
				setNextCardIndex(indexOfNextCard);
			} else {
				const timer = setTimeout(() => {
					setNextCardIndex(indexOfNextCard);
				}, 1000); // flip animation lasts 800 ms 
				return () => {return clearTimeout(timer);};
			}
		}
	}, [indexOfNextCard, nextCardIndex]);
	

	/* Threat & Crew popups */
	const [showThreatId, setShowThreatId] = useState(null);
	const [showCrewPopup, setShowCrewPopup] = useState(false);
	
	/* Dealing cards animation */
	const [dealThem, setDealThem] = useState(false);
	const [animationFinished, setAnimationFinished] = useState(false);
	useEffect(() => {
		setDealThem(false);
		setAnimationFinished(false);
		if (displayCards) {
			setTimeout(() => {
				setDealThem(true);
			}, 500);
			setTimeout(() => {
				setAnimationFinished(true);
			}, 3500);
		}
	}, [displayCards]);



	/**
	 * Animating card effects (on just resolved event card or when hovering)
	 */
	// Check if a new card has just been resolved, animate its effects
	let [newEffectTypes, setNewEffectTypes] = useState([]);
	let [newStatDiffs, setNewStatDiffs] = useState(null);
	useEffect(() => {
		if (resolvedEventCardIndex >= 0) {
			// update new effect trackers
			let card = group.gameStepCards[gameStep].cards[resolvedEventCardIndex];
			setNewEffectTypes(card.effectTypes ? card.effectTypes : []);
			setNewStatDiffs(card.statDiffs ? card.statDiffs : null);
		} else {
			// reset new effect trackers
			setNewEffectTypes([]);
			setNewStatDiffs(null);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resolvedEventCardIndex]);

	// Show applied card effects when hovering over a resolved card
	let [animatedCardEffects, setAnimatedCardEffects] = useState([]);
	let [animatedStatDiffs, setAnimatedStatDiffs] = useState(null);
	const handleAnimateCardEffects = (effectTypes, statDiffs) => {
		if (!isFacilitator) {
			setAnimatedCardEffects(effectTypes);
			setAnimatedStatDiffs(statDiffs);
		};
	};

	// Animate stats changed
	let animateCrewSafetyStats = false;
	let animateMissionSuccessStats = false;
	let animateComplianceStats = false;
	let animateStatDiffs = null;
	if (animatedCardEffects.length > 0) {
		// Hover effect (first priorty)
		animateCrewSafetyStats = (
			animatedCardEffects.indexOf('crewSafety') >= 0 && 
			animatedStatDiffs && 
			animatedStatDiffs.hasOwnProperty('crewSafety')
				? true : false
		);
		animateMissionSuccessStats = (
			animatedCardEffects.indexOf('missionSuccess') >= 0 && 
			animatedStatDiffs && 
			animatedStatDiffs.hasOwnProperty('missionSuccess')
				? true : false
		);
		animateComplianceStats = (
			animatedCardEffects.indexOf('compliance') >= 0 && 
			animatedStatDiffs && 
			animatedStatDiffs.hasOwnProperty('compliance')
				? true : false
		);
		animateStatDiffs = animatedStatDiffs;
	} else {
		// New card resolved effect (second priority)
		animateCrewSafetyStats = (
			newEffectTypes.indexOf('crewSafety') >= 0 && newStatDiffs && newStatDiffs.hasOwnProperty('crewSafety')
				? true : false
		);
		animateMissionSuccessStats = (
			newEffectTypes.indexOf('missionSuccess') >= 0 && 
			newStatDiffs && 
			newStatDiffs.hasOwnProperty('missionSuccess')
				? true : false
		);
		animateComplianceStats = (
			newEffectTypes.indexOf('compliance') >= 0 && newStatDiffs && newStatDiffs.hasOwnProperty('compliance')
				? true : false
		);
		animateStatDiffs = newStatDiffs;
	}

	/* Confirm button status, text and action */
	let confirmBtnData = getConfirmButtonData(
		gameIsPaused,
		animationFinished,
		languageId,
		group, 
		gamePhase,
		indexOfNextCard,
		actionCardStatus,
		resourcePlacementsConfirmed,
		allCardEffectsApplied,
		actionCardIsInPlay,
		priorityCardIsInPlay,
		handleConfirmActionCardOption,
		handleConfirmResourcePlacements, 
		handleApplyEventCardEffects, 
		triggerPossibleIncident,
		handleTriggerCriticalEvent,
		confirmAndContinue,
		eventCards,
		gameSteps,
	);



	return (
		<div className="GameBoardCRMH">
			<Logo onClick={() => {handleGoToPage('welcome');}} />
			<ProgressBar
				gameType={gameType}
				title={getText(gameStepData.title, languageId)} 
				subtitle={getText(gameStepData.subtitle, languageId)} 
				linkText={getText(generalUiTexts.briefings, languageId)}
				toggleInfo={toggleInfoPopup}
			/>
			<Button
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				isDisabled={confirmBtnData.isDisabled}
				isLoading={isConfirmingResourcePlacements}
				onClick={() => {confirmBtnData.action(...confirmBtnData.params);}}
			/>
			<div className="GameBoardCRMH-status">
				{/* Resources */}
				<Resources
					languageId={languageId}
					gameType={gameType}
					group={group}
					animatedCardEffects={animatedCardEffects}
					numberOfPlacedResources={numberOfPlacedResources}
					numberOfAvailableResources={numberOfAvailableResources}
				/>

				{/* Crew safety */}
				<GameBoardStat 
					animate={animateCrewSafetyStats}
					statDiff={animateStatDiffs ? animateStatDiffs.crewSafety : null}
					type="crewSafety"
					title={getText(generalUiTexts.crewSafety, languageId)}
					value={crewSafetyValue}
					maxValue = {appConfig['crewSafetyMax']}
				/>

				{/* Mission Success */}
				<GameBoardStat 
					animate={animateMissionSuccessStats}
					statDiff={animateStatDiffs ? animateStatDiffs.missionSuccess : null}
					type="missionSuccess"
					title={getText(generalUiTexts.missionSuccess, languageId)}
					value={missionSuccessValue}
					maxValue = {appConfig['missionSuccessMax']}
				/>

				{/* Compliance */}
				<GameBoardStat 
					animate={animateComplianceStats}
					statDiff={animateStatDiffs ? animateStatDiffs.compliance : null}
					type="compliance"
					title={getText(generalUiTexts.compliance, languageId)}
					value={complianceValue}
					maxValue = {appConfig['complianceMax']}
				/>


				{/* Crew */}
				<CrewStatus 
					languageId={languageId}
					gameType={gameType} 
					setShowCrewPopup={setShowCrewPopup} 
					selectedCrew={group.selectedCrew}
				/>
			</div>

			<div className="GameBoardCRMH-mainContent">
				{/* Event cards */}
				<div className={`GameBoardCRMH-cards ${dealThem ? 'cardsDealed' : ''}`}>
					{displayCards &&
						group.gameStepCards[gameStep].cards.map((card, index) => {
							let cardData = null;
							let isResolved = false;
							let cardAction = null;
							let cardParams = [];
							let movedFromPrevious = false;
							let shakeCard = false;
							let effectsApplied = false;
							let statDiffs = null;
							if (card) {
								cardData = eventCards.find((c) => {return c.id === card.id;});
								if (cardData) {
									/* Check if card is already resolved */
									isResolved = (card.effectsApplied === true);

									/* Check if effects have been applied */
									effectsApplied = (card.effectsApplied === true && card.concequenceType !== 'move');
									statDiffs = (card.statDiffs && card.concequenceType !== 'move' 
										? card.statDiffs : null);

									/* Check if card is clickable, get onclick action */
									if (nextCardIndex === index && !actionCardIsInPlay) {
										if (cardData.type === 'possible-threat') {
											/* Reveal possible threat */
											cardAction = triggerPossibleIncident;
											cardParams = [index, card.id];
											shakeCard = true;
										} else if (
											card.effectTypes &&
											card.effectTypes.some((effectType) => {
												return effectType === 'critical-event';
											})
										) {
											/* Reveal critical card */
											cardAction = handleTriggerCriticalEvent;
											cardParams = [index, card.id];
											shakeCard = true;
										} else if (!isResolved) {
											/* Apply effects */
											cardAction = handleApplyEventCardEffects;
											cardParams = [false, index];
											shakeCard = true;
										}
									}
								}
								if (index > 3) movedFromPrevious = true;
							}

							const popupIsOpen = (showCrewPopup || showThreatId ? true : false);
	
							return (
								<div
									key={index}
									className={'GameBoardCRMH-cardWrap' + (shakeCard ? ' shake' : '')}
									onClick={() => {if (cardAction && animationFinished) cardAction(...cardParams);}}
									onMouseOver={() => {
										if (card && (card.effectTypes || card.triggerThreatId)) {
											let cardEffects = card.effectTypes ? card.effectTypes : [];
											if (card.triggerThreatId) cardEffects.push(card.triggerThreatId);
											handleAnimateCardEffects(cardEffects, statDiffs);
										}
									}}
									onMouseOut={() => {
										if (animatedCardEffects.length > 0) handleAnimateCardEffects([], null);
									}}	
								>
									{card && (
										<EventCardCRM
											key={index}
											index={index}
											isDisabled={(priorityCardIsInPlay && nextCardIndex !== index)}
											resourcePlacementsConfirmed={resourcePlacementsConfirmed}
											effectsApplied={effectsApplied}
											popupIsOpen={popupIsOpen}
											languageId={languageId}
											gameType={gameType}
											gameScenario={gameScenario}
											card={card}
											selectedCrew={group.selectedCrew}
											selectedHospital={(group.hospital ? group.hospital : null)}
											handleToggleOption={handleToggleOption}
											movedFromPrevious={movedFromPrevious}
											animationFinished={animationFinished}
										/>
									)}
								</div>
							);
						})}
				</div>

				{/* Threats */}
				<div className="GameBoardCRMH-threats">
					<div className="GameBoardCRMH-threatsTitle">{getText(generalUiTexts.threats, languageId)}</div>
					<div className="GameBoardCRMH-threatCards">
						{group.threats && group.threats.map((threat, index) => {
							let isTriggered = (group.triggeredThreats && group.triggeredThreats.indexOf(threat) >= 0);
							let isAnimated = (animatedCardEffects.indexOf(threat) >= 0);	

							let threatData = threatsData.find((threatData) => {return threat === threatData.id;});
							return (
								<div
									key={index}
									className={'GameBoardCRMH-threatCard' + 
										(isTriggered ? ' triggered' : '') + 
										(isAnimated ? ' animate' : '')}
									onClick={() => {setShowThreatId(threatData.id);}}
								>{getText(threatData.title, languageId)}</div>
							);
						})}
					</div>
				</div>
			</div>



			{/* Threat pop up */}
			{showThreatId && !isFacilitator && (
				<ThreatPopup 
					languageId={languageId}
					gameType={gameType}
					gameScenario={gameScenario}
					threatId={showThreatId} 
					group={group} 
					handleTogglePopUpThreat={setShowThreatId} 
				/>
			)}

			{/* Crew popup */}
			{showCrewPopup && !isFacilitator && (
				<CrewPopup 
					languageId={languageId}
					gameType={gameType} 
					selectedCrew={group.selectedCrew} 
					setShowCrewPopup={setShowCrewPopup}
				/>
			)}
		</div>
	);
};

GameBoardCRMH.propTypes = {
	resolvedEventCardIndex: PropTypes.number,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	gameScenario: PropTypes.string.isRequired,
	isFacilitator: PropTypes.bool.isRequired,
	delayDealingCards: PropTypes.bool.isRequired,
	isConfirmingResourcePlacements: PropTypes.bool.isRequired,
	gameIsPaused: PropTypes.bool.isRequired,
	group: PropTypes.object.isRequired,
	gamePhase: PropTypes.number.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	triggerPossibleIncident: PropTypes.func.isRequired,
	handleToggleOption: PropTypes.func.isRequired,
	handleTriggerCriticalEvent: PropTypes.func.isRequired,
	handleConfirmActionCardOption: PropTypes.func.isRequired,
	handleConfirmResourcePlacements: PropTypes.func.isRequired,
	handleApplyEventCardEffects: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired
};

export default GameBoardCRMH;