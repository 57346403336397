import React from 'react';
import PropTypes from 'prop-types';
import {settingsUiTexts, adminUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import SettingsHeader from 'components/settings/settings-header';
import {Link} from 'react-router-dom';
import Companies from 'components/admin/companies/companies';
import Admins from 'components/admin/admins/admins';
import Company from 'components/admin/company/company';
import Facilitator from 'components/admin/facilitator/facilitator';
import Button from 'components/button/button';
import './admin.scss';
// TODO: 'en' -> languageId
const Admin = (props) => {
	let {
		authSessionData, 
		isCreatingCompany, 
		isDeletingCompany,
		isImportingPlayers,
		isDeletingPlayer,
		isCreatingFacilitator,
		isDeletingFacilitator,
		isResettingFacilitatorPw,
		isTogglingFacilitatorStatus,
		isEditingCompany,
		isEditingFacilitator,
		isSaving,
		loadErrorMsg,
		errMsgFacilitator,
		errMsgCompany,
		importErrorMsg,
		companies, 
		facilitators,
		games,
		archivedGames,
		groups,
		selectedCompany, 
		selectedFacilitator,
		selectCompany,
		createNewCompany, 
		deleteCompany,
		updateCompany,
		saveCompany,
		importPlayers,
		deletePlayer,
		createFacilitator,
		deleteFacilitator,
		resetFacilitatorPassword,
		toggleFacilitatorStatus,
		selectFacilitator,
		updateFacilitator,
		saveFacilitator,
		handleLogout
	} = props;

	if (selectedFacilitator) {
		let companyData = companies.find((c) => {return c.id === selectedCompany.id;});
		
		return (
			<div className="Admin">
				<SettingsHeader 
					languageId={'en'}
					page="admin" 
					email={authSessionData.email} 
					title={adminUiTexts.admin} 
					handleLogout={handleLogout}
				>
					<Button classes={['home']} text={adminUiTexts.company} onClick={() => {selectFacilitator(null);}} />
				</SettingsHeader>
				<div className="Admin-content">
					<Facilitator 
						isEditing={isEditingFacilitator}
						isSaving={isSaving}
						isDeletingFacilitator={isDeletingFacilitator}
						isTogglingFacilitatorStatus={isTogglingFacilitatorStatus}
						authSessionData={authSessionData}
						errMsgFacilitator={errMsgFacilitator}
						companyData={companyData}
						games={games}
						groups={groups}
						selectedFacilitator={selectedFacilitator} 
						updateFacilitator={updateFacilitator}
						saveFacilitator={saveFacilitator}
						toggleFacilitatorStatus={toggleFacilitatorStatus}
						deleteFacilitator={deleteFacilitator}
					/>
				</div>
			</div>
		);
	}

	if (selectedCompany) {
		let players = [];
		let companyData = companies.find((c) => {return c.id === selectedCompany.id;});
		if (companyData) players = companyData.players ? companyData.players : [];

		return (
			<div className="Admin">
				<SettingsHeader 
					page="admin" 
					languageId="en"
					email={authSessionData.email} 
					title={adminUiTexts.admin} 
					handleLogout={handleLogout}
				>
					<Button 
						classes={['home']} 
						text={getText(settingsUiTexts.home, 'en')} 
						onClick={() => {selectCompany(null);}}
					/>
				</SettingsHeader>
				<div className="Admin-content">
					<Company 
						isDeletingCompany={isDeletingCompany}
						isImportingPlayers={isImportingPlayers}
						isDeletingPlayer={isDeletingPlayer}
						isCreatingFacilitator={isCreatingFacilitator}
						isResettingFacilitatorPw={isResettingFacilitatorPw}
						isEditing={isEditingCompany}
						isSaving={isSaving}
						errMsgFacilitator={errMsgFacilitator}
						errMsgCompany={errMsgCompany}
						importErrorMsg={importErrorMsg}
						facilitators={facilitators}
						players={players}
						games={games}
						archivedGames={archivedGames}
						groups={groups}
						selectedCompany={selectedCompany} 
						updateCompany={updateCompany}
						saveCompany={saveCompany}
						deleteCompany={deleteCompany}
						importPlayers={importPlayers}
						deletePlayer={deletePlayer}
						createFacilitator={createFacilitator}
						resetFacilitatorPassword={resetFacilitatorPassword}
						selectFacilitator={selectFacilitator}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="Admin">
			<SettingsHeader 
				page="admin"
				languageId="en"
				email={authSessionData.email} 
				title={adminUiTexts.admin} 
				handleLogout={handleLogout}
			>
				<Link className="Admin-linkToLandingPage" to="/">{adminUiTexts.games}</Link>
			</SettingsHeader>
			<div className="Admin-content">
				{loadErrorMsg && <div className="Admin-loadErrMsg">{loadErrorMsg}</div>}
				<div className="Admin-title">{adminUiTexts.companies}</div>
				<Companies companies={companies} facilitators={facilitators} selectCompany={selectCompany} />
				<Button 
					text={adminUiTexts.createNewCompany} 
					classes={['sendEmail']} 
					isLoading={isCreatingCompany}
					onClick={() => {createNewCompany();}} 
				/>
				<p>&nbsp;</p>
				<div className="Admin-title">{adminUiTexts.admins}</div>
				<Admins 
					isResettingFacilitatorPw={isResettingFacilitatorPw}
					companyId={null}
					facilitators={facilitators} 
					games={games}
					resetFacilitatorPassword={resetFacilitatorPassword}
				/>
			</div>
		</div>
	);
};

Admin.propTypes = {
	authSessionData: PropTypes.object.isRequired,
	isCreatingCompany: PropTypes.bool.isRequired,
	isDeletingCompany: PropTypes.bool.isRequired,
	isImportingPlayers: PropTypes.bool.isRequired,
	isDeletingPlayer: PropTypes.bool.isRequired,
	isCreatingFacilitator: PropTypes.bool.isRequired,
	isDeletingFacilitator: PropTypes.bool.isRequired,
	isResettingFacilitatorPw: PropTypes.any,
	isTogglingFacilitatorStatus: PropTypes.bool.isRequired,
	isEditingCompany: PropTypes.bool.isRequired,
	isEditingFacilitator: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	loadErrorMsg: PropTypes.any,
	errMsgFacilitator: PropTypes.any,
	errMsgCompany: PropTypes.any,
	importErrorMsg: PropTypes.any,
	companies: PropTypes.array.isRequired,
	facilitators: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	archivedGames: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	selectedCompany: PropTypes.object,
	selectedFacilitator: PropTypes.object,
	selectCompany: PropTypes.func.isRequired,
	createNewCompany: PropTypes.func.isRequired,
	deleteCompany: PropTypes.func.isRequired,
	updateCompany: PropTypes.func.isRequired,
	saveCompany: PropTypes.func.isRequired,
	importPlayers: PropTypes.func.isRequired,
	deletePlayer: PropTypes.func.isRequired,
	createFacilitator: PropTypes.func.isRequired,
	deleteFacilitator: PropTypes.func.isRequired,
	resetFacilitatorPassword: PropTypes.func.isRequired,
	toggleFacilitatorStatus: PropTypes.func.isRequired,
	selectFacilitator: PropTypes.func.isRequired,
	updateFacilitator: PropTypes.func.isRequired,
	saveFacilitator: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Admin;