let reflectionsData = [
	{
		id: 'crew-reflection',
		gameStep: 'crew-reflection',
		resultGameStep: 'crew-reflection-result',
		title: {
			en: 'Staff selection',
			de: 'Personalauswahl',
		},
		subtitle: {
			en: '1st DEBRIEF',
			de: '1. Nachbesprechung'
		},
		resultSubtitle: {
			en: 'Results',
			de: 'Ergebnisse'
		},
		questions: [
			{
				id: 1,
				text: {
					en: `Personal issues may not necessarily be related to work but could still have an impact on the working environment.
						<br /><br />Have you experienced any situations where personal issues, your own or others, affected your ability to work effectively together as a team?`,
					de: `Persönliche Probleme hängen nicht unbedingt immer mit der Arbeit zusammen, können aber dennoch Auswirkungen auf das Arbeitsumfeld haben.
						<br /><br />Haben Sie bei Ihrer Arbeit bereits Situationen erlebt, in denen persönlichen Probleme, entweder Ihre eigenen oder die anderer, Ihre Fähigkeiten effektiv zusammen zu arbeiten erschwert haben?`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 2,
				text: {
					en: `In the game you meet some people with different personalities and attitude.
						<br /><br />In your own work, have you experienced situations where certain personalities and attitudes had an negative effect on the working environment?`,
					de: `Im Spiel werden Sie auf verschiedene Persönlichkeiten und Eigenschaften treffen.
						<br /><br />Haben Sie bei Ihrer Arbeit Situationen erlebt, in denen bestimmte Persönlichkeiten und Einstellungen einen negativen Einfluss auf das Arbeitsumfeld hatten?`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 3,
				text: {
					en: `What can you do, as an individual and/or as a team, to mitigate the negative effects on the working environment caused by personal issues and different personalities and attitudes?`,
					de: `Was können Sie als Einzelperson und/oder im Team tun, um negative Auswirkungen von persönlichen Problemen auf das Arbeitsumfeld abzumildern?`
				},
				answerType: 'text',
				required: true
			}
		]
	},
	{
		id: 'best-practices',
		gameStep: 'best-practices',
		resultGameStep: 'best-practices-result',
		title: {
			en: '2nd DEBRIEF',
			de: '2. Nachbesprechung'
		},
		subtitle: {
			en: 'Best practices',
			de: 'Empfohlene Vorgehensweisen'
		},
		subtitle2: {
			en: 'Reflection',
			de: 'Reflexion'
		},
		resultSubtitle: {
			en: 'Results',
			de: 'Ergebnisse'
		},
		question: {
			en: 'Discuss how you would implement the tools in a real-life scenario. Write your explanation here. Be practical and specific:',
			de: 'Besprechen Sie, wie Sie die Tools in einem echten Szenario implementieren würden. Notieren Sie hier Ihre Ideen. Seien Sie praktisch und konkret:'
		},
		numberOfBestPracticesCardsToSelect: 2
	},
	{
		id: 'dd-reflection',
		gameStep: 'dd-reflection',
		resultGameStep: 'dd-reflection-result',
		title: {
			en: '3rd DEBRIEF',
			de: '3. Nachbesprechung',
		},
		subtitle: {
			en: 'Reflection',
			de: 'Reflektion '
		},
		resultSubtitle: {
			en: 'Results',
			de: 'Ergebnisse'
		},
		showThreats: false,
		showDirtyDozens: true,
		numberOfDirtyDozensToSelect: 3,
		introText: {
			en: 'Make your own TOP 3 list by dragging the Dirty Dozen up and down.',
			de: 'Erstellen Sie Ihre eigene Top 3 Liste, indem Sie die Dirty Dozen nach oben oder nach unten ziehen.'
		},
		questions: [
			{
				id: 16,
				text: {
					en: 'Give examples on how and where these three Dirty Dozen affect you in your daily work.',
					de: 'Geben Sie Beispiele dafür, wie und wo sich diese drei Dirty Dozen auf Ihr tägliches Arbeitsleben auswirken.'
				},
				answerType: 'text',
				required: true
			},
			{
				id: 17,
				text: {
					en: 'What can we do to counteract the negative effect of the Dirty Dozen you have identified?',
					de: 'Was können wir tun, um den negativen Auswirkungen Ihrer gewählten Dirty Dozen entgegenzuwirken?'
				},
				answerType: 'text',
				required: true
			}
		]
	},
	{
		id: 'final-reflection',
		gameStep: 'final-reflection',
		resultGameStep: 'final-reflection-result',
		title: {
			en: 'Final',
			de: 'Finale'
		},
		subtitle: {
			en: 'Reflection',
			de: 'Reflexion'
		},
		showThreats: false,
		questions: [
			{
				id: 7,
				text: {
					en: `Select two of the topics on the list, which you believe is the most challenging for your company.
						<br /><br />Explain why you think they are challenging and then describe one or more concrete initiatives for improvement in those areas.`,
					de: `Wählen Sie zwei der Themen aus der Liste aus, die Ihrer Meinung nach die größten Herausforderungen für Ihr Unternehmen darstellen.
						<br /><br /> Erklären Sie, weshalb Sie glauben, dass diese eine Herausforderung darstellen und beschreiben Sie dann eine oder mehrere konkrete Initiativen zur Verbesserung.`
				},
				answerType: 'text',
				rows: 10,
				required: true
			},	
		],
		numberOfTopicsToSelect: 2,
		topics: [
			{
				id: 'handover-procedures',
				title: {
					en: 'Handover procedures',
					de: 'Übergabeverfahren'
				}
			},
			{
				id: 'tool-control',
				title: {
					en: 'Tool control',
					de: 'Werkzeugkontrolle'
				}
			},
			{
				id: 'environmental-effects',
				title: {
					en: 'Environmental effects',
					de: 'Umwelteinflüsse'
				}
			},
			{
				id: 'human-p-and-l',
				title: {
					en: 'Human performance and limitations',
					de: 'Menschliche Leistungsfähigkeit und Grenzen'
				}
			},
			{
				id: 'operational-pressure',
				title: {
					en: 'Operational pressure',
					de: 'Betriebsbedingter Druck'
				}
			},
			{
				id: 'safety-culture',
				title: {
					en: 'Safety culture',
					de: 'Sicherheitskultur'
				}
			},
			{
				id: 'use-of-documentation',
				title: {
					en: 'Use of documentation',
					de: 'Verwendung von Dokumentation'
				}
			}
		]
	},
];

export {reflectionsData};