let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gamesDbName = 'games';
let groupsDbName = 'groups';
let archiveDbName = 'archive';
let gameUrl = 'localhost:8080';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-mayday-test.web.app';
	apiURL = 'https://cgl-mayday-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-mayday-demo.web.app';
		apiURL = 'https://cgl-mayday-demo.web.app/api/';
		gamesDbName = 'games-demo';
		groupsDbName = 'groups-demo';
		archiveDbName = 'archive-demo';
	}
}

if (env === 'production') {
	gameUrl = 'mayday.aeroteam.dk';
	apiURL = 'https://cgl-mayday-production.web.app/api/';
}

const appConfig = {
	env: env,
	name: 'mayday',
	apiURL: apiURL,
	gamesDbName: gamesDbName,
	groupsDbName: groupsDbName,
	archiveDbName,
	defaultLanguage: 'en',
	minNumberOfGroups: 1,
	gameTitleMaxLength: 18,
	dirtyDozenMax: 12,
	inconvenienceMax: 10,
	riskMax: 10,
	experienceMax: 5,
	crewSafetyMax: 10,
	missionSuccessMax: 10,
	complianceMax: 10,
	st: {
		riskMax: 13,
		timeMax: 13,
		costMax: 13,
		scoreMax: 46
	},
	eventCardsPerRow: 4,
	gameUrl: gameUrl
};

export default appConfig;
