const titles= {
	pilot: {
		en: 'Pilot',
		ja: 'パイロット'
	},
	sccmCA: {
		en: 'SCCM / CA',
		ja: '先任客室乗務員'
	},
	ca: {
		en:'CA',
		ja: '客室乗務員'
	}
};

let crewData = {
	cardTypes: ['cockpit', 'sccm', 'ca'],

	crewSlots: [
		{
			id: 1,
			type: 'cockpit',
			cardTypes: ['cockpit'],
			title: {
				en: 'Captain',
				ja: '機長'
			}
		},
		{
			id: 2,
			type: 'cockpit',
			cardTypes: ['cockpit'],
			title: {
				en: 'First Officer',
				ja: '副操縦士'
			}
		},
		{
			id: 3,
			type: 'cabin',
			cardTypes: ['sccm'],
			title: {
				en: 'SCCM',
				ja: '先任客室乗務員'
			}
		},	
		{
			id: 4,
			type: 'cabin',
			cardTypes: ['sccm', 'ca'],
			title: {
				en: 'CA2',
				ja: '客室乗務員2'
			}
		},
		{
			id: 5,
			type: 'cabin',
			cardTypes: ['sccm', 'ca'],
			title: {
				en: 'CA3',
				ja: '客室乗務員3'
			}
		},
		{
			id: 6,
			type: 'cabin',
			cardTypes: ['sccm', 'ca'],
			title: {
				en: 'CA4',
				ja: '客室乗務員4'
			}
		},
	],
	availableCrew: [
		{
			id: 1,
			type: 'cockpit',
			cardType: 'cockpit',
			name: {
				en: 'Walther Harrison',
				ja: 'ワルター・ハリソン'
			},
			age: 44,
			title: titles.pilot,
			description: {
				en: 'Walther has 10 years experience as a fighter pilot and seven years as a commercial pilot. Never in doubt and calm as a rock. Rumor has it that he once crashed a jet fighter during training.',
				ja: 'ワルターは戦闘機パイロットとして10年、民間パイロットとして7年の経験を持つ。決して迷うことなく、岩のように冷静沈着だ。噂によると、過去に訓練の時にジェット戦闘機を墜落させたことがあるようだ。'
			},
			back: {
				text: {
					en: 'Walther is very experienced and calm as a rock.',
					ja: 'ワルターはとても経験豊富で、冷静沈着である。'
				},
				effects: [],
				conditionals: [
					{
						text: {
							en: 'The difference in experience between Michael and Walther makes it difficult for Michael to point out possible dangers.<br /><br />You get:',
							ja: 'マイケルとワルターの経験の差から、マイケルが起こりうる危険を指摘するのは難しい。: <br /><br />'
						},
						conditionType: 'crewId',
						crewId: 4,
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1}
						]
					}
				]
			}
		},
		{
			id: 2,
			type: 'cockpit',
			cardType: 'cockpit',
			name: {
				en: 'Jan Barnes',
				ja: 'ヤン・バーンズ'
			},
			age: 37,
			title: titles.pilot,
			description: {
				en: 'Jan has five years of experience in the company. He\'s very skilled and has performed outstanding in simulator training. Recently, however, he has been upset and sad due to a divorce.',
				ja: 'ヤンは会社で5年の経験がある。腕は確かで、フライトシミュレーターでの訓練でも抜群の成績を残している。しかし、最近、離婚の危機を迎え、悲しみに暮れている。'
			},
			back: {
				text: {
					en: 'Jan has his mind elsewhere.<br /><br />You get:',
					ja: 'ヤンは心ここに在らずである。: <br /><br />'
				},
				effects: [
					{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
					{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
				],
				conditionals: null
			}
		},
		{
			id: 3,
			type: 'cockpit',
			cardType: 'cockpit',
			name: {
				en: 'Norma Russell',
				ja: 'ノーマ・ラッセル'
			},
			age: 35,
			title: titles.pilot,
			description: {
				en: 'Norma is one of the few female pilots in the company. She has a good reputation. Her memory is outstanding; Ask her about any checklist and she remembers every single line. Norma used to fly passenger routes in Africa.',
				ja: 'ノーマは社内でも数少ない女性パイロットだ。彼女の評判は上々である。記憶力は抜群で、どのチェックリストについてでも、聞けば、一行一行に至るまで完璧に覚えている。ノーマは以前、アフリカで旅客便に乗務していた。'
			},
			back: {
				text: {
					en: 'Norma is a little too self confident.<br /><br />You get:',
					ja: 'ノーマは少し自信過剰なところがある。: <br /><br />'
				},
				effects: [
					{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
					{type: 'risk', value: 1}
				],
				conditionals: [
					{
						text: {
							en: 'There could be a potential issue with a young captain and an older first officer.<br /><br />You get:',
							ja: '若い機長と年配の副操縦士の間には、潜在的に問題が発生する可能性がある。: <br /><br />'
						},
						conditionType: 'crewPositions',
						crewPositions: [
							{slotId: 1, crewId: 3},
							{slotId: 2, crewId: 1}
						],
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
						]
					}
				]
			}
		},
		{
			id: 4,
			type: 'cockpit',
			cardType: 'cockpit',
			name: {
				en: 'Michael Jensen',
				ja: 'マイケル・ジェンセン'
			},
			age: 26,
			title: titles.pilot,
			description: {
				en: 'After years of dreaming, Michael finally got his licence two years ago. He has nine months of experience from flying cargo in the middle east. Michael is very eager to learn and has shown good results in simulator training. He has around 100 hours of flying this type of aircraft.',
				ja: '何年も前からこの仕事に就くことを夢見ていたマイケルは、2年前にようやく操縦士免許を取得した。中東での貨物輸送で9ヶ月の経験がある。マイケルはとても勉強熱心で、フライトシミュレーター訓練でも良い結果を出している。この型式の機体での飛行時間は、約100時間と少ない。'
			},
			back: {
				text: {
					en: 'Michael lacks experience with this type of aircraft and with flying in general.<br /><br />You get:',
					ja: 'マイケルは、この型式の飛行機と一般的な飛行の経験が不足している。: <br /><br />'
				},
				effects: [
					{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
				],
				conditionals: [
					/* Michael is captain AND Walter is the other pilot */
					{
						text: {
							en: 'Michael lacks experience with this type of aircraft and with flying in general. It is also difficult for Michael to work with Walther Harrison.<br /><br />There could be a potential issue with a very young and inexperienced captain.<br /><br />You get:',
							ja: 'マイケルは、この型式の飛行機と一般的な飛行の経験が不足している。そしてマイケルはウオルターハリソンと仕事をするのを苦手としてる。<br /><br />まだ若く経験の浅いキャプテンの為、問題になり得る。<br /><br />その結果 : <br /><br />'
							
						},
						conditionType: 'crewIdAndCrewPositions',
						crewId: 1,
						crewPositions: [
							{slotId: 1, crewId: 4},
						],
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1}
						]
					},
					/* Michael is not captain, Walter is the other pilot */
					{
						text: {
							en: 'Michael lacks experience with this type of aircraft and with flying in general.<br /><br />It is also difficult for Michael to work with Walther Harrison.<br /><br />You get:',
							ja: 'マイケルは、この型式の飛行機と一般的な飛行の経験が不足している。<br /><br />マイケルがワルター・ハリソンと仕事をするのは容易ではない。: <br /><br />'
						},
						conditionType: 'crewId',
						crewId: 1,
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1}
						]
					},
					/* Michael is captain, Walter is NOT the other pilot */
					{
						text: {
							en: 'There could be a potential issue with a very young and inexperienced captain.<br /><br />You get:',
							ja: 'まだ若く経験の浅いキャプテンの為、問題になり得る。<br /><br />その結果:<br /><br />'
						},
						conditionType: 'crewPositions',
						crewPositions: [{slotId: 1, crewId: 4}],
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1}
						]
					}
				]
			}
		},
		{
			id: 5,
			type: 'cabin',
			cardType: 'sccm',
			name: {
				en: 'Joan Lindström',
				ja: 'ヨアン・リンドストロム'
			},
			age: 42,
			title: titles.sccmCA,
			description: {
				en: 'Joan is a very experienced stewardess and usually in the role as Senior Cabin Crew Member. She doesn\'t like too much small talk and apparently has no sense of humor.',
				ja: 'ヨアンはとても経験豊富なキャビンアテンダントで、普段は先任客室乗務員という役割をしている。彼女はあまり世間話が好きではなく、ユーモアのセンスも無いようだ。'
			},
			back: {
				text: {
					en: 'Joan is a  great team member and a good choice.',
					ja: 'ヨアンは素晴らしいチームメンバーであり、良い選択である。'
				},
				effects: [],
				conditionals: null
			}
		},
		{
			id: 6,
			type: 'cabin',
			cardType: 'sccm',
			name: {
				en: 'Benjamin Arnason',
				ja: 'ベンジャミン・アーナソン'
			},
			age: 36,
			title: titles.sccmCA,
			description: {
				en: 'Benjamin has seven years of experience from the cabin. He has a lot of self confidence and even once challenged the directions from another Senior Cabin Crew Member while not in that role.',
				ja: 'ベンジャミンは、客室乗務員としての経験が7年ある。彼は自分に自信があり、他の上級客室乗務員からの指示に、その役割でないにもかかわらず挑んだこともある。'
			},
			back: {
				text: {
					en: 'Benjamin extrudes confidence and experience.',
					ja: 'ベンジャミンは、自信と経験を醸し出している。'
				},
				effects: [],
				conditionals: [
					{
						text: {
							en: 'Benjamin does not work very well with Joan Lindström.<br /><br />You get:',
							ja: 'ベンジャミンは、ヨアン・リンドストロムとあまり相性が良くない。: <br /><br />'
						},
						conditionType: 'crewId',
						crewId: 5,
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1}
						]
					}
				]
			}
		},
		{
			id: 7,
			type: 'cabin',
			cardType: 'ca',
			name: {
				en: 'Martha Patterson',
				ja: 'マーサ・パターソン'
			},
			age: 22,
			title: titles.ca,
			description: {
				en: 'Martha is fresh from training and ready to show the world how good a stewardess she is. She loves mountaineering and is always cool, calm and collected.',
				ja: 'マーサは訓練を受けたばかりで、自分が優れたスチュワーデスであるかを世界に示す準備ができている。登山が好きな彼女は、いつも冷静沈着だ。'
			},
			back: {
				text: {
					en: 'Martha has no flying experience at all.<br /><br />You get:',
					ja: 'マーサは、未だ客室での実務経験がない。: <br /><br />'
				},
				effects: [
					{type: 'dirtyDozen', dirtyDozenId: 9, value: 1}
				],
				conditionals: null
			}
		},
		{
			id: 8,
			type: 'cabin',
			cardType: 'ca',
			name: {
				en: 'Sara Sanders',
				ja: 'サラ・サンダース'
			},
			age: 25,
			title: titles.ca,
			description: {
				en: 'Sara has been working as a stewardess for more than three years. Sara has good communicative skills. She\'s always on top of the latest company gossip.',
				ja: 'サラは、客室乗務員として3年以上働いている。サラはコミュニケーション能力に長けている。彼女は常に最新の会社のゴシップを把握している。'
			},
			back: {
				text: {
					en: 'Sara is distracting her colleagues from what they are doing with her smalltalk.<br /><br />You get:',
					ja: 'サラは、雑談や世間話で同僚の気を逸らしている。: <br /><br />'
				},
				effects: [{type: 'dirtyDozen', dirtyDozenId: 2, value: 1}],
				conditionals: null
			}
		},
		{
			id: 9,
			type: 'cabin',
			cardType: 'ca',
			name: {
				en: 'Lillian Andersson',
				ja: 'リリアン・アンダーソン'
			},
			age: 33,
			title: titles.ca,
			description: {
				en: 'Lillian has substantial experience with handling upset people from working at a center for homeless people. She\'s been working as a stewardess for two years.',
				ja: 'リリアンは、ホームレスのためのケアセンターで働いていたため、激昂した人々の対応にかなりの経験を積んでいる。客室乗務員として2年働いている。'
			},
			back: {
				text: {
					en: 'Lillian is a great team member and a good choice.',
					ja: 'リリアンは素晴らしいチームメンバーであり、良い選択である。'
				},
				effects: [],
				conditionals: null
			}
		},
		{
			id: 10,
			type: 'cabin',
			cardType: 'ca',
			name: {
				en: 'Johnny Müller',
				ja: 'ジョニー・ミュラー'
			},
			age: 24,
			title: titles.ca,
			description: {
				en: 'Johnny is pursuing his dream of working in the aviation industry. At first he wanted to be a pilot but due to a health issue it wasn\'t possible. He now aims for the role as Senior Cabin Crew Member.',
				ja: 'ジョニーは、航空業界で働くという夢を追いかけている。当初はパイロットになりたかったが、健康上の問題で叶わなかった。現在は、上級職の客室乗務員を目指している。'
			},
			back: {
				text: {
					en: 'Johnny is a great addition to the team.',
					ja: 'ジョニーをチームに加えることは素晴らしい。'
				},
				effects: [],
				conditionals: [
						{
						text: {
							en: 'Johnny is a great team member but tries to impress Evelyn Velasquez when they fly together, distracting both of them and the passengers.<br /><br />You get:',
							ja: 'ジョニーは素晴らしいチームメンバーだが、一緒に飛行機に乗るとエヴリン・ヴェラスケスの気を引こうとし、二人と、さらには乗客の気を逸らしてしまう。: <br /><br />'
						},
						conditionType: 'crewId',
						crewId: 12,
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
						]
					}
				]
			}
		},
		{
			id: 11,
			type: 'cabin',
			cardType: 'ca',
			name: {
				en: 'Frank Adams',
				ja: 'フランク・アダムス'
			},
			age: 28,
			title: titles.ca,
			description: {
				en: 'Frank is a funny guy on a plane. Always ready with jokes from the aviation industry. The passengers love him.',
				ja: 'フランクは飛行機の中で笑いをとるタイプの男だ。いつも航空業界のジョークを用意している。乗客からも愛されている。'
			},
			back: {
				text: {
					en: 'Frank is distracting his colleagues with his never ending jokes.<br /><br />You get:',
					ja: 'フランクは終わることのないジョークで同僚の気を逸らしている。: <br /><br />'
				},
				effects: [
					{ type: 'inconvenience', value: -1 },
					{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},	
				],
				conditionals: null
			}
		},
		{
			id: 12,
			type: 'cabin',
			cardType: 'ca',
			name: {
				en: 'Evelyn Velasquez',
				ja: 'エヴリン・ヴェラスケス'
			},
			age: 31,
			title: titles.ca,
			description: {
				en: 'Evelyn has three years of experience as stewardess from another company. She loves working with Johnny Müller.',
				ja: 'エヴリンは他社で客室乗務員として3年の経験を積んでいる。彼女はジョニー・ミュラーとの仕事が大好きだ。'
			},
			back: {
				text: {
					en: 'Evelyn is an experienced stewardess and a good choice.',
					ja: 'エブリンは経験豊富な客室乗務員で、良い選択である。'
				},
				effects: [],
				conditionals: [
						{
						text: {
							en: 'Evelyn cannot focus on her duties when Johnny Müller is trying to impress her.<br /><br />You get:',
							ja: 'ジョニー・ミュラーが気に入られようとしてくるので、エヴリンは、自分の職務に集中できない。: <br /><br />'
						},
						conditionType: 'crewId',
						crewId: 10,
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'risk', value: 1}
						]
					}
				]
			}
		}
	]
}

module.exports = {crewData};