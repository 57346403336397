import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import {replaceTextPlaceholders} from 'helpers/group-crew-helper';
import './threat-popup.scss';

const ThreatPopup = ({languageId, gameType, gameScenario, threatId, group, handleTogglePopUpThreat}) => {
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;
	const threatsData = scenarioData.threatsData;

	let currentThreatData = threatsData.find((threat) => {return threat.id === threatId;});
	let currentEventCard = eventCards.find((eCard) => {return eCard.id === currentThreatData.eventCardId;});
	let currentChoice = null;
	for (const [, value] of Object.entries(group.gameStepCards)) {
		currentChoice = value.cards.find((card) => {
			return card && card.id === currentThreatData.eventCardId && card.hasOwnProperty('selectedOptionId');
		});
		if (currentChoice) break;
	}
	let currentChosenOption = currentEventCard.options.find((option) => {
		return option.id === currentChoice.selectedOptionId;
	});

	let eventText = getText(currentEventCard.text, languageId);
	let optionText = getText(currentChosenOption.text, languageId);

	/* Replace name & pronouns */
	if (currentEventCard.slotId) {
		let slotData = group.selectedCrew.find((c) => {return c.slotId === currentEventCard.slotId;});
		let crewMemberId = (slotData.prevId ? slotData.prevId : slotData.id);
		let crewMemberData = gamesData[gameType].crewData.availableCrew.find((c) => {
			return c.id === crewMemberId;
		});
		if (crewMemberData) {
			eventText = replaceTextPlaceholders(
				eventText, 
				getText(crewMemberData.name, languageId), 
				crewMemberData.gender ? crewMemberData.gender : null
			);
			optionText = replaceTextPlaceholders(
				optionText, 
				getText(crewMemberData.name, languageId), 
				crewMemberData.gender ? crewMemberData.gender : null
			);
		}
	}

	return (
		<div className="ThreatPopup-popup" onClick={() => {handleTogglePopUpThreat(null);}}>
			<div className="ThreatPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="ThreatPopup-closeBtn" onClick={() => {handleTogglePopUpThreat(null);}}/>
				<div className="ThreatPopup-title">{getText(currentThreatData.title, languageId)}</div>
				<div className="ThreatPopup-body">
					{eventText}
					<hr />
					<span className="ThreatPopup-text">{getText(generalUiTexts.youChose, languageId)}: </span>
					{optionText}
				</div>
			</div>
		</div>
	);
};

ThreatPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	gameScenario: PropTypes.string.isRequired,
	threatId: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	handleTogglePopUpThreat: PropTypes.func.isRequired,
};

export default ThreatPopup;
