let bestPracticesData = [
	{
		id: 15,
		title: {
			en: 'Safety survey',
			de: 'Sicherheitsumfrage'
		},
		textFront: {
			en: 'The company conducts a Safety Survey in the form of an anonymous questionnaire, where the employees can highlight the dirty dozen commonly experienced, diversions from safe practices and operational challenges.',
			de: 'Das Unternehmen führt eine Sicherheitsumfrage in Form eines anonymen Fragebogens durch, in dem die Mitarbeiter die am häufigsten vorkommenden Dirty Dozen und betriebliche Herausforderungen aufzeigen können.'
		},
		textBack: {
			en: 'The survey allows the company to focus their efforts into the areas where most needed. This will reduce the overall risk by focusing on the effectiveness of the barriers against errors.',
			de: 'Die Umfrage ermöglicht es dem Unternehmen, seine Anstrengungen auf die Bereiche zu konzentrieren, in denen sie am dringendsten benötigt werden. Indem der Schwerpunkt auf das Verhindern von Fehlern gelegt wird, kann das Gesamtrisiko gesenkt werdern.'
		},
		effects: [
			{type: 'risk', value: -2}
		],
		scenarioIds: ['default', 'light-one']
	},
	{
		id: 16,
		title: {
			en: 'Tool box meetings',
			de: 'Toolbox-Meeting'
		},
		textFront: {
			en: 'The company introduces short, informal, tool box meetings at least once a day.',
			de: 'Das Unternehmen führt mindestens einmal täglich kurze, informelle Toolbox-Meetings ein.'
		},
		textBack: {
			en: 'Regular meetings elevate the general situational awareness, increases the feeling of teamwork and allows for knowledge sharing.',
			de: 'Regelmäßige Treffen erhöhen das allgemeine Situationsbewusstsein, steigern das Gefühl der Teamarbeit und ermöglichen den Wissensaustausch.'
		},
		effects: [
			{type: 'time', value: -1},
			{type: 'risk', value: -1}
		],
		scenarioIds: ['default', 'light-two']
	},
	{
		id: 17,
		title: {
			en: 'Technical training',
			de: 'Technisches Training'
		},
		textFront: {
			en: 'The company conducts recurrent technical training.',
			de: 'Das Unternehmen führt regelmäßig technische Schulungen durch.'
		},
		textBack: {
			en: 'The knowledge of the staff increases, giving improved system knowledge and troubleshooting skills.',
			de: 'Der Wissensstand der Mitarbeiter erhöht sich, was zu besseren Systemkenntnissen und Fähigkeiten zur Fehlersuche führt.'
		},
		effects: [
			{type: 'risk', value: -1},
			{type: 'cost', value: -1}
		],
		scenarioIds: ['default', 'light-one']
	},
	{
		id: 18,
		title: {
			en: 'Minimum stock list',
			de: 'Mindestbestandsliste'
		},
		textFront: {
			en: 'The company asks the employees for their input to a “minimum stock” list of items that are repeatedly found out of stock.',
			de: 'Das Unternehmen bittet die Mitarbeiter, Artikel, die wiederholt nicht vorrätig sind, in eine "Mindestbestandsliste" einzutragen..'
		},
		textBack: {
			en: 'A full stock list reduces interruptions in the workflow.',
			de: 'Eine vollständige Bestandsliste verringert Unterbrechungen im Arbeitsablauf.'
		},
		effects: [
			{type: 'risk', value: -1},
			{type: 'time', value: -1}
		],
		scenarioIds: ['default', 'light-two']
	},
	{
		id: 19,
		title: {
			en: 'Planning',
			de: 'Planung'
		},
		textFront: {
			en: 'The company introduces a way of visualizing short and long term planning for staff.',
			de: 'Das Unternehmen führt eine Methode zur Veranschaulichung der kurz- und langfristigen Planung für Mitarbeiter ein.'
		},
		textBack: {
			en: 'This will increase staff general situational awareness, allow them to contribute and buy-in and enhance communication',
			de: 'Dies wird das allgemeine Situationsbewusstsein der Mitarbeiter erhöhen, die Kommunikation verbessern und ihnen die Möglichkeit geben, einen Beitrag zu leisten.'
		},
		effects: [
			{type: 'cost', value: -1},
			{type: 'time', value: -1}
		],
		scenarioIds: ['new-tasks', 'light-two']
	},
	{
		id: 20,
		title: {
			en: 'Team Meeting',
			de: 'Team Besprechung '
		},
		textFront: {
			en: 'The work shop team holds a "distraction and communication" meeting to identify active and latent safety issues and agree on best practices and behaviours to mitigate and manage these issues.',
			de: 'Das Werkstatt Team hält eine "Ablenkungs- und Kommunikationssitzung" ab, um aktive und latente Sicherheitsprobleme zu ermitteln und sich auf bewährte Praktiken und Verhaltensweisen zu einigen, um diese Probleme zu entschärfen und zu bewältigen.'
		},
		textBack: {
			en: 'This will improve the general safety culture and allow knowledge sharing of potential problems.',
			de: 'Dies wird die allgemeine Sicherheitskultur und den Wissensaustausch von potenzielle Probleme verbessern.'
		},
		effects: [
			{type: 'risk', value: -1},
			{type: 'cost', value: -1}
		],
		scenarioIds: ['new-tasks', 'light-one']
	},
	{
		id: 21,
		title: {
			en: 'Web page',
			de: 'Internet Seite'
		},
		textFront: {
			en: 'The company establish a webpage with FAQ, hint for troubleshooting, best pratices etc. Engineers and Planners are encouraged to participate with inputs.',
			de: 'Das Unternehmen richtet eine Website mit FAQ, Hinweisen zur Fehlerbehebung und bewährten Praktiken ein. Techniker und Planer sind aufgerufen, sich mit Beiträgen zu beteiligen.'
		},
		textBack: {
			en: 'This will give faster and better troubleshooting which will reduce downtime and costs. Employee ownership will increase.',
			de: 'Dies ermöglicht eine schnellere und bessere Fehlerbehebung, wodurch Ausfallzeiten und Kosten reduziert werden. Die Mitarbeiterbeteiligung wird zunehmen.'
		},
		effects: [
			{type: 'cost', value: -1},
			{type: 'time', value: -1}
		],
		scenarioIds: ['new-tasks', 'light-two']
	},
	{
		id: 22,
		title: {
			en: 'Technical Publications',
			de: 'Technische Unterlagen'
		}, 
		textFront: {
			en: 'The availability of technical Publications in the workplace is increased by easier access by the individual worker.',
			de: 'Die Verfügbarkeit von technischen Unterlagen am Arbeitsplatz wird durch den leichteren Zugang des einzelnen Mitarbeiters erhöht.'
		},
		textBack: {
			en: 'The risk of NOT consulting the manual is reduced, when the staff have easy access to needed information when and where they need it. Lack of knowledge is avoided.',
			de: 'Das Risiko, das Handbuch NICHT zu verwenden, wird verringert, indem der Zugang zu benötigten Informationen für jeden Mitarbeiter einfacher gestaltet wird. Wissensdefizite werden somit vermieden.'
		},
		effects: [
			{type: 'risk', value: -1},
			{type: 'time', value: -1}
		],
		scenarioIds: ['new-tasks', 'light-one']
	}
];


export { bestPracticesData };
