const staffTypes = {
	certifying: {
		en: 'Certifying Staff',
		de: 'Freigabeberechtigtes Personal'
	},
	apprentice: {
		en: 'Apprentice',
		de: 'Auszubildende/r'
	}
};

let crewData = {
	cardTypes: ['certifying', 'apprentice'],
	crewSlots: [
		{
			id: 1,
			type: 'certifying',
			cardTypes: ['certifying'],
			color: 'blue',
			title: staffTypes.certifying
		},
		{
			id: 2,
			type: 'certifying',
			cardTypes: ['certifying'],
			color: 'red',
			title: staffTypes.certifying
		},
		{
			id: 3,
			type: 'certifying',
			cardTypes: ['certifying'],
			color: 'orange',
			title: staffTypes.certifying
		},	
		{
			id: 4,
			type: 'certifying',
			cardTypes: ['certifying'],
			color: 'green',
			title: staffTypes.certifying
		},
		{
			id: 5,
			type: 'apprentice',
			cardTypes: ['apprentice'],
			color: 'white',
			title: staffTypes.apprentice
		},
	],
	crewSkills: {
		experienced: {
			en: 'Experienced',
			de: 'Erfahren',
		},
		assertive: {
			en: 'Assertive',
			de: 'Durchsetzungsfähig'
		},
		troubleshooter: {
			en: 'Troubleshooter',
			de: 'Troubleshooter'
		},
		teamplayer: {
			en: 'Teamplayer',
			de: 'Teamspieler'
		}
	},
	availableCrew: [
		{
			id: 1,
			type: 'certifying',
			cardType: 'certifying',
			name: {
				en: 'Adam',
				de: 'Adam',
			},
			age: 35,
			title: staffTypes.certifying,
			description: {
				en: 'Adam is a highly skilled engineer. His colleagues like him, but he is a restless soul who does not want to be committed.',
				de: 'Adam ist ein hoch qualifizierter Mechaniker. Seine Kollegen mögen ihn, aber er ist ein ruheloser Mensch, der sich ungern festlegen möchte.'
			},
			skillIds: ['experienced'],
			dirtyDozenIds: [5]
		},
		{
			id: 2,
			type: 'certifying',
			cardType: 'certifying',
			name: {
				en: 'Jim',
				de: 'Jim',
			},
			age: 56,
			title: staffTypes.certifying,
			description: {
				en: 'Jim is married with two grown up children. He loves life in the hangar but loathes the amount of paperwork he has to complete after a job.',
				de: 'Jim ist verheiratet und hat zwei erwachsene Kinder. Er liebt das Arbeitsleben im Hangar, verabscheut aber die Menge an Papierkram, die er nach einem Job erledigen muss.'
			},
			skillIds: ['experienced'],
			dirtyDozenIds: [1]
		},
		{
			id: 3,
			type: 'certifying',
			cardType: 'certifying',
			name: {
				en: 'Peter',
				de: 'Peter',
			},
			age: 42,
			title: staffTypes.certifying,
			description: {
				en: 'Peter is married to his second wife and expecting twins shortly. He has a strong personal drive, but there is not really an area that is right up his alley.',
				de: 'Peter ist mit seiner zweiten Frau verheiratet und erwartet in Kürze Zwillinge. Er hat einen starken persönlichen Antrieb, findet aber nichts was richtig zu ihm passt.'
			},
			skillIds: ['assertive'],
			dirtyDozenIds: [4]
		},
		{
			id: 4,
			type: 'certifying',
			cardType: 'certifying',
			name: {
				en: 'Liz',
				de: 'Liz'
			},
			age: 29,
			title: staffTypes.certifying,
			description: {
				en: 'Liz has been on the team for a year but previously worked for three other companies. She enjoys solving a tricky problem but also prefers to not work overtime so she can engage in political grass-root work.',
				de: 'Liz ist seit einem Jahr im Team, arbeitete aber zuvor für drei andere Unternehmen. Sie hat Spaß daran, knifflige Probleme zu lösen. Da sie sich politisch viel engagiert, macht sie ungern Überstunden.'
			},
			skillIds: ['troubleshooter'],
			dirtyDozenIds: [3]
		},
		{
			id: 5,
			type: 'certifying',
			cardType: 'certifying',
			name: {
				en: 'Bo',
				de: 'Bo'
			},
			age: 47,
			title: staffTypes.certifying,
			description: {
				en: 'Bo has spent many years working for different companies on line stations at airports abroad. His basic work philosophy is: “If it flew in, it can fly out”. He is always the one handing over jobs to the oncoming shift.',
				de: 'Bo hat viele Jahre lang im Ausland für verschiedene Wartungsunternehmen gearbeitet. Seine grundlegende Arbeitsphilosophie lautet: "Was reingeflogen ist, kann auch rausfliegen". Er ist immer derjenige, der die Aufträge an die kommende Schicht übergibt.'
			},
			skillIds: ['teamplayer'],
			dirtyDozenIds: [12]
		},
		{
			id: 6,
			type: 'certifying',
			cardType: 'certifying',
			name: {
				en: 'John',
				de: 'John'
			},
			age: 64,
			title: staffTypes.certifying,
			description: {
				en: 'John\'s life turned upside down when his wife recently died. He has health issues but aircraft and aircraft maintenance has been his entire life. He is highly skilled.',
				de: 'Johns Leben wurde auf den Kopf gestellt, als seine Frau kürzlich starb. Er hat gesundheitliche Probleme, aber Flugzeuge und deren Wartung waren schon immer seine Leidenschaft. Er ist hoch qualifiziert.'
			},
			skillIds: ['experienced', 'troubleshooter'],
			dirtyDozenIds: [8, 10]
		},
		{
			id: 7,
			type: 'certifying',
			cardType: 'certifying',
			name: {
				en: 'Charlotte',
				de: 'Charlotte'
			},
			age: 38,
			title: staffTypes.certifying,
			description: {
				en: 'Charlotte has been with the company since she was an apprentice. She is loyal and ambitious and rarely makes any mistakes. She recently divorced and is now a single mom.',
				de: 'Charlotte ist seit ihrer Lehrzeit im Unternehmen. Sie ist loyal, ehrgeizig und macht selten Fehler. Sie hat sich kürzlich scheiden lassen und ist nun alleinerziehende Mutter.'
			},
			skillIds: ['assertive', 'teamplayer'],
			dirtyDozenIds: [2, 7]
		},
		{
			id: 8,
			type: 'apprentice',
			cardType: 'apprentice',
			name: {
				en: 'Niels',
				de: 'Niels'
			},
			age: 22,
			title: staffTypes.apprentice,
			description: {
				en: 'Niels values any learning opportunity and always agrees to do overtime. He thinks teamwork is often "too much talk".',
				de: 'Niels schätzt jede Gelegenheit zu lernen und ist immer bereit Überstunden zu machen. Er meint, Teamarbeit ist oft "zu viel Gerede".'
			},
			skillIds: ['troubleshooter'],
			dirtyDozenIds: [6]
		},
		{
			id: 9,
			type: 'apprentice',
			cardType: 'apprentice',
			name: {
				en: 'Mona',
				de: 'Mona'
			},
			age: 24,
			title: staffTypes.apprentice,
			description: {
				en: 'Mona has always been fascinated by aircraft. After school, she signed up at the aeronautical college to get initial training, but struggled to get a contract with a company. She is social and outgoing.',
				de: 'Mona war schon immer von Flugzeugen fasziniert. Nach der Schule meldete sie sich bei der Luftfahrtschule an, um eine Erstausbildung zu absolvieren, hatte aber Schwierigkeiten einen Vertrag bei einem Unternehmen zu bekommen. Sie ist sozial und kontaktfreudig.'
			},
			skillIds: ['teamplayer'],
			dirtyDozenIds: [11]
		},
		{
			id: 10,
			type: 'apprentice',
			cardType: 'apprentice',
			name: {
				en: 'Christian',
				de: 'Christian'
			},
			age: 21,
			title: staffTypes.apprentice,
			description: {
				en: 'Christian had to relocate to another part of the country for the job. He loves the job and opportunity he has been given but also misses his family and friends.',
				de: 'Christian musste für diesen Job innerhalb des Landes umziehen. Er liebt den Job und die ihm gegebenen Möglichkeiten, vermisst aber auch seine Familie und Freunde.'
			},
			skillIds: ['assertive'],
			dirtyDozenIds: [9]
		},
	]
}

module.exports = {crewData};