import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// import 'firebase/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAvHhsZV8HXoVVk0kG0iafj0ctoqFCJAe4',
		authDomain: 'cgl-mayday-test.firebaseapp.com',
		databaseURL: 'https://cgl-mayday-test.firebaseio.com',
		projectId: 'cgl-mayday-test',
		storageBucket: 'cgl-mayday-test.appspot.com',
		messagingSenderId: '150390829005',
		appId: '1:150390829005:web:6928bca54f4fca8e065e84'
	};
}


/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyBdyQyIpcvJzQ4yieLQ1QTgank9h_-xR3U',
		authDomain: 'cgl-mayday-production.firebaseapp.com',
		databaseURL: 'https://cgl-mayday-production.firebaseio.com',
		projectId: 'cgl-mayday-production',
		storageBucket: 'cgl-mayday-production.appspot.com',
		messagingSenderId: '20432150526',
		appId: '1:20432150526:web:daafce3c7a8f40c984aaaf',
		measurementId: 'G-FLLJRNLPE7'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

/* Initialize analytics */
// if (env === 'production') {
// 	firebase.analytics();
// }

export default firebase.firestore;

// export default firebase.firestore().enablePersistence()
// 	.then(() => {
// 		console.log('Firestore persistence enabled!');
// 		return firebase.firestore();
// 	})
// 	.catch((error) => {
// 		if (error.code === 'failed-precondition') {
// 			console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');
// 		} else if (error.code === 'unimplemented') {
// 			console.error('The current browser does not support all of the features required to enable persistence');
// 		} else {
// 			console.error('Unknown persistence error: ', error);
// 		}
// 		return firebase.firestore();
// 	});