const threatsData = [
	{ id: 'crew-sus', eventCardId: 1, title: {
		en: 'Crew Suspicion' 
	}},
	{ id: 'jump-seat-passenger', eventCardId: 2, title: {
		en: 'Jump Seat Passenger' }
	},
	{ id: 'captain-headache', eventCardId: 3, title: {
		en: 'Captain Headache' }
	},
	{ id: 'minimum-fuel', eventCardId: 4, title: {
		en: 'Minimum Fuel' }
	},
	{ id: 'oxygen-masks', eventCardId: 5, title: {
		en: 'Oxygen Masks U/S' }
	},
	{ id: 'cockpit-door-lock', eventCardId: 6, title: {
		en: 'Cockpit Door Lock U/S' }
	},
	{ id: 'dog-in-cabin', eventCardId: 7, title: {
		en: 'Dog in Cabin' }
	},
	{ id: 'full-flight', eventCardId: 8, title: {
		en: 'Full Flight' }
	},
	{ id: 'drunk-passenger', eventCardId: 10, title: {
		en: 'Drunk Passenger' }
	},
	{ id: 'hand-luggage', eventCardId: 11, title: {
		en: 'Hand Luggage' }
	},
	{ id: 'emergency-exit', eventCardId: 12, title: {
		en: 'Emergency Exit' }
	},
	{ id: 'fear-of-flying', eventCardId: 14, title: {
		en: 'Fear of Flying' }
	},
	{ id: 'possible-holding', eventCardId: 15, title: {
		en: 'Possible Holding' }
	},
	{ id: 'short-connection', eventCardId: 16, title: {
		en: 'Short Connection' }
	},
	{ id: 'table-broken', eventCardId: 19, title: {
		en: 'Table Broken' }
	},
	{ id: 'drunk-passenger2', eventCardId: 103, title: {
		en: 'Drunk Passenger' }
	},
	{ id: 'passenger-strapped', eventCardId: 104, title: {
		en: 'Passenger strapped down'
	}},
];

export { threatsData };
