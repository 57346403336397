const titles = {
	pilot: {
		en: 'Pilot'
	},
	captain: {
		en: 'Captain'
	},
	firstOfficer: {
		en: 'First Officer'
	},
	paramedic: {
		en: 'Paramedic'
	},
	sysOp: {
		en: 'System Operator'
	},
	medical: {
		en: 'Medical'
	},
	doctor: {
		en: 'Doctor'
	},
	techCrew: {
		en: 'Technical crew'
	}
};

let crewData = {
	cardTypes: ['pilot', 'tech', 'doctor'],

	experienceTypes: {
		pilot: {
			title: titles.pilot,
			label: {
				en: 'Pilot experience'
			}
		},
		paramedic: {
			title: titles.paramedic,
			label: {
				en: 'Paramedic experience'
			}
		},
		sysop: {
			title: titles.sysOp,
			label: {
				en: 'SO experience'
			}
		},
		medical: {
			title: titles.medical,
			label: {
				en: 'Medical experience'
			}
		}
	},
	issueTypes: {
		persistent: {
			en: 'Persistent'
		},
		complacent: {
			en: 'Complacent'
		},
		restless: {
			en: 'Restless'
		},
		confident: {
			en: 'Over confident'
		},
		pressure: {
			en: 'Pressure'
		},
		distraction: {
			en: 'Distraction'
		},
		enclosed: {
			en: 'Enclosed'
		},
		fatigue: {
			en: 'Fatigue'
		},
		stress: {
			en: 'Stress'
		},
		introvert: {
			en: 'Introvert'
		},
		autocratic: {
			en: 'Autocratic'
		},
		inexperienced: {
			en: 'Inexperienced'
		}

	},
	crewSlots: [
		{
			id: 1,
			type: 'pilot',
			cardTypes: ['pilot'],
			title: titles.captain,
			experienceId: 'pilot'
		},
		{
			id: 2,
			type: 'pilot',
			cardTypes: ['pilot'],
			title: titles.firstOfficer,
			experienceId: 'pilot'
		},
		{
			id: 3,
			type: 'paramedic',
			cardTypes: ['tech'],
			title: titles.paramedic,
			experienceId: 'paramedic'
		},	
		{
			id: 4,
			type: 'sysop',
			cardTypes: ['tech'],
			title: titles.sysOp,
			experienceId: 'sysop'
		},
		{
			id: 5,
			type: 'doctor',
			cardTypes: ['doctor'],
			title: titles.doctor,
			experienceId: 'medical'
		},
	],

	availableCrew: [
		{
			id: 1,
			type: 'pilot',
			cardType: 'pilot',
			name: {
				en: 'Walther'
			},
			gender: 'm',
			age: 55,
			title: titles.pilot,
			description: {
				en: 'Walter is very experienced after 25 years as a SAR pilot. He has recently returned for active flying duty after a period of 2 years, working on a NATO project. Never in doubt and calm as a rock. Rumor has it that he once crashed a jet fighter during training.'
			},
			experiences: [
				{id: 'pilot', value: 5},
			],
			issues: ['persistent']
		},
		{
			id: 2,
			type: 'pilot',
			cardType: 'pilot',
			name: {
				en: 'Christian'
			},
			gender: 'm',
			age: 49,
			title: titles.pilot,
			description: {
				en: 'Christian has spent many years flying with different units everywhere. His basic philosophy is "If it flew in, it can fly out". He is always the one debriefing the next crew.'
			},
			experiences: [
				{id: 'pilot', value: 3}
			],
			issues: ['complacent', 'restless']
		},
		{
			id: 3,
			type: 'pilot',
			cardType: 'pilot',
			name: {
				en: 'Sarah'
			},
			gender: 'f',
			age: 42,
			title: titles.pilot,
			description: {
				en: 'Sarah is one of the few female pilots. She has a good reputation and her memory is outstanding. Aks her about any checklist and she remembers every single line. Sarah has experience from flying different operations abroad.'
			},
			experiences: [
				{id: 'pilot', value: 4}
			],
			issues: ['confident']
		},
		{
			id: 4,
			type: 'pilot',
			cardType: 'pilot',
			name: {
				en: 'Christopher'
			},
			gender: 'm',
			age: 35,
			title: titles.pilot,
			description: {
				en: 'Christopher has five years experinecd as a SAR / HEMS pilot. He is very skilled and performed outstanding during training. Recent weeks, however  he has been upset and sad due to a divorce.'
			},
			experiences: [
				{id: 'pilot', value: 3}
			],
			issues: ['pressure', 'distraction']
		},
		{
			id: 5,
			type: 'pilot',
			cardType: 'pilot',
			name: {
				en: 'Michael'
			},
			gender: 'm',
			age: 26,
			title: titles.pilot,
			description: {
				en: 'Michael lives across the border. He has been in the job for one year, and he loves the job and the opportunity he has been given but he misses his family and friends. He has a girlfriend at home.'
			},
			experiences: [
				{id: 'pilot', value: 2}
			],
			issues: ['enclosed']
		},
		{
			id: 6,
			type: 'tech',
			cardType: 'tech',
			name: {
				en: 'Jim'
			},
			gender: 'm',
			age: 56,
			title: titles.techCrew,
			description: {
				en: 'Jim is married and has two grown up children. He loves life in the hangar but loathes the amount of paperwork he has to complete after a job. He is also known for being stubborn.'
			},
			experiences: [
				{id: 'paramedic', value: 2},
				{id: 'sysop', value: 3}
			],
			issues: ['persistent']
		},
		{
			id: 7,
			type: 'tech',
			cardType: 'tech',
			name: {
				en: 'Peter James'
			},
			gender: 'm',
			age: 48,
			title: titles.techCrew,
			description: {
				en: 'Peter (PJ) is married to his second wife who is expecting twins shortly. He has a strong personal drive, but there is not really an area that is right up his alley.'
			},
			experiences: [
				{id: 'paramedic', value: 3},
				{id: 'sysop', value: 5}
			],
			issues: ['fatigue']
		},
		{
			id: 8,
			type: 'tech',
			cardType: 'tech',
			name: {
				en: 'Adam'
			},
			gender: 'm',
			age: 35,
			title: titles.techCrew,
			description: {
				en: 'Adam is a highly skilled paramedic. His is liked by his colleagues, but he is a restless soul who does not want to be committed. He also think teamwork is "too much talk." Single, social and outgoing.'
			},
			experiences: [
				{id: 'paramedic', value: 5},
				{id: 'sysop', value: 2}
			],
			issues: ['restless']
		},
		{
			id: 9,
			type: 'tech',
			cardType: 'tech',
			name: {
				en: 'Louise'
			},
			gender: 'f',
			age: 41,
			title: titles.techCrew,
			description: {
				en: 'Louise is highly experienced and leading the Technical Crew group.  She is a single mom with two children by the age of 8 (girl) and age 13 (boy). She is loyal and ambitious and rarely makes any mistakes.'
			},
			experiences: [
				{id: 'paramedic', value: 3},
				{id: 'sysop', value: 4}
			],
			issues: ['stress']
		},
		{
			id: 10,
			type: 'tech',
			cardType: 'tech',
			name: {
				en: 'Niels'
			},
			gender: 'm',
			age: 24,
			title: titles.techCrew,
			description: {
				en: 'Niels is single and has been in the job for 1 years. He values the learning opportunity he has benn given and always agree to do overtime. He is very intelligent and very fast to aquire new knowledge. However, he is considered to be somewhat arrogant by his colleagues.'
			},
			experiences: [
				{id: 'paramedic', value: 2},
				{id: 'sysop', value: 2}
			],
			issues: ['introvert']
		},
		{
			id: 11,
			type: 'doctor',
			cardType: 'doctor',
			name: {
				en: 'Stuart'
			},
			gender: 'm',
			age: 59,
			title: titles.doctor,
			description: {
				en: 'Stuart has been a in the group for the last 2 years. He was tired of the work at the hospital, where he work in intensive care.  15 years ago he was also a Flying Doctor for a period of 4 years. Stuart is married to his second wife and they have 5 children in total.'
			},
			experiences: [
				{id: 'medical', value: 4},
			],
			issues: ['pressure']
		},
		{
			id: 12,
			type: 'doctor',
			cardType: 'doctor',
			name: {
				en: 'Ashley'
			},
			gender: 'f',
			age: 45,
			title: titles.doctor,
			description: {
				en: 'Ashley has been a SAR/HEMS doctor for the last 10 years. She likes the environment and is dedicated to her work. She also has a strong will and likes to be in charge. Ashley is married to another doctor at the local hospital. Together they have 2 children af the age of 13 and 16.'
			},
			experiences: [
				{id: 'medical', value: 5},
			],
			issues: ['autocratic']
		},
		{
			id: 13,
			type: 'doctor',
			cardType: 'doctor',
			name: {
				en: 'Muhammed'
			},
			gender: 'm',
			age: 33,
			title: titles.doctor,
			description: {
				en: 'Muhammed has just joined the team. He worked 1 year as a doctor in the emergency and trauma section at a nearby hospital. He is very proud of his work and puts the patient in front.'
			},
			experiences: [
				{id: 'medical', value: 3},
			],
			issues: ['inexperienced']
		}
	]
}

module.exports = {crewData};