/* eslint-disable max-len */
const eventCards = [
	/* Check-in */
	{
		id: 2,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Stressed CA'
		},
		text: {
			en: 'One crewmember is stressed due to a traffic accident she witnessed the same morning.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Let her off duty and wait for a standby CA'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for a replacement CA.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask her to forget what she saw and go to work'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'She begins her shift after a talk with you.'
					},
					effects: [{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Flying with three CAs is acceptable'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You fly with 3 CAs.'
					},
					effects: [
						{ type: 'remove-crewmember', slotId: 5 },
						{ type: 'threat', threatId: 'minimum-crew' },
						{ type: 'dirtydozen', dirtyDozenId: 3, value: 1 },
					],
				},
			},
		],
	},
	{
		id: 3,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Prisoner escort'
		},
		text: {
			en: 'A handcuffed prisoner is escorted by a single police officer. There was supposed to be two men guarding him, but the other one has not shown up yet.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait 20 min. for second police officer. Delay 20 min'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'After 20 minutes the second officer arrives.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 2, optionId: 'a' },
							],
							text: {
								en: 'While you wait for the replacement CA, the second police officer shows up.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow boarding with only one guard'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The second officer never shows up.'
					},
					effects: [{ type: 'threat', threatId: 'single-guard' }],
				},
			},
		],
	},
	{
		id: 4,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Old wind chart'
		},
		text: {
			en: 'The weather seems good, but the wind charts are 24 hours old.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for a new wind chart'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait a short while for the updated wind chart.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 2, optionId: 'a' },
								{ type: 'prev-choice', cardId: 3, optionId: 'a' },
							],
							text: {
								en: 'You wait for the updated wind charts while you also wait for the replacement CA and the delayed police officer.'
							},
							effects: [],
						},
						{
							conditions: [
								{ type: 'prev-choice', cardId: 3, optionId: 'a' },
							],
							text: {
								en: 'You wait for the updated wind charts while you also wait for the delayed police officer.'
							},
							effects: [],
						},
						{
							conditions: [
								{ type: 'prev-choice', cardId: 2, optionId: 'a' },
							],
							text: {
								en: 'You wait for the updated wind charts while you also wait for the replacement CA.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Call OPS for a short briefing over the phone'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Briefing from OPS clarifies any issues.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'It can be risky to fly with outdated wind charts.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'old-wind-chart' },
					],
				},
			},
		],
	},
	{
		id: 5,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Human remains in cargo'
		},
		text: {
			en: 'The human remains of a Danish citizen is in cargo, but a signature is missing on one of the forms.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call the Danish authorities for clarifying details.'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Human remains are loaded.'
					},
					effects: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Deny transportation of human remains.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Human remains are not loaded.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Accept transportation of human remains'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Human remains are loaded.'
					},
					effects: [
						{ type: 'threat', threatId: 'human-remains' },
					],
				},
			},
		],
	},
	/* Preflight */
	{
		id: 6,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Denied entry'
		},
		text: {
			en: 'A Danish passenger apparently joked about a bomb in SPU airport earlier and was denied entry.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You deny the man from boarding. You don\'t know how he will react on board.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Accept boarding'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You allow the man on board. He could however, make the other passengers nervous with his talk.'
					},
					effects: [{ type: 'threat', threatId: 'unwanted-visitor' }],
				},
			},
		],
	},
	{
		id: 7,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Slow cleaning'
		},
		text: {
			en: 'Cleaning is behind schedule due to lack of staff.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Help out the cleaning staff the best you can'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'With your help the cleaning almost catches up to schedule.'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Delay 15 min. and let cleaning staff finish'
				},
				cost: 1,
				consequences: {
					text: {
						en: '15 minutes after what was planned the cleaning is done.'
					},
					effects: [{ type: 'inconvenience', value: 2 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Stop cleaning'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You stop the cleaning crew before they are done, to keep up with the schedule.'
					},
					effects: [
						{ type: 'threat', threatId: 'no-cleaning' },
						{ type: 'inconvenience', value: 1 },
					],
				},
			},
		],
	},
	{
		id: 9,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Short supply of drinking water'
		},
		text: {
			en: 'Somehow there is only a supply of 50 water bottles left.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Wait for extra supply. Expected delay: 20 minutes'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You wait for the extra water.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 7, optionId: 'b' },
							],
							text: {
								en: 'The extra water arrives while the cleaning staff finishes.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Do nothing'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You have very little water supply, in case someone needs it.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'threat', threatId: 'limited-water' },
					],
				},
			},
		],
	},
	{
		id: 10,
		gameStep: 'preflight',
		type: 'default',
		title: {
			en: 'Fuel truck late'
		},
		text: {
			en: 'The fuel truck arrive 20. min late due to starter problems.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Delay boarding until fueling is done'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You are 20 min delayed.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
					conditionals: [
						{
							conditions: [
								{ type: 'prev-choice', cardId: 7, optionId: 'b' },
							],
							text: {
								en: 'As you are already delayed, the fueling doesn\'t add to that.'
							},
							effects: [],
						},
						{
							conditions: [
								{ type: 'prev-choice', cardId: 9, optionId: 'a' },
							],
							text: {
								en: 'As you are already delayed, the fueling doesn\'t add to that.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Start boarding during fueling'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You begin boarding before the fueling is done. Note SOP.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'boarding-while-fueling' },
					],
				},
			},
		],
	},
	/* Boarding */
	{
		id: 12,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Fever'
		},
		text: {
			en: 'A passenger with fever is seated in the rear. Her MEDIF states that her condition isn\'t contagious.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Deny boarding'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The passenger is denied boarding.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Accept boarding'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The passenger could be ill with something serious that is difficult to handle while airborne.'
					},
					effects: [{ type: 'threat', threatId: 'fever' }],
				},
			},
		],
	},
	{
		id: 13,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Scuba gear in handluggage'
		},
		text: {
			en: 'Three passengers have very large bags with heavy scuba gear in handluggage.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Reject objects onboard'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You refuse to have the gear on board.'
					},
					effects: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Require that bags are moved to cargo'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You have the gear taken to cargo. The scuba gear could contain dangerous goods.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'hidden-goods' },
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Allow objects as cabin baggage'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You find room for the gear in the cabin. The scuba gear could contain dangerous goods.'
					},
					effects: [{ type: 'threat', threatId: 'hidden-goods' }],
				},
			},
		],
	},
	{
		id: 14,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Blind passenger'
		},
		text: {
			en: 'A blind passenger has trouble locating his handluggage at the gate.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Help the passenger find his handluggage and check that its contents is safe'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You find his bag and everything is as it should be, but it slightly delays the boarding process.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask the gate agent to solve the problem'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The gate agent helps the passenger.'
					},
					effects: [{ type: 'inconvenience', value: -1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let other passengers help him out'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'From the corner of your eye, you notice that some passengers seem to have found his luggage.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 15,
		gameStep: 'boarding',
		type: 'default',
		title: {
			en: 'Afraid of flying'
		},
		text: {
			en: 'A young male passenger seems afraid of flying and looks very scared.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Calm him down and convince him to go onboard'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You manage to calm him, and after a while you get him boarded. But he\'s still very nervous and a potential threat.'
					},
					effects: [{ type: 'threat', threatId: 'scared-passenger' }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Deny boarding and locate his suitcase in cargo'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You refuse to board him. It causes a slight delay to find his luggage in cargo.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Convince him to board despite his condition'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The scared passenger could behave erratically.'
					},
					effects: [{ type: 'threat', threatId: 'scared-passenger' }],
				},
			},
		],
	},

	/* Flight 1 */
	{
		id: 17,
		gameStep: 'flight1',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'single-guard' }],
				cardId: 101,
			},
			{
				conditions: [{type: 'no-threat', threatId: 'single-guard' }],
				cardId: 102
			}
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'You hear some noises from where the prisoner is sitting ...'
		},
		roundsMax: 0,
	},
	{
		id: 18,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Dog bites passenger'
		},
		text: {
			en: 'A small dog has bitten a passenger passing by. The owner and the bitten passenger are yelling at each other.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Calm them down and ask them to figure out a compensation themselves'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You manage to defuse the situation.'
					},
					effects: [],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Ask the bitten passenger to complain to the airplane company'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'They agree to settle the issue upon arrival and with the airline.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore the incident'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You ignore the incident.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 19,
		gameStep: 'flight1',
		type: 'conditional-replace',
		conditionals: [
			{
				conditions: [{ type: 'no-threat', threatId: 'limited-water' }],
				cardId: 31,
			},
		],
		title: {
			en: 'Water please'
		},
		text: {
			en: 'The lack of water means there is only about half a glass of water, soda or juice for each passenger.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Distribute almost everything to the passengers'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You distribute what you have of beverages. You have no cold beverages in case someone needs it.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{ type: 'threat', threatId: 'no-water' },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Keep a short supply of drinking water for later needs'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You keep some water left in case someone really needs it.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Only serve for passengers that demands it'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You only serve beverages when requested.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 20,
		gameStep: 'flight1',
		type: 'default',
		title: {
			en: 'Scared 10 year old'
		},
		text: {
			en: 'A 10 year old boy is crying and says he saw a thing the size of a suitcase hit the wing. The wing looks fine.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Calm him down and listen to his story'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The boy is truly convinced that the air plane hit an object after take-off. He might be right...'
					},
					effects: [{ type: 'threat', threatId: 'scared-kid' }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Tell him that everything is fine'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The boy is convinced when you tell him everything is fine.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore him'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Some other passengers manages to calm him down.'
					},
					effects: [],
				},
			},
		],
	},

	/* Flight 2 */
	{
		id: 22,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'passenger-injured1' }],
				cardId: 104,
			},
			{
				conditions: [{ type: 'threat', threatId: 'passenger-injured2' }],
				cardId: 104,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Do you have injured passengers?'
		},
		title2: {
			en: 'Incident averted'
		},
		text2: {
			en: 'Congratulations. You avoided an incident with injured passengers, because you solved the conflict with the prisoner.'
		},
		roundsMax: 0,
	},
	{
		id: 23,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'fever' }],
				cardId: 103,
			},
		],
		title: {
			en: 'Possible Incident'
		},
		text: {
			en: 'Do you have sick passengers on board?'
		},
		title2: {
			en: 'Incident averted'
		},
		text2: {
			en: 'Congratulations. Because you denied a passenger with fever onboard you avoided a weight and balance problem from scared fellow passengers reseating themselves.'
		},
		roundsMax: 0,
	},
	{
		id: 24,
		gameStep: 'flight2',
		type: 'default',
		title: {
			en: 'Headache'
		},
		text: {
			en: 'A passenger asks for painkillers because of a headache.'
		},
		roundsMax: 2,
		options: [
			{
				id: 'a',
				text: {
					en: 'Give painkillers and ask for details regarding the headache'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The passenger has been scuba diving and is afraid that it might be a symptom of decompression sickness.'
					},
					effects: [{ type: 'threat', threatId: 'pos-comp-sickness' }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Give painkillers'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Nothing happens.'
					},
					effects: [],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore request'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Nothing happens.'
					},
					effects: [],
				},
			},
		],
	},
	{
		id: 25,
		gameStep: 'flight2',
		type: 'possible-threat',
		conditionals: [
			{
				conditions: [{ type: 'threat', threatId: 'scared-passenger' }],
				cardId: 105,
			},
		],
		title: {
			en: 'Possible critical event'
		},
		text: {
			en: 'How\'s the scared passenger holding up?'
		},
		title2: {
			en: 'Incident averted'
		},
		text2: {
			en: 'You check on the passenger who was scared at boarding. You can\'t shake the feeling that he could have broken down, if his fright had been allowed to escalate.'
		},
		roundsMax: 0,
	},
	/* Flight 3 */
	{
		id: 27,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Physical discomfort'
		},
		text: {
			en: 'Three passengers complain over pain in shoulder and elbow joints. They all did scuba diving about 32 hours ago.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Treat passengers with pure oxygen. Report to flight deck'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Passengers are not well but conscious and scared.'
					},
					effects: [
						{
							type: 'replace-threat',
							oldThreatId: 'pos-comp-sickness',
							threatId: 'comp-sickness',
						},
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Report to flight deck'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'One of them loses consciousness. Others are disoriented.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{
							type: 'replace-threat',
							oldThreatId: 'pos-comp-sickness',
							threatId: 'comp-sickness',
						},
					],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'One of them loses consciousness. Others are disoriented.'
					},
					effects: [
						{ type: 'risk', value: 1 },
						{
							type: 'replace-threat',
							oldThreatId: 'pos-comp-sickness',
							threatId: 'comp-sickness',
						},
					],
				},
			},
		],
	},
	{
		id: 28,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Feeling tired'
		},
		text: {
			en: 'Two CAs are feeling dizzy and tired.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Report to the flight deck that something does not feel right in the cabin'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You report to flight deck.<br /><br />You should notice any changes of human performance and limitations.'
					},
					effects: [{ type: 'threat', threatId: 'sick-crew' }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You ignore the crew symptoms.<br /><br />You should notice any changes of human performance and limitations.'
					},
					effects: [
						{ type: 'risk', value: 2 },
						{ type: 'threat', threatId: 'sick-crew' }
					],
				},
			},
		],
	},
	{
		id: 29,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Sleep tight'
		},
		text: {
			en: 'More than usual have fallen asleep in the cabin.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Use oxygen bottles for the crew and notify flight deck'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You make sure crew have oxygen and notify flight deck.'
					},
					effects: [{ type: 'threat', threatId: 'low-oxygen' }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Wake up people by using intercom'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You wake up the passengers.'
					},
					effects: [{ type: 'risk', value: 2 }],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Let the passengers relax'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You let the passengers sleep.'
					},
					effects: [{ type: 'risk', value: 2 }],
				},
			},
		],
	},
	{
		id: 30,
		gameStep: 'flight3',
		type: 'default',
		title: {
			en: 'Balance'
		},
		text: {
			en: 'The air craft keeps pulling left when it should go straight.'
		},
		roundsMax: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Investigate fuel consumption, hydraulics etc.'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You notice a significant fuel imbalance. Fuel leakage in right wing tank is diagnosed.'
					},
					effects: [{type: 'threat', threatId: 'fuel-leakage'}],
				},
			},
			{
				id: 'default',
				text: {
					en: 'Ignore and compensate by trimming'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You do your best to compensate by trimming.'
					},
					effects: [{ type: 'risk', value: 3 }],
				},
			},
		],
	},

	/* All is well card */
	{
		id: 31,
		type: 'possible-threat',
		conditionals: [],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'You check up on how much drinking water is left ...'
		},
		title2: {
			en: 'All is well'
		},
		text2: {
			en: 'Beverage supplies are above what is needed for the rest of the flight.'
		},
		roundsMax: 1,
	},

	/* Critical events */
	{
		id: 101,
		title: {
			en: 'Fight with prisoner'
		},
		type: 'critical',
		text: {
			en: 'The prisoner attacks the police officer guarding him and they end up on the floor. The officer is in big trouble.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Assist the officer and pacify prisoner'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The CA helps out the officer but breaks a tooth.'
					},
					effects: [{ type: 'dirtydozen', dirtyDozenId: 7, value: 2 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Let other passengers help the officer'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'A passenger breaks his nose, but the situation gets under control.'
					},
					effects: [{ type: 'threat', threatId: 'passenger-injured1' }],
				},
			},
		],
	},
	{
		id: 102,
		title: {
			en: 'Fight with prisoner'
		},
		type: 'critical',
		text: {
			en: 'The prisoner attacks the police officers guarding him and they end up on the floor. One of the officers is in big trouble.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Assist the officers and pacify prisoner'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The CA gets a nosebleed but they manage to subdue the prisoner.'
					},
					effects: [{ type: 'dirtydozen', dirtyDozenId: 7, value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Let other passengers help the officers'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'A passenger breaks his nose, but the situation gets under control.'
					},
					effects: [{ type: 'threat', threatId: 'passenger-injured2' }],
				},
			},
		],
	},
	{
		id: 103,
		title: {
			en: 'Passengers require reseating'
		},
		type: 'critical',
		text: {
			en: '20 passengers seated in the rear moves to the front because they are afraid of getting infected by the passenger with fever.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Calm them down and let them return to their seats'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You manage to calm the crowd.'
					},
					effects: [
						{ type: 'inconvenience', value: 1 },
						{ type: 'dirtydozen', dirtyDozenId: 4, value: 1 },
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Let them stay in the front of the cabin'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You have a problem with weight and balance.'
					},
					effects: [
						{ type: 'risk', value: 2 },
						{ type: 'threat', threatId: 'off-trim' },
					],
				},
			},
		],
	},
	{
		id: 104,
		title: {
			en: 'Revenge'
		},
		type: 'critical',
		text: {
			en: 'The passenger that broke his nose trying to pacify the prisoner has poured a pot of hot coffee all over the prisoner who is screaming.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Stop the passenger and strap him to a chair'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The passenger calms down.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Try to calm down the passenger'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The passenger calms down.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Ignore the incident'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'The passenger calms down.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
		],
	},
	{
		id: 105,
		title: {
			en: 'Panic'
		},
		type: 'critical',
		text: {
			en: 'The passenger who is afraid of flying is now in full panic, screaming and trying to open a safety exit.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Stop the passenger and strap him to a chair'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You strap him to a chair.'
					},
					effects: [{ type: 'inconvenience', value: 1 }],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Try to calm down the passenger'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You spend time trying to calm him to little effect.'
					},
					effects: [{ type: 'inconvenience', value: 2 }],
					conditionals: [
						{
							conditions: [{ type: 'crew', isSelected: true, crewId: 9 }],
							text: {
								en: 'Lillian manages to calm them down.'
							},
							effects: [],
						},
					],
				},
			},
			{
				id: 'c',
				text: {
					en: 'Ignore him'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You ignore him.'
					},
					effects: [{ type: 'inconvenience', value: 2 }],
				},
			},
		],
	},
];

export { eventCards };
