/* eslint-disable max-len */
/* 
Stress / Pressure / Fatigue
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 

Distraction / Complacency / Lack of awareness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},

Lack of communication / teamwork / assertiveness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},

Lack of resources, lack of knowledge, norms
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
*/


/*
Lack of Communication 
{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},

Distraction 
{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},

Lack of Resources 
{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},

Stress 
{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},

Complacency 
{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},

Lack of Teamwork 
{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},

Pressure 
{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},

Lack of Awareness 
{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},

Lack of Knowledge 
{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},

Fatigue 
{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},

Lack of Assertiveness 
{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},

Norms 
{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},

*/

/*
{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
*/

let eventCardsData = {
	/* Day 1 */
	'day-1': [
		{
			id: 5,
			title: {
				en: 'The passenger seat',
				de: 'Der Passagiersitz'
			},
			text: {
				en: 'You are asked to contact a captain who has reported a problem with a cabin seat reclining mechanism. A repair normally takes 25 minutes. The aircraft has been boarded at full capacity. No spare aircraft are available.',
				de: 'Sie werden gebeten, sich mit einem Kapitän in Verbindung zu setzen, der ein Problem mit dem Verstellmechanismus eines Kabinensitzes gemeldet hat. Eine Reparatur dauert normalerweise 25 Minuten. Das Flugzeug ist bereits voll besetzt. Es sind keine Ersatzflugzeuge verfügbar.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You replace the actuator straight away while the passengers wait in the aisle.',
						de: 'Sie tauschen den Aktuator sofort aus, während die Fahrgäste im Gang warten.'
					},
					consequences: {
						text: {
							en: 'Passengers may distract you and create pressure, so you may feel compelled to select a "quick fix".',
							de: 'Passagiere können Sie ablenken und unter Druck setzen, so dass Sie sich gezwungen fühlen, eine "schnelle Lösung" zu wählen.'
						},
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You ask the captain to send the passengers back to the terminal while you perform the repair job.',
						de: 'Sie bitten den Kapitän, die Passagiere zurück zum Terminal zu schicken, während Sie die Reparatur durchführen.'
					},
					consequences: {
						text: {
							en: 'This puts a lot of operational pressure on both aircraft and ground crew.',
							de: 'Dies stellt sowohl für die Flugzeugbesatzung als auch für das Bodenpersonal eine große Belastung dar.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You inform the captain that you will defer the defect in accordance with the Minimum Equipment List.',
						de: 'Sie teilen dem Kapitän mit, dass Sie den Mangel gemäß der Minimum Equipment List zurückstellen werden.'
					},
					consequences: {
						text: {
							en: 'Some passengers must be off-loaded.',
							de: 'Einige Passagiere müssen das Flugzeug verlassen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'time', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 6,
			title: {
				en: 'The arm rest',
				de: 'Die Armlehne '
			},
			text: {
				en: 'You are performing some maintenance tasks in the flight deck. On your way through the cabin, you notice an arm rest which has come completely detached from a passenger seat.',
				de: 'Sie führen einige Wartungsarbeiten in der Kabine durch. Auf Ihrem Weg durch die Kabine bemerken Sie eine Armlehne, die sich vollständig von einem Passagiersitz gelöst hat.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'There is no entry about this defect in the log. It is a simple 10-minutes job, so you add it to the work order you have, and carryout the job while you are in the area.',
						de: 'Es gibt keinen Eintrag über diesen Mangel im TechLog. Es handelt sich um einen einfachen 10-Minuten-Auftrag, den Sie in Ihren Arbeitsauftrag aufnehmen und ausführen, während Sie vor Ort sind.'
					},
					consequences: {
						text: {
							en: 'A proactive action but maybe your time and skills were needed elsewhere.',
							de: 'Eine proaktive Maßnahme, aber vielleicht wurden Ihre Zeit und Ihre Fähigkeiten an anderer Stelle benötigt.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'time', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'There is a procedure for flight and cabin crew to report defects. You ignore the problem and focus on your own tasks.',
						de: 'Es gibt ein Verfahren für das Flug- und Kabinenpersonal, um Mängel zu melden. Sie ignorieren das Problem und konzentrieren sich auf Ihre eigenen Aufgaben.'
					},
					consequences: {
						text: {
							en: 'If unreported the defect may incur delays or reduced revenues from an inoperative seat.',
							de: 'Wird der Defekt nicht gemeldet, kann es zu Verspätungen oder Umsatzeinbußen durch einen nicht funktionierenden Sitz kommen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You talk to your supervisor right away and tell her about your discovery.',
						de: 'Sie sprechen sofort mit Ihrem Vorgesetzten und erzählen ihr von Ihrer Entdeckung.'
					},
					consequences: {
						text: {
							en: 'You are distracted from your present task.',
							de: 'Sie sind von Ihrer aktuellen Aufgabe abgelenkt.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 7,
			title: {
				en: 'The towing',
				de: 'Der Schleppvorgang'
			},
			text: {
				en: `You have been working on an aircraft sitting on the apron outside the hangar in order to get it ready for the morning schedule. Everybody else has left for the day. Company policy states 1) Towing an aircraft into the hangar requires two wing-walkers. 2) All aircraft shall be parked inside overnight to protect them from weather and increase morning departure dispatch reliability.
					<br /><br />You try to call Ground Handling but nobody picks up.`,
				de: `Sie haben an einem Flugzeug gearbeitet, das auf dem Vorfeld vor dem Hangar steht, um es auf den morgendlichen Flugplan vorzubereiten. Alle anderen sind für heute gegangen. Die Unternehmensrichtlinien besagen: 1) Für das Schleppen eines Flugzeugs in den Hangar sind zwei Wing-Walker erforderlich. 2) Alle Flugzeuge werden über Nacht drinnen geparkt, um sie vor Witterungseinflüssen zu schützen und die Zuverlässigkeit der Abfertigung am Morgen zu erhöhen.
					<br /><br />Sie versuchen, die Bodenabfertigung anzurufen, aber niemand nimmt ab.`
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You write a sticky note with the text: “Handlers! Remember to put aircraft in hangar” and pin it to the door to the break room.',
						de: 'Sie schreiben einen Klebezettel mit dem Text: "Abfertiger! Denkt daran, die Flugzeuge in den Hangar zu bringen" und kleben ihn an die Tür zum Pausenraum.'
					},
					consequences: {
						text: {
							en: 'If the staff do not see the note, the aircraft sits outside all night.',
							de: 'Wenn das Personal den Zettel nicht sieht, bleibt das Flugzeug die ganze Nacht draußen stehen.'
						},
						effects: [
							{type: 'cost', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'The aircraft cleaning company is in the hangar. You ask two of the cleaners to act as wing walkers when you tow the aircraft in.',
						de: 'Die Flugzeugreinigungsfirma befindet sich im Hangar. Sie bitten zwei der Reinigungskräfte, als "Wing Walker" zu fungieren, wenn Sie das Flugzeug einschleppen.'
					},
					consequences: {
						text: {
							en: 'Use of untrained staff may cause confusion and communication breakdown during critical phases of towing.',
							de: 'Der Einsatz von ungeschultem Personal kann in kritischen Phasen des Schleppvorgangs zu Verwirrung und Kommunikationsstörungen führen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},

						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'If the rules are impossible to comply with, you can do nothing. You leave the aircraft as it is and go home.',
						de: 'Wenn die Regeln nicht eingehalten werden können, können Sie nichts tun. Sie lassen das Flugzeug so, wie es ist, und gehen nach Hause.'
					},
					consequences: {
						text: {
							en: 'Risk of delays in the morning rotation if the aircraft is exposed to inclement weather.',
							de: 'Wenn das Flugzeug schlechtem Wetter ausgesetzt ist, besteht die Gefahr von Verspätungen im morgendlichen Flugbetrieb. '
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'time', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},

						],
					},
				},
			]
		},
		{
			id: 8,
			title: {
				en: 'The bolt',
				de: 'Der Bolzen'
			},
			text: {
				en: 'You are installing a bolt in the hot section of an engine. The manual calls for the bolt to be lubricated with a special heat resistant compound. The only batch in the hangar is quarantined as it expired three weeks ago.',
				de: 'Sie bauen eine Schraube in die Hot Section eines Triebwerks ein. Laut Handbuch muss die Schraube mit einer speziellen hitzebeständigen Paste geschmiert werden. Die einzige Charge im Hangar liegt im Sperrlager, da sie vor drei Wochen abgelaufen ist.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You retrieve it from quarantine and use it anyway as it looks satisfactory.',
						de: 'Sie holen die Charge aus dem Sperrlager und verwenden sie trotzdem, da sie zufriedenstellend aussieht.'
					},
					consequences: {
						text: {
							en: 'Use of a product which doesn\'t conform to specifications can introduce an immediate or latent problem in the engine.',
							de: 'Die Verwendung eines Produkts, das nicht den Spezifikationen entspricht, kann ein unmittelbares oder latentes Problem im Triebwerk verursachen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},

						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'This is the only engine you know of where this special compound is used. All other comparable installations use a more common compound. You use that one.',
						de: 'Dies ist das einzige Triebwerk, von dem Sie wissen, für das diese spezielle Mischung verwendet wird. Alle anderen vergleichbaren Einbauten verwenden eine gängigere Mixtur. Sie verwenden einfach diese.'
					},
					consequences: {
						text: {
							en: 'Use of a product which doesn\'t conform to specifications can introduce an immediate or latent problem in the engine.',
							de: 'Die Verwendung eines Produkts, das nicht den Spezifikationen entspricht, kann ein unmittelbares oder latentes Problem im Triebwerk verursachen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You stop work and order a delivery of the special compound. It will arrive in fourteen days.',
						de: 'Sie stellen die Arbeit ein und bestellen eine Lieferung der Spezialpaste. Sie wird in vierzehn Tagen eintreffen.'
					},
					consequences: {
						text: {
							en: 'Lack of resources may cause increased down-time, cost and operational pressure.',
							de: 'Ein Mangel an Ressourcen kann zu längeren Ausfallzeiten, höheren Kosten und größerem Betriebsdruck führen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		}
	],
	/* Day 2 */
	'day-2': [
		{
			id: 10,
			title: {
				en: 'The incorrect manual',
				de: 'Das fehlerhafte Handbuch'
			},
			text: {
				en: 'You are on night shift carrying out a complex test according to the Maintenance Manual. However, the prescribed test procedures do not seem to quite fit the aircraft layout.',
				de: 'Sie sind in der Nachtschicht und führen einen komplexen Test gemäß dem Wartungshandbuch durch. Die vorgeschriebenen Prüfverfahren scheinen jedoch nicht ganz zum Luftfahrzeugtyp zu passen.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Wait until the morning to get advice from the Quality Manager.',
						de: 'Sie warten bis zum Morgen, um den Rat des Qualitätsmanagers einzuholen.'
					},
					consequences: {
						text: {
							en: 'Aircraft is delayed.',
							de: 'Das Flugzeug hat Verspätung.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Tweak the test procedure so you are sure you will obtain the required result.',
						de: 'Sie optimieren das Testverfahren, so dass Sie sicher sein können, dass Sie das gewünschte Ergebnis erhalten.'
					},
					consequences: {
						text: {
							en: 'You cannot be sure that the test was effective and adequate.',
							de: 'Sie können nicht sicher sein, dass der Test wirksam und angemessen war.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Do what the book says. Then raise a technical publication query.',
						de: 'Sie tun, was im Handbuch steht. Dann stellen Sie eine Anfrage zur technischen Veröffentlichung.'
					},
					consequences: {
						text: {
							en: 'You cannot be sure that the test was effective and adequate.',
							de: 'Sie können nicht sicher sein, dass der Test wirksam und angemessen war.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 11,
			title: {
				en: 'The double shift',
				de: 'Die Doppelschicht'
			},
			text: {
				en: 'You have to work a double shift in order to finish an engine change. The last hours of the job involve running the engine, opening cowlings and making adjustments repeatedly. It is windy and cold and getting very late.',
				de: 'Sie müssen eine Doppelschicht einlegen, um einen Triebwerkstausch abzuschließen. Die letzten Stunden der Arbeit bestehen darin, wiederholt das Triebwerk laufen zu lassen, die Cowlings zu öffnen und Einstellungen vorzunehmen. Es ist windig, kalt und es wird sehr spät.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You perform the work as fast as possible while the aircraft is on the apron.',
						de: 'Sie führen die Arbeiten so schnell wie möglich aus, während sich das Flugzeug auf dem Vorfeld befindet.'
					},
					consequences: {
						text: {
							en: 'The rush to finish the job may lead you to forget important safety routines.',
							de: 'Die Arbeit in der Eile kann dazu führen, dass Sie wichtige Sicherheitsroutinen vergessen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You tow the aircraft into the hangar each time you do adjustments.',
						de: 'Sie schleppen das Flugzeug jedes Mal in den Hangar, wenn Sie Einstellungen vornehmen.'
					},
					consequences: {
						text: {
							en: 'Towing the aircraft into the hangar may introduce additional safety risks.',
							de: 'Das Schleppen des Flugzeugs in den Hangar kann zusätzliche Sicherheitsrisiken bergen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You perform the work while the aircraft is on the apron but take frequent breaks inside to get warm.',
						de: 'Sie führen die Arbeit aus, während sich das Flugzeug auf dem Vorfeld befindet, machen aber häufig Pausen im Inneren, um sich aufzuwärmen.'
					},
					consequences: {
						text: {
							en: 'Despite reducing the negative effect of the environment, there is still pressure to finish the job.',
							de: 'Obwohl die negativen Auswirkungen der Umwelt verringert wurden, besteht weiterhin Druck, die Arbeit zu beenden.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 12,
			title: {
				en: 'The original tool',
				de: 'Das Spezialwekzeug'
			},
			text: {
				en: 'You are performing an adjustment and need a special fixture mentioned in the manual. You find that the original tool has never been purchased because of the cost.',
				de: 'Sie führen eine Einstellung durch und benötigen eine spezielle Vorrichtung, die im Handbuch erwähnt wird. Sie stellen fest, dass das Originalwerkzeug aus Kostengründen nie gekauft wurde.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'The neighbor company has an internally approved alternative for the required tool. You copy the tool and use that to complete the job. Then you ask the supervisor to add it to your inventory.',
						de: 'Das Nachbarunternehmen hat eine intern genehmigte Alternative für das benötigte Werkzeug. Sie kopieren das Werkzeug und verwenden es, um die Arbeit zu erledigen. Dann bitten Sie den Vorgesetzten, es zu Ihrem Bestand hinzuzufügen.'
					},
					consequences: {
						text: {
							en: 'The inadequacy of the tool may go undetected, if you bypass the approval process.',
							de: 'Dass das Werkzeug ungeeignet ist, kann unentdeckt bleiben, wenn Sie das Genehmigungsverfahren umgehen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You evaluate the test setup and decide it is perfectly safe to perform the measurements using your standard tools.',
						de: 'Sie bewerten den Versuchsaufbau und entscheiden, dass es absolut sicher ist, die Messungen mit Ihren Standardwerkzeugen durchzuführen.'
					},
					consequences: {
						text: {
							en: 'If you get the measurements wrong, the integrety of the system is put at risk.',
							de: 'Wenn Sie die Messungen falsch durchführen, ist die Zuverlässigkeit des Systems gefährdet.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You complete and sign off the job minus the final adjustment check and defer that check to a new work order after ordering the tool.',
						de: 'Sie beenden und unterschreiben den Auftrag ohne die endgültige Einstellungsprüfung und verschieben diese auf einen neuen Arbeitsauftrag nach der Bestellung des Werkzeugs.'
					},
					consequences: {
						text: {
							en: 'There is an increased risk of system failure if the test is not completed.',
							de: 'Es besteht ein erhöhtes Risiko von Systemausfällen, wenn die Prüfung nicht abgeschlossen wird.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		},
		{
			id: 13,
			title: {
				en: 'The sealant',
				de: 'Der Sealer'
			},
			text: {
				en: 'You need to apply some sealant now in order for it  to cure before the aircraft is needed. It is in stock but you cannot issue it for the task as the computer system is down.',
				de: 'Sie müssen jetzt etwas Sealer auftragen, damit es aushärten kann, bevor das Flugzeug den Hangar verlässt. Im Lager ist es eingelagert, aber Sie können es für die Auftrag nicht ausgeben, da das Computersystem ausgefallen ist.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You take the sealant and leave a note on the screen asking the next person using the computer to enter your transaction into the system.',
						de: 'Sie nehmen den Sealer und hinterlassen eine Notiz auf dem Bildschirm, in der Sie die nächste Person am Computer bitten, Ihre Transaktion in das System einzugeben.'
					},
					consequences: {
						text: {
							en: 'The note may go unnoticed so the system is not updated.',
							de: 'Der Hinweis könnte unbemerkt bleiben, wodurch das System nicht aktualisiert wird.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You take the sealant with the intention of making the entry the next day.',
						de: 'Sie nehmen den Sealer mit der Absicht, die Buchung am nächsten Tag vorzunehmen.'
					},
					consequences: {
						text: {
							en: 'The update may not be done, if you get distracted or forget.',
							de: 'Die Buchung wird möglicherweise nicht durchgeführt, wenn Sie abgelenkt werden oder es vergessen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You contact Operations and tell them the aircraft will be grounded until sealant is available.',
						de: 'Sie setzen sich mit der Betriebsleitung in Verbindung und teilen ihr mit, dass das Flugzeug am Boden bleibt, bis der Sealer verfügbar ist.'
					},
					consequences: {
						text: {
							en: 'Huge costs may result from delays or cancellations.',
							de: 'Durch Verspätungen oder Absagen können enorme Kosten entstehen.'
						},
						effects: [
							{type: 'cost', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		}
	],
	/* Day 3 */
	'day-3': [
		{
			id: 20,
			title: {
				en: 'The scratch',
				de: 'Der Kratzer'
			},
			text: {
				en: 'You are installing a component when a pilot asks you to have a quick look at her aircraft. She wants to know if a scratch on a side window is within limits.',
				de: 'Sie bauen gerade ein Bauteil ein, als eine Pilotin Sie bittet, sich ihr Flugzeug kurz anzuschauen. Sie möchte wissen, ob ein Kratzer an der Seitenscheibe noch in Ordnung ist.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You do this 15 minute job straight away.',
						de: 'Sie erledigen diesen 15-minütigen Job sofort.'
					},
					consequences: {
						text: {
							en: 'You assume that you can do this quickly, but things may escalate.',
							de: 'Sie gehen davon aus, dass Sie das schnell erledigen können, aber die Dinge könnten schnell ausufern.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'time', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You ask her to use the formal reporting channels.',
						de: 'Sie bitten die Pilotin, den offiziellen Meldeweg zu benutzen.'
					},
					consequences: {
						text: {
							en: 'Be aware of how you communicate this: the pilot may feel brushed off.',
							de: 'Achten Sie darauf, wie Sie dies kommunizieren: Die Pilotin könnte sich dadurch abgewiesen fühlen.'
						},
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You tell her that you are busy and that scratches are usually ok, if you cannot feel them with your nails.',
						de: 'Sie sagen ihr, dass Sie beschäftigt sind und dass Kratzer normalerweise in Ordnung sind, wenn man sie nicht mit den Fingernägeln fühlen kann.'
					},
					consequences: {
						text: {
							en: 'Safety may be compromised if your advice is inaccurate.',
							de: 'Wenn Ihr Rat ungenau ist, besteht die Gefahr, dass die Sicherheit beeinträchtigt wird.'
						},
						effects: [
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 21,
			title: {
				en: 'The calibration sticker',
				de: 'Die Kalibrierungsplakette'
			},
			text: {
				en: 'You are about to use a calibrated tool, when you find that the calibration sticker is missing. You remember using the tool a few days ago and also remember the due date is yet to expire.',
				de: 'Sie wollen ein kalibriertes Werkzeug benutzen, als Sie feststellen, dass die Kalibrierungsplakette fehlt. Sie erinnern sich, dass Sie das Werkzeug vor einigen Tagen benutzt haben und dass das Fälligkeitsdatum noch nicht abgelaufen ist.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You use the tool as it is.',
						de: 'Sie verwenden das Werkzeug so, wie es ist.'
					},
					consequences: {
						text: {
							en: 'There may be a reason why the sticker is removed. Maybe the tool is faulty?',
							de: 'Es könnte einen Grund geben, warum der Aufkleber entfernt wurde. Vielleicht ist das Werkzeug defekt?'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You drive to the other side of the airport to pick up a similar tool in the line station tool box.',
						de: 'Sie fahren auf die andere Seite des Flughafens, um ein ähnliches Werkzeug aus dem Werkzeugkasten der Line Station zu holen.'
					},
					consequences: {
						text: {
							en: 'Increased time doing this may cause stress, pressure and delays.',
							de: 'Ein erhöhter Zeitaufwand für diese Tätigkeit kann zu Stress, Druck und Verzögerungen führen.'
						},
						effects: [
							{type: 'time', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You consult the calibration database and retrieve the information required for making a new sticker. You mark the tool and use it to finish the job.',
						de: 'Sie konsultieren die Kalibrierungsdatenbank und rufen die für die Erstellung eines neuen Aufklebers erforderlichen Informationen ab. Sie markieren das Werkzeug und verwenden es, um die Arbeit zu beenden.'
					},
					consequences: {
						text: {
							en: 'Increased time doing this may cause stress, pressure and delays.',
							de: 'Ein erhöhter Zeitaufwand für diese Tätigkeit kann zu Stress, Druck und Verzögerungen führen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 22,
			title: {
				en: 'The routine job',
				de: 'Die Routinearbeit'
			},
			text: {
				en: 'You are about to perform a routine job. When trying to access the maintenance job instructions, you find that the manual server is down.',
				de: 'Sie sind dabei, eine Routinearbeit durchzuführen. Als Sie versuchen, die Anweisungen für die Wartungsarbeiten aufzurufen, stellen Sie fest, dass der Server für das Handbuch nicht verfügbar ist.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You have done this job many times. You can safely do it again without consulting the manual.',
						de: 'Sie haben diese Aufgabe schon viele Male erledigt. Sie können sie ohne Bedenken wiederholen, ohne das Handbuch zu Rate zu ziehen.'
					},
					consequences: {
						text: {
							en: 'Complacency and distractions may cause you to forget details.',
							de: 'Nachlässigkeit und Ablenkungen können dazu führen, dass Sie Details vergessen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You know that an identical system is installed on another type of aircraft; and those manuals are available. You use them to complete the job.',
						de: 'Sie wissen, dass ein identisches System in einem anderen Flugzeugtyp installiert ist, und dass diese Handbücher verfügbar sind. Sie verwenden sie, um die Arbeit zu erledigen.'
					},
					consequences: {
						text: {
							en: 'Complacency and Lack of knowledge may cause you to believe that the systems are similar when they are in fact, not.',
							de: 'Selbstgefälligkeit und mangelndes Wissen können dazu führen, dass Sie davon ausgehen, dass die Systeme ähnlich sind, obwohl sie es nicht sind.'
						},
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You ask a neighbor company if you can use their manual.',
						de: 'Sie fragen ein befreundetes Unternehmen, ob Sie dessen Handbuch verwenden können.'
					},
					consequences: {
						text: {
							en: 'It will take extra time to obtain the manual and you cannot be sure it is updated.',
							de: 'Es dauert länger, das Handbuch zu beschaffen, und Sie wissen nicht, ob es aktuell ist.'
						},
						effects: [
							{type: 'time', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 23,
			title: {
				en: 'The ladder',
				de: 'Die Leiter'
			},
			text: {
				en: 'When fetching a ladder from the equipment storage area, you notice that another ladder has a loose step.',
				de: 'Während Sie eine Leiter aus dem Gerätelager holen, stellen Sie fest, dass eine andere Leiter eine lose Stufe aufweist.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You put a red tag on it, move it to quarantine and report it to your supervisor.',
						de: 'Sie versehen diese mit einem roten Etikett, bringen sie ins Sperrlager und melden sie Ihrem Vorgesetzten.'
					},
					consequences: {
						text: {
							en: 'While this may take longer to do right now, you eliminate a potential hazard.',
							de: 'Das kann zwar länger dauern, aber Sie beseitigen damit eine potenzielle Gefahr.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You do nothing. You expect your colleagues to check any ladder they want to use.',
						de: 'Sie tun nichts. Sie erwarten von Ihren Kollegen, dass diese die Leitern, die sie benutzen wollen, überprüfen.'
					},
					consequences: {
						text: {
							en: 'Ignoring latent problems increases the risk of an accident.',
							de: 'Das Ignorieren verborgener Probleme erhöht das Risiko eines Unfalls.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You remove the equipment check label so everybody can see that the ladder is broken.',
						de: 'Sie entfernen das Prüfplakat für die Ausrüstung, damit jeder sehen kann, dass die Leiter kaputt ist.'
					},
					consequences: {
						text: {
							en: 'The latent hazard has not been removed and still poses a risk to the safe working environment.',
							de: 'Die Gefahrenquelle wurde nicht beseitigt und stellt nach wie vor ein Risiko für die sichere Arbeitsumgebung dar.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		}
	],
	/* Day 4 */
	'day-4': [
		{
			id: 25,
			title: {
				en: 'Almost done',
				de: 'Fast fertig'
			},
			text: {
				en: 'You and a colleague have performed an inspection; and you shall sign off and release the aircraft. Time is running late, and you have to leave. All testing has been completed and only the reinstallation of panels and cowlings remain.',
				de: 'Sie und ein Kollege haben eine Inspektion durchgeführt; Sie sollen das Flugzeug abzeichnen und freigeben. Die Zeit drängt, und Sie müssen gehen. Alle Tests sind abgeschlossen, und es müssen nur noch die Panels und Cowlings wieder montiert werden.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Your colleague agrees to finish the job. You sign for the inspection in the logbook and work order and leave.',
						de: 'Ihr Kollege erklärt sich bereit, die Arbeit zu beenden. Sie unterschreiben die Inspektion im Logbuch sowie den Arbeitsauftrag und gehen.'
					},
					consequences: {
						text: {
							en: 'Errors may go undetected if the aircraft is prematurely released.',
							de: 'Wenn das Flugzeug vorzeitig freigegeben wird, könnten Fehler unentdeckt bleiben.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You ask for somebody else to sign once the job is completely done.',
						de: 'Nachdem die Arbeit vollständig erledigt ist, bitten Sie eine andere Person um eine Unterschrift.'
					},
					consequences: {
						text: {
							en: 'This is a task handover situation where errors may go undetected if handled informally.',
							de: 'Dies ist eine Übergabe, bei der Fehler unentdeckt bleiben können, wenn sie informell gehandhabt werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You ask your colleague to complete the job and prepare the paperwork. You will sign for the aircraft next morning before first flight.',
						de: 'Sie bitten Ihren Kollegen, den Auftrag auszuführen und die Papiere vorzubereiten. Sie werden für das Flugzeug am nächsten Morgen vor dem ersten Flug unterschreiben.'
					},
					consequences: {
						text: {
							en: 'The aircraft may end up on the apron without release.',
							de: 'Es könnte sein, dass das Flugzeug ohne Freigabe auf das Vorfeld gelangt.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'time', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'time', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 26,
			title: {
				en: 'The late colleague',
				de: 'Der verspätete Kollege'
			},
			text: {
				en: 'You are working on a complex modification that you must hand over at the end of the shift. The colleague taking over is late, and you have to leave.',
				de: 'Sie arbeiten an einer komplexen Modifikation, die Sie am Ende Ihrer Schicht übergeben müssen. Der Kollege, der die Arbeit übernimmt, kommt zu spät, und Sie müssen gehen.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A',
					},
					text: {
						en: 'You shoot a video explaining what you have done and the task status. You send it to your colleague.',
						de: 'Sie drehen ein Video, in dem Sie erklären, was Sie getan haben und wie der Stand der Arbeit ist. Sie senden es an Ihren Kollegen.'
					},
					consequences: {
						text: {
							en: 'You cannot be sure that the colleague watches and understands the video.',
							de: 'Sie können nicht sicher sein, dass der Kollege das Video sieht und versteht.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You hand over the task to another person from the next shift and ask him to pass the information on.',
						de: 'Sie übergeben die Aufgabe an eine andere Person der nächsten Schicht und bitten sie, die Informationen weiterzugeben.'
					},
					consequences: {
						text: {
							en: 'The message may get lost in translation when passed on.',
							de: 'Die Botschaft kann bei der Weitergabe möglicherweise verloren gehen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'time', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Reluctantly you cancel your private arrangements and wait for the colleague to show up.',
						de: 'Widerwillig sagen Sie Ihre privaten Vereinbarungen ab und warten auf den Kollegen.'
					},
					consequences: {
						text: {
							en: 'You put yourself under more stress and may forget or deviate from procedures.',
							de: 'Sie setzen sich selbst mehr Stress aus und vergessen eventuell Abläufe oder weichen von ihnen ab.'
						},
						effects: [
							{type: 'time', value: 2},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 27,
			title: {
				en: 'The temporary malfunction',
				de: 'Die vorübergehende Störung'

			},
			text: {
				en: 'A pilot has reported a temporary malfunction of a system.',
				de: 'Ein Pilot hat eine vorübergehende Störung eines Systems gemeldet.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You do 3 consecutive tests, but the system works fine. You release the aircraft with (No Fault Found).',
						de: 'Sie führen 3 aufeinanderfolgende Tests durch, doch das System funktioniert einwandfrei. Sie geben das Flugzeug mit (No Fault Found) frei.'
					},
					consequences: {
						text: {
							en: 'If the system starts to fail more consistently, you may end up with a grounded aircraft.',
							de: 'Wenn das System häufiger ausfällt, kann es sein, dass das Flugzeug gegroundet wird.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You cannot repeat the system failure during tests. It is commonly known that a certain component causes trouble. You replace that component and release the aircraft.',
						de: 'Sie können den Systemfehler während der Tests nicht wiederholen. Es ist allgemein bekannt, dass eine bestimmte Komponente Probleme verursacht. Sie ersetzen diese Komponente und geben das Flugzeug frei.'
					},
					consequences: {
						text: {
							en: 'Your bias towards a preferred type of action may prevent you from seeing other indications pointing to other components.',
							de: 'Ihre Voreingenommenheit für einer bevorzugten Vorgehensweise kann Sie daran hindern, weitere Anzeichen zu erkennen, die auf andere Komponenten hinweisen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do a full and comprehensive troubleshooting of the entire system and all components.',
						de: 'Sie führen eine vollständige und umfassende Fehlersuche im gesamten System durch.'
					},
					consequences: {
						text: {
							en: 'This may be a very lenghty operation depending on system complexity.',
							de: 'Je nach Komplexität des Systems kann dies ein sehr aufwendiger Vorgang sein.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		},
		{
			id: 28,
			title: {
				en: 'The functional check',
				de: 'Der Functional Check'
			},
			text: {
				en: 'You are conducting a Functional Check of a system and shall measure the voltage between pins a and b in a plug. Only two wires are installed in the plug, and they are at positions aa and bb.',
				de: 'Sie führen einen Functional Check eines Systems durch und sollen die Spannung zwischen den Stiften a und b in einem Stecker messen. In dem Stecker sind nur zwei Pins installiert, und zwar an den Positionen aa und bb.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'This is obviously a typing error in the manual. You conduct the test replacing a with aa and b with bb.',
						de: 'Dies ist offensichtlich ein Tippfehler in der Anweisung. Sie führen den Test durch, indem Sie a durch aa und b durch bb ersetzen.'
					},
					consequences: {
						text: {
							en: 'The test you perform may not be designed to identify any existing faults in the system.',
							de: 'Der von Ihnen durchgeführte Test ist möglicherweise nicht darauf ausgelegt, vorhandene Fehler im System zu erkennen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You stop the test and ask engineering to contact the aircraft manufacturer for guidance.',
						de: 'Sie brechen die Prüfung ab und bitten das Engineering, den Flugzeughersteller um Rat zu fragen.'
					},
					consequences: {
						text: {
							en: 'This will most likely increase the downtime significantly.',
							de: 'Dies wird die Standzeit höchstwahrscheinlich erheblich verlängern.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You skip the part of the testing procedure that cannot be performed in accordance with the instruction.',
						de: 'Sie überspringen den Teil des Prüfverfahrens, der nicht gemäß der Anweisung durchgeführt werden kann.'
					},
					consequences: {
						text: {
							en: 'Your maintenance actions are inadequate in detecting all faults that may exist in the system.',
							de: 'Ihre Wartungsmaßnahmen reichen nicht aus, um alle möglichen Fehler im System zu erkennen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		}
	]
};


export { eventCardsData };
