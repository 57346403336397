/* eslint-disable max-len */
/* 
Stress / Pressure / Fatigue
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 

Distraction / Complacency / Lack of awareness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},

Lack of communication / teamwork / assertiveness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},

Lack of resources, lack of knowledge, norms
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
*/


/*
Lack of Communication 
{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},

Distraction 
{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},

Lack of Resources 
{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},

Stress 
{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},

Complacency 
{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},

Lack of Teamwork 
{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},

Pressure 
{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},

Lack of Awareness 
{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},

Lack of Knowledge 
{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},

Fatigue 
{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},

Lack of Assertiveness 
{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},

Norms 
{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},

*/

/*
{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
*/

let eventCardsData = {
	/* Day 1 */
	'day-1': [
		{
			id: 5,
			title: {
				en: 'The missing tool',
				de: 'Das fehlende Werkzeug'
			},
			text: {
				en: 'After maintenance the aircraft is scheduled to fly right away to make a slot at a painting facility. A tool used on the aircraft can however not accounted for.',
				de: 'Nach der Wartung ist geplant, dass das Flugzeug sofort abfliegt, um einen Termin in einer Lackieranlage wahrzunehmen. Ein am Luftfahrzeug verwendetes Werkzeug ist jedoch nicht auffindbar. '
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Ground the aircraft until the tool is recovered.',
						de: 'Das Luftfahrzeug muss am Boden bleiben, bis das Werkzeug geborgen ist.'
					},
					consequences: {
						text: {
							en: 'Tool control is essential to prevent FOD.',
							de: 'Die Kontrolle des Werkzeugs ist wichtig, um FOD zu vermeiden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Let the aircraft do the ferry flight and use the down time during painting to search for the tool.',
						de: 'Sie lassen das Flugzeug den Überführungsflug machen und nutzen die Ausfallzeit während der Lackierung, um nach dem Werkzeug zu suchen.'
					},
					consequences: {
						text: {
							en: 'The risk of problems is no less for a ferry flight than for a flight with passengers.',
							de: 'Das Risiko von Problemen ist bei einem Überführungsflug nicht geringer als bei einem Flug mit Passagieren.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},	
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Release the aircraft for flight but inform management so they can decide further action.',
						de: 'Sie geben das Luftfahrzeug für den Flug frei, informieren aber das Management, damit es über das weitere Vorgehen entscheiden kann.'
					},
					consequences: {
						text: {
							en: 'The pilots expect a released aircraft to be in fully serviceable condition.',
							de: 'Die Piloten erwarten, dass ein freigegebenes Flugzeug in einem voll funktionsfähigen Zustand ist.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 6,
			title: {
				en: 'Poor repairs',
				de: 'Schlechte Reparaturen'
			},
			text: {
				en: 'An aircraft is back at the workshop after a long dry-lease. You notice some repairs which looks substandard.',
				de: 'Ein Flugzeug ist nach einem langen Dry-Lease wieder in der Werkstatt. Sie bemerken einige Reparaturen, die mangelhaft aussehen.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You check the records to see if all repairs are duly signed for.',
						de: 'Sie überprüfen die Unterlagen, um festzustellen, ob alle Reparaturen ordnungsgemäß abgezeichnet sind.'
					},
					consequences: {
						text: {
							en: 'Never assume anything. Even if the paperwork is fine the repair may still be bad.',
							de: 'Erwarten Sie niemals etwas. Selbst wenn die Papiere in Ordnung sind, kann die Reparatur trotzdem mangelhaft sein.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You report your findings to the manager',
						de: 'Sie melden Ihre Feststellungen an den Manager.'
					},
					consequences: {
						text: {
							en: 'Reporting thru proper channels may help direct resources to addressing this issue.',
							de: 'Die Meldung über die richtigen Wege kann dazu beitragen, dass Ressourcen für die Behebung des Problems bereitgestellt werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do nothing as the aircraft has been accepted back after the lease.',
						de: 'Sie unternehmen nichts, da das Flugzeug nach Ablauf des Leasingvertrags zurückgenommen wurde.'
					},
					consequences: {
						text: {
							en: 'Maybe something has been overlooked? Your input may be needed here.',
							de: 'Wurde vielleicht etwas übersehen? Hier könnte Ihr Beitrag gefragt sein.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 7,
			title: {
				en: 'The modified tube',
				de: 'Das modifizierte Rohr'
			},
			text: {
				en: 'A Service Bulletin to the hydraulic system requires installation of a modified tube. The delivered part does not fit quite well.',
				de: 'Ein Service Bulletin zum Hydrauliksystem erfordert den Einbau eines modifizierten Rohrs. Das gelieferte Teil passt nicht richtig.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You bend the tube so it will fit properly.',
						de: 'Sie biegen das Rohr, damit es richtig passt.'
					},
					consequences: {
						text: {
							en: 'Modifying components without proper maintenance date may negatively change the system\'s reliability.',
							de: 'Die Änderung von Bauteilen ohne geeignete Wartungsanweisung kann sich negativ auf die Zuverlässigkeit des Systems auswirken.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You stop the installation and order a new pipe from the TC-holder.',
						de: 'Sie brechen die Installation ab und bestellen ein neues Rohr beim TC-Halter.'
					},
					consequences: {
						text: {
							en: 'Service or maintenance problems should always be reported. The TC-holder may be unaware about problems with his product.',
							de: 'Service- oder Wartungsprobleme sollten immer gemeldet werden. Der TC-Halter ist sich möglicherweise nicht über Probleme mit seinem Produkt im Klaren.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},

						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You force the pipe into position, since it is delivered with the approved SB kit.',
						de: 'Sie zwingen das Rohr in Position, da es mit dem zugelassenen SB-Kit geliefert wird.'
					},
					consequences: {
						text: {
							en: 'Complacency may cause best maintenance practices to be forgotten so damage to the system occurs.',
							de: 'Selbstgefälligkeit kann dazu führen, dass bewährte Wartungspraktiken vergessen werden und es zu Schäden am System kommt.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},

						],
					},
				},
			]
		},
		{
			id: 8,
			title: {
				en: 'The skin repair',
				de: 'Die Hautreparatur'
			},
			text: {
				en: 'You are performing a repair to the skin. The job description contains a rivet pattern which you cannot apply due to its interference with an existing repair.',
				de: 'Sie führen eine Reparatur an der Skin durch. Die Auftragsbeschreibung enthält ein Nietmuster, das Sie nicht anwenden können, weil es mit einer bestehenden Reparatur kollidiert.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You tweak the pattern slightly, so it can be applied.',
						de: 'Sie ändern das Muster leicht ab, damit es angewendet werden kann.'
					},
					consequences: {
						text: {
							en: 'Modifying maintenance date may negatively change the system\'s reliability.',
							de: 'Die Änderung der Instandhaltungsvorgaben kann sich negativ auf die Zuverlässigkeit des Systems auswirken.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},

						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You stop the work and request a new repair procedure taking the existing repair into consideration',
						de: 'Sie stoppen die Arbeit und beantragen ein neues Reparaturverfahren, das die bestehende Reparatur berücksichtigt.'
					},
					consequences: {
						text: {
							en: 'Maintenance problems should always be reported. The TC-holder may be unaware about the actual condition of in-service aircrafts.',
							de: 'Wartungsprobleme sollten immer gemeldet werden. Der TC-Halter kennt möglicherweise nicht den tatsächlichen Zustand der in Betrieb befindlichen Flugzeuge.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You perform the repair as best as possible and sign it of as "temporary repair performed".',
						de: 'Sie führen die Reparatur so gut wie möglich durch und vermerken "Vorläufige Reparatur durchgeführt".'
					},
					consequences: {
						text: {
							en: 'The quality of the repair may not be adequate. All jobs should be done to approved standards.',
							de: 'Die Qualität der Reparatur ist möglicherweise nicht ausreichend. Alle Arbeiten sollten nach genehmigten Standards durchgeführt werden.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		}
	],
	/* Day 2 */
	'day-2': [
		{
			id: 10,
			title: {
				en: 'Aircraft limitations',
				de: 'Einschränkungen des Luftfahrzeugs'
			},
			text: {
				en: 'You have followed the procedure of the Minimum Equipment List and deferred some navigational equipment. This however means that the aircraft is restricted to operating in certain weather conditions.',
				de: 'Sie haben das Verfahren der Minimum Equipment List (MEL) befolgt und ein Navigationsgerät zurückgestellt. Dies bedeutet jedoch, dass das Flugzeug nur bei bestimmten Wetterbedingungen eingesetzt werden kann.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You have done your job and can leave the rest to the pilot.',
						de: 'Sie haben Ihre Arbeit getan und können den Rest dem Piloten überlassen.'
					},
					consequences: {
						text: {
							en: 'At the time the pilot discovers the write-up, it may be to late or costly to change aircraft although weather enroute is questionable. This may put operational pressure and stress on the pilot.',
							de: 'Zu dem Zeitpunkt, an dem der Pilot die Aufzeichnung entdeckt, kann es zu spät oder zu teuer sein, das Flugzeug zu wechseln, obwohl das Wetter auf der Strecke unsicher ist. Dies kann den Piloten unter Druck und Stress setzen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You contact operations or the pilot to inform about the condition.',
						de: 'Sie setzen sich mit dem Flugbetrieb oder dem Piloten in Verbindung, um ihn über die Situation zu informieren.'
					},
					consequences: {
						text: {
							en: 'It is important that communication reaches all of the involved parties.',
							de: 'Es ist wichtig, dass die Kommunikation alle beteiligten Parteien erreicht.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You wait at the aircraft so you are available to give a briefing to the pilot when he arrives.',
						de: 'Sie warten am Flugzeug, damit Sie dem Piloten eine Einweisung geben können, wenn er eintrifft.'
					},
					consequences: {
						text: {
							en: 'At that time flight planning may be difficult to change. This may put operational pressure and stress on the pilot.',
							de: 'Zu diesem Zeitpunkt ist es möglicherweise schwierig, die Flugplanung zu ändern. Dies kann den Piloten unter Druck und Stress setzen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 11,
			title: {
				en: 'A double shift',
				de: 'Eine Doppelschicht'
			},
			text: {
				en: 'You have been asked to work a double shift to ensure that an aircraft is ready for the morning rotation. The maintenance tasks are quite commonplace and not covered by the rules for RII / Independent inspections.',
				de: 'Sie wurden gebeten, eine Doppelschicht zu übernehmen, um sicherzustellen, dass ein Flugzeug für die morgendliche Rotation bereit ist. Die Wartungsarbeiten sind recht alltäglich und fallen nicht unter die Vorschriften für "Required Inspection Item" (RII) / unabhängige Inspektionen.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You accept, but ask the supervisor to ensure that a colleague is available in the early morning, to double check your work.',
						de: 'Sie akzeptieren, bitten aber den Vorgesetzten, dafür zu sorgen, dass ein Kollege am frühen Morgen zur Verfügung steht, um Ihre Arbeit unabhängig zu überprüfen.'
					},
					consequences: {
						text: {
							en: 'Applying Error Capturing techniques is a good way of counteracting the negative effects of working long hours.',
							de: 'Die Anwendung von Fehlererfassungstechniken ist ein gutes Mittel, um den negativen Auswirkungen der langen Arbeitszeiten entgegenzuwirken.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You refuse to do this work, because you think your chance of making a mistake is unacceptable large, if you work so many hours.',
						de: 'Sie weigern sich, diese Arbeit zu erledigen, weil Sie der Meinung sind, dass die Gefahr, einen Fehler zu machen, bei so vielen Arbeitsstunden unzumutbar groß ist.'
					},
					consequences: {
						text: {
							en: 'Postponing the work increase operational cost and may cause scheduling problems elsewhere in the organisation.',
							de: 'Die Verschiebung der Arbeit erhöht die Betriebskosten und kann an anderer Stelle im Unternehmen zu Problemen bei der Zeitplanung führen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds:[3, 9, 12]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do the job.',
						de: 'Sie machen die Arbeit.'
					},
					consequences: {
						text: {
							en: 'Ignoring the effect of working long hours, especially during WOCL (Window of Circadian Low), may increase the risk of making mistakes and affect your physical performance, decision making and level of attention.',
							de: 'Die Auswirkungen langer Arbeitszeiten, insbesondere während des WOCL (Window of Circadian Low), zu ignorieren, kann das Fehlerrisiko erhöhen und die körperliche Leistungsfähigkeit, das Entscheidungsvermögen und die Aufmerksamkeit beeinträchtigen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 12,
			title: {
				en: 'The missing supervisor',
				de: 'Der fehlende Vorgesetzte'
			},
			text: {
				en: 'At the start of work, the supervisor doesn\'t show up. You get a phone call from the Reception relaying a message, that the supervisor is at the hospital undergoing emergency surgery.',
				de: 'Zu Beginn der Arbeit erscheint der Vorgesetzte nicht. Sie erhalten einen Anruf von der Rezeption, die Ihnen mitteilt, dass der Vorgesetzte im Krankenhaus ist und notoperiert wird.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You and your colleagues take a seat in the break room waiting for somebody to come and act as manager.',
						de: 'Sie und Ihre Kollegen nehmen im Pausenraum Platz und warten darauf, dass jemand kommt und die Leitung übernimmt.'
					},
					consequences: {
						text: {
							en: 'Planned maintenance are not performed and operational problems and pressure increase. Lack of communication cause confusion and induces wrong decisions throughout the organisation.',
							de: 'Geplante Wartungsarbeiten werden nicht durchgeführt und die betrieblichen Probleme und der Druck nehmen zu. Mangelnde Kommunikation sorgt für Verwirrung und führt zu Fehlentscheidungen im gesamten Unternehmen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'cost', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You try to look up who is substitute but the organisational chart is outdated. You therefore assume the role as supervisor and starts delegating the work orders piled up on the office desk.',
						de: 'Sie versuchen nachzuschauen, wer die Vertretung ist, aber das Organigramm ist veraltet. Sie übernehmen also die Rolle des Vorgesetzten und beginnen, die Arbeitsaufträge, die sich auf dem Schreibtisch stapeln, zu delegieren.'
					},
					consequences: {
						text: {
							en: 'Proactive and assertive action but lack of knowledge and lack of communication may cause you to focus on the wrong things. This may introduce operational problems.',
							de: 'Mangelndes Wissen und fehlende Kommunikation können dazu führen, dass Sie sich auf die falschen Dinge konzentrieren. Dies kann zu Problemen im Arbeitsablauf führen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]}, 
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You ask your colleagues if they know of any work that needs to be finished.',
						de: 'Sie fragen Ihre Kollegen, ob sie wissen, welche Arbeiten erledigt werden müssen.'
					},
					consequences: {
						text: {
							en: 'The situational awareness of both you and your colleagues may be inaccurate causing you to focus on the wrong things. This may introduce operational problems.',
							de: 'Das Situationsbewusstsein von Ihnen und Ihren Kollegen könnte ungenau sein, was dazu führt, dass Sie sich auf die falschen Dinge konzentrieren. Dies kann zu Problemen im Arbeitsablauf führen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]}, 
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 13,
			title: {
				en: 'Maintenance Practices',
				de: 'Instandhaltungpraktiken'
			},
			text: {
				en: 'You are performing maintenance when an apprentice passing by, stops to let you know, that information given to him during recent class training about Best Maintenance Practices, could possibly apply to the job you are undertaking.',
				de: 'Sie führen Wartungsarbeiten durch, als ein Auszubildender vorbeikommt und Sie darauf hinweist, dass er während der letzten Schuleinheit über die empfohlenen Wartungspraktiken Informationen erhalten hat, möglicherweise auf die von Ihnen durchgeführte Arbeit zutreffen könnten.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You tell the apprentice to go away. You work by the Aircraft Maintenance Manual and it doesn\'t mention anything about changed procedures.',
						de: 'Sie sagen dem Lehrling, er solle verschwinden. Sie arbeiten nach dem Flugzeug-Wartungshandbuch, und darin steht nichts über geänderte Verfahren.'
					},
					consequences: {
						text: {
							en: 'Dismissing the apprentice may discourage future knowledge sharing, prevent a valuable learning experience and induce lack of assertiveness in the apprentice.',
							de: 'Wenn Sie den Auszubildenden wegschicken, kann dies die Weitergabe von Wissen behindern, eine wertvolle Lernerfahrung verhindern und zu mangelndem Selbstvertrauen bei dem Auszubildenden führen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You ask the apprentice to provide you with some material about this so you can study it at a later time.',
						de: 'Sie bitten den Auszubildenden, Ihnen einige Unterlagen zu diesem Thema zur Verfügung zu stellen, damit Sie sie zu einem späteren Zeitpunkt prüfen können.'
					},
					consequences: {
						text: {
							en: 'Both you and the apprentice may forget do look more into this. Valuable information may be lost.',
							de: 'Möglicherweise vergessen sowohl Sie als auch der Auszubildende, sich intensiver damit zu befassen. Wertvolle Informationen können dadurch verloren gehen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'This information is new to you and you go to the supervisor straight away, to suggest that a staff meeting is held to discuss how to deal with these new Best Maintenance Practices.',
						de: 'Sie gehen sofort zum Vorgesetzten und schlagen vor, eine Personalversammlung abzuhalten, um zu besprechen, wie man mit diesen neuen "Best Practices" in der Instandhaltung umgeht.'
					},
					consequences: {
						text: {
							en: 'A proactive and immediate response. You are however getting distracted from your present task at the risk of making an error or omission later.',
							de: 'Sie werden jedoch von Ihrer aktuellen Aufgabe abgelenkt und riskieren, dass Ihnen später ein Fehler oder ein Versäumnis unterläuft.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		}
	],
	/* Day 3 */
	'day-3': [
		{
			id: 20,
			title: {
				en: 'The STC',
				de: 'Das STC'
			},
			text: {
				en: 'You are working on a system which was previously modified by an STC. The STC is not available and you have to replace some worn bolts during the job.',
				de: 'Sie arbeiten an einem System, das zuvor von einem STC modifiziert wurde. Das STC ist nicht verfügbar und Sie müssen während der Arbeit einige abgenutzte Bolzen ersetzen.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You identify the bolt type by examining the old ones.',
						de: 'Sie identifizieren den Schraubentyp, indem Sie die alten Schrauben untersuchen.'
					},
					consequences: {
						text: {
							en: 'This action have the potential for many types of errors - and maybe the installed bolt was actually the wrong type.',
							de: 'Bei dieser Aktion können viele Fehler auftreten - und möglicherweise war die installierte Schraube schon falsch.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You stop work and request a copy of the STC',
						de: 'Sie stoppen die Arbeit und fordern eine Kopie des STC an.'
					},
					consequences: {
						text: {
							en: 'It is essential to always use the proper maintenance data.',
							de: 'Es ist wichtig, dass Sie immer die richtigen Instandhaltungsunterlagen verwenden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You use a standard bolt and annotate the work card that this should be checked again when the STC is available.',
						de: 'Sie verwenden eine Standardschraube und vermerken auf der Arbeitskarte, dass diese erneut überprüft werden soll, sobald das STC verfügbar ist.'
					},
					consequences: {
						text: {
							en: 'It may introduce a latent fault into the system if unapproved hardware is used.',
							de: 'Die Verwendung von nicht zugelassenen Bauteilen kann einen latenten Fehler in das System einbringen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 21,
			title: {
				en: 'The quarantined grease',
				de: 'Das gesperrte Schmierfett'
			},
			text: {
				en: 'During a lubrication task you run out of grease. You know that similar grease is in quarantine due to a missing certificate.',
				de: 'Während einer Schmierungsaufgabe geht Ihnen das Fett aus. Sie wissen, dass ein ähnliches Schmierfett aufgrund eines fehlenden Zertifikats in Quarantäne ist.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Stop work until the grease is formally available.',
						de: 'Sie stoppen die Arbeit, bis das Fett offiziell verfügbar ist.'
					},
					consequences: {
						text: {
							en: 'A proper response which may be challenged due to operational pressure.',
							de: 'Eine angemessene Reaktion, die aufgrund des betrieblichen Drucks in Frage gestellt werden kann.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
			
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Use the grease but annotate it in the records that unapproved consumables were used.',
						de: 'Verwenden Sie das Schmierfett, aber vermerken Sie in den Aufzeichnungen, dass nicht zugelassene Verbrauchsmaterialien verwendet wurden.'
					},
					consequences: {
						text: {
							en: 'It may introduce a latent fault into the system if an unapproved consumable is used.',
							de: 'Die Verwendung eines nicht zugelassenen Verbrauchsmaterials kann einen Fehler verursachen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Borrow some grease from the company next door.',
						de: 'Leihen Sie sich etwas Schmierfett von der Firma nebenan.'
					},
					consequences: {
						text: {
							en: 'This may work if you remember that this grease also need receiving inspection and documentation.',
							de: 'Das kann funktionieren, wenn Sie daran denken, dass auch dieses Fett einer Eingangskontrolle und Dokumentation unterzogen werden muss.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 2, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 22,
			title: {
				en: 'The open toolbox',
				de: 'Der offene Werkzeugkasten'
			},
			text: {
				en: 'You are called in, outside of business hours, to tow an aircraft out of the hangar. You notice an open toolbox in the vicinity of the aircraft.',
				de: 'Sie werden außerhalb der Geschäftszeiten gerufen, um ein Flugzeug aus dem Hangar zu schleppen. Sie bemerken einen offenen Werkzeugkasten in der Nähe des Flugzeugs.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Check the toolbox to see if something is missing',
						de: 'Sie überprüfen die Werkzeugkiste, um zu sehen, ob etwas fehlt.'
					},
					consequences: {
						text: {
							en: 'That is fine if you have an updated list of tools available.',
							de: 'Das ist in Ordnung, wenn Sie eine aktuelle Liste der Werkzeuge zur Verfügung haben.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Use the present padlock to lock the box',
						de: 'Verwenden Sie das vorhandene Vorhängeschloss, um den Kasten zu verschließen.'
					},
					consequences: {
						text: {
							en: 'It may already be to late for preventing tools to be unaccounted for.',
							de: 'Es fehlen möglicherweise bereits Werkzeuge.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Call the owner of the toolbox, to verify that he has not been working on that aircraft.',
						de: 'Rufen Sie den Besitzer des Werkzeugkastens an, um sich zu vergewissern, dass er nicht an dem Flugzeug gearbeitet hat.'
					},
					consequences: {
						text: {
							en: 'This may not guarantee that others has not used this tool box.',
							de: 'Dies ist jedoch keine Garantie dafür, dass andere Personen den Werkzeugkasten nicht benutzt haben.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 23,
			title: {
				en: 'Removing a component',
				de: 'Ausbauen einer Komponente'
			},
			text: {
				en: 'You are performing a task involving removing a component in order to clean it. Removal/installation is very time consuming and more experienced staff say they normally clean it without removing it.',
				de: 'Sie führen eine Aufgabe durch, bei der Sie ein Bauteil ausbauen, um es zu reinigen. Der Ausbau/Einbau ist sehr zeitaufwändig, und erfahrenere Mitarbeiter sagen, dass sie das Bauteil normalerweise reinigen, ohne es auszubauen.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You adapt that practice as well.',
						de: 'Sie übernehmen diese Praxis ebenfalls.'
					},
					consequences: {
						text: {
							en: 'If an undocumented procedure is in fact better, you should take steps to making it formal - and not use it until then.',
							de: 'Wenn ein nicht dokumentiertes Verfahren tatsächlich besser ist, sollten Sie Schritte unternehmen, um es offiziell zu machen - und es bis dahin nicht anwenden.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You chose to follow the time consuming procedure from the manual anyway.',
						de: 'Sie haben sich trotzdem dafür entschieden, das zeitaufwändige Verfahren aus dem Handbuch anzuwenden.'
					},
					consequences: {
						text: {
							en: 'It is essential to follow maintenance data but also to report impractical procedures',
							de: 'Es ist wichtig, Wartungsdaten zu befolgen, aber auch unpraktische Verfahren zu melden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You stop work and request a No Technical Objection from the manufacturer, that cleaning can take place while installed.',
						de: 'Sie stellen die Arbeiten ein und beantragen beim Hersteller eine technische Unbedenklichkeitsbescheinigung (NTO), dass die Reinigung im eingebauten Zustand erfolgen kann.'
					},
					consequences: {
						text: {
							en: 'A safe approach which however may be challenged due to operational pressure.',
							de: 'Dieser Ansatz ist sicher, kann jedoch aufgrund des betrieblichen Zeitdrucks in Frage gestellt werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		}
	],
	/* Day 4 */
	'day-4': [
		{
			id: 25,
			title: {
				en: 'On the apron',
				de: 'Auf dem Vorfeld'
			},
			text: {
				en: 'You are performing line maintenance on the apron when the weather deteriorates and it becomes windy and starts to rain.',
				de: 'Sie führen gerade Line Maintenance Arbeiten auf dem Vorfeld durch, als sich das Wetter verschlechtert. Es wird windig und es beginnt zu regnen.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You stop work until the aircraft can be towed into a hangar',
						de: 'Sie unterbrechen die Arbeit, bis das Flugzeug in einen Hangar geschleppt werden kann.'
					},
					consequences: {
						text: {
							en: 'It can be required to perform line maintenance in the hangar if the weather is bad.',
							de: 'Bei schlechtem Wetter kann es erforderlich sein, die Line Maintenance im Hangar durchzuführen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds:[4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You continue work despite. It is only line maintenance after all.',
						de: 'Sie arbeiten trotzdem weiter. Es handelt sich schließlich nur um Line Maintenance.'
					},
					consequences: {
						text: {
							en: 'Working in adverse conditions can introduce errors. Also protection of the aircraft must be taken into consideration.',
							de: 'Das Arbeiten unter widrigen Bedingungen kann zu Fehlern führen. Auch der Schutz des Flugzeugs muss beachtet werden.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'The aircraft is not scheduled for flights so you plan to finish the work tomorrow, when the weather hopefully is better.',
						de: 'Das Flugzeug ist nicht für Flüge vorgesehen, also planen Sie, die Arbeit morgen zu beenden, wenn das Wetter dann hoffentlich besser ist.'
					},
					consequences: {
						text: {
							en: 'An option if task handover is documented. Operational demands can however change creating stress.',
							de: 'Eine Lösung, wenn die Aufgabenübergabe richtig dokumentiert wurde. Änderungen im Arbeitsablauf können jedoch Stress verursachen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]}, 
				
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		},
		{
			id: 26,
			title: {
				en: 'Visitors',
				de: 'Besucher'
			},
			text: {
				en: 'You and a small team are performing a difficult and extensive modification. Management keeps showing up with potential customers to show what you are doing.',
				de: 'Sie und ein kleines Team führen eine schwierige und umfangreiche Modifikation durch. Die Geschäftsleitung taucht immer wieder mit potenziellen Kunden auf, um Ihnen zu zeigen, was Sie gerade tun.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You insist that this stops, so you can work uninterrupted.',
						de: 'Sie bestehen darauf, dass dies aufhört, damit Sie ungestört arbeiten können.'
					},
					consequences: {
						text: {
							en: 'Distractions should be avoided where possible but some may be required due to operational demands.',
							de: 'Ablenkungen sollten nach Möglichkeit vermieden werden, sind aber aufgrund der betrieblichen Erfordernisse unter Umständen unvermeidlich.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You have a team meeting to discuss how you can perform a safe work under these circumstances',
						de: 'Sie halten eine Teamsitzung ab, um zu besprechen, wie Sie unter diesen Umständen sicher arbeiten können.'
					},
					consequences: {
						text: {
							en: 'Discussing problems and taking challenges into consideration using Human Factor knowledge is an important part of daily work.',
							de: 'Das Besprechen von Problemen und das Berücksichtigen von Herausforderungen mit Hilfe von Kenntnissen über menschliche Faktoren ist ein wichtiger Teil der täglichen Arbeit.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do nothing. If management thinks this is a good idea in order to get new customers, you must play along.',
						de: 'Sie tun nichts. Wenn die Geschäftsleitung dies für eine gute Idee hält, um neue Kunden zu gewinnen, müssen Sie mitspielen.'
					},
					consequences: {
						text: {
							en: 'Management and staff should work together to resolve safety challenges.',
							de: 'Management und Mitarbeiter sollten gemeinsam an der Lösung von Sicherheitsproblemen arbeiten.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 27,
			title: {
				en: 'A near-miss',
				de: 'Ein Beinahe-Unfall'
			},
			text: {
				en: 'A serious near-miss has occurred. You think a safety report should be filed, but your colleagues are afraid it will make the team look bad, if brought to the attention of management.',
				de: 'Ein schwerer Beinahe-Unfall hat sich ereignet. Sie sind der Meinung, dass ein Sicherheitsbericht eingereicht werden sollte, aber Ihre Kollegen befürchten, dass das Team schlecht dastehen würde, wenn es der Geschäftsleitung zur Kenntnis gebracht wird.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You report it anyway.',
						de: 'Sie melden es trotzdem.'
					},
					consequences: {
						text: {
							en: 'Reporting should be done with confidence in a Just Culture. It is important to know what is going on.',
							de: 'Die Meldung sollte in einer fairen Atmosphäre mit Vertrauen erfolgen. Es ist wichtig zu wissen, was vor sich geht.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You do not report it.',
						de: 'Sie melden es nicht.'
					},
					consequences: {
						text: {
							en: 'Failure to report near misses and latent conditions may cause management to be unaware of the need for action.',
							de: 'Wenn Sie Beinahe-Unfälle und latente Zustände nicht melden, kann dies dazu führen, dass die Geschäftsleitung nicht weiß, dass Handlungsbedarf besteht.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You verbally tell the supervisor about it, but refuse to give any details.',
						de: 'Sie erzählen dem Vorgesetzten mündlich davon, weigern sich aber, Einzelheiten zu nennen.'
					},
					consequences: {
						text: {
							en: 'Using informal reporting procedures may leave decision makers out of the loop.',
							de: 'Die Verwendung formloser Meldeverfahren kann dazu führen, dass die verantwortlichen Entscheidungsträger nicht informiert werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 28,
			title: {
				en: 'Personal protection',
				de: 'Persönlicher Arbeitsschutz'
			},
			text: {
				en: 'You are new to the workforce and notice that there is a relaxed attitude towards the lack of availability of personal protective equipment.',
				de: 'Sie sind neu am Arbeitsplatz und stellen fest, dass die fehlende Verfügbarkeit von persönlicher Schutzausrüstung locker gesehen wird.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You refuse to do any work requiring protective equipment, unless it is available to you.',
						de: 'Sie weigern sich, Arbeiten auszuführen, die eine Schutzausrüstung erfordern, wenn diese nicht zur Verfügung steht.'
					},
					consequences: {
						text: {
							en: 'Challenging Norms can be difficult and subject you to pressure.',
							de: 'Die Anfechtung von Standards kann schwierig sein und Sie unter Druck setzen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You ask a colleague to do your tasks, if they involve need for protective equipment.',
						de: 'Sie bitten einen Kollegen, Ihre Aufgaben zu übernehmen, wenn diese mit der Notwendigkeit von Schutzausrüstung verbunden sind.'
					},
					consequences: {
						text: {
							en: 'The problem is not resolved but just passed on to someone less assertive than you.',
							de: 'Das Problem wird nicht gelöst, sondern nur an jemanden weitergegeben, der weniger durchsetzungsfähig ist als Sie.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'While you are still new to the team, you follow the norm of not using protective equipment, with the intention of raising your voice at a later time.',
						de: 'Solange Sie noch neu im Team sind, folgen Sie der internen Norm, keine Schutzausrüstung zu benutzen, mit der Absicht, Ihre Stimme zu einem späteren Zeitpunkt zu erheben.'
					},
					consequences: {
						text: {
							en: 'Working in a group, you may experience a gradual shift in your own willingness to accept larger risks.',
							de: 'Wenn Sie in einer Gruppe arbeiten, kann es sein, dass sich Ihre eigene Bereitschaft, größere Risiken einzugehen, mit der Zeit verändert.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		}
	]
};


export { eventCardsData };
