import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import ProgressBar from 'components/progress-bar/progress-bar';
import Button from 'components/button/button';
import {generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Medal from 'components/ui/medal';
import GroupPlayers from 'components/group-players/group-players';
import GroupsPopup from 'components/groups-popup/groups-popup';
import './game-result-st.scss';

const GameResultST = (props) => {
	let {game, companyData, gameStep, groups, handleGoToPage, confirmAndContinue, toggleInfoPopup} = props;
	const gameSteps = gamesData[game.type].gameSteps;
	let resultStepData = gameSteps.find((step) => {return step.id === gameStep;});

	/* Show group popup */
	const [showGroupsPopup, setShowGroupsPopup] = useState(false);

	/* Sorted group results */
	let allGroups = groups.map((group) => {
		return {
			title: group.title, 
			timeValue: group.timeValue,
			riskValue: Math.ceil(1.5 * group.riskValue),
			costValue: group.costValue,
			total: (group.timeValue + Math.ceil(1.5 * group.riskValue) + group.costValue),
			players: group.players
		};
	});
	
	/* Sorted group results */
	let sortedGroups = JSON.parse(JSON.stringify(allGroups));
	sortedGroups = sortArrayByProperty(sortedGroups, 'total', 'ASC');

	/* Confirm button data */
	let confirmBtnData = getConfirmButtonData(true, game, gameStep, getText(generalUiTexts.next, game.languageId));

	return (
		<div className={'GameResult ' + game.languageId}>
			<ProgressBar 
				gameType={game.type}
				title={getText(resultStepData.title, game.languageId)} 
				subtitle={getText(resultStepData.title, game.languageId)} 
				onClick={() => {handleGoToPage('welcome');}}
			/>
			<Button
				text={''}
				classes={['st', 'info']}
				onClick={() => {toggleInfoPopup();}}
			/>
			<Button 
				isDisabled={confirmBtnData.isDisabled}
				text={confirmBtnData.text}
				classes={confirmBtnData.classes.concat(['st'])}
				onClick={() => {confirmAndContinue(gameStep);}}
			/>
			<div className="GameResult-container">
				<div className="GameResult-header">
					<div className="GameResult-headerTitle group" onClick={() => {setShowGroupsPopup(true);}}>
						<span>{getText(generalUiTexts.group, game.languageId)}</span>
					</div>
					<div className="GameResult-headerTitle time">
						<span>{getText(generalUiTexts.extraTime, game.languageId)}</span>
					</div>
					<div className="GameResult-headerTitle risk">
						<span>{getText(generalUiTexts.extraRisk, game.languageId)}</span> 
						<span> (x1,5)</span></div>
					<div className="GameResult-headerTitle cost">
						<span>{getText(generalUiTexts.extraCost, game.languageId)}</span>
					</div>
					<div className="GameResult-headerTitle total">
						<span>{getText(generalUiTexts.total, game.languageId)}</span>
					</div>
				</div>
				<div className="GameResult-groupsWrapper">
					{allGroups.map((group, gIndex) => {
						return (
							<div key={gIndex} className="GameResult-groupRow">
								<div className="GameResult-group">
									<div className="GameResult-groupTitle">{group.title}</div>
									<div className="GameResult-groupPlayers">
										<GroupPlayers group={group} companyData={companyData} type="hover" />
									</div>
								</div>

								<div className="GameResult-statValue">{group.timeValue}</div>
								<div className="GameResult-statValue">{group.riskValue}</div>
								<div className="GameResult-statValue">{group.costValue}</div>
								<div className="GameResult-statValue total">
									<span>{group.total}/{appConfig.st.scoreMax}</span>
								</div>
								<Medal 
									game={game}
									gameStep={gameStep}
									winner={group.total === sortedGroups[0]?.total } 
									groups={groups} 
								/>
							</div>
						);
					})}
				</div>
			</div>
			
			{/* Groups popup */}
			{showGroupsPopup &&
				<GroupsPopup 
					languageId={game.languageId}
					groups={groups}
					companyData={companyData}
					setShowGroupsPopup={setShowGroupsPopup}
				/>
			}
		</div>
	);
};

GameResultST.propTypes = {
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	gameStep: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func
};

export default GameResultST;