const { gamePhasesUiTexts } = require('./../ui-texts');

const gamePhases = [
	{
		id: 1,
		title: gamePhasesUiTexts.crewSelection,
		steps: [
			{
				id: 'crewSelectionIsOpen',
				btnText1: gamePhasesUiTexts.firstPhase,
				btnText2: gamePhasesUiTexts.crewSelection
			},
			{
				id: 'crewResultsIsOpen',
				btnText1: gamePhasesUiTexts.thisPhase,
				btnText2: gamePhasesUiTexts.results
			}
		],
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
			{id: 2, title: gamePhasesUiTexts.reflection},
			{id: 3, title: gamePhasesUiTexts.debrief},
			{id: 4, title: gamePhasesUiTexts.results}
		],
		statusBtn: {
			gameStepIds: ['crew-reflection', 'crew-reflection-result', 'crew-feedback-result'],
			types: ['reflection', 'reflection', 'scoreboard'],
			reflectionIds: ['crew-reflection', 'crew-reflection', null]
		}
	},
	{
		id: 2,
		title: gamePhasesUiTexts.onTheGround,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.onTheGround,
		steps: [
			{
				id: 'groundRoundIsOpen',
				btnText1: gamePhasesUiTexts.thisPhase,
				btnText2: gamePhasesUiTexts.onTheGround
			},
			{
				id: 'groundResultsIsOpen',
				btnText1: gamePhasesUiTexts.thisPhase,
				btnText2: gamePhasesUiTexts.results
			}
		],
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
			{id: 2, title: gamePhasesUiTexts.reflection},
			{id: 3, title: gamePhasesUiTexts.debrief},
			{id: 4, title: gamePhasesUiTexts.results}
		],
		statusBtn: {
			gameStepIds: ['ground-reflection', 'ground-reflection-result', 'ground-result'],
			types: ['reflection', 'reflection', 'scoreboard'],
			reflectionIds: ['ground-reflection', 'ground-reflection', null]
		}
	},
	{
		id: 3,
		title: gamePhasesUiTexts.airborne,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.airborne,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
		]
	},
	{
		id: 4,
		title: gamePhasesUiTexts.decisionMaking,
		btnText1: gamePhasesUiTexts.open,
		btnText2: '%decisionTool%',
		sections: [
			{id: 1, title: '%decisionTool%'}
		],
		statusBtn: {
			gameStepIds: ['decision-making', 'decision-making-result'],
			types: ['decision-making', 'decision-making'],
		}
	},
	{
		id: 5,
		title: gamePhasesUiTexts.communication,
		btnText1: gamePhasesUiTexts.open,
		btnText2: '%communicationTool%',
		sections: [
			{id: 1, title: '%communicationTool%'}
		],
		statusBtn: {
			gameStepIds: ['communication', 'communication-result'],
			types: ['communication', 'communication'],
		}
	},
	{
		id: 6,
		title: gamePhasesUiTexts.finalReflection,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.finalReflection,
		sections: [
			{id: 1, title: gamePhasesUiTexts.reflection},
		],
		statusBtn: {
			gameStepIds: ['final-reflection', 'final-reflection-result'],
			types: ['reflection', 'reflection'],
			reflectionIds: ['final-reflection', 'final-reflection']
		}
	},	
	{
		id: 7,
		title: gamePhasesUiTexts.finalResults,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.finalResults,
		sections: [
			{id: 1, title: gamePhasesUiTexts.debrief}
		],
		statusBtn: {
			gameStepIds: ['flights-result'],
			types: ['scoreboard']
		}
	},
	{
		id: 8,
		title: gamePhasesUiTexts.gameover,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.endGame,
		sections: [
			{id: 1, title: gamePhasesUiTexts.gameover},
		],
		statusBtn: {
			gameStepIds: ['flights-result', 'gameover'],
			types: ['scoreboard', 'scoreboard']
		}
	},
];

module.exports = {gamePhases};