/* eslint-disable max-len */
/* 
Stress / Pressure / Fatigue
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 

Distraction / Complacency / Lack of awareness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},

Lack of communication / teamwork / assertiveness
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},

Lack of resources, lack of knowledge, norms
{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
*/


/*
Lack of Communication 
{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},

Distraction 
{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},

Lack of Resources 
{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},

Stress 
{type: 'dirtyDozen', dirtyDozenId: 4, value: 1},

Complacency 
{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},

Lack of Teamwork 
{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},

Pressure 
{type: 'dirtyDozen', dirtyDozenId: 7, value: 1},

Lack of Awareness 
{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},

Lack of Knowledge 
{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},

Fatigue 
{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},

Lack of Assertiveness 
{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},

Norms 
{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},

*/

/*
{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
*/

let eventCardsData = {
	/* Day 1 */
	'day-1': [
		{
			id: 5,
			title: {
				en: 'Counting stock',
				de: 'Lagerbestand zählen'
			},
			text: {
				en: 'The maintenance workshop is not very busy at the time. You are asked to assist the spares department in a counting of stock. How do you react?',
				de: 'In der Werkstatt herrscht zur Zeit nicht viel Betrieb. Sie werden gebeten, im Ersatzteillager bei der Zählung der Bestände zu helfen. Wie reagieren Sie?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Refuse. This is work not suitable for a person of your skill level.',
						de: 'Sie lehnen ab. Diese Arbeit ist für eine Person mit Ihren Fähigkeiten nicht geeignet.'
					},
					consequences: {
						text: {
							en: 'Teamwork is suffering here.',
							de: 'Die Teamarbeit leidet darunter.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You accept since the pay is the same as you normally get.',
						de: 'Sie nehmen die Arbeit an, da die Bezahlung derjenigen entspricht, die Sie normalerweise erhalten.'
					},
					consequences: {
						text: {
							en: 'Accepting the job can strengthen the sense of teamwork',
							de: 'Wenn Sie die Arbeit annehmen, kann das den Teamgeist stärken.'
						},
						effects: [
							{type: 'time', value: 1},	
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do not want to do this type of work. To avoid it you report ill.',
						de: 'Sie wollen diese Art von Arbeit nicht machen. Um sie zu vermeiden, melden Sie sich krank.'
					},
					consequences: {
						text: {
							en: 'Not all job are equally interesting - Norms can challenge the teamwork',
							de: 'Nicht alle Jobs sind gleich interessant - Persönliche Standards können die Teamarbeit herausfordern.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 6,
			title: {
				en: 'The neighbor company',
				de: 'Das Nachbarunternehmen'
			},
			text: {
				en: 'The neighbor company is short on manpower. Your company has sold them your assistance for the next 5 weeks. You soon discover that their safety and quality standards are much lower than what you are used to. How do you react?',
				de: 'Das Nachbarunternehmen hat einen Mangel an Arbeitskräften. Ihr Unternehmen hat Sie als Hilfe für die nächsten 5 Wochen ausgeliehen. Schon bald stellen Sie fest, dass die Sicherheits- und Qualitätsstandards des Betriebs viel niedriger sind als die, die Sie gewohnt sind. Wie reagieren Sie darauf?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'This is not your business to address. You do your own tasks to the standards, you are used to.',
						de: 'Es ist nicht Ihre Aufgabe, sich darum zu kümmern. Sie erledigen Ihre eigenen Aufgaben nach den Standards, an die Sie gewöhnt sind.'
					},
					consequences: {
						text: {
							en: 'Being assertive and true to the good fundamentals of work is essential.',
							de: 'Es ist wichtig, dass Sie sich durchsetzen und den guten Grundsätzen der Arbeit treu bleiben.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You are not supposed to release any of the work, so you do not care about which standard is applied.',
						de: 'Sie sollen nichts von der Arbeit freigeben, also ist es Ihnen egal, welcher Standard angewendet wird.'
					},
					consequences: {
						text: {
							en: 'Lack of assertiveness may cause substandard work and errors to take place',
							de: 'Mangel an Durchsetzungskraft könnte zu minderwertiger Ausführung der Arbeit und Fehlern führen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You tell your own company, that you do not want to compromise your standards, and ask them to assign another person to work for the neighbor company.',
						de: 'Sie sagen Ihrem eigenen Unternehmen, dass Sie Ihre Standards nicht verringern wollen, und bitten es, eine andere Person für die Arbeit im Nachbarunternehmen einzusetzen.'
					},
					consequences: {
						text: {
							en: 'Starting a dialog about safety is generally a good thing. Remember to communicate about it in a professional manner.',
							de: 'Die Anregung eines Gesprächs über Sicherheit ist im Allgemeinen eine gute Sache. Denken Sie aber daran, auf professionelle Art und Weise darüber zu sprechen.'
						},
						effects: [
							{type: 'cost', value: 2},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]}, 
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 7,
			title: {
				en: 'Out of stock',
				de: 'Nicht auf Lager'
			},
			text: {
				en: 'There is an AOG. You need a special consumable, which is out of stock. The normal and approved vendor cannot deliver until next day. Another, but not approved, vendor can however deliver here-and-now. What do you do?',
				de: 'Es gibt ein AOG. Sie benötigen ein spezielles Verbrauchsmaterial, das nicht auf Lager ist. Der bisher genutzte und zugelassene Lieferant kann erst am nächsten Tag liefern. Ein anderer, bisher nicht zugelassener Lieferant, kann jedoch heute noch liefern. Was tun Sie nun?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Postpone the work until the approved source can deliver.',
						de: 'Sie verschieben die Arbeit, bis der zugelassene Lieferant liefern kann.'
					},
					consequences: {
						text: {
							en: 'Hopefully company Norms will support your actions',
							de: 'Hoffentlich werden die Unternehmensrichtlinien Ihr Vorgehen unterstützen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 3, value: 1},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Use the unapproved vendor here and now, file a safety report about your actions, and takes steps to ensure that the vendor gets approved for use in the future.',
						de: 'Verwenden Sie den nicht zugelassenen Anbieter hier und jetzt, erstellen Sie einen Sicherheitsbericht über Ihr Vorgehen und unternehmen Sie Schritte, um sicherzustellen, dass der Anbieter für die Zukunft zugelassen wird.'
					},
					consequences: {
						text: {
							en: 'Reporting this may help develop more robust procedures taking similar situations into account.',
							de: 'Die Berichterstattung darüber kann zur Entwicklung robusterer Verfahren beitragen, die ähnliche Situationen berücksichtigen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},

						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'Break this stupid rule. Obtain the consumable from the unapproved vendor without using the formal system, so that no records exists of the transaction.',
						de: 'Brechen sie diese dumme Regel. Beziehen Sie das Verbrauchsmaterial von dem nicht zugelassenen Lieferanten, ohne das formale System zu nutzen, so dass keine Aufzeichnungen über die Transaktion existieren.'
					},
					consequences: {
						text: {
							en: 'Within Safety Management it is essential that work as perceived equals work as performed. Management may think there is never any problems.',
							de: 'Im Rahmen des Sicherheitsmanagements ist es wichtig, dass die wahrgenommene Arbeit der geleisteten Arbeit entspricht. Das Management könnte ansonsten denken, dass es nie zu Zwischenfällen kommt.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 8,
			title: {
				en: 'Access to manuals',
				de: 'Zugang zu Handbüchern'
			},
			text: {
				en: 'The company keeps all manuals available on a manual server. In one of the hangars the network connection is however so slow and unstable, that it is practically impossible to access any manuals. What do you do?',
				de: 'Das Unternehmen hält alle Handbücher auf einem Handbuchserver bereit. In einem der Hangars ist die Netzwerkverbindung jedoch so langsam und instabil, dass es praktisch unmöglich ist, auf die Handbücher zuzugreifen. Was können Sie tun?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Copy the most frequently used manuals from the server onto the local computer, so they are easy to access and open.',
						de: 'Sie kopieren die am häufigsten verwendeten Handbücher vom Server auf den lokalen Computer, damit sie leicht zugänglich sind und geöffnet werden können.'
					},
					consequences: {
						text: {
							en: 'If no procedure is available to keep the copies updated, you may end up using invalid information',
							de: 'Wenn es kein Verfahren gibt, um die Kopien auf dem neuesten Stand zu halten, kann es passieren, dass Sie veraltete Informationen verwenden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Go to the other hangars and extract hard copies of the information you need.',
						de: 'Sie gehen in einen anderen Hangar und holen die benötigten Informationen in Papierform.'
					},
					consequences: {
						text: {
							en: 'This could work if you remember that the copies are now uncontrolled documents.',
							de: 'Dies könnte funktionieren, wenn Sie daran denken, dass die Kopien evtl. nicht mehr auf dem neuesten Stand sind.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds:  [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'For now you work from memory if the system is down, but you file a safety report, so steps can be taken to resolve the issue.',
						de: 'Sie arbeiten erst einmal nach Gedächtnis, wenn das System ausgefallen ist. Sie reichen aber einen Sicherheitsbericht ein, damit Schritte zur Behebung des Problems eingeleitet werden können.'
					},
					consequences: {
						text: {
							en: 'Pointing out this problem to management is good, but working from memory can create errors.',
							de: 'Es ist gut, die Unternehmensleitung auf dieses Problem hinzuweisen, aber die Arbeit aus dem Gedächtnis kann zu Fehlern führen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds:  [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['troubleshooter']},
						],
					},
				},
			]
		}
	],
	/* Day 2 */
	'day-2': [
		{
			id: 10,
			title: {
				en: 'Stress',
				de: 'Stress'
			},
			text: {
				en: 'You have noticed that a colleague shows signs of stress. How do you react?',
				de: 'Sie haben festgestellt, dass ein Kollege Anzeichen von Stress zeigt. Wie reagieren Sie darauf?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Ask him how he is doing and offer to help him seek advice or help, if needed.',
						de: 'Sie fragen ihn, wie es ihm geht, und bieten Sie ihm an, ihm bei der Suche nach Rat oder Hilfe zu helfen, falls nötig.'
					},
					consequences: {
						text: {
							en: 'An assertive action. Be aware that the way you communicate can cause him to reject your help.',
							de: 'Eine selbstbewusste Maßnahme. Seien Sie sich bewusst, dass die Art und Weise, wie Sie kommunizieren, dazu führen kann, dass er Ihre Hilfe ablehnt.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Do nothing - this is a personal thing which you should not involve yourself in.',
						de: 'Sie tun nichts - dies ist eine persönliche Angelegenheit, in die Sie sich nicht einmischen sollten.'
					},
					consequences: {
						text: {
							en: 'Often signs of stress are more visible to others that one self. Look out for each other in the team.',
							de: 'Oft sind die Anzeichen von Stress für andere besser sichtbar als für einen selbst. Achten Sie im Team aufeinander.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'HR should deal with this - you leave them an anonymous note about your observations.',
						de: 'Die Personalabteilung sollte sich damit befassen - Sie hinterlassen ihr eine anonyme Notiz über Ihre Beobachtungen.'
					},
					consequences: {
						text: {
							en: 'Being contacted by HR can cause rejection. Company Norms play a big role here.',
							de: 'Wenn Sie von der Personalabteilung kontaktiert werden, kann das zu Ablehnung führen. Die betrieblichen Werte spielen hier eine große Rolle.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},	
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 11,
			title: {
				en: 'Note to pilots',
				de: 'Hinweis für Piloten'
			},
			text: {
				en: 'As a new person in the company, you notice that the technicians quite often release a job without doing an operational test after replacing e.g. lamps or antennas. They instead leave a note to pilots about checking the system before flight. What is your reaction to this?',
				de: 'Als neuer Mitarbeiter im Unternehmen stellen Sie fest, dass die Techniker häufig einen Auftrag freigeben, ohne nach dem Austausch von z. B. Lampen oder Antennen einen Betriebstest durchzuführen. Stattdessen hinterlassen sie einen Hinweis an die Piloten, dass sie das System vor dem Flug überprüfen sollen. Was ist Ihre Reaktion darauf?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Seems like a timesaving and good idea. The pilots have to verify that their systems are GO before flight anyway.',
						de: 'Es scheint eine zeitsparende und gute Idee zu sein. Die Piloten müssen sich vor dem Flug ohnehin vergewissern, dass ihre Systeme in Ordnung sind.'
					},
					consequences: {
						text: {
							en: 'An Operational Check is part of a good error capture system. Never sign for anything you did not do.',
							de: 'Ein Operational Check ist Teil eines guten Fehlererfassungssystems. Unterschreiben Sie nie für etwas, das Sie nicht getan haben.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'This is the company norm - you accept that and have no personal opinion about it.',
						de: 'Dies ist die Vorgehensweise im Unternehmen - Sie akzeptieren das und haben keine eigene Meinung dazu.'
					},
					consequences: {
						text: {
							en: 'Norms may be good or bad. What is important is that the work is carried out to the applicable Standard.',
							de: 'Vorgehensweisen können gut oder schlecht sein. Wichtig ist, dass die Arbeit nach geltenden Normen ausgeführt wird.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'This is unacceptable and you file a safety report with some examples.',
						de: 'Dies ist inakzeptabel und Sie reichen einen Sicherheitsbericht mit einigen Beispielen ein.'
					},
					consequences: {
						text: {
							en: 'Norms are difficult to change. Involvement of Safety Management may put focus on this problem.',
							de: 'Normen sind schwer zu ändern. Die Einbindung des Sicherheitsmanagements kann dieses Problem in den Mittelpunkt rücken.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 12,
			title: {
				en: 'An audit',
				de: 'Ein Audit'
			},
			text: {
				en: 'There is a compliance audit of the work shop going on today, and one of the auditors has informed you, that he will be observing how you perform a task. How do you react?',
				de: 'Heute findet ein Compliance-Audit in der Werkstatt statt, und einer der Auditoren hat Ihnen mitgeteilt, dass er Sie bei der Ausführung einer Aufgabe beobachten wird. Wie reagieren Sie darauf?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'This is a good chance to show how well both you and the workshop adhere to quality and safety standards.',
						de: 'Dies ist eine gute Gelegenheit zu zeigen, wie gut Sie und die Werkstatt die Qualitäts- und Sicherheitsstandards einhalten.'
					},
					consequences: {
						text: {
							en: 'Audits are important for maintaining approvals and keeping management informed about what is happening.',
							de: 'Audits sind wichtig, um Genehmigungen aufrechtzuerhalten und das Management über den Stand der Dinge zu informieren.'
						},
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You are very annoyed about this interference, which you consider a major distraction. You have no intention about making this easy for the auditor.',
						de: 'Sie sind sehr verärgert über diese Störung, die Sie als eine große Ablenkung empfinden. Sie haben nicht die Absicht, es dem Auditor leicht zu machen.'
					},
					consequences: {
						text: {
							en: 'Some distractions are unavoidable. The AM expects staff to cooperate with auditors.',
							de: 'Einige Ablenkungen sind unvermeidlich. Der Accountable Manager erwartet, dass das Personal mit Auditoren kooperiert.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do not want this kind of attention to your work. You tell the supervisor that he must persuade the auditor to find another to look at.',
						de: 'Sie möchten diese Art von Aufmerksamkeit nicht auf Ihre Arbeit gerichtet haben. Sie sagen dem Vorgesetzten, dass er den Auditor überzeugen muss, einen anderen Mitarbeiter zu auditieren.'
					},
					consequences: {
						text: {
							en: 'Auditors are a part of the team. How you perform indicates how well standards are implemented.',
							de: 'Auditoren sind ein Teil des Teams. Deine Leistung zeigt, wie gut die Standards bei euch umgesetzt werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 6, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 13,
			title: {
				en: 'A final check',
				de: 'Eine abschließende Kontrolle'
			},
			text: {
				en: 'All jobs are completed after inspections and you ask a colleague to close all the access panels. You notice that he does so without looking for foreign objects prior to closing the panels. What is your reaction?',
				de: 'Alle Arbeiten sind nach der Inspektion abgeschlossen und Sie bitten einen Kollegen, alle Zugangsdeckel zu schließen. Sie stellen fest, dass er dies tut, ohne vor dem Schließen der Öffnungen nach Fremdkörpern zu suchen. Was ist Ihre Reaktion?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'It should not be necessary to do any additional checks, since all the task cards state the jobs are completed (except for the panel closing).',
						de: 'Es sollte nicht notwendig sein, zusätzliche Kontrollen durchzuführen, da auf allen Arbeitskarten vermerkt ist, dass die Arbeiten abgeschlossen sind (mit Ausnahme des Schließens der Deckel).'
					},
					consequences: {
						text: {
							en: 'A final visual check is an important element of good error capturing practices.',
							de: 'Eine abschließende Sichtprüfung ist ein wichtiges Element einer guten Vorgehensweise bei der Fehlererfassung.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You stop the job, insist that the closed panels are reopened and that visual inspections are performed prior to closing any panels.',
						de: 'Sie stoppen die Arbeiten, bestehen darauf, dass die geschlossenen Panels wieder geöffnet werden und dass vor dem Schließen aller Panels eine Sichtprüfung durchgeführt wird.'
					},
					consequences: {
						text: {
							en: 'Being assertive and true to the good fundamentals of error capturing is essential.',
							de: 'Es ist wichtig, dass Sie sich durchsetzen und den guten Grundsätzen der Fehlererfassung treu bleiben.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You consider any extra visual check optional. It is a good idea if time and manpower permits this to be done, but it is not absolutely necessary to do.',
						de: 'Sie betrachten jede zusätzliche Sichtprüfung als optional. Es ist eine gute Idee, wenn die Zeit und die Arbeitskraft dies erlauben, aber es ist nicht unbedingt notwendig.'
					},
					consequences: {
						text: {
							en: 'Standards and norms should not be subject to variation depending on the situation.',
							de: 'Standards und Normen sollten nicht je nach Situation variiert werden.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		}
	],
	/* Day 3 */
	'day-3': [
		{
			id: 20,
			title: {
				en: 'The training session',
				de: 'Die Trainingsveranstaltung'
			},
			text: {
				en: 'The entire department has been asked to participate in some specialised training sessions being held on site. You have had similar training before at your old work place. So what do you do?',
				de: 'Die gesamte Abteilung wurde gebeten, an einer speziellen Schulung teilzunehmen, die vor Ort stattfindet. Sie haben eine ähnliche Schulung bereits an Ihrem alten Arbeitsplatz absolviert. Was tun Sie also?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You do not participate in this training which you do not consider necessary for you. You do however take you chance to put your name on the participation list so you are not called in again later.',
						de: 'Sie nehmen nicht an dieser Schulung teil, die Sie nicht für notwendig erachten. Sie nutzen jedoch die Gelegenheit, sich in die Teilnehmerliste einzutragen, damit Sie später nicht noch einmal einbestellt werden.'
					},
					consequences: {
						text: {
							en: 'This training may be different and it would be complacent to skip the opportunity to refresh your knowledge.',
							de: 'Diese Schulung könnte anders sein, und es wäre selbstgefällig, die Gelegenheit zur Auffrischung Ihrer Kenntnisse auszulassen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]}, 
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
					
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You participate in the training.',
						de: 'Sie nehmen an der Schulung teil.'
					},
					consequences: {
						text: {
							en: 'Knowing this subject may provide you an opportunity to give valuable inputs during the training.',
							de: 'Wenn Sie das Thema kennen, können Sie während der Schulung wertvolle Beiträge leisten.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {	
						en: 'You ask the training manager to credit you for previous training.',
						de: 'Sie bitten den Schulungsleiter, Ihnen die frühere Schulung anzurechnen.'
					},
					consequences: {
						text: {
							en: 'A review of you previous training may show that you are in compliance with requirements.',
							de: 'Eine Überprüfung Ihrer bisherigen Ausbildung kann zeigen, dass Sie die Anforderungen erfüllen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds:  [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['experienced']},
						],
					},
				},
			]
		},
		{
			id: 21,
			title: {
				en: 'In the drain',
				de: 'Im Abfluss'
			},
			text: {
				en: 'You notice that a colleague pours used solvent in the sewer drain. What do you do?',
				de: 'Sie bemerken, dass ein Kollege benutztes Lösungsmittel in den Abfluss schüttet. Was tun Sie?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Nothing.',
						de: 'Nichts.'
					},
					consequences: {
						text: {
							en: 'Lack of assertiveness and failure to address obvious violations may cause damage to both the environment and the company reputation.',
							de: 'Fehlendes Engagement und das Versäumnis, offensichtliche Verstöße anzusprechen, können sowohl der Umwelt als auch dem Ruf des Unternehmens schaden.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 11, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},	
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
				
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'Confront him and asks him not to do it again because of the environmental impact.',
						de: 'Sie konfrontieren ihn damit und bitten ihn, dies wegen der Umweltauswirkungen nicht mehr zu tun.'
					},
					consequences: {
						text: {
							en: 'A assertive interaction can be fine if communicated in the right way.',
							de: 'Eine durchsetzungsfähige Interaktion kann in Ordnung sein, wenn sie auf die richtige Weise kommuniziert wird.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do not confront your colleague but file a report about it.',
						de: 'Sie konfrontieren Ihren Kollegen nicht damit, sondern erstellen einen Sicherheitsbericht darüber.'
					},
					consequences: {
						text: {
							en: 'Keeping safety managers informed about such things may help put focus on an area where improvements is needed.',
							de: 'Wenn Sie den Safety Manager über solche Dinge auf dem Laufenden halten, kann dies dazu beitragen, den Schwerpunkt auf einen Bereich zu legen, in dem Verbesserungen erforderlich sind.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 22,
			title: {
				en: 'The electrician',
				de: 'Der Elektriker'
			},
			text: {
				en: 'An outside electrician is brought in to work on the parking hangar lighting system. He is going to work very close to several of the parked aircrafts.',
				de: 'Ein externer Elektriker wird beauftragt, an der Beleuchtung der Abstellhalle zu arbeiten. Er wird in unmittelbarer Nähe zu mehreren geparkten Flugzeugen arbeiten.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You ask him to stop work until the hangar has been emptied.',
						de: 'Sie bitten ihn, die Arbeiten einzustellen, bis der Hangar geräumt ist.'
					},
					consequences: {
						text: {
							en: 'Although time-consuming this will effectively limit the aircraft\'s exposure to damage.',
							de: 'Dies ist zwar zeitaufwändig, aber die Gefahr, dass die Flugzeuge beschädigt werden, wird dadurch wirksam begrenzt.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You ask him to be very careful and let you know if he damages anything.',
						de: 'Sie bitten ihn, sehr vorsichtig zu sein und Ihnen Bescheid zu geben, wenn er etwas beschädigt.'
					},
					consequences: {
						text: {
							en: 'A person without proper training and knowledge may not himself be aware when a damage has occurred.',
							de: 'Eine Person, die nicht entsprechend geschult ist, merkt möglicherweise selbst nicht, wenn ein Schaden entstanden ist.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 9, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You remain in the area in order to monitor his work.',
						de: 'Sie bleiben in der Nähe, um seine Arbeit zu überwachen.'
					},
					consequences: {
						text: {
							en: 'Supervision and monitoring may not prevent any damage but could aid in its detection.',
							de: 'Beaufsichtigung und Überwachung können Schäden zwar nicht verhindern, aber sie können helfen, sie zu erkennen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 23,
			title: {
				en: 'Distraction',
				de: 'Ablenkung'
			},
			text: {
				en: 'Female cabin crew attendants often pass through the hangar area. You notice that some of your male colleagues gets distracted by this. What is your reaction?',
				de: 'Weibliche Flugbegleiterinnen gehen oft durch den Hangarbereich. Sie bemerken, dass sich einige Ihrer männlichen Kollegen dadurch ablenken lassen. Wie reagieren Sie darauf?'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You contact the crews and ask them do stay out of the hangar.',
						de: 'Sie wenden sich an die Besatzungen und bitten sie, den Hangar nicht zu betreten.'
					},
					consequences: {
						text: {
							en: 'Removing the distraction is a good option if the communication is done right.',
							de: 'Die Ablenkung zu vermeiden ist eine gute Option, sofern die Kommunikation richtig verläuft.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 8, value: 1},
							{type: 'cost', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You ask your colleagues to act professionally.',
						de: 'Sie bitten Ihre Kollegen, sich professionell zu verhalten.'
					},
					consequences: {
						text: {
							en: 'Your colleagues may feel accused and teamwork may suffer if the communication is not done properly',
							de: 'Ihre Kollegen könnten sich beschuldigt fühlen und die Teamarbeit könnte darunter leiden, wenn die entsprechende Kommunikation nicht richtig stattfindet.'
						},
						effects: [
							{type: 'risk', value: 1},	
							{type: 'dirtyDozen', dirtyDozenId: 2, value: 1},

							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You file an anonymous report about your observations.',
						de: 'Sie reichen einen anonymen Bericht über Ihre Beobachtungen ein.'
					},
					consequences: {
						text: {
							en: 'It may be a good idea to leave sensitive issues to the safety department to investigate.',
							de: 'Es kann eine gute Idee sein, die Untersuchung sensibler Fragen der Sicherheitsabteilung zu überlassen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		}
	],
	/* Day 4 */
	'day-4': [
		{
			id: 25,
			title: {
				en: 'Double shifts',
				de: 'Doppelschichten'
			},
			text: {
				en: 'Management has together with the union representative introduced double hours shifts for the next 5 weeks due to production increase. All staff is positive about this but also concerned about fatigue issues.',
				de: 'Die Geschäftsleitung hat zusammen mit dem Gewerkschaftsvertreter aufgrund der Produktionssteigerung für die nächsten 5 Wochen Doppelschichten eingeführt. Das gesamte Personal ist darüber erfreut, aber auch besorgt über Ermüdungserscheinungen.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'Request a solution from management which takes human factors into account.',
						de: 'Sie verlangen von der Unternehmensleitung eine Lösung, die die Human Factors berücksichtigt.'
					},
					consequences: {
						text: {
							en: 'This should be part of the Risk assessment during the management change process. Lack of assertiveness from staff and/or lack of Human Factors training for management may however prevent this from being addressed.',
							de: 'Dies sollte Teil der Risikobewertung während des "Management of Change" Prozesses sein. Fehlendes Selbstbewusstsein des Personals und/oder Schulung des Managements in Bezug auf Human Factors können verhindern, dass dieses Thema berücksichtigt wird.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},

							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
			
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'This is not worth meddling with. Keep a low profile and look after yourself.',
						de: 'Hier lohnt es sich nicht, sich einzumischen. Sie halten sich unauffällig und kümmern sich um sich selbst.'
					},
					consequences: {
						text: {
							en: 'Lack of assertiveness leaves latent problems unsolved. Risk of making errors increase with fatigue, if mitigating measures are not introduced.',
							de: 'Mangelndes Durchsetzungsvermögen lässt latente Probleme ungelöst. Das Risiko, Fehler zu machen, steigt mit zunehmender Ermüdung, wenn keine Abhilfemaßnahmen getroffen werden.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 10, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [3, 9, 12]},
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You just do the best you can. This is a management decision so Human Factor principles must yield.',
						de: 'Sie tun einfach Ihr Bestes. Das ist eine Managemententscheidung, also müssen die Grundsätze des Human Factors hinten angestellt werden.'
					},
					consequences: {
						text: {
							en: 'Human Factor principles should be applied at all levels of the organisation. Especially latent problems may go unsolved if management is not actively involved.',
							de: 'Die Grundsätze der Human Factors sollten auf allen Ebenen der Organisation angewandt werden. Insbesondere latente Probleme können ungelöst bleiben, wenn das Management nicht aktiv einbezogen wird.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [4, 7, 10]}, 
							{type: 'risk', value: -1, conditionType: 'skill', conditionIds: ['assertive']},	
						],
					},
				},
			]
		},
		{
			id: 26,
			title: {
				en: 'Personal safety',
				de: 'Persönliche Sicherheit'
			},
			text: {
				en: 'You notice that upper management often ignore the requirements for use of protective safety equipment when entering the work areas.',
				de: 'Sie stellen fest, dass die obere Führungsebene die Anforderungen an das Tragen von Schutzausrüstungen beim Betreten der Arbeitsbereiche häufig ignoriert.'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'It is OK. Management sets the standards, so they can break them too, if they want.',
						de: 'Das ist in Ordnung. Die Geschäftsleitung legt die Normen fest, also kann sie auch gegen sie verstoßen, wenn sie will.'
					},
					consequences: {
						text: {
							en: 'In a company with a sound safety culture the standards should apply to all.',
							de: 'In einem Unternehmen mit einer soliden Sicherheitskultur gelten die Normen für alle Mitarbeiter gleichermaßen.'
						},
						effects: [
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You point it out to them next time you see it happen.',
						de: 'Sie weisen sie das nächste Mal darauf hin, wenn Sie so etwas sehen.'
					},
					consequences: {
						text: {
							en: 'Very assertive but the method of communication may be cause for rejection.',
							de: 'Das ist sehr wirkungsvoll, aber die Art der Kommunikation kann zu Ablehnung führen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You file an anonymous safety report about it.',
						de: 'Sie erstatten einen anonymen Sicherheitsbericht über den Vorfall.'
					},
					consequences: {
						text: {
							en: 'The safety department should be able to handle this type of problem',
							de: 'Die Sicherheitsabteilung sollte in der Lage sein, mit dieser Art von Problem umzugehen.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'time', value: 1},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['assertive']},
						],
					},
				},
			]
		},
		{
			id: 27,
			title: {
				en: 'Personal phone calls',
				de: 'Persönliche Telefongespräche'
			},
			text: {
				en: 'Against company safety policy, a member of your team tends to spend a lot of time on personal phone calls. How do you react?',
				de: 'Entgegen den Sicherheitsrichtlinien des Unternehmens neigt ein Mitglied Ihres Teams dazu, viel Zeit mit privaten Telefonaten zu verbringen. Wie reagieren Sie darauf?'
			},
			certifiedCrewOnly: false,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'You do nothing about this.',
						de: 'Sie unternehmen nichts dagegen.'
					},
					consequences: {
						text: {
							en: 'Neglecting problems may cause them to become triggers of safety incidents.',
							de: 'Die Vernachlässigung von Problemen kann dazu führen, dass diese zu Auslösern von Sicherheitsvorfällen werden.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 5, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
					
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You kindly inform him about the dangers of distractions and insist that he changes his behavior.',
						de: 'Sie weisen ihn freundlich auf die Gefahren von Ablenkungen hin und bestehen darauf, dass er sein Verhalten ändert.'
					},
					consequences: {
						text: {
							en: 'Making good norms clear in an assertive manner can be good if proper communication is used.',
							de: 'Gute Standards überzeugend darzustellen kann helfen, wenn sie angemessen kommuniziert werden.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do not confront him but file an anonymous report about your observations.',
						de: 'Sie konfrontieren ihn nicht, sondern erstatten einen anonymen Bericht über Ihre Beobachtungen.'
					},
					consequences: {
						text: {
							en: 'The safety department should be able to handle this type of problem',
							de: 'Die Sicherheitsabteilung sollte in der Lage sein, mit dieser Art von Problem umzugehen.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'time', value: 1, conditionType: 'dirtyDozen', conditionIds: [2, 5, 8]},
							{type: 'time', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		},
		{
			id: 28,
			title: {
				en: 'The cover-up',
				de: 'Die Vertuschung'
			},
			text: {
				en: 'Before an audit, the supervisor asks you to help cover-up the fact, that previous findings has not been properly addressed.',
				de: 'Vor einem Audit bittet der Vorgesetzte Sie, die Tatsache zu vertuschen, dass frühere Beanstandungen nicht vernünftig abgestellt wurden.'
			},
			certifiedCrewOnly: true,
			options: [
				{
					id: 'a',
					title: {
						en: 'Option A',
						de: 'Option A'
					},
					text: {
						en: 'This is fine. The workshop should be allowed to address this in due time without outside interference.',
						de: 'Das ist in Ordnung. Die Werkstatt sollte die Möglichkeit haben, dies zu gegebener Zeit ohne Einmischung von außen zu klären.'
					},
					consequences: {
						text: {
							en: 'Deceiving an auditor is deceiving the Accountable Manager. Safety is in everybody\'s interest and norms should reflect this.',
							de: 'Einen Auditor zu täuschen bedeutet, den Accountable Manager zu täuschen. Sicherheit liegt im Interesse aller, und die Normen sollten dies widerspiegeln.'
						},
						effects: [
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},	
						],
					},
				},
				{
					id: 'b',
					title: {
						en: 'Option B',
						de: 'Option B'
					},
					text: {
						en: 'You tell your supervisor that you will not support his cover-up.',
						de: 'Sie sagen Ihrem Vorgesetzten, dass Sie seine Vertuschung nicht unterstützen werden.'
					},
					consequences: {
						text: {
							en: 'Assertive action but future teamwork may suffer if proper communication is not used.',
							de: 'Dies ist ein mutiger Schritt, aber die zukünftige Zusammenarbeit im Team könnte darunter leiden, wenn keine angemessene Kommunikation stattfindet.'
						},
						effects: [
							{type: 'dirtyDozen', dirtyDozenId: 1, value: 1},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
				{
					id: 'c',
					title: {
						en: 'Option C',
						de: 'Option C'
					},
					text: {
						en: 'You do not challenge this but take sick leave on the day.',
						de: 'Sie stellen dies nicht in Frage, sondern lassen sich an dem Tag krankschreiben.'
					},
					consequences: {
						text: {
							en: 'This does not solve the underlying problems with workshop norms.',
							de: 'Auf diese Weise werden die zugrundeliegenden Probleme, die mit den Werkstattnormen verbunden sind, nicht gelöst.'
						},
						effects: [
							{type: 'time', value: 1},
							{type: 'cost', value: 1},
							{type: 'risk', value: 1},
							{type: 'dirtyDozen', dirtyDozenId: 12, value: 1},
							{type: 'risk', value: 1, conditionType: 'dirtyDozen', conditionIds: [1, 6, 11]},
							{type: 'cost', value: -1, conditionType: 'skill', conditionIds: ['teamplayer']},
						],
					},
				},
			]
		}
	]
};


export { eventCardsData };
