import React from 'react';
import PropTypes from 'prop-types';
import { gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import CrewDndContainer from './crew-dnd-container';
import CrewDndCard from './crew-dnd-card';
import './crew-slots.scss';

const CrewSlots = (props) => {
	let {
		isFacilitator, 
		languageId,
		gameType,
		crewCards, 
		cardTypes,
		selectedCrewMember, 
		handleFindCard, 
		handleMoveCard, 
		setSelectedCrewMember
	} = props;
	
	return (
		<div className={'CrewSlots ' + languageId + 
			(gameType === 'safetytraining' ? ' st' : '') +
			(gameType === 'crm-helicopters' ? ' heli' : '')
		}>
			{gamesData[gameType].crewData.crewSlots.map((slot, index) => {
				return (
					<div key={index} className="CrewSlots-slot">
						<span className="CrewSlots-slotTitle">{getText(slot.title, languageId)}</span>
						<CrewDndContainer 
							key={index} 
							gameType={gameType}
							containerId={slot.id}
							cardTypes={cardTypes}
							cardTypesAccepted={slot.cardTypes}
							handleFindCard={handleFindCard}
							handleMoveCard={handleMoveCard}
						>
							{crewCards.map((card) => {
								if (card.slotId === slot.id) {
									return <CrewDndCard 
										key={card.id}
										isInFocus = {false}
										isDraggable = {!isFacilitator}
										isPlaced = {true}
										languageId={languageId}
										gameType={gameType}
										cardData={card}
										cardTitle={getText(slot.title, languageId)}
										cardColor={(slot.color ? slot.color : null)}
										onClick={() => {
											if (!selectedCrewMember && gameType !== 'safetytraining') {
												setSelectedCrewMember(card);
											}
										}}
									/>;
								}
								return null;
							})}
						</CrewDndContainer>
					</div>
				);
			})}
		</div>
	);
};



CrewSlots.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	crewCards: PropTypes.array.isRequired,
	cardTypes: PropTypes.array.isRequired,
	selectedCrewMember: PropTypes.object,
	handleFindCard: PropTypes.func.isRequired,
	handleMoveCard: PropTypes.func.isRequired,
	setSelectedCrewMember: PropTypes.func.isRequired
};

export default CrewSlots;