let reflectionsData = [
	{
		id: 'crew-reflection',
		gameStep: 'crew-reflection',
		resultGameStep: 'crew-reflection-result',
		title: {
			en: 'Crew',
			ja: '乗務員'
		},
		subtitle: {
			en: 'Reflection',
			ja: '振り返り・自分の仕事への反映'
		},
		questions: [
			{
				id: 1,
				number: 1,
				text: {
					en: 'Personal issues may not necessarily be related to work, but could still have an impact on the working environment.<br /><br />Please give examples where you have experienced situations where personal issues, your own or others, affected your ability to work effectively together as a crew!',
					ja: '個人的な問題は必ずしも仕事に関与を及ぼすとは限りませんが、職場環境へ影響を与える可能性があります。<br /><br />自分や他人の個人的な問題が、チームとして効果的に協働する貴方の能力に影響を与えた経験を例として挙げてください！'
				},
				answerType: 'text',
				required: true
			},
			{
				id: 2,
				number: 2,
				text: {
					en: 'In the game there are also possible issues regarding the combination of different personalities and attitudes.<br /><br />From your own work, please give examples where you have experienced situations where the combination of different personalities had an effect on the working environment on board!',
					ja: `このゲームでは、異なる性格や態度の人の組み合わせによって問題が発生します。
						<br /><br />貴方の仕事で、性格が異なる人の組み合わせが職場環境に影響を及ぼした状況を経験した例を挙げてください！`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 3,
				number: 3,
				text: {
					en: 'What have you done or what could you do, as an individual and/or as crew, to mitigate the negative effects on the working environment on board of personal issues and different personalities? You can use the examples from above.',
					ja: `個人的な問題や互いの性格の相違が職場環境に与える悪影響を軽減するために、個人として、あるいはチームとして、あなたがしたことは何でしょうか、あるいは何ができるでしょうか？
						<br /><br />上の例を参考にしても結構です。`
				},
				answerType: 'text',
				required: true
			}
		]
	},
	{
		id: 'ground-reflection',
		gameStep: 'ground-reflection',
		resultGameStep: 'ground-reflection-result',		
		title: {
			en: 'On the ground',
			ja: '地上'
		},
		subtitle: {
			en: 'Reflection',
			ja: '振り返り・自分の仕事への反映'
		},
		showThreats: true,
		questions: [
			{
				id: 3.5,
				number: 0,
				text: {
					en: '<strong>Threats – before take-off</strong><br> The threats displayed in the column to the right are conditions that you have accepted before take-off, which all can pose a potential threat or risk for the flight.',
					ja: `<strong>スレット – 離陸前</strong>
						<br> 右の欄に表示されているスレットは、離陸前に許容し受け入れた条件であり、いずれもフライトに潜在的な脅威やリスクをもたらす可能性があるものです。`
				},
				answerType: 'intro',
				required: false
			},
			{
				id: 4,
				number: 1,
				text: {
					en: 'From your own work, give examples of situations, where you have accepted a potential threat or risk before take-off that could lead to a critical incident under the “right” circumstances?',
					ja: `自分の仕事の中で、「相応の」状況の場合には重大な事故につながる可能性のあるスレットやリスクを、仕事の前に受け入れてしまった状況を例に挙げてください。`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 5,
				number: 2,
				text: {
					en: 'Give examples from your own work, where you have decided not to accept a potential threat or risk to avoid a potential critical incident.',
					ja: `自分の仕事の中で、潜在的な脅威やリスクを受け入れることなく、潜在的な重大なインシデントを回避した例を挙げてください。`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 6,
				number: 3,
				text: {
					en: 'When evaluating multiple potential threats in real life, how do you prioritize to determine which threats are the most important at the moment?',
					ja: `実生活で複数の潜在的なスレット（脅威）に直面するとき、どのように優先順位をつけ、その時点で最も重要な脅威を判断していますか？`
				},
				answerType: 'text',
				required: true
			}
		]
	},
	{
		id: 'final-reflection',
		gameStep: 'final-reflection',
		resultGameStep: 'final-reflection-result',
		title: {
			en: 'Final',
			ja: '最終'
		},
		subtitle: {
			en: 'Reflection',
			ja: '振り返り・自分の仕事への反映'
		},
		showThreats: false,
		questions: [
			{
				id: 6.5,
				number: 0,
				text: {
					en: 'In the game you received information about weather, airport, runway, time and facilities for alternative airports when faced with a critical situation. You also had a list of displayed threats on the gameboard',
					ja: `ゲームでは、危機的状況に直面したときに、天候、空港、滑走路、時間、代替空港の設備などの情報を得ることができます。また、ゲームボードには表示されたスレットのリストが表示されています。`
				},
				answerType: 'intro',
				required: false
			},
			{
				id: 7,
				number: 1,
				text: {
					en: 'How do you make sure that all relevant information and potential threats are taken into consideration when making decisions in real life, when the information and threats are not displayed or visible like in the game?',
					ja: `ゲームのように情報や脅威が表示・可視化されない現実の世界で、意思決定をする際に、関連する情報や潜在的なスレット（脅威）をすべて考慮させるにはどうすればいいのでしょうか？`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 8,
				number: 2,
				text: {
					en: 'How do you ensure that everyone understands the information and instructions in a NITS briefing onboard the aircraft?',
					ja: `機内での問題発生時に行うブリーフィングの情報や指示を全員が理解するためにはどうすればよいのでしょうか？`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 9,
				number: 3,
				text: {
					en: 'How could you enhance the communication within the crew during an emergency situation or a critical incident onboard?',
					ja: `機内で緊急事態や重大なインシデントが発生した際に、乗組員内のコミュニケーションをどのように強化することができるでしょうか？`
				},
				answerType: 'text',
				required: true
			},
			{
				id: 9.5,
				number: 0,
				text: {
					en: 'The topics below are important for CRM and the general working environment of all employees. If your company were to improve on these issues, how would you prioritize them? Rate the topics below from 1 to 5, where 5 means it should be of high priority.',
					ja: `以下のトピックは、CRMと全従業員の一般的な労働環境にとって重要です。もし、あなたの所属組織がこれらの問題を改善することになった場合、あなたは、どのように優先順位をつけますか？以下のトピックを1～5で評価してください（5は優先度が高いことを意味します）。`
				},
				answerType: 'intro',
				required: false
			},
			{
				id: 10,
				number: 4,
				text: {
					en: '<strong>Safety culture</strong><br>Promotion of a safety mindset and creating an "active" safety culture by having an open and honest environment, where concerns are easily communicated and reported if applicable.',
					ja: `<strong>安全文化</strong><br>安全マインドを促進し、オープンで正直な環境を持つことで、懸念が容易に伝わり、該当する場合は報告されるような「活発な」安全文化を創造すること。`
				},
				answerType: 'scale',
				required: true
			},
			{
				id: 11,
				number: 5,
				text: {
					en: '<strong>Procedures and Practices</strong><br>Influence on improvement of company standards and SOP together with an easy formalized way of changing/updating standards.',
					ja: `<strong>手順と実践</strong><br>社内でのスタンダードや手順書の改善、スタンダードの変更・更新の容易な変更作業などに影響を与えること。`
				},
				answerType: 'scale',
				required: true
			},
			{
				id: 12,
				number: 6,
				text: {
					en: '<strong>Working Environment</strong><br>Promotion for work/life balance initiatives that could improve the general working environment.',
					ja: `<strong>作業環境</strong><br>一般的な労働環境を改善しうるワーク・ライフ・バランスの取り組みの推進。`
				},
				answerType: 'scale',
				required: true
			},
			{
				id: 13,
				number: 7,
				text: {
					en: '<strong>Operational Pressure</strong><br>Effective and realistic Scheduling with sufficient resources to cope with busy periods and room for vacation, training and illness.',
					ja: `<strong>運用プレッシャー</strong><br>繁忙期に対応できる十分な人的リソースと、休暇や研修、病気のための余裕を持った効果的かつ現実的なスケジューリング。`
				},
				answerType: 'scale',
				required: true
			},
			{
				id: 14,
				number: 8,
				text: {
					en: '<strong>Training</strong><br>The company should engage in more practical, hands on, and classroom training whenever possible with focus on quality and content.',
					ja: `<strong>訓練</strong><br>品質と内容を重視し、可能な限り、より実践的な実務や座学での訓練に取り組むべきこと。`
				},
				answerType: 'scale',
				required: true
			},
			{
				id: 15,
				number: 9,
				text: {
					en: 'Do you have any suggestions to improve some of the topics above or are there any other topics which could be relevant to improve?',
					ja: `上の項目のいくつかを改善するための提案はありますか？また、改善に関連する可能性のある他の項目はありますか？`
				},
				answerType: 'text',
				required: true
			}
		]
	}
];

export {reflectionsData};