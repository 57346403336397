const skillsData = {
	experienced: {
		title: {
			en: 'Experienced',
			de: 'Erfahren'
		},
		verb: {
			en: 'is',
			de: 'ist'
		},
	},
	assertive: {
		title: {
			en: 'Assertive',
			de: 'Durchsetzungsfähig '
		},
		verb: {
			en: 'is',
			de: 'ist'
		},
	},
	troubleshooter: {
		title: {
			en: 'Troubleshooter',
			de: 'Troubleshooter'
		},
		verb: {
			en: 'is a',
			de: 'ist'
		},
	},
	teamplayer: {
		title: {
			en: 'Teamplayer',
			de: 'Teamspieler'
		},
		verb: {
			en: 'is a',
			de: 'ist'
		},
	}
};

module.exports = {skillsData};