import React from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {ReactComponent as StaffIcon} from 'assets/images/icon-staff.svg';
import {ReactComponent as ApprenticeIcon} from 'assets/images/icon-staff-apprentice.svg';
import './crew-card-st-front.scss';

const CrewCardSTFront = ({isSelected, languageId, cardData, layout, onClick}) => {
	// Get correct crew icon (certifying / apprentice staff)
	let CrewIcon = (cardData.type === 'certifying' ? StaffIcon : ApprenticeIcon);

	return (
		<div className={'CrewCardSTFront ' 
			+ languageId + ' ' + layout + (isSelected ? ' selected' : '')} onClick={onClick}>
			<div className={'CrewCardSTFront-top ' + cardData.type + ' ' + cardData.color}>
				<CrewIcon />
				<div className="CrewCardSTFront-info">
					<div className="CrewCardSTFront-name"><span>{getText(cardData.name, languageId)}</span></div>
					<div className="CrewCardSTFront-title">{getText(cardData.title, languageId)}</div>
					<div className="CrewCardSTFront-age">
						<span>{getText(generalUiTexts.age, languageId)}: </span>{cardData.age}
					</div>
				</div>
			</div>
			<div className={'CrewCardSTFront-bottom ' + cardData.type}>
				<div className="CrewCardSTFront-description">
					{renderMarkdown(getText(cardData.description, languageId))}
				</div>
			</div>
		</div>
	);
};

CrewCardSTFront.defaultProps = {
	layout: 'default',
	onClick: null
};

CrewCardSTFront.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	layout: PropTypes.string,
	cardData: PropTypes.object.isRequired,
	onClick: PropTypes.func
};

export default CrewCardSTFront;