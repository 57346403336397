import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {crewData} from 'data/safetytraining/crew-data';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import GameBoardSTDnDContainer from 'components/game-board-st/game-board-st-dnd-container';
import {ReactComponent as StaffIconUnknown} from 'assets/images/icon-staff-unknown.svg';
import GameBoardStDnDItem from 'components/game-board-st/game-board-st-dnd-item';
import './event-card-st-front.scss';

const EventCardSTFront = (props) => {
	let {
		isDisabled,
		animationFinished,
		selectedOptionsConfirmed,
		languageId,
		group,
		card,
		cardData,
		cardTypes,
		handleMoveCard,
		handleFindCard
	} = props;

	let text = JSON.parse(JSON.stringify(getText(cardData.text, languageId)));
	if (cardData.certifiedCrewOnly === true) {
		text += '<br /><br /><span>' + getText(generalUiTexts.certifyingStaffOnly, languageId) + '.<span>';
	}

	return (
		<div className="EventCardSTFront">
			<div className="EventCardSTFront-header">
				<div className="EventCardSTFront-title">{getText(cardData.title, languageId)}</div>
			</div>
			<div className="EventCardSTFront-body">
				<div className="EventCardSTFront-text">{renderMarkdown(text)}</div>
				<div className="EventCardSTFront-options">
					{cardData.options.map((option, index) => {
						const isSelected = (card.selectedOptionId === option.id);
						const crewMemberId = (card.selectedCrewMemberId ? card.selectedCrewMemberId : null);
						let crewCardData = null;
						if (isSelected && crewMemberId) {
							const slotId = group.selectedCrew.find((s) => {return s.id === crewMemberId;}).slotId;
							crewCardData = crewData.availableCrew.find((c) => {return c.id === crewMemberId;});
							crewCardData.color = crewData.crewSlots.find((s) => {return s.id === slotId;}).color;
						}
						let cardTypesAccepted = cardTypes;
						if (cardData.certifiedCrewOnly === true) cardTypesAccepted = ['certifying'];

						return (
							<div
								key={index}
								className={'EventCardSTFront-option ' + (isSelected ? ' selected' : '')}
							>
								<GameBoardSTDnDContainer
									gameType={'safetytraining'}
									containerId={'option-' + cardData.id + '-' + option.id}
									cardTypes={cardTypes}
									cardTypesAccepted={cardTypesAccepted}
									handleMoveCard={handleMoveCard}
									handleFindCard={handleFindCard}
								>
									
									{(isSelected ? 
										<GameBoardStDnDItem 
											type="small"
											isDraggable={!selectedOptionsConfirmed} 
											isSelected={isSelected} 
											isDraglayer={false}
											cardData={crewCardData}
										/> : <div className="EventCardSTFront-optionIcon"><StaffIconUnknown /></div>
									)}
									
									<div className="EventCardSTFront-optionTitle">
										{getText(option.title, languageId)}
									</div>
									<div className="EventCardSTFront-optionText">
										{getText(option.text, languageId)}
									</div>
								</GameBoardSTDnDContainer>
							</div>
						);
					})
					}
				</div>
			</div>
			{(isDisabled && animationFinished) && <div className="EventCard-disabledOverlay" />}
		</div>
	);
};

EventCardSTFront.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	animationFinished: PropTypes.bool.isRequired,
	selectedOptionsConfirmed: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	card: PropTypes.object.isRequired,
	cardData: PropTypes.object.isRequired,
	cardTypes: PropTypes.array.isRequired,
	handleMoveCard: PropTypes.func.isRequired,
	handleFindCard: PropTypes.func.isRequired,
};

export default EventCardSTFront;
