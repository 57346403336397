import React, {Component} from 'react';
import firebaseInit from 'firebase-init';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {checkIfFullscreen, toggleFullscreen} from 'helpers/fullscreen-helper';
import AdminLoginController from 'components/admin/admin-login-controller';
import LoginController from 'components/login/login-controller';
import LandingPage from 'components/landing-page/landing-page';
import './app.css';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFullscreen: false,
		};
	}

	/* Component mounted */
	componentDidMount = () => {
		/* Initialize firebase */
		firebaseInit(); // .then(() => {console.log('Firestore initialized');});

		/* Fullscreen listeners */
		document.removeEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('fullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		document.removeEventListener('webkitfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('mozfullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('fullscreenchange', this.updateFullscreenStatus, false);
		document.removeEventListener('MSFullscreenChange', this.updateFullscreenStatus, false);
	}

	/**
	 * Toggle full screen
	 */
	handleToggleFullscreen = () => {
		let isFullscreen = toggleFullscreen();
		this.setState({isFullscreen: isFullscreen});
	};
	updateFullscreenStatus = () => {
		let isFullscreen = checkIfFullscreen();
		this.setState({isFullscreen: isFullscreen});
	};

	/* Render app */
	render = () => {
		return (
			<div className="App" id="app">
				<BrowserRouter>
					<Routes>
						<Route path="/admin" element={<AdminLoginController />} />
						<Route 
							path="/crm-aeroplanes"
							element={
								<LoginController 
									gameType="crm-aeroplanes" 
									isFullscreen={this.state.isFullscreen}
									handleToggleFullscreen={this.handleToggleFullscreen}
								/>
							}
						/>
						<Route 
							path="/crm-helicopters"
							element={
								<LoginController 
									gameType="crm-helicopters" 
									isFullscreen={this.state.isFullscreen}
									handleToggleFullscreen={this.handleToggleFullscreen}
								/>
							}
						/>
							
						
						<Route 
							path="/humanfactorstraining"
							element={
								<LoginController 
									gameType="safetytraining" 
									isFullscreen={this.state.isFullscreen}
									handleToggleFullscreen={this.handleToggleFullscreen}
								/>
							}
						/>
						<Route path="/" element={<LandingPage />} />
					</Routes>
				</BrowserRouter>
			</div>
		);
	};
}

export default App;