import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {skillsData} from 'data/skills-data';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import {getText} from 'helpers/language-helper';
import {ReactComponent as StaffIcon} from 'assets/images/icon-staff.svg';
import {ReactComponent as ApprenticeIcon} from 'assets/images/icon-staff-apprentice.svg';
import GameBoardStDnDItem from 'components/game-board-st/game-board-st-dnd-item';
import './crew-card-st-back.scss';

const CrewCardSTBackBack = (props) => {
	let {
		isDraggable, 
		isSelected, 
		animateDD, 
		layout, 
		languageId,
		cardData, 
		onClick, 
		toggleDirtyDozenPopup, 
		shake = false
	} = props;

	// Delay showing new dd after card flip
	let [dirtyDozenIds, setDirtyDozenIds] = useState(cardData.dirtyDozenIds);
	let [animDDId, setAnimDDId] = useState(null);
	useEffect(() => {
		let timer = null;
		if (!animateDD) setAnimDDId(null);
		if (dirtyDozenIds.length < cardData.dirtyDozenIds.length) {
			/* New dd dozen */
			timer = setTimeout(() => {
				setDirtyDozenIds(cardData.dirtyDozenIds);
				if (animateDD) {
					setAnimDDId(cardData.dirtyDozenIds[cardData.dirtyDozenIds.length - 1]);
				}
			}, 1000); // event card flip animation lasts 800 ms 
		} else {
			/* Hover card effect */
			if (animateDD) {
				setAnimDDId(cardData.dirtyDozenIds[dirtyDozenIds.length - 1]);
			}
			/* Dirty dozen removed (crew member was not used in prev day) */
			if (dirtyDozenIds.length > cardData.dirtyDozenIds.length) {
				setDirtyDozenIds(cardData.dirtyDozenIds);
			}
		}

		return () => {if (timer) return clearTimeout(timer);};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardData.dirtyDozenIds, animateDD]);

	// Get unique dirty dozen 
	let uniqueDirtyDozenIds = dirtyDozenIds.filter((item, index) =>  {
		return cardData.dirtyDozenIds.indexOf(item) === index;
	});

	// Get correct crew icon (certifying / apprentice staff)
	let CrewIcon = (cardData.type === 'certifying' ? StaffIcon : ApprenticeIcon);

	return (
		<div 
			className={'CrewCardSTBack ' + languageId + ' ' + layout + ' ' + cardData.type + 
				(isSelected ? ' selected' : '') + (onClick ? ' clickable' : '')} 
			onClick={onClick}
		>
			<div className={'CrewCardSTBack-top ' + cardData.color}>
				<div className="CrewCardSTBack-imageAndTitle">
					<div className={`${shake ? 'shake' : ''}`}>
						{(layout === 'gameboard' 
							? <GameBoardStDnDItem 
								type="big"
								isDraggable={isDraggable} 
								isSelected={isSelected} 
								isDraglayer={false}
								languageId={languageId}
								cardData={cardData}
							/>
							: <div className="CrewCardSTBack-image"><CrewIcon /></div>
						)}
					</div>
					<div className="CrewCardSTBack-title">{getText(cardData.title, languageId)}</div>
				</div>
				<div className="CrewCardSTBack-info">
					<div className="CrewCardSTBack-nameAndAge">
						{getText(cardData.name, languageId)}, {cardData.age}
					</div>
					<div className="CrewCardSTBack-skills">{cardData.skillIds.map((skillId) => {
						return (
							<div key={skillId} className={'CrewCardSTBack-skill id-' + skillId}>
								<span>{getText(skillsData[skillId].title, languageId)}</span>
							</div>
						);
					})}
					</div>
				</div>
			</div>
			<div className="CrewCardSTBack-bottom">
				{[...Array(6)].map((_, i) => {
					let ddTitle = null;
					let ddId = null;
					let animate = false;
					let numberOfThisDD = 0;
					if (i < uniqueDirtyDozenIds.length) {
						ddId = uniqueDirtyDozenIds[i];
						const ddData = dirtyDozenData.find((dd) => {return dd.id === ddId;});
						if (ddData) ddTitle = getText(ddData.title, languageId);
						numberOfThisDD = dirtyDozenIds.filter((dd) => {return dd === ddId;}).length;
						if (animDDId === ddId) animate = true;
					}
					return (
						<div 
							key={i} 
							className={'CrewCardSTBack-dirtyDozen id-' + ddId 
								+ (ddTitle ? ' active' : '') 
								+ (numberOfThisDD > 1 ? ' multi' : '')
								+ (animate ? ' animate' : '')
							}
							onClick={(e) => {
								if (toggleDirtyDozenPopup && ddId) {
									e.stopPropagation(); toggleDirtyDozenPopup(ddId);
								}
							}}
						>
							<span>{ddTitle}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

CrewCardSTBackBack.defaultProps = {
	isDraggable: false,
	isSelected: false,
	layout: 'default',
	onClick: null
};

CrewCardSTBackBack.propTypes = {
	isDraggable: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool.isRequired,
	animateDD: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	layout: PropTypes.string,
	cardData: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	toggleDirtyDozenPopup: PropTypes.func,
	shake: PropTypes.bool,
};

export default CrewCardSTBackBack;