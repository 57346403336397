const threatsData = [
	{id: 'distraction', eventCardId: 1, title: {
		en: 'Distraction'}
	},
	{id: 'survival-suit', eventCardId: 2, title: {
		en: 'Damaged survival suit'}}
		,
	{id: 'fatigued-captain', eventCardId: 3, title: {
		en: 'Captain Fatigue'}}
		,
	{id: 'aed-battery', eventCardId: 4, title: {
		en: 'AED Battery uncertainty'}}
		,
	{id: 'fuel', eventCardId: 7, title: {
		en: 'Fuel'}}
		,
	{id: 'survival-suit-2', eventCardId: 8, title: {
		en: 'Survival suit'}}
		,
	{id: 'weather-radar', eventCardId: 12, title: {
		en: 'Weather Radar U/S'}}
		,
	{id: 'icing', eventCardId: 13, title: {
		en: 'Icing'}}
		,
	{id: 'thunderstorm', eventCardId: 14, title: {
		en: 'Thunderstorm'}}
		,
	{id: 'aed-low-battery', eventCardId: 101, title: {
		en: 'AED Low battery'}}
		,
	{id: 'aed-low-battery-2', eventCardId: 102, title: {
		en: 'AED Low battery'}}
		,
	{id: 'survival-suit-3', eventCardId: 103, title: {
		en: 'Survival suit'}}
		,
	{id: 'fatigue', eventCardId: 104, title: {
		en: 'Fatigue'}}
		,
	{id: 'hospital-1', eventCard: 18, title: {
		en: 'Selected Hospital #1'}}
		,
	{id: 'hospital-2', eventCard: 18, title: {
		en: 'Selected Hospital #2'}}
		,
	{id: 'hospital-3', eventCard: 18, title: {
		en: 'Selected Hospital #3'}}
		,
];

export {threatsData};