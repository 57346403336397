const decisionToolsData = [
	{
		id: 'dodar',
		title: {
			en: 'DODAR',
			ja: 'DODAR'
		},
		questions: [
			{
				id: 'diagnosis',
				title: {
					en: 'Diagnosis',
					ja: 'Diagnosis [診断]'
				},
				required: true,
			},
			{
				id: 'options',
				title: {
					en: 'Options',
					ja: 'Options [選択又はチョイス]',
				},
				required: true,
			},
			{
				id: 'decision',
				title: {
					en: 'Decision',	
					ja: 'Decision [決断]'
				},
				required: true,
			},
			{
				id: 'action',
				title: {
					en: 'Action',
					ja: 'Action [行動]'
				},
				required: true,
			},
			{
				id: 'review',
				title: {
					en: 'Review',
					ja: 'Review [見直し]'
				},
				required: true,
			}
		]
	},
	{
		id: 'tdodar',
		title: {
			en: 'T-DODAR',
			ja: 'T-DODAR'
		},
		questions: [
			{
				id: 'time',
				title: {
					en: 'Time',
					ja: 'Time [時間]'
				},
				required: true,
			},
			{
				id: 'diagnosis',
				title: {
					en: 'Diagnosis',
					ja: 'Diagnosis [診断]'
				},
				required: true,
			},
			{
				id: 'options',
				title: {
					en: 'Options',
					ja: 'Options [選択又はチョイス]'
				},
				required: true,
			},
			{
				id: 'decision',
				title: {
					en: 'Decision',
					ja: 'Decision [決断]'
				},
				required: true,
			},
			{
				id: 'action',
				title: {
					en: 'Action',
					ja: 'Action [行動]'
				},
				required: true,
			},
			{
				id: 'review',
				title: {
					en: 'Review',
					ja: 'Review [見直し]'
				},
				required: true,
			}
		]
	},
	{
		id: 'fordec',
		title: {
			en: 'FORDEC',
			ja: 'FORDEC'
		},
		questions: [
			{
				id: 'facts',
				title: {
					en: 'Facts',
					ja: 'Facts [事実]'
				},
				required: true,
			},
			{
				id: 'options',
				title: {
					en: 'Options',
					ja: 'Options [選択又はチョイス]'
				},
				required: true,
			},
			{
				id: 'risks',
				title: {
					en: 'Risks',
					ja: 'Risks [リスク]'
				},
				required: true,
			},
			{
				id: 'decide',
				title: {
					en: 'Decide',
					ja: 'Decide [決める又は決断]'
				},
				required: true,
			},
			{
				id: 'execute',
				title: {
					en: 'Execute',
					ja: 'Execute [実行又は遂行する]'
				},
				required: true,
			},
			{
				id: 'check',
				title: {
					en: 'Check',
					ja: 'Check [確認]'
				},
				required: true,
			}
		]
	},
	{
		id: 'decide',
		title: {
			en: 'DECIDE',
			ja: 'DECIDE'
		},
		questions: [
			{
				id: 'detect',
				title: {
					en: 'Detect',
					ja: 'Detect [探知する]'
				},
				required: true,
			},
			{
				id: 'estimate',
				title: {
					en: 'Estimate',
					ja: 'Estimate [推定する]'
				},
				required: true,
			},
			{
				id: 'choose',
				title: {
					en: 'Choose',
					ja: 'Choose [選択する]'
				},
				required: true,
			},
			{
				id: 'identify',
				title: {
					en: 'Identify',
					ja: 'Identify [確認する]'
				},
				required: true,
			},
			{
				id: 'do',
				title: {
					en: 'Do',
					ja: 'Do [行動する]'
				},
				required: true,
			},
			{
				id: 'evaluate',
				title: {
					en: 'Evaluate',
					ja: 'Evaluate [評価する]'
				},
				required: true,
			},
		]
	}, 
	{
		id: 'pest',
		title: {
			en: 'PEST',
			ja: 'PEST'
		},
		questions: [
			{
				id: 'problem',
				title: {
					en: 'Problem',
					ja: 'Problem [問題]'
				},
				required: true,
			},
			{
				id: 'evaluation',
				title: {
					en: 'Evaluation',
					ja: 'Evaluation [評価]'
				},
				required: true,
			},
			{
				id: 'specials',
				title: {
					en: 'Specials',
					ja: 'Specials [特別]'
				},
				required: true,
			},
			{
				id: 'time',
				title: {
					en: 'Time',
					ja: 'Time [時間]'
				},
				required: true,
			}
		]
	},
	{
		id: 'grade',
		title: {
			en: 'GRADE',
			ja: 'GRADE'
		},
		questions: [
			{
				id: 'gather',
				title: {
					en: 'Gather',
					ja: 'Gather [収集する]'
				},
				required: true,
			},
			{
				id: 'review',
				title: {
					en: 'Review',
					ja: 'Review [見直す]'
				},
				required: true,
			},
			{
				id: 'analyse',
				title: {
					en: 'Analyse',
					ja: 'Analyse [分析する]'
				},
				required: true,
			},
			{
				id: 'decide',
				title: {
					en: 'Decide',
					ja: 'Decide [決める]'
				},
				required: true,
			},
			{
				id: 'evaluate',
				title: {
					en: 'Evaluate',
					ja: 'Evaluate [評価する]'
				},
				required: true,
			}
		]
	},
	{
		id: 'star',
		title: {
			en: 'STAR',
			ja: 'STAR'
		},
		questions: [
			{
				id: 'stop',
				title: {
					en: 'Stop',
					ja: 'Stop [止める]'
				},
				required: true,
			},
			{
				id: 'think',
				title: {
					en: 'Think',
					ja: 'Think [考える]'
				},
				required: true,
			},
			{
				id: 'act',
				title: {
					en: 'Act',
					ja: 'Act [行動]'
				},
				required: true,
			},
			{
				id: 'reflect',
				title: {
					en: 'Reflect',
					ja: 'Reflect [評価]'
				},
				required: true,
			}
		]
	},
	{
		id: 'piosee',
		title: {
			en: 'PIOSEE',
			ja: 'PIOSEE'
		},
		questions: [
			{
				id: 'problem',
				title: {
					en: 'Problem',
					ja: 'Problem [問題]'
				},
				required: true,
			},
			{
				id: 'information',
				title: {
					en: 'Information',
					ja: 'Information [情報]'
				},
				required: true,
			},
			{
				id: 'options',
				title: {
					en: 'Options',
					ja: 'Options [選択肢]'
				},
				required: true,
			},
			{
				id: 'select',
				title: {
					en: 'Select',
					ja: 'Select [選ぶ]'
				},
				required: true,
			},
			{
				id: 'execute',
				title: {
					en: 'Execute',
					ja: 'Execute [行動]'
				},
				required: true,
			},
			{
				id: 'evaluate',
				title: {
					en: 'Evaluate',
					ja: 'Evaluate [評価]'
				},
				required: true,
			}
		]
	}
];

const communicationToolsData = [
	{
		id: 'nits',
		title: {
			en: 'NITS',
			ja: 'NITS'
		},
		questions: [
			{
				id: 'nature',
				title: {
					en: 'Nature',
					ja: 'Nature [本質又は特質]'
				},
				required: true,
			},
			{
				id: 'intention',
				title: {
					en: 'Intention',
					ja: 'Intention [意向又は意志]'
				},
				required: true,
			},
			{
				id: 'time',
				title: {
					en: 'Time',
					ja: 'Time [時間]'
				},
				required: true,
			},
			{
				id: 'specials',
				title: {
					en: 'Specials',
					ja: 'Specials [特別な]'
				},
				required: true,
			},
		]
	},
	{
		id: 'nitsa',
		title: {
			en: 'NITSA',
			ja: 'NITSA'
		},
		questions: [
			{
				id: 'nature',
				title: {
					en: 'Nature',
					ja: 'Nature [本質又は特質]'
				},
				required: true,
			},
			{
				id: 'intention',
				title: {
					en: 'Intention',
					ja: 'Intention [意向又は意志]'
				},
				required: true,
			},
			{
				id: 'time',
				title: {
					en: 'Time',
					ja: 'Time [時間]'
				},
				required: true,
			},
			{
				id: 'specials',
				title: {
					en: 'Specials',
					ja: 'Specials [特別な]'
				},
				required: true,
			},
			{
				id: 'announcement',
				title: {
					en: 'Announce&shy;ment',
					ja: 'Announce&shy;ment [発表]',
				},
				required: true,
			}	
		]
	}
];

const exerciseToolsData = [
	{
		id: 'speakup',
		title: {
			en: 'SPEAK UP',
			ja: '声を上げる'
		},
		intro: {
			en: 'Perform a debriefing of the current mission. Fill out the briefing tool and perform a debriefing for the class.',
			ja: '只今のミッションデをブリーフィングする(チームで事後に振り返り、話し合う)。ブリーフィングツールに答えを記載してクラスにデブリーフィング発表する。'
		},
		questions: [
			{
				id: 'setting',
				title: {
					en: 'Setting / Scenario',
					ja: 'セッティング又はシナリオ'
				},
				placeholder: {
					en: 'Define briefing guideline and describe the scenario',
					ja: 'ブリーフィングガイドラインを説明又は定義付けし、シナリオを説明する'
				},
				required: true,
			},
			{
				id: 'personal',
				title: {
					en: 'Personal Experience',
					ja: '個人的な経験'
				},
				placeholder: {
					en: 'How did you experience the mission?',
					ja: 'ミッションをどう感じましたか？'
				},
				required: true,
			},
			{
				id: 'emotions',
				title: {
					en: 'Emotions, feelings, experience',
					ja: '感情、気持ち、経験'
				},
				placeholder: {
					en: 'What did you think, feel, experience during the mission?',
					ja: '今回のミッションをどの様に感じましたか？感想を述べて下さい。'
				},
				required: true,
			},
			{
				id: 'action',
				title: {
					en: 'Actions / Reactions',
					ja: 'アクション／リアクション'
				},	
				placeholder: {
					en: 'How did you react or how did you see others react during the mission?',
					ja: 'あなたはミッション中どう感じ、そして他の人の感じ方をどの様に見えましたか？'
				},
				required: true,
			},
			{
				id: 'knowledge',
				title: {
					en: 'Knowledge gained',
					ja: '学んだ事'
				},	
				placeholder: {
					en: 'What have you learned from this mission?',
					ja: 'このミッションで何を学びましたか？'
				},
				required: true,
			},
			{
				id: 'unsolved',
				title: {
					en: 'Unsolved issues',
					ja: '解決出来なかった事'
				},
				placeholder: {
					en: 'Is there any unfinished / unsolved issues you would like to discuss?',
					ja: '解決又は終わらせなかった事について話したいことありますか？'
				},
				required: true
			},
			{
				id: 'precausions',
				title: {
					en: 'Precautions / Training',
					ja: '予防策／トレーニング'
				},
				placeholder: {
					en: 'Does this lead to any changes - training, focus, SOP, safety, other?',
					ja: 'トレーニング、集中、標準作業手順書、安全、その他。これら何かの変化に導きますか'
				},
				required: true
			}
		]
	}
];

export {decisionToolsData, communicationToolsData, exerciseToolsData};