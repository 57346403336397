const { gamePhasesUiTexts } = require('./../ui-texts');

const gamePhases = [
	{
		id: 1,
		title: gamePhasesUiTexts.crewSelection,
		steps: [
			{
				id: 'crewSelectionIsOpen',
				btnText1: gamePhasesUiTexts.firstPhase,
				btnText2: gamePhasesUiTexts.crewSelection
			}
		],
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
			// {id: 2, title: gamePhasesUiTexts.reflection},
			// {id: 3, title: gamePhasesUiTexts.debrief},
		],
		// statusBtn: {
		// 	gameStepIds: ['crew-reflection', 'crew-reflection-result'],
		// 	types: ['reflection', 'reflection'],
		// 	reflectionIds: ['crew-reflection', 'crew-reflection']
		// }
	},
	{
		id: 2,
		title: gamePhasesUiTexts.checkIn,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.checkIn,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
		]
	},
	{
		id: 3,
		title: gamePhasesUiTexts.reflection,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.reflection,
		sections: [
			{id: 1, title: gamePhasesUiTexts.reflection}, 
			{id: 2, title: gamePhasesUiTexts.results}, 
		],
		statusBtn: {
			gameStepIds: ['check-in-reflection', 'check-in-reflection-result'],
			types: ['reflection', 'reflection'],
			reflectionIds: ['check-in-reflection', 'check-in-reflection']
		}
	},
	{
		id: 4,
		title: gamePhasesUiTexts.scramble,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.scramble,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
		]
	},

	{
		id: 5,
		title: gamePhasesUiTexts.outbound,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.outbound,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
		]
	},
	{
		id: 6,
		title: gamePhasesUiTexts.onSite,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.onSite,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
		]
	},
	{
		id: 7,
		title: gamePhasesUiTexts.transportHospital,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.transportHospital,
		sections: [
			{id: 1, title: gamePhasesUiTexts.game}, 
		]
	},
	{
		id: 8,
		title: gamePhasesUiTexts.reflection,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.reflection,
		sections: [
			{id: 1, title: gamePhasesUiTexts.reflection}, 
			{id: 2, title: gamePhasesUiTexts.results}, 
		],
		statusBtn: {
			gameStepIds: ['final-reflection', 'final-reflection-result'],
			types: ['reflection', 'reflection'],
			reflectionIds: ['final-reflection', 'final-reflection']
		}
	},
	{
		id: 9,
		title: gamePhasesUiTexts.missionDebriefing,
		btnText1: gamePhasesUiTexts.nextPhase,
		// btnText2: '%exerciseTool%',
		btnText2: gamePhasesUiTexts.missionDebriefing,
		sections: [
			{id: 1, title: '%exerciseTool%'},
			{id: 2, title: gamePhasesUiTexts.results}, 
		],
		statusBtn: {
			gameStepIds: ['exercise', 'exercise-result'],
			types: ['exercise', 'exercise'],
		}		
	},
	{
		id: 10,
		title: gamePhasesUiTexts.bestPractices,
		btnText1: gamePhasesUiTexts.nextPhase,
		btnText2: gamePhasesUiTexts.debrief,
		sections: [
			{id: 1, title: gamePhasesUiTexts.reflection},
			{id: 2, title: gamePhasesUiTexts.results}, 
		],
		statusBtn: {
			gameStepIds: ['final-debrief', 'final-debrief-result'],
			types: ['reflection', 'reflection'],
			reflectionIds: ['final-debrief', 'final-debrief']
		}
	},
	{
		id: 11,
		title: gamePhasesUiTexts.finalResults,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.finalResults,
		sections: [
			{id: 1, title: gamePhasesUiTexts.results}
		],
		statusBtn: {
			gameStepIds: ['final-result'],
			types: ['scoreboard']
		}
	},
	{
		id: 12,
		title: gamePhasesUiTexts.gameover,
		btnText1: gamePhasesUiTexts.open,
		btnText2: gamePhasesUiTexts.endGame,
		sections: [
			{id: 1, title: gamePhasesUiTexts.gameover},
		],
		statusBtn: {
			gameStepIds: ['final-result', 'gameover'],
			types: ['scoreboard', 'scoreboard']
		}
	}
];

module.exports = {gamePhases};