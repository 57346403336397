import { generalUiTexts } from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import { dirtyDozenData } from 'data/dirty-dozen-data';
import {getText} from 'helpers/language-helper';
import { applyOrRemoveCrewMemberEffects, findNewCrewMember } from './group-crew-helper';


function getIndexOfGameStep(gameStep, gameType) {
	const gameSteps = gamesData[gameType].gameSteps;
	let indexOfGameStep = gameSteps.findIndex((s) => {return s.id === gameStep;});
	return indexOfGameStep;
}
/**
 * Check if resource placements are confirmed
 * @param {object} group 
 */
function checkIfResourcePlacementsConfirmed(group) {
	let resourcePlacementsConfirmed = false;
	if (
		group.gameStepCards[group.gameStep] && 
		group.gameStepCards[group.gameStep].resourcePlacementsConfirmed === true
	) {
		resourcePlacementsConfirmed = true;
	}
	return resourcePlacementsConfirmed;
}

/**
 * Check if all event cards has had their effects applied
 * @param {object} group 
 */
function checkIfAllCardsEffectsApplied(group) {
	let allCardEffectsApplied = true;
	if (
		group.gameStepCards[group.gameStep] &&
		group.gameStepCards[group.gameStep].cards.some((card) => {
			return (card && (!card.hasOwnProperty('effectsApplied') || card.effectsApplied === false));
		})
	) {
		allCardEffectsApplied = false;
	}
	return allCardEffectsApplied;
}

/**
 * Get index of next action card
 * An action card is a card that requires the user to select and confirm an option AFTER initial
 * resource placements has been confirmed
 * Action card states are: 'select-option', 'confirm-selected-option', 'ready-to-resolve'
 */
function getIndexOfNextActionCard(group, eventCards) {
	let indexOfNextActionCard = -1;
	if (
		group.gameStepCards[group.gameStep] && 
		group.gameStepCards[group.gameStep].resourcePlacementsConfirmed === true
	) {
		indexOfNextActionCard = group.gameStepCards[group.gameStep].cards.findIndex((card) => {
			const cardData = eventCards.find((eCard) => {return (card && eCard.id === card.id);});
			return (
				cardData && 
				cardData.isActionCard === true && 
				cardData.options && cardData.options.length > 0 &&
				cardData.noDefaultOption === true &&
				(!card.hasOwnProperty('effectsApplied') || card.effectsApplied === false) &&
				(!card.hasOwnProperty('actionConfirmed') || !card.actionConfirmed)
			);
		});
	}
	return indexOfNextActionCard;
}

/**
 * Get index of next card that requires user interaction
 * @param {object} group 
 * @param {array} eventCards 
 */
function getIndexOfNextPriorityCard(group, eventCards) {
	let indexOfNextCard = -1;
	if (group.gameStepCards[group.gameStep]) {
		indexOfNextCard = group.gameStepCards[group.gameStep].cards.findIndex((card) => {
			const cardData = eventCards.find((eCard) => {return (card && eCard.id === card.id);});
			return (
				cardData && (
					(
						cardData.type === 'possible-threat' &&
					(!card.hasOwnProperty('effectsApplied') || card.effectsApplied === false) // possible threat
					) ||
					(
						card.hasOwnProperty('effectTypes') && 
						card.effectTypes.indexOf('critical-event') >= 0 // triggers critical event
					)
				)
			);
		});
	}
	return indexOfNextCard;
}

/**
 * Get the confirm button status
 * @param {bool} gameIsPaused
 * @param {object} group 
//  * @param {bool} criticalCardStatus
 * @param {bool} resourcePlacementsConfirmed 
 * @param {bool} allCardEffectsApplied 
 * @param {bool} aCardRequiresDirectAction
 * @param {function} handleConfirmResourcePlacements 
 * @param {function} handleApplyEventCardEffects 
 * @param {function} confirmAndContinue 
 */
function getConfirmButtonData(
	gameIsPaused,
	animationFinished,
	languageId,
	group, 
	gamePhase,
	indexOfNextCard,
	actionCardStatus,
	resourcePlacementsConfirmed,
	allCardEffectsApplied,
	actionCardIsInPlay,
	priorityCardIsInPlay,
	handleConfirmActionCardOption,
	handleConfirmResourcePlacements, 
	handleApplyEventCardEffects, 
	triggerPossibleIncident,
	handleTriggerCriticalEvent,
	confirmAndContinue,
	eventCards,
	gameSteps
) {
	/* Default confirm button data */
	let confirmBtnData = {
		isDisabled: false,
		classes: ['next'],
		text: getText(generalUiTexts.confirm, languageId),
		action: handleConfirmResourcePlacements,
		params: []
	};

	/* Game is paused */
	if (gameIsPaused) {
		confirmBtnData.isDisabled = true;
		confirmBtnData.classes = ['next', 'paused'];
		confirmBtnData.text = getText(generalUiTexts.paused, languageId);
		return confirmBtnData;
	}

	/* Action card in play */
	if (actionCardIsInPlay && actionCardStatus) {
		if (actionCardStatus === 'select-option') {
			confirmBtnData.isDisabled = true;
		} else {
			confirmBtnData.action = handleConfirmActionCardOption;
			confirmBtnData.params = [indexOfNextCard];
		}
		if (!animationFinished) confirmBtnData.isDisabled = true;
		return confirmBtnData;
	}


	/* Game is not paused */
	if (group.gameStepCards[group.gameStep]) {
		/* Resource placements have been confirmed */
		if (resourcePlacementsConfirmed) {
			if (!allCardEffectsApplied) {
				/* Not all card effects have been applied: apply effect of next card */
				confirmBtnData.text = getText(generalUiTexts.turnCard, languageId);
				confirmBtnData.action = handleApplyEventCardEffects;
			} else {
				/* All card effects have been applied: go to next step */
				let indexOfGameStep = gameSteps.findIndex((step) => {return step.id === group.gameStep;});
				if (indexOfGameStep >= 0 && indexOfGameStep + 1 < gameSteps.length) {
					if (gamePhase >= gameSteps[indexOfGameStep + 1].phase) {
						/* Next game step is open */
						if (gameSteps[indexOfGameStep + 1].page === 'game-board') {
							confirmBtnData.text = getText(generalUiTexts.nextTurn, languageId);	
						} else {
							confirmBtnData.text = getText(generalUiTexts.next, languageId);
						}
						confirmBtnData.action = confirmAndContinue;
						confirmBtnData.params = [group.gameStep];
					} else {
						/* Next game step is locked */
						confirmBtnData.isDisabled = true;
						confirmBtnData.text = getText(generalUiTexts.waiting, languageId);
						confirmBtnData.classes = ['next', 'waiting'];
					}
				}
			}
		}

		/* Trigger possible threat / critical event */
		if (priorityCardIsInPlay) {
			confirmBtnData.isDisabled = true;
			if (indexOfNextCard >= 0) {
				let card = group.gameStepCards[group.gameStep].cards[indexOfNextCard];
				let cardData = eventCards.find((c) => {return (card && c.id === card.id);});
				if (cardData) {
					if (cardData.type === 'possible-threat') {
						confirmBtnData.isDisabled = false;
						confirmBtnData.text = getText(generalUiTexts.turnCard, languageId);
						confirmBtnData.action = triggerPossibleIncident;
						confirmBtnData.params = [indexOfNextCard, card.id];
					}
					if (
						card.effectTypes && card.effectTypes.some((effectType) => {
							return effectType === 'critical-event';
						})
					) {
						confirmBtnData.isDisabled = false;
						confirmBtnData.text = getText(generalUiTexts.turnCard, languageId);
						confirmBtnData.action = handleTriggerCriticalEvent;
						confirmBtnData.params = [indexOfNextCard, card.id];
					}
				}
			}
		}
	}

	if (!animationFinished) confirmBtnData.isDisabled = true;

	return confirmBtnData;
}

/**
 * Get all cards than will move to next round
 * @param {string} gameStep
 * @param {object} gameStepCards
 * @param {string} scenario
 */
function getUnresolvedMoveCards(gameStep, gameStepCards, eventCards) {
	let unresolvedMoveCards = [];
	if (gameStepCards && gameStepCards[gameStep] && gameStepCards[gameStep].cards) {
		gameStepCards[gameStep].cards.forEach((card, i) => {
			/* Return if no card, bottom row, or effects have already been applied */
			if (!card || i > 3 || (card.hasOwnProperty('effectsApplied') && card.effectsApplied === true)) {
				return;
			}
			let selectedOptionId = null;
			if (
				gameStepCards[gameStep].cards[i].hasOwnProperty('selectedOptionId') &&
				gameStepCards[gameStep].cards[i].selectedOptionId
			) {
				selectedOptionId = gameStepCards[gameStep].cards[i].selectedOptionId;
			}

			/* No option selected */
			if (!selectedOptionId) {
				let cardData = eventCards.find((c) => {
					return c.id === gameStepCards[gameStep].cards[i].id;
				});
				if (cardData.roundsMax > 1 && cardData.hasOwnProperty('options')) {
					/* Card can be moved */
					unresolvedMoveCards.push({ ...card, cardIndex: i });
				}
			}
		});
	}
	return unresolvedMoveCards;
}

/**
 * Get the index of the next unresolved cards (effects not applied yet)
 * @param {string} gameStep
 * @param {object} gameStepCards
 */
function getIndexOfNextUnresolvedCard(gameStep, gameStepCards) {
	let cardIndex = -1;
	if (
		gameStepCards && 
		gameStepCards[gameStep] && 
		gameStepCards[gameStep].resourcePlacementsConfirmed === true && 
		gameStepCards[gameStep].cards
	) {
		gameStepCards[gameStep].cards.forEach((card, i) => {
			if (cardIndex >= 0 || !card) return;
			if (!card.hasOwnProperty('effectsApplied') || card.effectsApplied === false) {
				cardIndex = i;
			}
		});
	}
	return cardIndex;
}

/**
 * Get the effects of an event card
 * @param {object} group 
 * @param {array} consequences 
 */
function getEventCardEffects(group, consequences, gameType) {
	const gameSteps = gamesData[gameType].gameSteps;

	let effects = [];
	
	/* Default effects */
	if (consequences.effects) effects = consequences.effects;
	
	/* Conditional effects */
	if (consequences.conditionals) {
		let applyConditionalEffects = false;
		let conditionalIndex = null;				
		consequences.conditionals.forEach((conditional, index) => {
			/* A conditional effect has already been matched */
			if (applyConditionalEffects) return;

			/* Check all conditions for conditional */
			if (conditional && conditional.conditions) {
				let conditionsAreMet = checkIfConditionsAreMet(
					conditional.conditions, 
					group.selectedCrew, 
					group.dirtyDozenValues, 
					group.threats,
					group.gameStep, 
					group.gameStepCards,
					gameSteps
				);
				if (conditionsAreMet) {
					applyConditionalEffects = true;
					conditionalIndex = index;
				}
			}
		});
		if (applyConditionalEffects && consequences.conditionals[conditionalIndex].effects) {
			effects = consequences.conditionals[conditionalIndex].effects;
		}	
	}

	return effects;
}

/**
 * Check if all conditions of a conditional is met
 * @param {array} conditions
 * @param {array} selectedCrew
 * @param {array} dirtyDozenValues
 * @param {array} threats
 * @param {string} gameStep
 * @param {object} gameStepCards
 */
function checkIfConditionsAreMet(conditions, selectedCrew, dirtyDozenValues, threats, 
	gameStep, gameStepCards, gameSteps) {
	let conditionsAreMet = true;

	conditions.forEach((condition) => {
		/* Condition: crew */
		if (condition.type === 'crew') {
			let crewMemberIsSelected = selectedCrew.some((crew) => {
				return crew.id === condition.crewId;
			});
			if ((crewMemberIsSelected && !condition.isSelected) || (!crewMemberIsSelected && condition.isSelected)) {
				conditionsAreMet = false;
			}
		}

		/* Condition: dirty dozen */
		if (condition.type === 'dirtydozen') {
			let dirtyDozenIndex = dirtyDozenData.findIndex((dd) => {
				return dd.id === condition.dirtyDozenId;
			});
			if (
				dirtyDozenIndex >= 0 &&
				((condition.hasOwnProperty('valueMin') && dirtyDozenValues[dirtyDozenIndex] < condition.valueMin) ||
					(condition.hasOwnProperty('valueMax') && dirtyDozenValues[dirtyDozenIndex] > condition.valueMax))
			) {
				conditionsAreMet = false;
			}
		}

		/* Condition: threat exists */
		if (condition.type === 'threat') {
			if (
				!threats.some((threat) => {
					return threat === condition.threatId;
				})
			) {
				conditionsAreMet = false;
			}
		}

		/* Condition: threat does not exist */
		if (condition.type === 'no-threat') {
			if (
				threats.some((threat) => {
					return threat === condition.threatId;
				})
			) {
				conditionsAreMet = false;
			}
		}

		/* Condition: previous choice */
		if (condition.type === 'prev-choice') {
			let currentGameStepIndex = gameSteps.findIndex((step) => {
				return step.id === gameStep;
			});
			let eventCardFound = false;
			for (let i = 1; i <= currentGameStepIndex; i++) {
				if (eventCardFound) break;
				let gameStepId = gameSteps[i].id;
				if (
					gameStepCards.hasOwnProperty(gameStepId) &&
					gameStepCards[gameStepId].cards.some((card) => {
						return card && card.id === condition.cardId;
					})
				) {
					let cardData = gameStepCards[gameSteps[i].id].cards.find((card) => {
						return card.id === condition.cardId;
					});
					if (cardData) {
						eventCardFound = true;
						if (!cardData.selectedOptionId || cardData.selectedOptionId !== condition.optionId) {
							conditionsAreMet = false;
						}
					}
				}
			}
		}
	});
	return conditionsAreMet;
}

/**
 * Prepare the cards of a round
 * @param {object} group
 * @param {string} gameScenario
 */
function adjustGameStepCards(group, gameType, gameScenario) {
	/* Get event cards */
	const gameSteps = gamesData[gameType].gameSteps;
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;

	/* Get group properties */
	const gameStep = group.gameStep;
	let gameStepCards = JSON.parse(JSON.stringify(group.gameStepCards));
	let endurance = (group.endurance ? JSON.parse(JSON.stringify(group.endurance)) : null);

	/* New cards (top row) */
	gameStepCards[gameStep].cards.forEach((card, index) => {
		if (!card || index > 3) return;

		/* Replace card with other card depending on conditions */
		let cardData = eventCards.find((c) => {return c.id === card.id;});
		if (
			cardData &&
			cardData.hasOwnProperty('type') &&
			(cardData.type === 'replace' || cardData.type === 'conditional-replace')
		) {
			if (cardData.type === 'replace') card.id = cardData.cardId;
			if (cardData.hasOwnProperty('conditionals') && cardData.conditionals) {
				let conditionalCardId = null;
				cardData.conditionals.forEach((conditional) => {
					/* A conditionals conditions have already been matched */
					if (conditionalCardId !== null) return;

					/* Check all conditions for conditional */
					if (conditional && conditional.conditions) {
						let conditionsAreMet = checkIfConditionsAreMet(
							conditional.conditions,
							group.selectedCrew,
							group.dirtyDozenValues,
							group.threats,
							gameStep,
							gameStepCards,
							gameSteps
						);

						if (conditionsAreMet) conditionalCardId = conditional.cardId;
					}
				});
				if (conditionalCardId) card.id = conditionalCardId;
			}
		}

		/* Apply special card effects */
		if (cardData && cardData.hasOwnProperty('effects')) {
			cardData.effects.forEach((effect) => {
				if (effect.type === 'endurance') {
					endurance = effect.value;
				}
			});
		}
	});
	

	/* Old cards (move to bottom row, handle round effects) */
	let prevGameStepIndex =
		gameSteps.findIndex((step) => {
			return step.id === gameStep;
		}) - 1;
	if (
		gameStepCards.hasOwnProperty(gameSteps[prevGameStepIndex].id) &&
		gameStepCards[gameSteps[prevGameStepIndex].id].cards
	) {
		gameStepCards[gameSteps[prevGameStepIndex].id].cards.forEach((card, i) => {
			if (card) {
				/* Move cards from previous top row to current bottom row */
				if (card.concequenceType === 'move' && i < 4) {
					gameStepCards[gameStep].cards[i + 4] = { id: card.id };
				}

				/* Effects that last 1 or more rounds */
				if (card.futureEffects && card.futureEffects.length > 0) {
					card.futureEffects.forEach((effect) => {
						/* Limited resources */
						if (effect.type === 'disable-resources') {
							if (effect.duration === 1) {
								gameStepCards[gameStep].resourcesDisabled = effect.value;
							} else {
								// TODO: handle effects that last multiple rounds (might not be neccessary)
							}
						}
					});
				}
			}
		});
	}

	gameStepCards[gameStep].cardsAdjusted = true;

	/* Reset resources */
	let resources = JSON.parse(JSON.stringify(group.resources));
	for (let i = 0; i < resources.length; i++) {
		resources[i].isAvailable = true;
		resources[i].isPlaced = false;
	}

	/* Limit number of resources if that effect has been activated */
	if (gameStepCards[gameStep].resourcesDisabled) {
		for (let i = 0; i < gameStepCards[gameStep].resourcesDisabled; i++) {
			resources[i].isAvailable = false;
		}
	}

	return { gameStepCards, resources, endurance };
}

/**
 * Place / remove a resource on an event card option
 * @param {object} group
 * @param {string} gameScenario
 * @param {string} cardId
 * @param {string} optionId
 */
function toggleEventCardOption(group, cardId, optionId, gameType, gameScenario) {
	/* Get event cards */
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;

	/* Get group properties */
	const gameStep = group.gameStep;
	let gameStepCards = JSON.parse(JSON.stringify(group.gameStepCards));

	/* Prepare group updates */
	let groupUpdates = {};

	/* Get resources and cards */
	let resources = JSON.parse(JSON.stringify(group.resources));
	let cards = gameStepCards.hasOwnProperty(gameStep) ? gameStepCards[gameStep].cards : null;

	/* Card is on gameboard */
	if (
		cards &&
		cards.some((card) => {
			return card && card.id === cardId;
		})
	) {
		let cIndex = cards.findIndex((card) => {
			return card && card.id === cardId;
		});
		let availableResources = resources.filter((r) => {
			return r.isAvailable === true && r.isPlaced === false;
		});
		let cardData = eventCards.find((c) => {
			return c.id === cardId;
		});

		/* Card data is available */
		if (cardData && cardData.options) {
			let updateGroup = false;
			let optionData = cardData.options.find((o) => {
				return o.id === optionId;
			});
			if (!cards[cIndex].hasOwnProperty('selectedOptionId') || !cards[cIndex].selectedOptionId) {
				/* No options are selected on this card */
				if (optionData.cost <= availableResources.length) {
					/* Enough resources: Select option, place resources */
					cards[cIndex].selectedOptionId = optionId;
					let resourcesToPlace = optionData.cost;
					for (let i = resources.length - 1; i >= 0; i--) {
						let resource = resources[i];
						if (resourcesToPlace > 0 && resource.isAvailable && resource.isPlaced === false) {
							resources[i].isPlaced = true;
							resourcesToPlace--;
						}
					}
					updateGroup = true;
				} else {
					// Not enough resources
				}
			} else {
				/* An option on this card is already selected */
				if (cards[cIndex].selectedOptionId === optionId) {
					/* The option already selected, deselect & return resources */
					cards[cIndex].selectedOptionId = null;
					let resourcesToReturn = optionData.cost;
					for (let i = 0; i < resources.length; i++) {
						let resource = resources[i];
						if (resourcesToReturn > 0 && resource.isPlaced === true) {
							resource.isPlaced = false;
							resourcesToReturn--;
						}
					}
					updateGroup = true;
				} else {
					/* Different option on same card already selected */
					let selectedOptionData = cardData.options.find((o) => {
						return o.id === cards[cIndex].selectedOptionId;
					});
					if (availableResources.length + selectedOptionData.cost >= optionData.cost) {
						/* Enough resources to switch options */
						if (optionData.cost > selectedOptionData.cost) {
							/* New option costs more, place additional resources */
							let resourcesToPlace = optionData.cost - selectedOptionData.cost;
							for (let i = resources.length - 1; i >= 0; i--) {
								let resource = resources[i];
								if (resourcesToPlace > 0 && resource.isAvailable && resource.isPlaced === false) {
									resources[i].isPlaced = true;
									resourcesToPlace--;
								}
							}
						} else {
							/* New option costs less, return excess resources */
							let resourcesToReturn = selectedOptionData.cost - optionData.cost;
							for (let i = 0; i < resources.length; i++) {
								let resource = resources[i];
								if (resourcesToReturn > 0 && resource.isPlaced === true) {
									resource.isPlaced = false;
									resourcesToReturn--;
								}
							}
						}
						/* Update selected option */
						cards[cIndex].selectedOptionId = optionId;
						updateGroup = true;
					} else {
						/* Not enough resources to switch option */
					}
				}
			}

			/* Update databse if option successfully selected / deselected / switched */
			if (updateGroup) {
				gameStepCards[gameStep].cards = cards;
				groupUpdates.gameStepCards = gameStepCards;
				groupUpdates.resources = resources;
			}
		}
	} else {
		console.error('card is not on gameboard');
	}

	return groupUpdates;
}

/**
 * Check if user has selected an option on all required cards
 * @param {array} cards 
 * @param {string} gameScenario 
 */

function allRequiredActionsHaveBeenTaken(cards, gameType, gameScenario) {
	/* Get event cards */
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;

	let cardWithMissingRequiredActions = cards.some((card) => {
		let cardData = eventCards.find((c) => {return (card && c.id === card.id);});
		return (
			cardData &&
			cardData.noDefaultOption === true &&
			(!card.hasOwnProperty('selectedOptionId') || !card.selectedOptionId)
		);
	});

	return !cardWithMissingRequiredActions;
}



/**
 * Calculate the effects of an event card
 * @param {bool} firstCard
 * @param {number} cardIndex
 * @param {object} group 
 * @param {string} gameScenario 
 */
function calculateCardEffects(firstCard, index, group, gameType, gameScenario) {
	/* Get event cards */
	const gameSteps = gamesData[gameType].gameSteps;
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;

	/* Prepare group updates */
	let groupUpdates = {};

	/* Get group properties */
	const gameStep = group.gameStep;
	let gameStepCards = JSON.parse(JSON.stringify(group.gameStepCards));
	let triggeredThreats = [];
	if (group.triggeredThreats) triggeredThreats = JSON.parse(JSON.stringify(group.triggeredThreats));
	let selectedCrew = JSON.parse(JSON.stringify(group.selectedCrew));
	let threats = JSON.parse(JSON.stringify(group.threats));
	let inconvenienceValue = JSON.parse(JSON.stringify(group.inconvenienceValue));
	let riskValue = JSON.parse(JSON.stringify(group.riskValue));
	let dirtyDozenValues = JSON.parse(JSON.stringify(group.dirtyDozenValues));
	let crewSafetyValue = (group.crewSafetyValue ? JSON.parse(JSON.stringify(group.crewSafetyValue)) : 0);
	let missionSuccessValue = (group.missionSuccessValue ? JSON.parse(JSON.stringify(group.missionSuccessValue)) : 0);
	let complianceValue = (group.complianceValue ? JSON.parse(JSON.stringify(group.complianceValue)) : 0);
	let endurance = (group.endurance ? JSON.parse(JSON.stringify(group.endurance)) : null);
	let hospital = (group.hospital ? JSON.parse(JSON.stringify(group.hospital)) : null);
	let futureEffects = [];

	/* Check if there are any cards that will be moved to next round (crm-aeroplanes only) */
	let unresolvedMoveCards = [];
	if (
		gameType === 'crm-aeroplanes' && 
		index < 0 && 
		gameStepCards.hasOwnProperty(gameStep) && 
		(!gameStepCards[gameStep].hasOwnProperty('cardsMoved') || gameStepCards[gameStep].cardsMoved === false)
	) {
		unresolvedMoveCards = getUnresolvedMoveCards(gameStep, gameStepCards, eventCards);
	}

	if (unresolvedMoveCards && unresolvedMoveCards.length > 0) {
		/* Resolve all cards that will move to next round */
		unresolvedMoveCards.forEach((card) => {
			gameStepCards[gameStep].cards[card.cardIndex].effectsApplied = true;
			gameStepCards[gameStep].cards[card.cardIndex].concequenceType = 'move';
		});
		gameStepCards[gameStep].cardsMoved = true;
		groupUpdates.gameStepCards = gameStepCards;
	} else {
		/* Find index of next card where effects have not been applied */
		let cardIndex = (index >= 0 
			? index 
			: getIndexOfNextUnresolvedCard(gameStep, gameStepCards)
		);

		/* Check if it is the first card to be resolved */
		let isFirstUnresolvedCard = gameStepCards[gameStep].cards.filter((c) => {
			return (c && c.effectsApplied === true && c.concequenceType !== 'move');
		}).length === 0;

		if (cardIndex >= 0 && (!firstCard || isFirstUnresolvedCard)) {
			/* Get card data and row */
			let cardData = eventCards.find((c) => {
				return c.id === gameStepCards[gameStep].cards[cardIndex].id;
			});

			/* Get selected option id */
			let selectedOptionId = null;
			if (
				gameStepCards[gameStep].cards[cardIndex].hasOwnProperty('selectedOptionId') &&
				gameStepCards[gameStep].cards[cardIndex].selectedOptionId
			) {
				selectedOptionId = gameStepCards[gameStep].cards[cardIndex].selectedOptionId;
			}
			if (!cardData.hasOwnProperty('options') ) {
				/* TODO: better if condition */
				/* Card has no options (probably an avoided critial event) */
				gameStepCards[gameStep].cards[cardIndex].effectsApplied = true;
				gameStepCards[gameStep].cards[cardIndex].concequenceType = 'resolved';
				groupUpdates.gameStepCards = gameStepCards;
			} else {
				/* Prepare effects */
				let effects = [];
				let conditionals = [];
				let effectTypes = [];
				let statDiffs = {
					inconvenience: 0, 
					risk: 0,
					crewSafety: 0, 
					missionSuccess: 0, 
					compliance: 0
				};
				let applyConditionalEffects = false;
				let conditionalIndex = null;
				let triggerThreatId = null;
				let isCallFacilitatorCard = false;

				/* Get default effects & conditionals */
				if (
					(!cardData.options || cardData.options.length === 0) && 
					cardData.consequences && cardData.consequences.effects
				) {
					/* Effects / conditionals do not depend on any selected option */
					effects = cardData.consequences.effects;
					isCallFacilitatorCard = (cardData.consequences.hasCallFacilitatorText === true ? true : false);
					if (cardData.consequences.conditionals) conditionals = cardData.consequences.conditionals;
				} else {
					/* Automatically select default option if no available options are selected */
					if (!selectedOptionId && cardData.options) selectedOptionId = 'default';
					let optionData = cardData.options.find((o) => {return o.id === selectedOptionId;});
					if (optionData && optionData.consequences) {
						if (optionData.consequences.effects) effects = optionData.consequences.effects;
						isCallFacilitatorCard = 
							(optionData.consequences.hasCallFacilitatorText === true ? true : false);
						if (optionData.consequences.conditionals) conditionals = optionData.consequences.conditionals;
					}
				}

				/* Get conditional effects (if any) */
				if (conditionals && conditionals.length > 0) {
					conditionals.forEach((conditional, index) => {
						/* A conditional effect has already been matched */
						if (applyConditionalEffects) return;

						/* Check all conditions for conditional */
						if (conditional && conditional.conditions) {
							let conditionsAreMet = checkIfConditionsAreMet(
								conditional.conditions,
								selectedCrew,
								dirtyDozenValues,
								threats,
								gameStep,
								gameStepCards,
								gameSteps
							);
							if (conditionsAreMet) {
								applyConditionalEffects = true;
								conditionalIndex = index;
								let threatCondition = conditional.conditions.find((c) => {return c.type === 'threat';});
								if (threatCondition) triggerThreatId = threatCondition.threatId;
							}
						}
					});
					if (applyConditionalEffects) {
						isCallFacilitatorCard = 
							(conditionals[conditionalIndex].hasCallFacilitatorText === true ? true : false);
						if (conditionals[conditionalIndex].effects) {
							effects = conditionals[conditionalIndex].effects;	
						}		
					}			
				}

				/* Add triggered threat */
				if (triggerThreatId) {
					if (triggeredThreats.indexOf(triggerThreatId) < 0) triggeredThreats.push(triggerThreatId);
					groupUpdates.triggeredThreats = triggeredThreats;
				}

				/* Apply effects */
				if (effects.length > 0) {
					effects.forEach((effect) => {
						/* Log effect types */
						if (effectTypes.indexOf(effect.type) < 0) effectTypes.push(effect.type);

						/* Add threat */
						if (effect.type === 'threat') threats.push(effect.threatId);

						/* Replace threat (or add, if no threat to replace) */
						if (effect.type === 'replace-threat') {
							const oldThreatIndex = threats.indexOf(effect.oldThreatId);
							if (oldThreatIndex >= 0) {
								threats.splice(oldThreatIndex, 1, effect.threatId);
							} else {
								threats.push(effect.threatId);
							}
						}

						/* Change inconvenience value */
						if (effect.type === 'inconvenience') inconvenienceValue += effect.value;

						/* Change risk value */
						if (effect.type === 'risk') riskValue += effect.value;

						/* Change dirty dozen value */
						if (effect.type === 'dirtydozen') {
							let dirtyDozenIndex = dirtyDozenData.findIndex((dd) => {
								return dd.id === effect.dirtyDozenId;
							});
							if (dirtyDozenIndex >= 0) dirtyDozenValues[dirtyDozenIndex] += effect.value;
						}

						/* Change crewSafety value */
						if (effect.type === 'crewSafety') crewSafetyValue += effect.value;

						/* Change missionSuccess value */
						if (effect.type === 'missionSuccess') missionSuccessValue += effect.value;

						/* Change compliance value */
						if (effect.type === 'compliance') complianceValue += effect.value;

						/* Update endurance */
						if (effect.type === 'endurance') endurance = effect.value;

						/* Update selected hospital */
						if (effect.type === 'hospital') hospital = effect.value;

						/* Disable one or more resources in the next round(s) */
						if (effect.type === 'disable-resources') {
							futureEffects.push({ type: effect.type, value: effect.value, duration: effect.duration });
						}

						/* Remove or replace crewmember */
						if (effect.type === 'remove-crewmember' || effect.type === 'replace-crewmember') {
							const slotIndex = selectedCrew.findIndex((crew) => {
								return crew.slotId === effect.slotId;
							});
							if (slotIndex >= 0) {
								selectedCrew[slotIndex].prevId  = selectedCrew[slotIndex].id;
								if (gameType === 'crm-aeroplanes') {
									/* Remove crew member effects */
									let removeCrewMemberResult = applyOrRemoveCrewMemberEffects(
										'remove',
										selectedCrew[slotIndex].id,
										selectedCrew,
										dirtyDozenValues,
										riskValue,
										gameType
									);
									dirtyDozenValues = removeCrewMemberResult.newDirtyDozenValues;
									riskValue = removeCrewMemberResult.newRiskValue;
								}
								/* Flag as removed */
								if (effect.type === 'remove-crewmember') selectedCrew[slotIndex].isRemoved = true;

								if (effect.type === 'replace-crewmember') {
									/* Find and add new crew member */
									const notAllowedCrewemberIds = (effect.notAllowedCrewemberIds 
										? effect.notAllowedCrewemberIds 
										: []
									);
									let newCrewMemberId = findNewCrewMember(
										slotIndex, selectedCrew, gameType, notAllowedCrewemberIds
									);
									selectedCrew[slotIndex].id = newCrewMemberId;

									if (gameType === 'crm-aeroplanes') {
										/* Apply new crew member effects */
										let addCrewMemberResult = applyOrRemoveCrewMemberEffects(
											'apply',
											newCrewMemberId,
											selectedCrew,
											dirtyDozenValues,
											riskValue,
											gameType
										);
										dirtyDozenValues = addCrewMemberResult.newDirtyDozenValues;
										riskValue = addCrewMemberResult.newRiskValue;
									}

									/* Flag as replaced */
									selectedCrew[slotIndex].isReplaced = true;
								}
							}
						}
					});

					/* Make sure stat values are >= 0 */
					inconvenienceValue = Math.max(0, inconvenienceValue);
					riskValue = Math.max(0, riskValue);
					dirtyDozenValues.forEach((dd, i) => {
						dirtyDozenValues[i] = Math.max(0, dd);
					});
					crewSafetyValue = Math.max(0, crewSafetyValue);
					missionSuccessValue = Math.max(0, missionSuccessValue);
					complianceValue = Math.max(0, complianceValue);

					/* Track total difference in values */
					statDiffs.inconvenience = inconvenienceValue - group.inconvenienceValue;
					statDiffs.risk = riskValue - group.riskValue;	
					statDiffs.crewSafety = crewSafetyValue - group.crewSafetyValue;
					statDiffs.missionSuccess = missionSuccessValue - group.missionSuccessValue;
					statDiffs.compliance = complianceValue - group.complianceValue;
					

					/* Update properties affected by effects */
					gameStepCards[gameStep].cards[cardIndex].effectTypes = effectTypes;
					if (futureEffects.length > 0) {
						gameStepCards[gameStep].cards[cardIndex].futureEffects = futureEffects;
					}
					groupUpdates.threats = threats;
					groupUpdates.inconvenienceValue = inconvenienceValue;
					groupUpdates.riskValue = riskValue;
					groupUpdates.dirtyDozenValues = dirtyDozenValues;
					groupUpdates.crewSafetyValue = crewSafetyValue;
					groupUpdates.missionSuccessValue = missionSuccessValue;
					groupUpdates.complianceValue = complianceValue;
					groupUpdates.endurance = endurance;
					groupUpdates.hospital = hospital;
					groupUpdates.selectedCrew = selectedCrew;
				} 
				/* Update game step cards and group data */
				gameStepCards[gameStep].cards[cardIndex].selectedOptionId = selectedOptionId;
				gameStepCards[gameStep].cards[cardIndex].effectsApplied = true;
				gameStepCards[gameStep].cards[cardIndex].statDiffs = statDiffs;
				gameStepCards[gameStep].cards[cardIndex].concequenceType = 
					(applyConditionalEffects ? 'conditional' : 'standard');
				if (applyConditionalEffects) {
					gameStepCards[gameStep].cards[cardIndex].conditionalIndex = conditionalIndex;
				}
				if (triggerThreatId) {
					gameStepCards[gameStep].cards[cardIndex].triggerThreatId = triggerThreatId;
				}
				groupUpdates.gameStepCards = gameStepCards;

				/* Log card id if is a "call facilitator" card */
				if (isCallFacilitatorCard) {
					groupUpdates.callFacilitatorCardId = cardData.id;
				}
			}
		} else {
			// Card not found
		}
	}
	return groupUpdates;
}

/**
 * Handle possible incident event cards
 * @param {object} group 
 * @param {number} cardIndex 
 * @param {number} cardId 
 * @param {string} gameType
 * @param {string} gameScenario 
 */
function handlePossibleIncident(group, cardIndex, cardId, gameType, gameScenario) {
	/* Get event cards */
	const gameSteps = gamesData[gameType].gameSteps;
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;

	/* Prepare group updates */
	let groupUpdates = {};

	/* Check if threat is present, get critical card id if it is */
	let newCardId = null;
	let triggerThreatId = null;
	let cardData = eventCards.find((c) => {return c.id === cardId;});
	if (cardData && cardData.conditionals) {
		let conditionalIndex = -1;
		cardData.conditionals.forEach((conditional, index) => {
			/* A conditional effect has already been matched */
			if (conditionalIndex >= 0) return;

			/* Check all conditions for conditional */
			if (conditional && conditional.conditions) {
				let conditionsAreMet = checkIfConditionsAreMet(
					conditional.conditions, 
					group.selectedCrew, 
					group.dirtyDozenValues, 
					group.threats,
					group.gameStep, 
					group.gameStepCards,
					gameSteps
				);
				if (conditionsAreMet) {
					conditionalIndex = index;
					let threatCondition = conditional.conditions.find((c) => {return c.type === 'threat';});
					if (threatCondition) triggerThreatId = threatCondition.threatId;
				}
			}
		});
		if (conditionalIndex >= 0) {
			newCardId = cardData.conditionals[conditionalIndex].cardId;
		}
	}

	/* Update card */
	let gameStepCards = JSON.parse(JSON.stringify(group.gameStepCards));
	let triggeredThreats = [];
	if (group.triggeredThreats) triggeredThreats = JSON.parse(JSON.stringify(group.triggeredThreats));
	if (gameStepCards.hasOwnProperty(group.gameStep)) {
		if (newCardId) {
			/* Replace current card with critical event */
			gameStepCards[group.gameStep].cards[cardIndex].id = newCardId;
			if (triggerThreatId) {
				if (triggeredThreats.indexOf(triggerThreatId) < 0) triggeredThreats.push(triggerThreatId);
				groupUpdates.triggeredThreats = triggeredThreats;
				gameStepCards[group.gameStep].cards[cardIndex].triggerThreatId = triggerThreatId;
			}
		} else {
			/* Critical event avoided */
			gameStepCards[group.gameStep].cards[cardIndex].effectsApplied = true;
		}
		groupUpdates.gameStepCards = gameStepCards;
	}

	return groupUpdates;
};

/**
 * Trigger critical event
 * @param {objecg} group 
 * @param {number} cardIndex 
 * @param {number} cardId 
 * @param {string} gameScenario 
 */
function triggerCriticalEvent(group, cardIndex, cardId, gameType, gameScenario) {
	/* Get event cards */
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;

	/* Prepare group updates */
	let groupUpdates = {};

	const gameStep = group.gameStep;
	let cardData = eventCards.find((c) => {return c.id === cardId;});
	let gameStepCards = JSON.parse(JSON.stringify(group.gameStepCards));
	if (cardData && gameStepCards.hasOwnProperty(gameStep)) {	
		let selectedOptionId = gameStepCards[gameStep].cards[cardIndex].selectedOptionId;
		let optionData = cardData.options.find((option) => {return option.id === selectedOptionId;});
		if (optionData && optionData.consequences) {
			let effects = getEventCardEffects(group, optionData.consequences, gameType);
			let effectData = effects.find((effect) => {return effect.type === 'critical-event';});
			if (effectData) {
				gameStepCards[gameStep].cards[cardIndex].id = effectData.criticalCardId;	
				delete gameStepCards[gameStep].cards[cardIndex].effectsApplied;
				delete gameStepCards[gameStep].cards[cardIndex].effectTypes;
				delete gameStepCards[gameStep].cards[cardIndex].concequenceType;
				delete gameStepCards[gameStep].cards[cardIndex].selectedOptionId;
				groupUpdates.gameStepCards = gameStepCards;
			}
		};
	}

	return groupUpdates;
}

export {
	getIndexOfGameStep,
	checkIfResourcePlacementsConfirmed,
	checkIfAllCardsEffectsApplied,
	getIndexOfNextActionCard,
	getIndexOfNextPriorityCard,
	getConfirmButtonData,
	getUnresolvedMoveCards,
	getIndexOfNextUnresolvedCard,
	checkIfConditionsAreMet,
	adjustGameStepCards,
	toggleEventCardOption,
	calculateCardEffects,
	allRequiredActionsHaveBeenTaken,
	handlePossibleIncident,
	triggerCriticalEvent
};
