import React from 'react';
import PropTypes from 'prop-types';
import {fillMeter} from 'helpers/layout-helper';
import './game-board-stat.scss';

const GameBoardStat = ({languageId, animate, statDiff, type, title, value, maxValue, onClick}) => {

	return (
		<div 
			className={'GameBoardStat ' + (languageId ? languageId + ' ' : '') + type + (onClick ? ' clickable' : '')} 
			onClick={() => {if (onClick) onClick();}}
		>
			<div className="GameBoardStat-title">{title}</div>
			<div className={'GameBoardStat-content' + (animate ? ' animate' : '')}>
				{type !== 'dirtydozen' && <div className={'GameBoardStat-fillmeter'}>
					{fillMeter(maxValue, value, 'GameBoardStat-point', animate, statDiff)}
				</div>}
				{type === 'dirtydozen' && <span>{value}/{maxValue}</span>}
				
			</div>
		</div>
	);
};

GameBoardStat.propTypes = {
	languageId: PropTypes.string,
	animate: PropTypes.bool.isRequired,
	statDiff: PropTypes.number,
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired,
	onClick: PropTypes.func
};

export default GameBoardStat;