import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import {settingsUiTexts} from 'data/ui-texts';
import {decisionToolsData, communicationToolsData, exerciseToolsData} from 'data/tools-data';
import {formatDate} from 'helpers/date-helper';
import {getText} from 'helpers/language-helper';
import SettingsHeader from 'components/settings/settings-header';
import Button from 'components/button/button';
import GroupPlayers from 'components/group-players/group-players';
import GroupsPopup from 'components/groups-popup/groups-popup';
import {useWindowSize} from 'helpers/windowchange-helper';
import {isIOS} from 'react-device-detect';
import './overview.scss';

const Overview = ({
	languageId,
	email,
	companyData,
	game,
	groups,
	toggleGroupPopup,
	toggleStatusPopup,
	handleUnlockNextStepInPhase,
	handleGoToNextGamePhase,
	handleGoToPage,
	handleLogout,
	togglePauseGame,
}) => {

	/* Get game data */
	const gameUrlPath = gamesData[game.type].urlPath;
	const gamePhases = gamesData[game.type].gamePhases;
	const gameSteps = gamesData[game.type].gameSteps;
	const scenario = gamesData[game.type].scenarios.find((sc) => {return sc.id === game.scenario;});
	const decisionTool = decisionToolsData.find((d) => {return d.id === game.decisionTool;});
	const communicationTool = communicationToolsData.find((c) => {return c.id === game.communicationTool;});
	const exerciseToolId = (game.exerciseTool ? game.exerciseTool : exerciseToolsData[0].id);
	const exerciseTool = exerciseToolsData.find((c) => {return c.id === exerciseToolId;});

	/* Get next step to unlock in current game phase */
	let nextBtn = null;
	let currentGamePhaseIndex = gamePhases.findIndex((phase) => {return phase.id === game.gamePhase;});
	if (gamePhases[currentGamePhaseIndex].steps) {
		gamePhases[currentGamePhaseIndex].steps.forEach((step, index) => {
			if (nextBtn) return;
			if (!game.hasOwnProperty(step.id) || game[step.id] === false) {
				nextBtn = JSON.parse(JSON.stringify(step));
				nextBtn.isLastPhase = false;
				nextBtn.action = handleUnlockNextStepInPhase;
				nextBtn.type = 'step';
				if (currentGamePhaseIndex === 0 && index === 0) {
					nextBtn.type = 'first-step-first-phase';
				}
			}
		});
	}

	/* Get next game phase (if no steps need to be unlocked), check if it is last game phase */
	if (nextBtn === null) {
		if (currentGamePhaseIndex < 0) currentGamePhaseIndex = 0;
		nextBtn = ((currentGamePhaseIndex + 1) < gamePhases.length 
			? JSON.parse(JSON.stringify(gamePhases[currentGamePhaseIndex + 1]))
			: null
		);
		if (nextBtn) {
			nextBtn.isLastPhase = (currentGamePhaseIndex + 1 === gamePhases.length - 1);
			nextBtn.action = handleGoToNextGamePhase;
			nextBtn.type = 'phase';
			if (nextBtn.btnText2 && typeof nextBtn.btnText2 === 'string') {
				if (nextBtn.btnText2 === '%decisionTool%') nextBtn.btnText2 = decisionTool.title;
				if (nextBtn.btnText2 === '%communicationTool%') nextBtn.btnText2 = communicationTool.title;
				if (nextBtn.btnText2 === '%exerciseTool%') nextBtn.btnText2 = exerciseTool.title;
			}
		}
	}

	/* Get status button status */
	let statusPopupBtnIsActive = false;
	let minIndex = null;
	if (gamePhases[currentGamePhaseIndex].statusBtn) {
		statusPopupBtnIsActive = true;
		groups.forEach((group) => {
			let index = gamePhases[currentGamePhaseIndex].statusBtn.gameStepIds.indexOf(group.gameStep);
			if (minIndex === null) {
				minIndex = index;
			} else {
				if (index < minIndex) minIndex = index;
			}
		});
		if (minIndex < 0) statusPopupBtnIsActive = false;
	} else {
		minIndex = -1;
	}



	/* Show group players popup */
	const [showGroupsPopup, setShowGroupsPopup] = useState(false);
	const sizeCheck = useWindowSize();
	const groupsDiv = React.useRef(null);
	const groupsDivBox = groupsDiv?.current?.getBoundingClientRect();
	const [isIOs, setIsIOs] = useState(isIOS);
	const [stepsHeight, setStepsHeight] = useState(0);
	useEffect(() => {
		setIsIOs(isIOS);
		if (groupsDivBox?.height) {
			setStepsHeight(groupsDivBox?.height);
		}
	}, [sizeCheck, groupsDivBox]);


	/* Render */
	return (
		<div className="Overview">
			{/* Header */}
			<SettingsHeader 
				languageId={languageId}
				page={'overview'}
				email={email} 
				title={game.title} 
				gameType={game.type} 
				gameUrlPath={gameUrlPath}
				code={game.id} 
				handleLogout={handleLogout}
			>
				<Button
					classes={['home']}
					text={getText(settingsUiTexts.home, languageId)}
					onClick={() => {handleGoToPage('settings');}}
				/>
			</SettingsHeader>

			{/* Game info */}
			<div className="Overview-gameInfo">
				<div className="Overview-infoBox">
					{getText(settingsUiTexts.scheduledStart, languageId)}:<br />
					{game.playDate}
				</div>
				<div className="Overview-infoBox">
					{getText(settingsUiTexts.createdDate, languageId)}:<br />
					{formatDate(game.created)}
				</div>
				<div className="Overview-infoBox">
					{getText(settingsUiTexts.scenario, languageId)}:<br />
					{scenario ? getText(scenario.title, languageId) : '?'}
				</div>
				{( game.type !== 'safetytraining' && game.type !== 'crm-helicopters' ) && 
				<div className="Overview-infoBox">
					{getText(settingsUiTexts.decisionTool, languageId)}:<br />
					{decisionTool ? getText(decisionTool.title, languageId) : '?'}
				</div>}
				{( game.type !== 'safetytraining' && game.type !== 'crm-helicopters' ) &&  
				<div className="Overview-infoBox">
					{getText(settingsUiTexts.communicationTool, languageId)}:<br />
					{communicationTool ? getText(communicationTool.title, languageId) : '?'}
				</div>}
				{( game.type === 'crm-helicopters' ) &&  
				<div className="Overview-infoBox">
					{getText(settingsUiTexts.exerciseTool, languageId)}:<br />
					{exerciseTool ? getText(exerciseTool.title, languageId) : '?'}
				</div>}
				<div className="Overview-infoBox">
					{getText(settingsUiTexts.numberOfGroups, languageId)}:<br />
					{groups.length}
				</div>
			</div>

			{/* Buttons */}
			<div className="Overview-buttons">
				{/* Next button (next phase step or next game phase) */}
				{nextBtn && (
					<div className={'Overview-nextPhase' + (nextBtn.isLastPhase ? ' endgame' : '')}>
						{/* Text */}
						{(!nextBtn.isLastPhase && nextBtn.btnText1) && 
							<div className="Overview-nextPhaseText">
								<strong>{getText(nextBtn.btnText1, languageId)}: </strong>
								{(nextBtn.btnText2 
									? getText(nextBtn.btnText2, languageId)
									: ''
								)}
							</div>
						}
				
						{/* Next step btn */}
						<div
							className={'Overview-nextPhaseBtn' + (game.isPaused ? ' paused' : '')}
							onClick={() => {if (!game.isPaused) nextBtn.action();}}
						>
							<div className={`${nextBtn.btnText1 ? '' : 'Overview-nextGamePhaseBtn--higher'}`}>
								{!nextBtn.isLastPhase 
									? getText(settingsUiTexts.openNextGamePhase, languageId).toUpperCase()
									: getText(settingsUiTexts.finishGame, languageId)
								}
							</div>
						</div>
					</div>
				)}

				{/* Status popup btn */}
				<div 
					className={'Overview-statusPopupBtn' + (statusPopupBtnIsActive ? ' enabled' : '')}
					onClick={() => {toggleStatusPopup(true, minIndex);}}
				><span>{getText(settingsUiTexts.status, languageId)}</span></div>
			</div>

			{/* Pause button */}
			<div
				className={`Overview-pauseBtn ${game.isPaused && 'Overview-pauseBtn--active'}`}
				onClick={() => {togglePauseGame();}}
			>
				<span>
					{game.isPaused 
						? getText(settingsUiTexts.unpause, languageId) 
						: getText(settingsUiTexts.pause, languageId)}
				</span>
			</div>			

			{/* Groups */}
			<div ref={groupsDiv} className="Overview-groups">
				<div 
					className="Overview-groupsHeader" 
					onClick={() => {setShowGroupsPopup(true);}}
				><span>{getText(settingsUiTexts.group, languageId)}</span></div>
				<div className="Overview-groupsBody">
					{groups.map((group, index) => {
						return (
							<div key={index} className="Overview-group">
								<div className={'Overview-groupTitle' + (group.isPlaying ? ' playing' : '')}>
									{group.title}
								</div>
								<div className="Overview-groupPlayers">
									<GroupPlayers group={group} companyData={companyData} type="hover" />
								</div>
								{group.isPlaying && 
									<div 
										className="Overview-groupPlaying" 
										onClick={() => {toggleGroupPopup(group.id);}}
									/>
								}
							</div>
						);
					})}
				</div>
			</div>
			{/* Game steps */}
			<div 
				className="Overview-gameSteps" 
				style={{height: (isIOs && (stepsHeight !== 0)) ? `${stepsHeight}px` : 'auto'}}
			>
				<div className="Overview-gameStepsHeader">
					{gamePhases.map((phase, index) => {
						let className = 'Overview-gameStepsHeaderSection phase-' + phase.id + ' ' + languageId + 
							(phase.id > game.gamePhase ? ' futurePhase' : '') +
							(
								nextBtn && nextBtn.type === 'first-step-first-phase'
									? '' 
									: phase.id === game.gamePhase ? ' currentPhase' : ''
							) + 
							' steps-' + phase.sections.length;
						return (
							<div key={index} className={className}>
								<div className="Overview-gameStepsHeaderSectionTitle">
									{getText(phase.title, languageId)}
								</div>
								<div className="Overview-gameStepsHeaderSectionSteps">
									{phase.sections.map((section) => {
										let sectionTitle = JSON.parse(JSON.stringify(section.title));
										if (typeof sectionTitle === 'string') {
											sectionTitle = sectionTitle
												.replace(/%decisionTool%/g, getText(decisionTool.title, languageId))
												.replace(/%communicationTool%/g, 
													getText(communicationTool.title, languageId))
												.replace(/%exerciseTool%/g, getText(exerciseTool.title, languageId));
										} else {
											sectionTitle = getText(sectionTitle, languageId);
										}
										return (
											<div key={section.id} className="Overview-gameStepsHeaderSectionStep">
												{sectionTitle}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
				<div className="Overview-gameStepsBody">
					{groups.map((group, index) => {
						let gameStepData = gameSteps.find((step) => {return step.id === group.gameStep;});
						return (
							<div key={index} className="Overview-gameStepsGroup">
								<div className={'Overview-groupContainer' + (group.isPlaying ? ' playing' : '')}>
									{group.isPlaying && gamePhases.map((phase, index) => {
										/* Future phase */
										if (phase.id > gameStepData.phase) return null;

										/* Current or past phase */
										let className = 'Overview-groupPhase steps-' + phase.sections.length;
										if (index === gamePhases.length - 1) className += ' last';
										return (
											<div key={phase.id} className={className}>
												{phase.sections.map((section, index) => {
													/* Current phase, future section */
													if (
														phase.id === gameStepData.phase && 
														section.id > gameStepData.sectionId
													) return null;							

													/* Current phase, current section */
													let className = 'Overview-groupSection';
													/* Previous phase or current phase, previous section or gameover */
													if (
														phase.id < gameStepData.phase || 
														section.id < gameStepData.sectionId ||
														gameStepData.id === 'gameover'
													) className += ' done';
													return <div key={phase.id + '-' + index} className={className}/>;
												})}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
				<div className={'Overview-phaseLines ' + game.type}>
					{gamePhases.map((_, index) => {
						return <div key={index} className={'Overview-phaseLine Overview-phaseLine--' + (index + 1)} />;
					})}
				</div>
			</div>

			{/* Groups popup */}
			{showGroupsPopup &&
				<GroupsPopup 
					languageId={languageId}
					groups={groups}
					companyData={companyData}
					setShowGroupsPopup={setShowGroupsPopup}
				/>
			}
		</div>
	);
};

Overview.propTypes = {
	languageId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	groups: PropTypes.array.isRequired,
	toggleGroupPopup: PropTypes.func.isRequired,
	toggleStatusPopup: PropTypes.func.isRequired,
	handleUnlockNextStepInPhase: PropTypes.func.isRequired,
	handleGoToNextGamePhase: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	togglePauseGame: PropTypes.func.isRequired,
};

export default Overview;
