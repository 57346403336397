const threatsData = [
	{ id: 'minimum-crew', eventCardId: 2, title: {
		en: 'Minimum crew' }
	},
	{ id: 'single-guard', eventCardId: 3, title: {
		en: 'Single Guard' }
	},
	{ id: 'old-wind-chart', eventCardId: 4, title: {
		en: 'Outdated wind chart' }
	},
	{ id: 'human-remains', eventCardId: 5, title: {
		en: 'Human remains' }
	},
	{ id: 'unwanted-visitor', eventCardId: 6, title: {
		en: 'Unwanted visitor' }
	},
	{ id: 'no-cleaning', eventCardId: 7, title: {
		en: 'No cleaning' }
	},
	{ id: 'limited-water', eventCardId: 9, title: {
		en: 'Limited water' }
	},
	{ id: 'boarding-while-fueling', eventCardId: 10, title: {
		en: 'Boarding while fueling' }
	},
	{ id: 'fever', eventCardId: 12, title: {
		en: 'Fever' }
	},
	{ id: 'hidden-goods', eventCardId: 13, title: {
		en: 'Hidden dangerous goods' }
	},
	{ id: 'scared-passenger', eventCardId: 15, title: {
		en: 'Scared passenger' }
	},
	{ id: 'no-water', eventCardId: 19, title: {
		en: 'No water' }
	},
	{ id: 'scared-kid', eventCardId: 20, title: {
		en: 'Scared Kid' }
	},
	{ id: 'pos-comp-sickness', eventCardId: 24, title: {
		en: 'Possible decompression sickness'}
	},
	{ id: 'comp-sickness', eventCardId: 27, title: {
		en: 'Decompression Sickness' }
	},
	{ id: 'sick-crew', eventCardId: 28, title: {
		en: 'Sick crew members' }
	},
	{ id: 'low-oxygen', eventCardId: 29, title: {
		en: 'Low cabin pressure' }
	},
	{ id: 'fuel-leakage', eventCardId: 30, title: {
		en: 'Fuel leakage'}
	},
	{ id: 'passenger-injured1', eventCardId: 101, title: {
		en: 'Passenger injured' }
	},
	{ id: 'passenger-injured2', eventCardId: 102, title: {
		en: 'Passenger injured' }
	},
	{ id: 'off-trim', eventCardId: 103, title: {
		en: 'Off trim' }
	},
];

export { threatsData };
