const threatsData = [
	{id: 'late-crew', eventCardId: 2, title: {
		en: 'Late crew'
	}},
	{id: 'missed-connections', eventCardId: 3, title: {
		en: 'Missed connections'
	}},
	{id: 'minimum-fuel', eventCardId: 4, title: {
		en: 'Minimum fuel'
	}},
	{id: 'unaccompanied-minors', eventCardId: 5, title: {
		en: 'Unaccompanied minors'
	}},
	{id: 'first-aid-kit-unsealed', eventCardId: 7, title: {
		en: 'First aid kit unsealed'
	}},
	{id: 'database-expiry', eventCardId: 8, title: {
		en: 'Database expiry'
	}},
	{id: 'no-food', eventCardId: 9, title: {
		en: 'No food'
	}},
	{id: 'electrical-malfunction', eventCardId: 10, title: {
		en: 'Electrical malfunction'
	}},
	{id: 'cello-in-cabin', eventCardId: 12, title: {
		en: 'Cello in cabin'
	}},
	{id: 'deportee', eventCardId: 13, title: {
		en: 'Deportee'
	}},
	{id: 'dehydrated-baby', eventCardId: 14, title: {
		en: 'Dehydrated baby'
	}},
	{id: 'medicine-in-cargo', eventCardId: 15, title: {
		en: 'Insulin in cargo'
	}},
	{id: 'arguing-passengers', eventCardId: 17, title: {
		en: 'Arguing passengers'
	}},
	{id: 'coffee-in-cabin', eventCardId: 25, title: {
		en: 'Coffee in cabin'
	}},
	{id: 'no-intercom', eventCardId: 28, title: {
		en: 'No intercom'
	}}
];

export {threatsData};