/* eslint-disable max-len */
const eventCards = [
	/* CHECK-IN */
	{
		id: 1,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Text message'
		},
		text: {
			en: 'One of the pilots recieves a text message from home during preflight inspection. Sick child (again), and you have just started on a 72 hour duty.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Call back right away'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You were interrupted in your preparation. You could forget to complete required tasks.'
					},
					effects: [
						{type: 'threat', threatId: 'distraction'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: -1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Text back that you will call later'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You were distracted in your pre-flight inspection. You forget to call back.'
					},
					effects: [
						{type: 'threat', threatId: 'distraction'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1}
					],
				},	
			},
			{
				id: 'c',
				text: {
					en: 'Ignore until pre-flight is finished'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You continue your pre-flight inspection without being interrupted.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'compliance', value: 1},
						{type: 'missionSuccess', value: 1},
					],
				},	
			}
		],
	},
	{
		id: 2,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Survival suit leakage'
		},
		text: {
			en: 'During inspection of equipment you find one of the survival suits to have a leakage on the right side under the arm.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Change it right away'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You change the suit.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'compliance', value: 1},
						{type: 'missionSuccess', value: 1},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Repair it'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You repair the suit.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: 1},
					]
				}
			},
			{
				id: 'c',
				text: {
					en: 'Go as it is'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You leave it as it is. You are probably not going in the water anyway.'
					},
					effects: [
						{type: 'threat', threatId: 'survival-suit'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1}
					]
				}
			}
		],
	},
	{
		id: 3,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'Lack of sleep'
		},
		text: {
			en: '%name% tells you %pronounSubj% didn\'t sleep well. %PronounSubj% feels tired.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		slotId: 1,
		options: [
			{
				id: 'a',
				text: {
					en: 'Send %pronounObj% home and call a standby'
				},
				cost: 2,
				consequences: {
					text: {
						en: '%name% checks in after 1 hour.'
					},
					effects: [
						{type: 'replace-crewmember', slotId: 1},
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: 1},
						{type: 'compliance', value: 1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Send %pronounObj% for sleep/rest'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Let %pronounObj% sleep. This puts pressure on the rest of the crew.'
					},
					effects: [
						{type: 'threat', threatId: 'fatigued-captain'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1}
					]
				}
			},
			{
				id: 'c',
				text: {
					en: 'Let %pronounObj% perfom %pronounPoss% duties in %pronounPoss% own pace'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Possibilty that items are missed in the preparation phase.'
					},
					effects: [
						{type: 'threat', threatId: 'fatigued-captain'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: -1}
					]
				}
			}
		],
	},
	{
		id: 4,
		gameStep: 'check-in',
		type: 'default',
		title: {
			en: 'AED Battery'
		},
		text: {
			en: 'Battery on the AED is not fully charged. SoC at 45% (State of Charge).'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Recharge AED now'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Charging Time 1 hour.'
					},
					effects: [
						{type: 'threat', threatId: 'aed-battery'},
						{type: 'missionSuccess', value: 1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Replace AED'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You replace the AED.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
						{type: 'compliance', value: 1}
					]
				}
			},
			{
				id: 'c',
				text: {
					en: 'Ignore. This is ok for now'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You are ok with the battery state of charge.'
					},
					effects: [
						{type: 'threat', threatId: 'aed-battery'},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: -1}
					]
				}
			}
		],
	},

	/* SCRAMBLE */
	{
		id: 6,
		gameStep: 'scramble',
		type: 'replace',
		cardId: 6,
		conditionals: [
			{
				conditions: [{ type: 'prev-choice', cardId: 4, optionId: 'a' }, ],	
				cardId: 102
			},
			{
				conditions: [{ type: 'prev-choice', cardId: 4, optionId: 'c' }, ],	
				cardId: 101
			}
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Congratulations. By replacing the AED, you avoided taking off with an AED at 42% capacity.'
		},
		roundsMax: 0,
	},
	{
		id: 7,
		gameStep: 'scramble',
		type: 'default',
		title: {
			en: 'Fuel'
		},
		text: {
			en: 'Fuel has not been uplifted correctly. There is 30 min less than expected. Current endurance is now 2:30.'
		},
		roundsMax: 1,
		effects: [
			{type: 'endurance', value: '2:30'}
		],
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Upload fuel'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You get the helicopter fueled. It takes approx. 10 min. Endurance in now 3:00 Hours.'
					},
					effects: [
						{type: 'endurance', value: '3:00'},
						{type: 'crewSafety', value: 1},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Continue mission with current fuel'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Possibility of fuel issues later.'
					},
					effects: [
						{type: 'threat', threatId: 'fuel'},
						{type: 'missionSuccess', value: -1},
					]
				}
			}
		],
	},
	{
		id: 8,
		gameStep: 'scramble',
		type: 'default',
		title: {
			en: 'Survival suit'
		},
		text: {
			en: 'During strapping in, the rescue diver rips his survival suit.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Change the survival suit'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You change the survival suit. It takes 6 minutes.'
					},
					effects: [
						{type: 'compliance', value: 1},
						{type: 'missionSuccess', value: 1}
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'b' }, ],	
							text: {
								en: 'You change the survival suit. It takes 6 minutes.'
							},
							effects: [
								{type: 'compliance', value: 1},
								{type: 'missionSuccess', value: -1}
							],
						}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Continue, you are probably not going into the water anyway'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You continue the mission with the damaged survival suit.'
					},
					effects: [
						{type: 'threat', threatId: 'survival-suit-2'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1}
					]
				}
			}
		],
	},
	{
		id: 9,
		gameStep: 'scramble',
		type: 'default',
		title: {
			en: 'Crew member missing'
		},
		text: {
			en: '%name% is missing.'
		},
		slotId: 4,
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Send a crew member to search for %pronounObj% but continue scramble'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You find %pronounObj% in the resting area sound asleep.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'b' }, ],	
							text: {
								en: 'You find %pronounObj% in the resting area sound asleep.'
							},
							effects: [
								{type: 'missionSuccess', value: -1}
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 8, optionId: 'b' }, ],	
							text: {
								en: 'You find %pronounObj% in the resting area sound asleep.'
							},
							effects: [
								{type: 'missionSuccess', value: -1}
							],
						},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Wait for %pronounObj%. %PronounSubj% is probably on the way.'
				},
				cost: 1,
				consequences: {
					text: {
						en: '%PronounSubj% was in the shower and it took extra time to get ready.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 7, optionId: 'b' }, ],	
							text: {
								en: '%PronounSubj% was in the shower and it took extra time to get ready.'
							},
							effects: [
								{type: 'missionSuccess', value: -1}
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 8, optionId: 'b' }, ],	
							text: {
								en: '%PronounSubj% was in the shower and it took extra time to get ready.'
							},
							effects: [
								{type: 'missionSuccess', value: -1}
							],
						},
					]
				}
			}
		],
	},

	/* OUTBOUND */
	{
		id: 11,
		gameStep: 'outbound',
		type: 'replace',
		cardId: 11,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'survival-suit-2'}],
				cardId: 103,
			},
			{
				conditions: [{type: 'threat', threatId: 'survival-suit-3'}],
				cardId: 103,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Congratulations. By changing the suit earlier on, you avoided decreased crew safety or mission success.'
		},
		roundsMax: 0,
	},
	{
		id: 12,
		gameStep: 'outbound',
		type: 'default',
		title: {
			en: 'Weather Radar'
		},
		text: {
			en: 'Weather Radar "freezes".'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Try to reset / restart the weather radar'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Weather Radar is working again.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: 1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Ignore and fly the old fashioned way'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Weather Radar U/S.'
					},
					effects: [
						{type: 'threat', threatId: 'weather-radar'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: -1},
					]
				}
			}
		],
	},
	{
		id: 13,
		gameStep: 'outbound',
		type: 'default',
		title: {
			en: 'Icing Condition'
		},
		text: {
			en: 'You encounter light to moderate icing conditions en-route.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue through the area. It seems ok at the moment'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You continue to monitor. It is still at an acceptable level with a slight tendency to increase.'
					},
					effects: [{type: 'threat', threatId: 'icing'}],
					conditionals: [
						{
							conditions: [
								{ type: 'threat', threatId: 'weather-radar'},
							],
							text: {
								en: 'You try to monitor the situation, but the missing Weather Radar makes it difficult to predict.'
							},
							effects: [
								{type: 'threat', threatId: 'icing'},
								{type: 'crewSafety', value: -1},
								{type: 'missionSuccess', value: -1}
							],
						},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Fly low level to avoid icing conditions'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You continue to monitor. It seems to be ok.'
					},
					effects: [
						{type: 'crewSafety', value: -1}
					]
				}
			}
		],
	},
	{
		id: 14,
		gameStep: 'outbound',
		type: 'default',
		title: {
			en: 'Thunderstorms'
		},
		text: {
			en: 'Thunderstorms are observed in the vicinity.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Go through the area'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You continue through the area of thunderstorms.'
					},
					effects: [
						{type: 'threat', threatId: 'thunderstorm'},
						{type: 'compliance', value: -1}
					],
					conditionals: [
						{
							conditions: [{type: 'threat', threatId: 'weather-radar'}, ],
							text: {
								en: 'You continue through the area of thunderstorms. You try to monitor the situation, but the missing Weather Radar makes it almost impossible to avoid.'
							},
							effects: [
								{type: 'threat', threatId: 'thunderstorm'},
								{type: 'compliance', value: -1},
								{type: 'crewSafety', value: -1},
								{type: 'missionSuccess', value: -1}
							],
						},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Fly around the area. It is about 15 Nm extra'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'It takes time to fly around the thunderstorm.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1}
					]
				}
			}
		],
	},
	/* ON SITE */
	{
		id: 16,
		gameStep: 'on-site',
		type: 'default',
		title: {
			en: 'Injury to crew'
		},
		text: {
			en: 'When exiting the helicopter DOC severely strains his ankle.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Take care of DOC first'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You help the DOC first and then proceed with the casualties.'
					},
					effects: [
						{type: 'crewSafety', value: 1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Take care of the casualties first'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You ask the pilots to help you to carry the casulties to the Helicopter.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
						{type: 'crewSafety', value: -1}
					]
				}
			}
		],
	},

	{
		id: 17,
		gameStep: 'on-site',
		type: 'default',
		title: {
			en: 'Prioritise'
		},
		text: {
			en: 'Second casualty, also wearing a diving suit, is confused and only speaks his native language that you dont understand. How do you handle the two casualties?'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'First casualty go on the helicopter and second casualty in the ambulance'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'First casualty is going in the helicopter.'
					},
					effects: [
						{type: 'missionSuccess', value: 1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'First casualty go in the ambulance and second casualty on the helicopter'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Second casualty is going in the helicopter.'
					},
					effects: [
						{type: 'missionSuccess', value: -1}
					]
				}
			}
		],
	},
	{
		id: 18,
		gameStep: 'on-site',
		type: 'default',
		title: {
			en: 'Select primary hospital'
		},
		text: {
			en: 'Evaluate patient condition and select primary hospital.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Hospital #1'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Fly towards Hospital #1.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'hospital', value: 1}
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 17, optionId: 'a' }, ],	
							text: {
								en: 'Fly towards Hospital #1.'
							},
							effects: [
								{type: 'crewSafety', value: 1},
								{type: 'missionSuccess', value: -1},
								{type: 'hospital', value: 1}
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 17, optionId: 'b' }, ],	
							text: {
								en: 'Fly towards Hospital #1.'
							},
							effects: [
								{type: 'crewSafety', value: 1},
								{type: 'missionSuccess', value: 1},
								{type: 'hospital', value: 1}
							],
						},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Hospital #2'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Fly towards Hospital #2.'
					},
					effects: [
						{type: 'crewSafety', value: -1},
						{type: 'hospital', value: 2}
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 17, optionId: 'a' }, ],	
							text: {
								en: 'Fly towards Hospital #2.'
							},
							effects: [
								{type: 'crewSafety', value: -1},
								{type: 'missionSuccess', value: 1},
								{type: 'hospital', value: 2}
							],
						},
					]
				}
			},
			{
				id: 'c',
				text: {
					en: 'Hospital #3'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'Fly towards Hospital #3.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: 1},
						{type: 'hospital', value: 3}
					],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 17, optionId: 'a' }, ],	
							text: {
								en: 'Fly towards Hospital #3.'
							},
							effects: [
								{type: 'crewSafety', value: 1},
								{type: 'missionSuccess', value: 1},
								{type: 'hospital', value: 3}
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 17, optionId: 'b' }, ],	
							text: {
								en: 'Fly towards Hospital #3.'
							},
							effects: [
								{type: 'crewSafety', value: 1},
								{type: 'missionSuccess', value: 1},
								{type: 'hospital', value: 3}
							],
						},
					]
				}
			}
		],
	},
	{
		id: 19,
		gameStep: 'on-site',
		type: 'default',
		title: {
			en: 'Weather is deteriorating'
		},
		text: {
			en: 'Heavy showers is approaching.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Stay to stabilize the patient and call MET Office for weather update'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Take off delayed 8 min and 15 NM longer route is suggested from MET Office.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Take-off towards Hospital. Go straight to hospital Low level VFR'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Take off right away. Caution of obstacles.'
					},
					effects: [
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: 1}
					]
				}
			},
			{
				id: 'c',
				text: {
					en: 'Take-off towards Hospital. Go straight to hospital IFR'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Take off right away. Caution of icing / thunderstorms.'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'threat', threatId: 'weather-radar'}, ],
							text: {
								en: 'Take off right away. Caution of icing / thunderstorms.'
							},
							effects: [
								{type: 'crewSafety', value: -1},
								{type: 'compliance', value: -1}
							],
						},
					]
				}
			}
		],
	},

	/* TRANSPORT HOSPITAL */
	{
		id: 21,
		gameStep: 'transport-hospital',
		type: 'replace',
		cardId: 21,
		conditionals: [
			{
				conditions: [{type: 'threat', threatId: 'fatigued-captain'}],
				cardId: 104,
			},
		],
		title: {
			en: 'Possible incident'
		},
		text: {
			en: 'Congratulations. Because you changed the captain in the beginning you avoided a critical event involving fatigue.'
		},
		roundsMax: 0,		
	},
	{
		id: 22,
		gameStep: 'transport-hospital',
		type: 'default',
		title: {
			en: 'Drones in the vicinity'
		},
		text: {
			en: '4 drones are flying near the landing area. They are following a demonstration close by.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue to hospital for landing on Helipad'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'You continue and keep an eye out for drones.'
					},
					effects: [
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: 1},
						{type: 'compliance', value: -1}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Land at nearby football field and coordinate with an ambulance'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You take no chances and request an ambulance to the nearby football field. This will delay the mission 15-20 minutes.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: 1}

					]
				}
			}
		],
	},
	{
		id: 23,
		gameStep: 'transport-hospital',
		type: 'default',
		title: {
			en: 'Patient unstable'
		},
		text: {
			en: 'During flight, the patient seems to be going into shock.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Land to stabilize the patient, and continue towards hospital when patient is stabilized'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'The Patient is stabilized after 10 min.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Continue flight towards hospital'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'The condition of the patient does not seem to change.'
					},
					effects: []
				}
			}
		],
	},
	{
		id: 24,
		gameStep: 'transport-hospital',
		type: 'default',
		title: {
			en: 'GPS'
		},
		text: {
			en: 'RAIM is unavailable and integrity goes to "ALARM".'
		},
		roundsMax: 1,
		noDefaultOption: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Continue to hospital for landing on Helipad'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'Continue towards hospital # 2'
					},
					effects: [],
					conditionals: [
						{
							conditions: [{ type: 'prev-choice', cardId: 18, optionId: 'a' }, ],	
							text: {
								en: 'You are not able to fly the GPS approach.'
							},
							effects: [
								{type: 'crewSafety', value: -1},
								{type: 'compliance', value: -1}
							],
						},
						{
							conditions: [{ type: 'prev-choice', cardId: 18, optionId: 'c' }, ],	
							text: {
								en: 'You are not able to fly the GPS approach.'
							},
							effects: [
								{type: 'crewSafety', value: -1},
								{type: 'compliance', value: -1}
							],
						}
					]
				}
			},
			{
				id: 'b',
				text: {
					en: 'Land at nearby football field and coordinate with an ambulance'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You take no chances and request an ambulance to the nearby football field. This will delay the mission 15-20 minutes.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: 1}
					]
				}
			}
		],
	},


	/* CRITICAL CARDS */
	{
		id: 101,
		title: {
			en: 'AED Battery'
		},
		type: 'critical',
		text: {
			en: 'The AED is now at 42% capacity.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		// isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Get a new AED from storage'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'This takes time. Delay 6 min.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
						{type: 'compliance', value: 1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'No time to change it. Go as it is.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Possibility of not having enough power for defibrilating.'
					},
					effects: [
						{type: 'threat', threatId: 'aed-low-battery'},
						{type: 'missionSuccess', value: -1},
					],
				},
			}
		],
	},
	{
		id: 102,
		title: {
			en: 'AED Battery'
		},
		type: 'critical',
		text: {
			en: 'The AED was plugged in, but for some reason did not recharge. Battery Status 42%.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		// isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Get a new AED from storage'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'This takes time. Delay 6 min.'
					},
					effects: [
						{type: 'missionSuccess', value: 1},
						{type: 'compliance', value: 1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'No time to change it. Go as it is.'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'Possibility of not having enough power for defibrilating.'
					},
					effects: [
						{type: 'threat', threatId: 'aed-low-battery-2'},
						{type: 'missionSuccess', value: -1},
					],
				},
			}
		],
	},
	{
		id: 103,
		title: {
			en: 'Survival suit'
		},
		type: 'critical',
		text: {
			en: 'It seems like there is a possibility of having to go into the water.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		// isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Return and change the suit'
				},
				cost: 2,
				consequences: {
					text: {
						en: 'It takes 7 min to change the suit and return to the helicopter.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: -1},
						{type: 'compliance', value: 1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Continue with damaged suit'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You continue with damaged suit.'
					},
					effects: [
						{type: 'threat', threatId: 'survival-suit-3'},
						{type: 'crewSafety', value: -1},
						{type: 'compliance', value: -1}
					],
				},
			}
		],
	},
	{
		id: 104,
		title: {
			en: 'Fatigue'
		},
		type: 'critical',
		text: {
			en: 'The captain is missing radiocall and does not always read back correctly.'
		},
		roundsMax: 1,
		noDefaultOption: true,
		// isActionCard: true,
		options: [
			{
				id: 'a',
				text: {
					en: 'Stay alert and engage in active communication. Focus on challenge / response'
				},
				cost: 1,
				consequences: {
					text: {
						en: 'You use effective CRM tools.'
					},
					effects: [
						{type: 'crewSafety', value: 1},
						{type: 'missionSuccess', value: 1}
					],
				},
			},
			{
				id: 'b',
				text: {
					en: 'Do nothing. He is probably ok'
				},
				cost: 0,
				consequences: {
					text: {
						en: 'You do not recognize signs of fatigue.'
					},
					effects: [
						{type: 'threat', threatId: 'fatigue'},
						{type: 'crewSafety', value: -1},
						{type: 'missionSuccess', value: -1}
					],
				},
			}
		],
	},
];

module.exports = {eventCards};
