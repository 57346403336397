const gamesArray = [
	'crm-aeroplanes', 'crm-helicopters', 'safetytraining'
];

const gamesData = {
	'crm-aeroplanes': {
		urlPath: '/crm-aeroplanes',
		title: {
			en: 'CRM Training Concept',
			ja: 'CRM訓練コンセプト'
		},
		subtitle: {
			en: 'AEROPLANES',
			ja: '飛行機'
		},
		titleShort: {
			en: 'CRM-A',
			ja: 'CRM-A'
		},
		titleLandingPage: {
			en: 'CRM TRAINING AEROPLANES',
			ja: 'CRM訓練　飛行機'
		},
		titleOverview: {
			en: 'CRM TRAINING - AEROPLANES',
			ja: 'CRM訓練 - 飛行機'
		},
		welcomeText: {
			en: require('data/crm-aeroplanes/texts/welcome_en.md').default,
			ja: require('data/crm-aeroplanes/texts/welcome_ja.md').default,
		},	
		crewInfoText: {
			en: require('data/crm-aeroplanes/texts/crew-info_en.md').default,
			ja: require('data/crm-aeroplanes/texts/crew-info_ja.md').default
		},
		goodbyeText: {
			en: require('data/crm-aeroplanes/texts/goodbye_en.md').default,
			ja: require('data/crm-aeroplanes/texts/goodbye_ja.md').default
		},
		gamePhases: require('data/crm-aeroplanes/game-phases').gamePhases,
		gameSteps: require('data/crm-aeroplanes/game-steps').gameSteps,
		crewData: require('data/crm-aeroplanes/crew-data').crewData,
		reflectionsData: require('data/crm-aeroplanes/reflections-data').reflectionsData,
		scenarios: [
			{
				id: 'bcn-cph',
				title: {
					en: 'BCN-CPH',
					ja: 'BCN-CPH'
				},
				route: 'bcn',
				languageIds: ['en'],
				preBriefing: {
					en: require('data/crm-aeroplanes/bcn/texts/preflight-briefing_en.md').default
				},
				endgameBriefing: {
					en: require('data/crm-aeroplanes/bcn/texts/endgame-briefing_en.md').default
				},
				eventCards: require('data/crm-aeroplanes/bcn/event-cards-data').eventCards,
				threatsData: require('data/crm-aeroplanes/bcn/threats-data').threatsData
			},
			{
				id: 'spu-cph',
				title: {
					en: 'SPU-CPH',
					ja: 'SPU-CPH'
				},
				route: 'spu',
				languageIds: ['en'],
				preBriefing: {
					en: require('data/crm-aeroplanes/spu/texts/preflight-briefing_en.md').default
				},
				endgameBriefing: {
					en: require('data/crm-aeroplanes/spu/texts/endgame-briefing_en.md').default
				},
				eventCards: require('data/crm-aeroplanes/spu/event-cards-data').eventCards,
				threatsData: require('data/crm-aeroplanes/spu/threats-data').threatsData
			},
			{
				id: 'pmi-lhr',
				title: {
					en: 'PMI-LHR',
					ja: 'PMI-LHR'
				},
				route: 'pmi',
				languageIds: ['en'],
				preBriefing: {
					en: require('data/crm-aeroplanes/pmi/texts/preflight-briefing_en.md').default
				},
				endgameBriefing: {
					en: require('data/crm-aeroplanes/pmi/texts/endgame-briefing_en.md').default
				},
				eventCards: require('data/crm-aeroplanes/pmi/event-cards-data').eventCards,
				threatsData: require('data/crm-aeroplanes/pmi/threats-data').threatsData
			},
			{
				id: 'mun-lpa',
				title: {
					en: 'MUN-LPA',
					ja: 'MUN-LPA'
				},
				route: 'mun',
				languageIds: ['en'],
				preBriefing: {
					en: require('data/crm-aeroplanes/mun/texts/preflight-briefing_en.md').default
				},
				endgameBriefing: {
					en: require('data/crm-aeroplanes/mun/texts/endgame-briefing_en.md').default
				},
				eventCards: require('data/crm-aeroplanes/mun/event-cards-data').eventCards,
				threatsData: require('data/crm-aeroplanes/mun/threats-data').threatsData
			},
			{
				id: 'oka-hnd',
				title: {
					en: 'OKA-HND',
					ja: '那覇 - 羽田',
				},
				route: 'oka',
				languageIds: ['en', 'ja'],
				preBriefing: {
					en: require('data/crm-aeroplanes/oka/texts/preflight-briefing_en.md').default,
					ja: require('data/crm-aeroplanes/oka/texts/preflight-briefing_ja.md').default,
				},
				endgameBriefing: {
					en: require('data/crm-aeroplanes/oka/texts/endgame-briefing_en.md').default,
					ja: require('data/crm-aeroplanes/oka/texts/endgame-briefing_ja.md').default
				},
				eventCards: require('data/crm-aeroplanes/oka/event-cards-data').eventCards,
				threatsData: require('data/crm-aeroplanes/oka/threats-data').threatsData
			}
		]
	},
	'crm-helicopters': {
		urlPath: '/crm-helicopters',
		title: {
			en: 'CRM Training Concept'
		},
		subtitle: {
			en: 'HELICOPTER'
		},
		titleShort: {
			en: 'CRM-H'
		},
		titleLandingPage: {
			en: 'CRM TRAINING HELICOPTER'
		},
		titleOverview: {
			en: 'CRM TRAINING - HELICOPTER'
		},
		welcomeText: {
			en: require('data/crm-helicopters/texts/welcome_en.md').default
		},	
		crewInfoText: {
			en: require('data/crm-helicopters/texts/crew-info_en.md').default
		},
		goodbyeText: {
			en: require('data/crm-helicopters/texts/goodbye_en.md').default
		},
		gamePhases: require('data/crm-helicopters/game-phases').gamePhases,
		gameSteps: require('data/crm-helicopters/game-steps').gameSteps,
		crewData: require('data/crm-helicopters/crew-data').crewData,
		reflectionsData: require('data/crm-helicopters/reflections-data').reflectionsData,
		scenarios: [
			{
				id: 'default',
				title: {
					en: 'Default'
				},
				route: 'default',
				languageIds: ['en'],
				eventCards: require('data/crm-helicopters/default/event-cards-data').eventCards,
				threatsData: require('data/crm-helicopters/default/threats-data').threatsData,
				locations: [
					{id: 0, type: 'base', title: {en: 'HQ'}, text: {
						en: require('data/crm-helicopters/default/locations/base_en.md').default
					}},
					{id: 1, type: 'hospital', title: {en: '#1'}, text: {
						en: require('data/crm-helicopters/default/locations/hospital-1_en.md').default
					}},
					{id: 2, type: 'hospital', title: {en: '#2'}, text: {
						en: require('data/crm-helicopters/default/locations/hospital-2_en.md').default
					}},
					{id: 3, type: 'hospital', title: {en: '#3'}, text: {
						en: require('data/crm-helicopters/default/locations/hospital-3_en.md').default
					}},
					{id: 4, type: 'hospital', title: {en: '#4'}, text: {
						en: require('data/crm-helicopters/default/locations/hospital-4_en.md').default
					}}
				],
				briefings: [
					{id: 0, gameStepId: null, text: {
						en: require('data/crm-helicopters/default/briefings/briefing-0_en.md').default
					}},
					{id: 1, gameStepId: 'check-in', text: {
						en: require('data/crm-helicopters/default/briefings/briefing-1_en.md').default
					}},
					{id: 2, gameStepId: 'scramble', text: {
						en: require('data/crm-helicopters/default/briefings/briefing-2_en.md').default
					}},
					{id: 3, gameStepId: 'outbound', text: {
						en: require('data/crm-helicopters/default/briefings/briefing-3_en.md').default
					}},
					{id: 4, gameStepId: 'on-site', text: {
						en: require('data/crm-helicopters/default/briefings/briefing-4_en.md').default
					}},
					{id: 5, gameStepId: 'transport-hospital', text: {
						en: require('data/crm-helicopters/default/briefings/briefing-5_en.md').default
					}}
				]
			},
		]
	},
	'safetytraining': {
		urlPath: '/humanfactorstraining',
		title: {
			en: 'HUMAN FACTOR TRAINING',
			de: 'HUMAN FACTOR TRAINING'
		},
		subtitle: null,
		titleShort: {
			en: 'Human Factor',
			de: 'Human Factor',
		},
		titleLandingPage: {
			en: 'HUMAN FACTOR TRAINING MAINTENANCE',
			de: 'HUMAN FACTOR TRAINING MAINTENANCE'
		},
		titleOverview: {
			en: 'HUMAN FACTOR TRAINING - MAINTENANCE',
			de: 'HUMAN FACTOR TRAINING - MAINTENANCE',
		},
		welcomeText: {
			en: require('data/safetytraining/texts/welcome_en.md').default,
			de: require('data/safetytraining/texts/welcome_de.md').default
		},
		crewInfoText: {
			en: require('data/safetytraining/texts/crew-info_en.md').default,
			de: require('data/safetytraining/texts/crew-info_de.md').default,
		},
		crewFeedbackText: {
			en: require('data/safetytraining/texts/crew-feedback_en.md').default,
			de: require('data/safetytraining/texts/crew-feedback_de.md').default
		},
		ddReflectionText: {
			en: require('data/safetytraining/texts/dd-reflection_en.md').default,
			de: require('data/safetytraining/texts/dd-reflection_de.md').default
		},
		finalReflectionText: {
			en: require('data/safetytraining/texts/final-reflection_en.md').default,
			de: require('data/safetytraining/texts/final-reflection_de.md').default,
		},
		finalResultsText: {
			en: require('data/safetytraining/texts/final-results_en.md').default,
			de: require('data/safetytraining/texts/final-results_de.md').default,
		},
		bestPracticesText: {
			en: require('data/safetytraining/texts/best-practices_en.md').default,
			de: require('data/safetytraining/texts/best-practices_de.md').default
		},
		goodbyeText: {
			en: require('data/safetytraining/texts/goodbye_en.md').default,
			de: require('data/safetytraining/texts/goodbye_de.md').default
		},
		gamePhases: require('data/safetytraining/game-phases').gamePhases,
		gameSteps: require('data/safetytraining/game-steps').gameSteps,
		crewData: require('data/safetytraining/crew-data').crewData,
		reflectionsData: require('data/safetytraining/reflections-data').reflectionsData,
		bestPracticesData: require('data/safetytraining/best-practices-data').bestPracticesData,
		scenarios: [
			{
				id: 'default',
				title: {
					en: 'Part 145 Case 1',
					de: 'Partl 145 Fall 1'
				},
				route: 'default',
				languageIds: ['en', 'de'],
				preBriefing: null,
				daysData: require('data/safetytraining/default/days-data').daysData,
				day1Text: {
					en: require('data/safetytraining/default/intros/day-1-intro_en.md').default,
					de: require('data/safetytraining/default/intros/day-1-intro_de.md').default
				},
				day2Text: {
					en: require('data/safetytraining/default/intros/day-2-intro_en.md').default,
					de: require('data/safetytraining/default/intros/day-2-intro_de.md').default
				},
				day3Text: {
					en: require('data/safetytraining/default/intros/day-3-intro_en.md').default,
					de: require('data/safetytraining/default/intros/day-3-intro_de.md').default
				},
				day4Text: {
					en: require('data/safetytraining/default/intros/day-4-intro_en.md').default,
					de: require('data/safetytraining/default/intros/day-4-intro_de.md').default
				}
			},
			{
				id: 'new-tasks',
				title: {
					en: 'Part 145 Case 2',
					de: 'Part 145 Fall 2'
				},
				route: 'new-tasks',
				languageIds: ['en', 'de'],
				preBriefing: null,
				daysData: require('data/safetytraining/new-tasks/days-data').daysData,
				day1Text: {
					en: require('data/safetytraining/new-tasks/intros/day-1-intro_en.md').default,
					de: require('data/safetytraining/new-tasks/intros/day-1-intro_de.md').default
				},
				day2Text: {
					en: require('data/safetytraining/new-tasks/intros/day-2-intro_en.md').default,
					de: require('data/safetytraining/new-tasks/intros/day-2-intro_de.md').default
				},
				day3Text: {
					en: require('data/safetytraining/new-tasks/intros/day-3-intro_en.md').default,
					de: require('data/safetytraining/new-tasks/intros/day-3-intro_de.md').default
				},
				day4Text: {
					en: require('data/safetytraining/new-tasks/intros/day-4-intro_en.md').default,
					de: require('data/safetytraining/new-tasks/intros/day-4-intro_de.md').default
				}
			},
			{
				id: 'light-one',
				title: {
					en: 'Maintenance Case 1',
					de: 'Maintenance Fall 1'
				},
				route: 'light-one',
				languageIds: ['en', 'de'],
				preBriefing: null,
				daysData: require('data/safetytraining/light-one/days-data').daysData,
				day1Text: {
					en: require('data/safetytraining/light-one/intros/day-1-intro_en.md').default,
					de: require('data/safetytraining/light-one/intros/day-1-intro_de.md').default
				},
				day2Text: {
					en: require('data/safetytraining/light-one/intros/day-2-intro_en.md').default,
					de: require('data/safetytraining/light-one/intros/day-2-intro_de.md').default
				},
				day3Text: {
					en: require('data/safetytraining/light-one/intros/day-3-intro_en.md').default,
					de: require('data/safetytraining/light-one/intros/day-3-intro_de.md').default
				},
				day4Text: {
					en: require('data/safetytraining/light-one/intros/day-4-intro_en.md').default,
					de: require('data/safetytraining/light-one/intros/day-4-intro_de.md').default
				}
			},
			{
				id: 'light-two',
				title: {
					en: 'Maintenance Case 2',
					de: 'Maintenance Fall 2'
				},
				route: 'light-two',
				languageIds: ['en', 'de'],
				preBriefing: null,
				daysData: require('data/safetytraining/light-two/days-data').daysData,
				day1Text: {
					en: require('data/safetytraining/light-two/intros/day-1-intro_en.md').default,
					de: require('data/safetytraining/light-two/intros/day-1-intro_de.md').default
				},
				day2Text: {
					en: require('data/safetytraining/light-two/intros/day-2-intro_en.md').default,
					de: require('data/safetytraining/light-two/intros/day-2-intro_de.md').default
				},
				day3Text: {
					en: require('data/safetytraining/light-two/intros/day-3-intro_en.md').default,
					de: require('data/safetytraining/light-two/intros/day-3-intro_de.md').default
				},
				day4Text: {
					en: require('data/safetytraining/light-two/intros/day-4-intro_en.md').default,
					de: require('data/safetytraining/light-two/intros/day-4-intro_de.md').default
				}
			},
		]
	}
};

module.exports = {gamesArray, gamesData};