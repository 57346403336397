import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import SelectSearch from 'react-select-search';
import { settingsUiTexts } from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import { decisionToolsData, communicationToolsData, exerciseToolsData } from 'data/tools-data';
import { languagesData } from 'data/languages-data';
import {getText} from 'helpers/language-helper';
import { handlePrintGameInfo } from 'helpers/print-helper';
import SettingsHeader from './settings-header';
import Select from 'components/select/select';
import Button from 'components/button/button';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './create-game.scss';

const CreateGame = (props) => {
	let {
		isCreatingGame,
		userData,
		companiesData,
		gameType,
		errMsg,
		game,
		users,
		groups,
		toggleCreateGamePage,
		createGame,
		handleLogout,
	} = props;

	/* Get created by if game was created by another facilitator */
	let createdBy = null;
	if (game && userData.isAdmin) {
		let createdByData = users.find((u) => {return u.id === game.facilitatorId;});
		if (createdByData) createdBy = createdByData.email;
	}

	/* Check if started */
	let isStarted = game?.isStarted ?? false;

	/* Get selected company */
	let [companyId, setCompanyId] = useState(game ? game.companyId : companiesData[0].id);
	let companyData = companiesData.find((c) => {return c.id === companyId;});

	/* Get available scenarios */
	let companyGameAccessData = (companyData && companyData.games
		? companyData.games.find((game) => {return game.id === gameType;})
		: null
	);
	let scenariosData = [];
	if (companyGameAccessData && gamesData[gameType].scenarios) {
		if (gamesData[gameType].scenarios.length > 0) {
			gamesData[gameType].scenarios.forEach((scenario) => {
				let scenarioObj = {...scenario, isDisabled: true};
				if (companyGameAccessData.scenarios && companyGameAccessData.scenarios.some((s) => {
					return (scenario.id === s.id && s.enabled === true);
				})) {scenarioObj.isDisabled = false;}
				scenariosData.push(scenarioObj);
			});
		}
	}

	/* Track if changes have been made */
	let [isEditing, setIsEditing] = useState(false);

	/* Track if saving */
	let [isSaving, setIsSaving] = useState(isCreatingGame);
	useEffect(() => {
		if (isSaving === true && isCreatingGame === false) {
			setIsEditing(false);
		}
		setIsSaving(isCreatingGame);
	}, [isSaving, isCreatingGame]);

	/* Validations */
	const clearedValidations = {title: {show: false, msg: ''}};
	const [validations, setValidations] = useState({...clearedValidations});
	const clearValidations = () => {setValidations({...clearedValidations});};

	/* Title */
	let [gameTitle, setGameTitle] = useState(game ? game.title : '');
	const updateGameTitle = (event) => {
		if (isStarted) return;
		setGameTitle(event.target.value);
		setIsEditing(true);
	};

	/* Date */
	let [gameDate, setGameDate] = useState(
		game ? game.playDate : dayjs(new Date()).format('YYYY-MM-DD')
	);
	const updateGameDate = (value) => {
		if (isStarted) return;
		setGameDate(value);
		setIsEditing(true);
	};

	/* Scenario */
	const defaultScenarioId = scenariosData[0].id;
	let [scenarioId, setGameScenarioId] = useState(game ? game.scenario : defaultScenarioId);
	const updateGameScenarioId = (value) => {
		if (isStarted) return;
		setGameScenarioId(value);
		setIsEditing(true);
	};

	/* Decision tool */
	const defaultDecisionToolId = decisionToolsData[0].id;
	let [decisionToolId, setDecisionToolId] = useState(game ? game.decisionTool : defaultDecisionToolId);
	const updateDecisionToolId = (value) => {
		if (isStarted) return;
		setDecisionToolId(value);
		setIsEditing(true);
	};

	/* Communication tool */
	const defaultCommunicationToolId = communicationToolsData[0].id;
	let [communicationToolId, setCommunicationToolId] = 
		useState(game ? game.communicationTool : defaultCommunicationToolId );
	const updateCommunicationToolId = (value) => {
		if (isStarted) return;
		setCommunicationToolId(value);
		setIsEditing(true);
	};

	/* Exercise tool */
	const defaultExerciseToolId = exerciseToolsData[0].id;
	let [exerciseToolId, setExerciseToolId] = useState(game ? game.exerciseTool : defaultExerciseToolId );
	const updateExerciseToolId = (value) => {
		if (isStarted) return;
		setExerciseToolId(value);
		setIsEditing(true);
	};

	/* Language */
	const defaultLanguageId = userData.languageId;
	let [languageId, setLanguageId] = useState(game ? game.languageId : defaultLanguageId );
	const updateLanguageId = (value) => {
		if (isStarted) return;
		setLanguageId(value);
		setIsEditing(true);
	};


	/* Auto-update scenario if new company is selected */
	useEffect(() => {
		const currentScenarioData = scenariosData.find((s) => {return s.id === scenarioId;});
		if (currentScenarioData && currentScenarioData.isDisabled === true) {
			let newScenarioData = scenariosData.find((s) => {return s.isDisabled === false;});
			if (newScenarioData) setGameScenarioId(newScenarioData.id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);

	/* Auto-update language id if new scenario is selected */
	useEffect(() => {
		const currentScenarioData = scenariosData.find((s) => {return s.id === scenarioId;});
		if (
			currentScenarioData && 
			currentScenarioData.languageIds && 
			currentScenarioData.languageIds.indexOf(languageId) < 0
		) {
			setLanguageId(currentScenarioData.languageIds[0]);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scenarioId]);

	/* Game groups & available players */
	let [gameGroups, setGameGroups] = useState(() => {
		let gGroups = [{ players: [] },	{ players: [] }];
		if (game) gGroups = [];
		return gGroups;
	});
	const getAvailablePlayers = (companyData) => {
		let aPlayers = companyData.players ? companyData.players : [];
		aPlayers.forEach((player) => {player.value = player.id;});
		if (groups.length > 0) {
			let allSelectedPlayers = [];
			groups.forEach((group) => {
				if (group.players && group.players.length > 0) {
					allSelectedPlayers = allSelectedPlayers.concat(group.players);
				}
			});
			aPlayers = aPlayers.filter((player) => {
				return (!allSelectedPlayers.some((p) => {return p.id === player.id;}));
			});
		}
		return aPlayers;
	};
	let [availablePlayers, setAvailablePlayers] = useState(() => {return getAvailablePlayers(companyData);});
	useEffect(() => {
		if (groups.length > 0 && gameGroups.length === 0) {
			let allSelectedPlayers = [];
			let newGameGroups = groups.map((group) => {
				if (group.players && group.players.length > 0) {
					allSelectedPlayers = allSelectedPlayers.concat(group.players);
				}
				return { id: group.id, players: group.players };
			});
			let newAvailablePlayers = availablePlayers.filter((player) => {
				return (!allSelectedPlayers.some((p) => {return p.id === player.id;}));
			});
			setGameGroups(newGameGroups);
			setAvailablePlayers(newAvailablePlayers);
		}
	}, [availablePlayers, gameGroups.length, groups]);


	/* Update number of groups */
	const updateNumberOfGroups = (event) => {
		if (isStarted) return;
		let numberOfGroups = event.target.value;
		if (numberOfGroups < appConfig.minNumberOfGroups) return;
		let groupsArray = JSON.parse(JSON.stringify(gameGroups));
		/* Add groups */
		if (groupsArray.length < numberOfGroups) {
			groupsArray = groupsArray.concat(
				Array.from({ length: numberOfGroups - groupsArray.length }, () => {
					return { players: [] };
				})
			);
		}
		/* Remove groups */
		if (groupsArray.length > numberOfGroups) {
			groupsArray.splice(numberOfGroups);
		}
		setGameGroups(groupsArray);
		setIsEditing(true);
	};


	/* Add or remove groups for tablet */
	const addRemoveGroups = (num) => {
		if (isStarted) return;
		let numberOfGroups = num;
		if (numberOfGroups < appConfig.minNumberOfGroups) return;
		let groupsArray = JSON.parse(JSON.stringify(gameGroups));
		/* Add groups */
		if (groupsArray.length < numberOfGroups) {
			groupsArray = groupsArray.concat(
				Array.from({ length: numberOfGroups - groupsArray.length }, () => {
					return { players: [] };
				})
			);
		}
		/* Remove groups */
		if (groupsArray.length > numberOfGroups) {
			groupsArray.splice(numberOfGroups);
		}
		setGameGroups(groupsArray);
		setIsEditing(true);
	};

	/* Delete group */
	const deleteGroup = (groupIndex) => {
		if (isStarted) return;
		if (gameGroups.length > appConfig.minNumberOfGroups) {
			let groupsArray = JSON.parse(JSON.stringify(gameGroups));
			let players = groupsArray[groupIndex].players;
			if (players && players.length > 0) {
				let newAvailablePlayers = [...availablePlayers];
				players.forEach((player) => {
					let playerObj = {...player, value: player.id};
					newAvailablePlayers.push(playerObj);
				});
				setAvailablePlayers(newAvailablePlayers);
			} 
			groupsArray.splice(groupIndex, 1);
			setGameGroups(groupsArray);
			setIsEditing(true);
		}
	};

	/* Add group member */
	const addGroupMember = (groupIndex, playerId) => {
		if (isStarted) return;
		let newAvailablePlayers = [...availablePlayers];
		let playerIndex = newAvailablePlayers.findIndex((ap) => {return ap.id === playerId;});
		if (playerIndex >= 0) {
			let playerObj = {id: newAvailablePlayers[playerIndex].id, name: newAvailablePlayers[playerIndex].name};
			newAvailablePlayers.splice(playerIndex, 1);
			let groupsArray = JSON.parse(JSON.stringify(gameGroups));
			groupsArray[groupIndex].players.push(playerObj);
			setAvailablePlayers(newAvailablePlayers);
			setGameGroups(groupsArray);
			setIsEditing(true);
		}		
	};

	/* Delete group member */
	const deleteGroupMember = (groupIndex, playerIndex) => {
		if (isStarted) return;
		let groupsArray = JSON.parse(JSON.stringify(gameGroups));
		let playerObj = groupsArray[groupIndex].players[playerIndex];
		playerObj.value = playerObj.id;
		groupsArray[groupIndex].players.splice(playerIndex, 1);
		let newAvailablePlayers = [...availablePlayers];
		newAvailablePlayers.push(playerObj);
		setAvailablePlayers(newAvailablePlayers);
		setGameGroups(groupsArray);
		setIsEditing(true);
	};

	/* Update company id */
	const updateCompanyId = (value) => {
		if (isStarted || value === companyId) return;

		setCompanyId(value);

		let gGroups = [{ players: [] },	{ players: [] }];
		setGameGroups(gGroups);

		let newCompanyData = companiesData.find((c) => {return c.id === value;});
		setAvailablePlayers(getAvailablePlayers(newCompanyData));

		setIsEditing(true);
	};

	/* Verify and create game */
	const handleCreateGame = () => {
		clearValidations();
		if (isSaving || isStarted) return;
		if (
			gameTitle.length > 0 &&
			gameTitle.length <= appConfig.gameTitleMaxLength &&
			gameDate.length > 0 &&
			gameGroups.length >= appConfig.minNumberOfGroups
		) {
			let gameId = game ? game.id : null;
			createGame(
				companyId,
				gameId,
				gameTitle,
				gameDate,
				scenarioId,
				decisionToolId,
				communicationToolId,
				exerciseToolId,
				languageId,
				gameGroups
			);
		}
		if (gameTitle.length === 0) {
			setValidations({...validations, title: 
				{ 
					show: true, 
					msg: getText(settingsUiTexts.missingGameTitle, userData.languageId)
				}
			});
			setTimeout(() => {clearValidations();}, 3000);
		}

		if (gameTitle.length > appConfig.gameTitleMaxLength) {
			let msg = JSON.parse(JSON.stringify(getText(settingsUiTexts.gameTitleMaxLength, userData.languageId)));
			msg = msg.replace(/%chars%/g, appConfig.gameTitleMaxLength);
			setValidations({...validations, title: { show: true, msg: msg}});
			setTimeout(() => {clearValidations();}, 3000);
		}
	};

	let [showPrintWarning, setShowPrintWarning] = useState(false);
	const handleShowPrintWarning = () => {
		setShowPrintWarning(true);
		setTimeout(() => {setShowPrintWarning(false);}, 3000);
	};

	/* Available languages */
	const selectedScenarioData = scenariosData.find((s) => {return s.id === scenarioId;});
	const langaugeOptions = languagesData.filter((language) => {
		return (
			selectedScenarioData && 
			selectedScenarioData.languageIds && 
			selectedScenarioData.languageIds.indexOf(language.id) >= 0
		);
	});

	return (
		<div className="CreateGame">
			<SettingsHeader
				languageId={userData.languageId}
				page='create-game'
				gameType={gameType}
				email={userData.email}
				title={(game 
					? getText(settingsUiTexts.editGame, userData.languageId) 
					: getText(settingsUiTexts.createGame, userData.languageId)
				)}
				handleLogout={handleLogout}
			>
				<Button
					classes={['home']}
					text={getText(settingsUiTexts.home, userData.languageId)}
					onClick={toggleCreateGamePage}
				/>
			</SettingsHeader>

			<div className="CreateGame-errMsg">{errMsg}</div>

			{/* Created by */}
			{createdBy && 
				<div className="CreateGame-createdBy">
					<span>{getText(settingsUiTexts.createdBy, userData.languageId)}:</span>
					<span>{createdBy}</span>
				</div>
			}

			
			<div className="CreateGame-section">
				{/* Game company */}
				<div className="CreateGame-subsection company">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.company, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						<Select
							isDisabled={isStarted}
							options={companiesData}
							defaultId={companyId}
							selectedId={companyId}
							onSelect={updateCompanyId}
						/>
					</div>
				</div>
				{/* Game title */}
				<div className="CreateGame-subsection title">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.gameTitle, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						{validations.title.show && <p className="CreateGame-validations">{validations.title.msg}</p>}
						<input
							disabled={isStarted}
							name="gameTitle"
							type="text"
							placeholder={getText(settingsUiTexts.gameTitlePlaceholder, userData.languageId)}
							value={gameTitle ? gameTitle : ''}
							onFocus={() => { clearValidations(); }}
							onChange={(event) => {updateGameTitle(event);}}
						/>
					</div>
				</div>
				{/* Game date */}
				<div className="CreateGame-subsection date">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.gameDate, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						<DatePicker
							disabled={isStarted}
							selected={gameDate ? dayjs(gameDate).toDate() : new Date()}
							onChange={(date) => {
								updateGameDate(dayjs(date).format('YYYY-MM-DD'));
							}}
							dateFormat="yyyy-MM-dd"
							placeholderText="yyyy-mm-dd"
							selectsStart
						/>
					</div>
				</div>
				
			</div>



			{/* Game config */}
			<div className="CreateGame-section">
				
				{/* Scenario type */}
				<div className="CreateGame-subsection">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.scenario, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						<Select
							isDisabled={isStarted}
							options={scenariosData}
							defaultId={defaultScenarioId}
							selectedId={scenarioId}
							onSelect={updateGameScenarioId}
						/>
					</div>
				</div>
				{/* Language */}
				<div className="CreateGame-subsection language">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.language, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						<Select
							isDisabled={isStarted}						
							options={langaugeOptions}
							defaultId={defaultLanguageId }
							selectedId={languageId}
							onSelect={updateLanguageId}
						/>
					</div>
				</div>
				{/* Decision making tool */}
				{( gameType !== 'safetytraining' && gameType !== 'crm-helicopters' ) && 
				<div className="CreateGame-subsection">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.decisionTool, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						<Select
							isDisabled={isStarted}
							options={decisionToolsData}
							defaultId={defaultDecisionToolId}
							selectedId={decisionToolId}
							onSelect={updateDecisionToolId}
						/>
					</div>
				</div>}
				{/* Communication tool */}
				{( gameType !== 'safetytraining' && gameType !== 'crm-helicopters' ) && 
				<div className="CreateGame-subsection">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.communicationTool, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						<Select
							isDisabled={isStarted}						
							options={communicationToolsData}
							defaultId={defaultCommunicationToolId }
							selectedId={communicationToolId}
							onSelect={updateCommunicationToolId}
						/>
					</div>
				</div>}
				{/* Exercise tool */}
				{( gameType === 'crm-helicopters' ) && 
				<div className="CreateGame-subsection">
					<div className="CreateGame-subsectionHeader">
						{getText(settingsUiTexts.exerciseTool, userData.languageId)}
					</div>
					<div className="CreateGame-subsectionBody">
						<Select
							isDisabled={isStarted}						
							options={exerciseToolsData}
							defaultId={defaultExerciseToolId }
							selectedId={exerciseToolId}
							onSelect={updateExerciseToolId}
						/>
					</div>
				</div>}
				{/* Create game btn */}
				{!isStarted && <div className="CreateGame-subsection button">
					<div className="CreateGame-createBtn">
						<Button
							isDisabled={!isEditing}
							classes={[game ? 'updateGame2' : 'createGame2']}
							isLoading={isSaving}
							text={game 
								? getText(settingsUiTexts.save, userData.languageId) 
								: getText(settingsUiTexts.create, userData.languageId)
							}
							onClick={handleCreateGame}
						/>
					</div>
				</div>}
			</div>

			{/* Groups */}
			<div className="CreateGame-section groups">
				{/* Number of groups */}
				<div className="CreateGame-sectionHeader">
					<div className="CreateGame-numberOfGroups">
						<span>{(isStarted 
							? getText(settingsUiTexts.groups, userData.languageId) 
							: getText(settingsUiTexts.numberOfGroups, userData.languageId)
						)}</span>
						{!isStarted && 
							<div className="CreateGame-setNumberOfGroups">
								<span 
									className="CreateGame-numberOfGroups--remove" 
									onClick={()=>{addRemoveGroups(gameGroups ? gameGroups.length - 1 : 0);}}
								>-</span>
								<input
									disabled={isStarted}
									name="numberOfGroups"
									type="number"
									min={appConfig.minNumberOfGroups}
									value={gameGroups ? gameGroups.length : ''}
									onChange={(event) => {updateNumberOfGroups(event);}}
								/>
								<span 
									className="CreateGame-numberOfGroups--add" 
									onClick={()=>{addRemoveGroups(gameGroups ? gameGroups.length + 1 : 0);}}
								>+</span>
							</div>}
					</div>
					<div className="CreateGame-printGroups">
						{showPrintWarning && 
							<div className="CreateGame-printGroupsWarning">
								{getText(settingsUiTexts.saveBeforePrint, userData.languageId)}
							</div>
						}
						<div 
							className="CreateGame-printGroupsBtn"
							onClick={() => {
								if (game) {
									handlePrintGameInfo(
										game.title, 
										userData.email, 
										game.playDate, 
										gameGroups, 
										companyData
									);
								} else {
									handleShowPrintWarning();
								}
							}}
						/>
					</div>
				</div>
				<div className="CreateGame-sectionBody">
					{gameGroups.map((group, groupIndex) => {
						/* Group players */
						const canDeleteGroup = gameGroups.length > appConfig.minNumberOfGroups;
						return (
							<div key={groupIndex} className="CreateGame-subsection group">
								<div className="CreateGame-subsectionHeader">
									<span className="groupLabel">
										{getText(settingsUiTexts.group, userData.languageId)}
									</span>
									<span>{groupIndex + 1}</span>
									{!isStarted && <div
										className={'CreateGame-deleteGroupBtn' + (canDeleteGroup ? '' : ' inactive')}
										onClick={() => {deleteGroup(groupIndex);}}
									/>}
								</div>
								<div className="CreateGame-subsectionBody">
									{!isStarted && <SelectSearch 
										options={availablePlayers} 
										value="" 
										name="player" 
										search={true}
										placeholder={
											getText(settingsUiTexts.startTypingPlayerName, userData.languageId)
										}
										emptyMessage={() => {
											return (
												<span className="CreateGame-noMatchingPlayers">
													{(availablePlayers.length > 0 
														? getText(settingsUiTexts.noMatchingNames, userData.languageId) 
														: getText(settingsUiTexts.allNamesAdded, userData.languageId)
													)}
												</span>
											);
										}}
										onChange={(valueProps) => {addGroupMember(groupIndex, valueProps);}}
									/>}
									{group.players.map((player, playerIndex) => {
										/* Only show players that are on the company player list */
										if (companyData.players && companyData.players.some((p) => {
											return p.id === player.id;
										})) {
											return (
												<div key={playerIndex} className="CreateGame-player">
													<div className={'CreateGame-playerName' + 
														(isStarted ? ' started' : '')}>{player.name} ({player.id})</div>
													{!isStarted && <div 
														className="CreateGame-playerDeleteBtn" 
														onClick={() => {deleteGroupMember(groupIndex, playerIndex);}}
													/>}
												</div>
											);
										}
										return null;
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

CreateGame.propTypes = {
	isCreatingGame: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	companiesData: PropTypes.array.isRequired,
	gameType: PropTypes.string.isRequired,
	game: PropTypes.object,
	users: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	errMsg: PropTypes.string,
	toggleCreateGamePage: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default CreateGame;
