import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import BestPractices from './best-practices';
import InfoPopup from 'components/info-popup/info-popup';

class BestPracticesController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoPopup: false,
			selectedCount:0,
		};
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		if (!this.props.isFacilitator) {
			/* Show info popup */
			const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
				return step.id === this.props.group.gameStep;
			});
			if (
				gameStepData.infoPopup && 
				gameStepData.infoPopup.autoShow === true && 
				this.props.group.bestPracticesCardsConfirmed !== true
			) {
				this.setState({showInfoPopup: true});
			}
		}

		/* Get selected cards */
		if (this.props.group.gameStep === 'best-practices') {
			let count = 0;
			if (this.props.group.bestPracticesCards) count = this.props.group.bestPracticesCards.length;
			this.setState({selectedCount: count});
		}
	};

	/**
	 * Component did update (new props)
	 * @param {object} prevProps 
	 */
	componentDidUpdate(prevProps) {
		if (
			JSON.stringify(this.props.group.bestPracticesCards) !== JSON.stringify(prevProps.group.bestPracticesCards)
		) {
			this.setState({selectedCount: this.props.group.bestPracticesCards.length});
		}
	}

	/**
	 * Hide / show briefing popup
	 */
	toggleInfoPopup = (show = null) => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (show !== null) showInfoPopup = show;
		this.setState({showInfoPopup});
	};

	/**
	 * Confirm best Practices Cards chosen
	 */
	confirmBestPracticesCards = () => {
		this.props.updateGroup({
			bestPracticesCardsConfirmed: true,
		});
	};

	/**
	 * Toggle Best Practices Selection
	 */
	toggleSelection = (card) => {
		let bestPracticesData = gamesData[this.props.game.type].bestPracticesData.filter((bestPractice) => {
			return bestPractice.scenarioIds.includes(this.props.game.scenario);
		});
		let count = 0;
		let newCardsArray = JSON.parse(JSON.stringify(this.props.group.bestPracticesCards));
		bestPracticesData.forEach((item) => {
			if (newCardsArray) {
				newCardsArray.forEach((groupCard) => {
					if ( groupCard.id === item.id ) {
						count = count + 1;
					}
				});
			} 
		});
		this.setState({selectedCount: count});

		if (newCardsArray.length === 0) {
			newCardsArray.push({id:card.id});
		} else if (newCardsArray.length === 1) {
			if (newCardsArray[0].id === card.id) {
				newCardsArray = [];
			} else {
				newCardsArray.push({id:card.id});
			}
		} else if (newCardsArray.length === 2) {
			newCardsArray.forEach((groupCard) => {
				if (groupCard.id === card.id) {
					newCardsArray = newCardsArray.filter((filteredCard) => { 
						return filteredCard.id !== card.id; 
					});
				} 
			});
		}

		this.props.updateGroup({
			bestPracticesCards: [...newCardsArray]
		});
	};

	/**
	 * Type answer to questions
	 * @param {object} event 
	 */
	handleInputText = (event) => {
		let answerId = parseInt(event.target.name);
		let answerValue = event.target.value; 
		let reflectionAnswers = [];
		if (this.props.group.reflectionAnswers) {
			reflectionAnswers = JSON.parse(JSON.stringify(this.props.group.reflectionAnswers));
		}
		let answerIndex = reflectionAnswers.findIndex((answer) => {
			return (answer.gameStep === this.props.group.gameStep && answer.id === answerId);
		});
		this.handleUpdateReflectionAnswers(answerIndex, answerId, answerValue);
	};

	/**
	 * Update reflection answers
	 * @param {number} answerIndex 
	 * @param {number} id 
	 * @param {any} value 
	 */
	handleUpdateReflectionAnswers = (answerIndex, id, value) => {
		let reflectionAnswers = [];
		if (this.props.group.reflectionAnswers) {
			reflectionAnswers = JSON.parse(JSON.stringify(this.props.group.reflectionAnswers));
		}
		
		if (answerIndex >= 0) {
			/* Question already answered, update answer */
			reflectionAnswers[answerIndex].value = value;
		} else {
			/* First time question is answered*/
			reflectionAnswers.push({gameStep: this.props.group.gameStep, id: id, value: value});
		}
		this.props.updateGroup({reflectionAnswers: reflectionAnswers});
	};

	/**
	 * Render component
	 */
	render() {
		return (
			<React.Fragment>
				<BestPractices
					game={this.props.game}
					group={this.props.group}
					handleGoToPage={this.props.handleGoToPage}
					confirmAndContinue={this.props.confirmAndContinue}
					scenario={this.props.game.scenario}
					toggleInfoPopup={this.toggleInfoPopup}
					updateGroup={this.props.updateGroup}
					toggleSelection={this.toggleSelection}
					confirmBestPracticesCards={this.confirmBestPracticesCards}
					selectedCount={this.state.selectedCount}
					handleInputText={this.handleInputText}
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={this.props.group.gameStep}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}	
			</React.Fragment>
		);
	}
}

BestPracticesController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default BestPracticesController;
